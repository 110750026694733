import React, { useContext } from "react";
import { Stores, StoresContext, DomainStores } from "../../Stores";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

import iconContactByPhone from "../../Content/icon-contact-byPhone.svg";
import iconContactByChat from "../../Content/icon-contact-viaChat.svg";
import iconContactByEmail from "../../Content/icon-contact-byEmail.svg";

import { ParcelBox, ParcelContactBox } from "./ParcelNotFoundStyles";
import { TitleHeader } from "Shoothill.Components/Styles/MyRiskStyles";
import {
    ContactBoxRow,
    ContactRowBox
} from "Shoothill.Components/Styles/ContactStyling";
import { useMediaQuery } from "@material-ui/core";
import {
	isNullorWhiteSpace
} from "Shoothill.Core/Utils";

declare var purechatApi: any;

interface IProps {
    isError: boolean;
}

export const ParcelNotFound: React.FC<IProps> = props => {
    const mapStoresContext: DomainStores["MapStore"] = useContext<Stores>(
        StoresContext
    ).domain.MapStore;

    const { isError } = props;

    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    return (
        <>
            <ParcelBox className="parcelNotFoundBox">
                <TitleHeader className=" header">Your location</TitleHeader>
                <h2>{mapStoresContext.address}</h2>
                <p>
                { !isError && 
                    <>
                    We can't find any details for this address please contact us
                    so that we can help you further...
                    </>
                }
                { isError && 
                    <>
                    An unexpected error has occured whilst trying to process your
                    request, please try again later or contact us below if the
                    problem persists.
                    </>
                }

                </p>
            </ParcelBox>

            {!isMobile && (
                <>
                    <ParcelContactBox>
                        <div className="card">
                            <img
                                src={iconContactByPhone}
                                alt={"Contact by phone"}
                            />
                            <h2>By phone</h2>
                            <h3>(833) 723-7322</h3>
                        </div>

                        <div className="card">
                            <img
                                src={iconContactByChat}
                                alt={"Contact by chat"}
                            />
                            <h2>Via chat</h2>
                            <h3>Chat now</h3>
                        </div>

                        <Link
                            component={RouterLink}
                            to="/contact#contactform"
                            title="Contact us via email"
                            className="card"
                        >
                            <img
                                src={iconContactByEmail}
                                alt={"Contact by email"}
                            />
                            <h2>By email</h2>
                            <h3>info@myfloodrisk.org</h3>
                        </Link>
                    </ParcelContactBox>
                </>
            )}

            {isMobile && (
                <>
                    <ContactBoxRow>
                        <ContactRowBox>
                            <div className="ContactIconBox">
                                <img
                                    src={iconContactByPhone}
                                    alt={"By phone"}
                                />
                            </div>
                            <div className="grouped">
                                <h2 className="full">By phone</h2>
                                <p className=" wiz">(833) 723-7322</p>
                            </div>
                        </ContactRowBox>
                        <ContactRowBox
                            onClick={() =>
                                purechatApi.set("chatbox.expanded", true)
                            }
                            style={{ cursor: "pointer" }}
                        >
                            <div className="ContactIconBox">
                                <img src={iconContactByChat} alt={"Via Chat"} />
                            </div>
                            <div className="grouped">
                                <h2>Via Chat</h2>
                                <p className=" wiz">Chat now</p>
                            </div>
                        </ContactRowBox>
                        <Link
                            component={RouterLink}
                            to="/contact#contactform"
                            title="Contact us via email"
                            className="card"
                        >
                            <ContactRowBox>
                                <div className="ContactIconBox">
                                    <img
                                        src={iconContactByEmail}
                                        alt={"By email"}
                                    />
                                </div>
                                <div className="grouped">
                                    <h2>By email</h2>
                                    <p className=" wiz">info@myfloodrisk.org</p>
                                </div>
                            </ContactRowBox>
                        </Link>
                    </ContactBoxRow>
                </>
            )}
        </>
    );
};
