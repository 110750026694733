import styled from "styled-components";
import ArrowDown from "../../Content/ArrowDown.svg";
import { Box} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

export const useRefLibStyles: any = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  error: {
    color: "#ea2828",
    textAlign: "left",
    fontSize: "13px",
    position: "absolute",
    top: "-2px"
  },
  form: {
    width: "100%"
  },
  titleBlue: {
    color: "#4190CA",
    fontWeight: "bold"
  },
  headingBlue: {
    color: "#4190CA",
    fontSize: "52px",
    "@media (max-width: 1024px)": {
      fontSize: "32px",
      lineHeight: "1"
    },
    opacity: 1,
    paddingTop: "30px",
    fontWeight: "bold"
  },
  colorBlack: {
    color: "#000000"
  },
  rootMargin: {
    /* flexGrow: 1, */
    paddingTop: "30px",
    marginBottom: "30px",
    width: "100%"
  },
  library: {
    textAlign: "center"
    /* minHeight: "100vh", */
  },
  libraryBottom: {
    flexGrow: 1,
    paddingTop: "30px",
    // marginBottom: "30px",
    textAlign: "center",
    "@media (max-width: 1024px)": {
      paddingTop: "10px"
    }
  },
  libraryHeadingWhite: {
    color: "#FFFFFF",
    fontSize: "72px",
    fontWeight: "bold",
    "@media (max-width: 1024px)": {
      fontSize: "32px",
      marginTop: "50px"
    }
  },
  headingWhite: {
    color: "#FFFFFF",
    fontSize: "32px",
    fontWeight: "bold",
    "@media (max-width: 1024px)": {
      fontSize: "18px",
      marginTop: "10px" /* ,
      borderTop: "solid 3px #ffffff" */
    }
  },
  headingBlue34: {
    color: "#4190CA",
    fontSize: "34px",
    fontWeight: "bold"
  },
  titleBlack: {
    color: "#000000",
    fontSize: "28px"
  },
  successSentTxt: {
    fontSize: "14px",
    color: "black",
    marginBottom: "0"
  },
  width100: {
    width: "100%"
  },
  contactBtn: {
    borderRadius: "10px",
    border: "none",
    backgroundColor: "#4190CA",
    width: "318px",
    height: "54px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#4190CA",
      color: "#000000"
    },
    textTransform: "none",
    fontWeight: "bold"
  },
  subHeadingGrey: {
    color: "#464545",
    fontSize: "21px",
    fontWeight: 400,
    "@media (max-width: 1040px)": {
      fontSize: "12px",
      lineHeight: "1"
    }
  },
  paper: {
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  text21: {
    fontSize: "21px"
  },
  textFieldZip: {
    width: "177px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "5px",
    height: "54px"
  },
  marginTop: {
    marginTop: "40px"
  },
  textField: {
    backgroundColor: "#FFFFFF",
    height: "54px",
    "@media (max-width: 1040px)": {
      height: "35px",
      borderRadius: "5px"
    },
    marginRight: "5px",
    width: "100%",
    minWidth: "260px",
    maxWidth: "318px",
    "@media (min-width: 1466px)": {
      /* minWidth: "318px", */
      width: "100%"
    },
    placeholder: {
      color: "#000000"
    }
  },
  textFieldDisplay: {
    backgroundColor: "#FFFFFF",
    height: "54px",
    "@media (max-width: 1040px)": {
      height: "35px",
      borderRadius: "5px"
    },
    marginRight: "5px",
    width: "100%",
    minWidth: "153px",
    "@media (min-width: 1466px)": {
      /* maxWidth: "153px", */
      width: "100%"
    },
    placeholder: {
      color: "#000000"
    }
  },
  categoryFieldDisplay: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0DAF0",
    height: "54px",
    "@media (max-width: 1040px)": {
      height: "35px",
      borderRadius: "5px"
    },
    marginRight: "5px",
    maxWidth: "668px",
    width: "100%",
    placeholder: {
      color: "#000000"
    }
  },
  gridList: {
    width: "100%",
    maxWidth: "1076px",
    margin: "0 auto!important",
    overflow: "hidden"
  },
  loadMoreBtn: {
    borderRadius: "10px",
    border: "solid 1px #E0DAF0",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "54px",
    color: "#4190CA",
    "&:hover": {
      backgroundColor: "#4190CA",
      color: "#000000"
    },
    textTransform: "none",
    "@media (max-width: 767px)": {
      width: "318px"
    }
  },
  whiteToBlueGrad: {
    backgroundImage: "linear-gradient(#FAFAFA, #4190CA)",
    "@media (min-width: 768px)": {
      padding: "0 15%"
    }
  }
}));

export const RefFilterBox: any = styled(Box)`
  padding-bottom: 61px;
  p {
    padding-bottom: 23px;
  }
  @media screen and (max-width: 1024px) {
    padding-bottom: 0px;
    padding: 0px 32px 20px;
    p {
      font-size: 16px;
      padding-bottom: 10px !important;
    }
  }
  .MuiSelect-icon {
    top: 0 !important;
    width: 34px;
    height: 34px;
    @media screen and (min-width: 1025px) {
      width: 54px;
      height: 54px;
    }
    color: transparent;
    background-image: url(${ArrowDown});
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0daf0;
  }

  .MuiInput-input {
    font-size: 21px;
    @media (max-width: 1024px) {
      font-size: 14px;
      border-radius: 5px;
    }
    font-weight: normal;
    padding: 14px 13px;
    color: #464545 !important;
    text-align: left;
  }
`;
