import React, { useState, useContext, useEffect } from "react";
// import logo from "src/logo.svg";
// import "App.css";
import { Fade } from "@material-ui/core";
import { Centered } from "../Shoothill.Components/General/";
import { Login, LoginModel } from "../Shoothill.Components/Account";
import { useTheme } from "@material-ui/styles";
import { UseDataApi, UseRouter } from "../Shoothill.Core/Utils";
import { Redirect } from "react-router";
import { StoresContext, Stores } from "../Stores";
import { ApiResult } from "../Models";
import { AccountStore } from "../Stores/Domain/AccountStores";

import { useStyles } from "../Shoothill.Components/Styles/LoginStyles";

export const LoginPage: React.FunctionComponent = () => {
  const theme: any = useTheme();
  const classes: any = useStyles(theme);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    // history,
    location // ,
    // match
  } = UseRouter();
  const {
    data,
    isLoading,
    // isError,
    doRequest
  } = UseDataApi<ApiResult>();
  const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
    .AccountStore;

  const handleSubmit: (loginModel: LoginModel) => void = (
    loginModel: LoginModel
  ): void => {
    doRequest("/api/account/login", loginModel);
  };

  useEffect(() => {
    const checkLoginReturn: () => any = (): any => {
      if (data !== null) {
        if (data.wasSuccessful) {
          accountStore.getLoginState(data.payload);
          const { from } = location.state || {
            from: { pathname: "/admin" }
          };
          if (from) {
            return <Redirect to={from} />;
          }
        } else {
          if (data.errors.length > 0) {
            setErrorMessage("* " + data.errors[0].message);
          } else {
            setErrorMessage("* Unknown error has occurred.");
          }
        }
      }
    };

    checkLoginReturn();
  }, [data]);

  if (accountStore.IsLoggedIn) {
    return <Redirect to={"/admin"} />;
  }

  window.scrollTo(0, 0);

  return (
    <Centered>
      <main role="main" className={classes.main}>
        <Fade in timeout={1000}>
          <Login
            onSubmit={handleSubmit}
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        </Fade>
      </main>
    </Centered>
  );
};
