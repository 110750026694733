import { makeStyles } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import React, { useContext, useEffect } from "react";
import { Stores, StoresContext } from "../Stores";
import { Box, Grid, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import * as ReactRouterDom from "react-router-dom";
import styled from "styled-components";
import { GridLarge } from "../Shoothill.Components/Styles";
import moment from "moment";
import { BlogPostStore } from "../Stores/Domain/BlogPostStore";
import ReactGA from "react-ga";
import * as Models from "../Models";
import { UseDataApi } from "../Shoothill.Core/Utils";

const useStyles: any = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	blogBar: {
		flexGrow: 1,
		backgroundColor: "#4190CA",
		height: "40px",
		paddingLeft: "25px",
		paddingTop: "8px"
	},
	blogBarText: {
		color: "#FFFFFF",
		fontSize: "17px",
		fontWeight: 400
	},
	blogPost: {
		textAlign: "center",
		minHeight: "100vh"
	},
	blogPostHeadingBlue: {
		color: "#4190CA",
		fontSize: "72px"
	},
	marginTop: {
		marginTop: "40px"
	},
	blogHome: {
		cursor: "pointer",
		fontWeight: "bold",
		textDecoration: "underline"
	}
}));

export const BlueGraditentBox: any = styled(Box)`
	background-image: linear-gradient(
		0deg,
		rgba(65, 144, 202, 1) 2%,
		rgba(255, 255, 255, 0.05) 30%
	);
`;

export const Row1040: any = styled(GridLarge)`
	margin: 0 auto;
	padding: 0;

	h1 {
		margin: 0 0 100px;
		color: #4190ca;
		font-size: 72px;
		line-height: 1em;
	}
`;

export const ArticleBox: any = styled(Box)`
	text-align: left;
	margin: 0 0 180px;
	padding: 0;
	display: inline-block;
	white-space: pre-line;
	font-weight: normal;
	font-size: 18px;

	p,
	h2,
	h3 {
		margin: 0 0 10px;
		padding: 0;
	}

	h2,
	h3 {
		color: #4190ca;
	}
`;

export const ImageBox: any = styled(Box)`
	margin: 0;
	padding: 0 0 40px 40px;
	min-width: 1px;
	max-width: 520px;
	float: right;
	overflow: hidden;
	img {
		width: 100%;
		height: auto;
	}
`;

export const DateSpaceTypo: any = styled(Box)`
	font-size: 18px;
	margin: 46px 0 29px;
`;

export const ArticleGrid: any = styled(Grid)``;

export interface IMatchParams {
	Id: string;
}

export const BlogPost: React.FC<ReactRouterDom.RouteComponentProps<
	IMatchParams
>> = props => {
	const classes: any = useStyles("blog");
	const blogPostStoreContext: BlogPostStore = useContext<Stores>(StoresContext)
		.domain.BlogPostStore;
	let blog: Models.BlogPost | undefined = blogPostStoreContext.GetBlogPost(
		props.match.params.Id
	);
	const { data, doRequest } = UseDataApi();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (blog === undefined) {
			const includeGenericDeletedWithId: Models.GenericIncludeDeletedWithId = {
				id: undefined,
				isBlog: true,
				includeDeleted: false
			};
			doRequest("/api/BlogPost/GetAll", includeGenericDeletedWithId);
		}
	}, [data]);

	useEffect(() => {
		if (data !== null) {
			if (data.wasSuccessful) {
				blogPostStoreContext.SetBlogPosts(data.payload);

				if (blog === undefined) {
					blog = blogPostStoreContext.GetBlogPost(props.match.params.Id);
				}
			}
		}
	}, [data]);

	ReactGA.pageview(window.location.pathname + window.location.search);

	return useObserver(() => (
		<>
			<Helmet>
				<title>{blog ? `${blog.name} - Blog` : "Blog"} | My Flood Risk</title>
			</Helmet>
			<BlueGraditentBox>
				<div className={classes.blogBar}>
					<Typography className={classes.blogBarText}>
						{/* <Link
                        className={classes.blogHome}
                        component={RouterLink}
                        to="/blog"
                    > */}
						<span
							className={classes.blogHome}
							onClick={() => window.history.go(-1)}
						>
							Blog
						</span>{" "}
						/ {blog ? blog.name : ""}
					</Typography>
				</div>
				<div className={classes.blogPost}>
					<Row1040>
						<article className="blogpost-header">
							<DateSpaceTypo>
								{blog ? moment(blog.startDate).format("dddd MMMM Do YYYY") : ""}
							</DateSpaceTypo>
							<h1 className={classes.blogPostHeadingBlue}>
								{blog ? blog.name : ""}
							</h1>
							<Grid container>
								<ArticleBox>
									<ImageBox>
										<img
											src={blog ? blog.desktopImageUrl : ""}
											alt={blog ? blog.name : ""}
										/>
									</ImageBox>
									<div
										dangerouslySetInnerHTML={{
											__html: blog ? blog.blogDetail : ""
										}}
									/>
								</ArticleBox>
							</Grid>
						</article>
					</Row1040>
				</div>
			</BlueGraditentBox>
		</>
	));
};
