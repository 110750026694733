import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles: any = makeStyles((theme: Theme) => ({
    main: {
      width: "auto",
      display: "block", // fix IE 11 issue
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    }
  }));