import { AddressSelectorGoogle } from "../Shoothill.Components/Forms";
import { Helmet } from "react-helmet";
import { Box, /* Button, */ Grid/* , Tooltip, Link  */} from "@material-ui/core";
import { CalculateFloodLoss } from "../AppUtils";
import { FloodLossModel } from "../Models";
import { GenericIncludeDeleted } from "../Models";
import iconFloodQuote from "../Content/icon-getFloodQuote.svg";
import potentialFloodRiskTool from "../Content/icon-PotentialFloodRiskTool2.svg";
import React, { useState, useEffect, useContext } from "react";
import { Stores, StoresContext } from "../Stores";
import { UseDataApi, ellipsis } from "../Shoothill.Core/Utils";
import { useObserver } from "mobx-react-lite";
/* import Zoom from "@material-ui/core/Zoom"; */
import { House1, House2, House3 } from "../Shoothill.Components/Styles";

import { useMediaQuery } from "../Shoothill.Core/Utils/Media";
import { MapStore } from "../Stores/Domain/MapStore";
import { RegionCostStore } from "../Stores/Domain/RegionCostStore";
import { UseRouter } from "../Shoothill.Core/Utils";

import {
    AppHeader,
    AppHeaderStart,
    HouseBox,
    BlueGraditentBox,
    WelcomeBox,
    InstructionBox,
    FormBox,
    WhiteGrid,
    /* FormInfoBox, */
    NFIBox,
    NFIinnerBox,
    NFISlider,
    WaterLevelBox,
    WaterLevelInnerBox,
    WaterLevelSlider,
    MobWaterLevelBox,
    MobWaterLevelInnerBox,
    MobWaterLevelSlider,
    FloodWaters,
    PFLBox,
    ResetButton,
    QuoteButtonLink,
    FloodGrid,
    useStyles
} from "../Shoothill.Components/Styles/FloodLossStyles";

export const FloodLoss: React.FC = () => {
    const classes: any = useStyles("floodloss");
    const { data, doRequest } = UseDataApi();
    const [houseImage, setHouseImage] = useState(House1);
    const [homeLoss, setHomeLoss] = useState(0);
    const [homeLossDisplay, setHomeLossDisplay] = useState(0);
    const [personalLoss, setPersonalLoss] = useState(0);
    const [personalLossDisplay, setPersonalLossDisplay] = useState(0);
    const [potentialFloodLoss, setPotentialFloodLoss] = useState(0);
    const [heightForChart, setheightForChart] = useState(1);
    const [possessionsValue, setPossessionsValue] = useState(20000);
    const [propertyValue, setPropertyValue] = useState(50000);
    const [floodWaterLevel, setFloodWaterLevel] = useState(1);
    const [floodWaterLevelHeight, setFloodWaterLevelHeight] = useState(0);
    const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;
    const regionCostStoreContext: RegionCostStore = useContext<Stores>(StoresContext).domain.RegionCostStore;
    const { history } = UseRouter();

    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 1023px)") ? true : false;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (regionCostStoreContext.regionCosts.length === 0) {
            const includeGenericDeleted: GenericIncludeDeleted = {
                includeDeleted: true
            };

            doRequest("/api/RegionCost/GetAll", includeGenericDeleted);
        }
    }, [data]);

    useEffect(() => {
        handleFloodLevelsChange({} as any, [1]);
    }, [mapStoresContext.location]);

    useEffect(() => {
        handlePossessionsChange({} as any, [20000]);
    }, [mapStoresContext.location]);

    useEffect(() => {
        if (data !== null) {
            if (data.wasSuccessful) {
                regionCostStoreContext.SetRegionCosts(data.payload);
            }
        }
    }, [data]);

    function handleHouseImageChange(event: object, value: any): void {
        if (value[0] > 750000) {
            setHouseImage(House3);
        } else if (value[0] > 500000 && value[0] < 750000) {
            setHouseImage(House2);
        } else if (value[0] < 500000) {
            setHouseImage(House1);
        }
    }

    function handlePropertyChange(event: object, value: any): void {
        setPropertyValue(value[0]);
        setHomeLoss(value[0]);
        setHomeLossDisplay(value[0]);
        calculateFloodLoss(value[0], floodWaterLevel, personalLoss);
    }

    function handlePossessionsChange(event: object, value: any): void {
        setPossessionsValue(value[0]);
        setPersonalLoss(value[0]);
        setPersonalLossDisplay(value[0]);
        calculateFloodLoss(homeLoss, floodWaterLevel, value[0]);
    }

    function handleFloodLevelsChange(event: object, value: any): void {
        setheightForChart(value[0]);
        /* let localFloodHeight: number = 0; */
        let floodValue: number = value[0] + (isMobile ? 3 : 40);
        /* localFloodHeight = value[0] * 2.5; */
        setFloodWaterLevelHeight(floodValue * (isMobile ? 1.1 : 2));
        setFloodWaterLevel(value[0]);
        calculateFloodLoss(homeLoss, value[0], personalLoss);
    }

    function calculateFloodLoss(
        costOfProperty: number,
        depth: number,
        costOfPossessions: number
    ): void {
        if (mapStoresContext.region !== "") {
            const regionCost: string = regionCostStoreContext.GetCostByRegion(
                mapStoresContext.region
            );
            if (regionCost !== "") {
                const floodLoss: FloodLossModel = CalculateFloodLoss(
                    costOfProperty,
                    depth,
                    parseFloat(regionCost),
                    costOfPossessions
                );

                let homeLossResultString: string = floodLoss.dwellingLossFormatted.toFixed(
                    0
                );
                let personalLossResultString: string = floodLoss.possessionsLossFormatted.toFixed(
                    0
                );
                let floodLossPotentialResultString: string = floodLoss.totalLossFormatted.toFixed(
                    0
                );

                setHomeLossDisplay(parseFloat(homeLossResultString));
                setPersonalLossDisplay(parseFloat(personalLossResultString));
                setPotentialFloodLoss(
                    parseFloat(floodLossPotentialResultString)
                );
            }
        }
    }

    function valuePropertyText(value: number): string {
        return `${value}`;
    }

    function valuePossessionsText(value: number): string {
        return `${value}`;
    }

    function valueFloodText(value: number): string {
        return `${value}`;
    }

    function handleResetLocation(): void {
        mapStoresContext.ResetLocation();
        setHouseImage(House1);
        setHomeLoss(0);
        setHomeLossDisplay(0);
        setPersonalLoss(0);
        setPersonalLossDisplay(0);
        setPotentialFloodLoss(0);
        setFloodWaterLevel(1);
        setFloodWaterLevelHeight(0);
    }

    let myAddress: string = "";

    if (mapStoresContext.hasAddress) {
        if (isMobile) {
            myAddress = ellipsis(mapStoresContext.address, 52, "...");

        } else {
            myAddress = mapStoresContext.address;

        }
    }

    function gotoFloodProtectionPage(): void {
        let quoteUri: string = "/moreaboutflood";
        history.push(quoteUri);
    }

    return useObserver(() => (
        <>
        <Helmet>
            <title>Potential Flood Loss - Illustrates the Potential 
             Cost of Flood Damage | My Flood Risk</title>
            <meta name="description" content="My Flood Risk, a free risk assessment tool, illustrates the potential 
             cost of flood damage based on property factors such as replacement cost value for home and contents." />
        </Helmet>
        <div className={classes.floodloss}>
            <BlueGraditentBox stlye={!mapStoresContext.hasAddress && {alignItems: "center"}}>
                {mapStoresContext.hasAddress && (
                    <>
                    <header>
                    <AppHeader>
                        <Grid container>
                            <Grid item xs={12}>
                                { !isMobile &&
                                <>
                                <FormBox
                                    mt={"21px"}
                                    mb={0}
                                    border={1}
                                    textAlign="center"
                                    fontSize={21}
                                    width={1}
                                >
                                    <div className="information">
                                        <p>
                                            Set the replacement value of your property and the estimated value of your possessions.
                                            Then drag the flood level to display the potential loss that could be expected.
                                        </p>
                                        <h5>
                                            Replacement Value of Property:
                                        </h5>
                                        <NFIBox
                                            ml={"0"}
                                            mr={"0"}
                                            mt={1}
                                            mb={2}
                                            textAlign="left"
                                        >
                                            <NFIinnerBox>
                                                <NFISlider
                                                    orientation="horizontal"
                                                    valueLabelDisplay="on"
                                                    defaultValue={[
                                                        50000
                                                    ]}
                                                    aria-labelledby="discrete-slider"
                                                    getAriaValueText={
                                                        valuePropertyText
                                                    }
                                                    onChange={
                                                        handleHouseImageChange
                                                    }
                                                    onChangeCommitted={
                                                        handlePropertyChange
                                                    }
                                                    max={1000000}
                                                    min={50000}
                                                    step={10000}
                                                />
                                            </NFIinnerBox>
                                        </NFIBox>

                                        <h5>
                                            Estimated Value of Possessions:
                                        </h5>
                                        <NFIBox
                                            ml={"0"}
                                            mr={"0"}
                                            mt={1}
                                            mb={2}
                                            textAlign="left"
                                        >
                                            <NFIinnerBox>
                                                <NFISlider
                                                    orientation="horizontal"
                                                    valueLabelDisplay="on"
                                                    defaultValue={[
                                                        5000
                                                    ]}
                                                    aria-labelledby="discrete-slider"
                                                    getAriaValueText={
                                                        valuePossessionsText
                                                    }
                                                    onChangeCommitted={
                                                        handlePossessionsChange
                                                    }
                                                    max={500000}
                                                    min={20000}
                                                    step={10000}
                                                />
                                            </NFIinnerBox>
                                        </NFIBox>

                                        <PFLBox>
                                            <h3>
                                                Potential
                                                Flood
                                                Loss
                                                <span className="value">
                                                    $
                                                    {
                                                        potentialFloodLoss
                                                    }
                                                </span>
                                                <span className="subText">
                                                    Your
                                                    combined
                                                    loss
                                                    potential
                                                </span>
                                            </h3>
                                            <h4>
                                                $
                                                {
                                                    homeLossDisplay
                                                }
                                                <span className="subText">
                                                    Home
                                                    loss
                                                </span>
                                            </h4>
                                            <h4 className="nobo">
                                                $
                                                {
                                                    personalLossDisplay
                                                }
                                                <span className="subText">
                                                    Personal
                                                    loss
                                                </span>
                                            </h4>
                                        </PFLBox>

                                        <QuoteButtonLink onClick={gotoFloodProtectionPage}>
                                            <div className={"floodIcon"}>
                                                <img src={iconFloodQuote} alt={"Learn more about Flood Protection"} />
                                            </div>
                                            <div className={"floodText"}>Learn more about <br />flood protection</div>
                                        </QuoteButtonLink>
                                    </div>

                                    <div className="diagram">
                                        <HouseBox
                                            id="houseImage"
                                            PaddingBottom={"0"}
                                            PaddingLeft={"0"}
                                            PaddingRight={"0"}
                                            PaddingTop={"0"}
                                            MarginTop={"0"}
                                            TextAlign={"left"}
                                            ImageUrl={houseImage}
                                        >
                                            <ResetButton
                                                onClick={handleResetLocation}
                                                title={"Click to start again"}>
                                                <span>Start again</span>
                                            </ResetButton>
                                            <WaterLevelBox>
                                                <span>
                                                    4'
                                                </span>
                                                <WaterLevelInnerBox>
                                                    <WaterLevelSlider
                                                        orientation="vertical"
                                                        valueLabelDisplay="on"
                                                        defaultValue={[
                                                            1
                                                        ]}
                                                        aria-labelledby="discrete-slider-small-steps"
                                                        getAriaValueText={
                                                            valueFloodText
                                                        }
                                                        onChangeCommitted={
                                                            handleFloodLevelsChange
                                                        }
                                                        max={
                                                            48
                                                        }
                                                        min={
                                                            1
                                                        }
                                                        step={
                                                            1
                                                        }
                                                    />
                                                </WaterLevelInnerBox>
                                                <span>
                                                    1"
                                                </span>
                                            </WaterLevelBox>
                                            <FloodWaters
                                                FloodValue={
                                                    floodWaterLevelHeight
                                                }
                                            />
                                        </HouseBox>
                                    </div>
                                </FormBox>
                                </>
                                }
                                { isMobile &&
                                <>
                                <FormBox
                                    mt={"8px"}
                                    mb={0}
                                    border={1}
                                    textAlign="center"
                                    fontSize={21}
                                    width={1}
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <InstructionBox>
                                            {
                                                myAddress
                                            }
                                            </InstructionBox>
                                        </Grid>
                                        <FloodGrid item xs={12}>
                                            <Box
                                                pt={1}
                                                ml={"15px"}
                                                mr={"15px"}
                                                mt={1}
                                                textAlign="left"
                                            >
                                                Replacement Value of Property: <span>${ propertyValue }</span>
                                            </Box>
                                            <NFIBox
                                                ml={"15px"}
                                                mr={"15px"}
                                                mt={1}
                                                mb={1}
                                                textAlign="left"
                                            >
                                                <NFIinnerBox>
                                                    <NFISlider
                                                        orientation="horizontal"
                                                        valueLabelDisplay="off"
                                                        defaultValue={[
                                                            50000
                                                        ]}
                                                        onChange={
                                                            handleHouseImageChange
                                                        }
                                                        onChangeCommitted={
                                                            handlePropertyChange
                                                        }
                                                        max={1000000}
                                                        min={50000}
                                                        step={10000}
                                                    />
                                                </NFIinnerBox>
                                            </NFIBox>
                                            <p>The replacement value of your property should be equal to the
                                                dwelling coverage on your homeowners policy,
                                                or the building coverage on your commercial property policy.</p>
                                        </FloodGrid>
                                        <FloodGrid item xs={12}>
                                            <Box
                                                pt={1}
                                                ml={"15px"}
                                                mr={"15px"}
                                                mt={1}
                                                textAlign="left"
                                            >
                                                Estimated Value of Possessions: <span>${ possessionsValue }</span>
                                            </Box>
                                            <NFIBox
                                                ml={"15px"}
                                                mr={"15px"}
                                                mt={1}
                                                mb={1}
                                                textAlign="left"
                                            >
                                                <NFIinnerBox>
                                                    <NFISlider
                                                        orientation="horizontal"
                                                        valueLabelDisplay="off"
                                                        defaultValue={[
                                                            5000
                                                        ]}
                                                        onChangeCommitted={
                                                            handlePossessionsChange
                                                        }
                                                        max={500000}
                                                        min={20000}
                                                        step={10000}
                                                    />
                                                </NFIinnerBox>
                                            </NFIBox>
                                            <p>This amount should usually be 50% of the replacement value of your property.</p>
                                        </FloodGrid>
                                        <FloodGrid item xs={12}>
                                            <div className={"floodDepth"}>
                                                Flood depth: {heightForChart}"
                                                <MobWaterLevelBox>
                                                    <MobWaterLevelInnerBox>
                                                        <MobWaterLevelSlider
                                                            orientation="horizontal"
                                                            valueLabelDisplay="off"
                                                            defaultValue={[
                                                                1
                                                            ]}
                                                            aria-labelledby="discrete-slider-small-steps"
                                                            getAriaValueText={
                                                                valueFloodText
                                                            }
                                                            onChangeCommitted={
                                                                handleFloodLevelsChange
                                                            }
                                                            max={
                                                                48
                                                            }
                                                            min={
                                                                1
                                                            }
                                                            step={
                                                                1
                                                            }
                                                        />
                                                    </MobWaterLevelInnerBox>
                                                </MobWaterLevelBox>
                                            </div>
                                        </FloodGrid>
                                        <Grid item xs={12}>
                                            <HouseBox
                                                id="houseImage"
                                                PaddingBottom={"0"}
                                                PaddingLeft={"0"}
                                                PaddingRight={"0"}
                                                PaddingTop={"0"}
                                                MarginTop={"0"}
                                                TextAlign={"left"}
                                                ImageUrl={houseImage}
                                            >
                                                <FloodWaters
                                                    FloodValue={
                                                        floodWaterLevelHeight
                                                    }
                                                />
                                            </HouseBox>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <PFLBox>
                                                    <h3>
                                                        Potential
                                                        Flood
                                                        Loss
                                                        <span className="value">
                                                            $
                                                            {
                                                                potentialFloodLoss
                                                            }
                                                        </span>
                                                        <span className="subText">
                                                            Your
                                                            combined
                                                            loss
                                                            potential
                                                        </span>
                                                    </h3>
                                                    <h4>
                                                        $
                                                        {
                                                            homeLossDisplay
                                                        }
                                                        <span className="subText">
                                                            Home
                                                            loss
                                                        </span>
                                                    </h4>
                                                    <h4 className="nobo">
                                                        $
                                                        {
                                                            personalLossDisplay
                                                        }
                                                        <span className="subText">
                                                            Personal
                                                            loss
                                                        </span>
                                                    </h4>
                                                </PFLBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormBox>
                                <WhiteGrid item xs={12}>
                                    <Box
                                        pt={2}
                                        mt={0}
                                        mb={"50px"}
                                        textAlign="center"
                                    >
                                        <QuoteButtonLink onClick={gotoFloodProtectionPage}>
                                            <div className={"floodIcon"}><img src={iconFloodQuote}
                                                alt={"Learn more about Flood Protection"} />
                                            </div>
                                            <div className={"floodText"}>Learn more about <br />flood protection</div>
                                        </QuoteButtonLink>
                                    </Box>
                                </WhiteGrid>
                                </>
                                }
                            </Grid>
                        </Grid>
                    </AppHeader>
                    </header>
                    </>
                )}
                {!mapStoresContext.hasAddress && (
                    <>
                    <AppHeaderStart>
                        <Grid container className=" gridContainer">
                            <Grid item xs={12}>
                                <WelcomeBox>
                                    <div className={"image"}>
                                        <img
                                            src={potentialFloodRiskTool}
                                            className={
                                                classes.potentialFloodRiskTool
                                            }
                                            alt={"Potential flood loss"}
                                        />
                                    </div>
                                    <h1>
                                        Potential Flood Loss
                                    </h1>
                                    <p>
                                        The purpose of this tool is to provide an
                                        illustration of potential losses in the
                                        event
                                        { !isMobile && <br /> }of a flood based on specific property
                                        factors such as home and content values.
                                    </p>
                                </WelcomeBox>
                                <div className="AddressSelector">
                                    <AddressSelectorGoogle />
                                </div>
                            </Grid>
                        </Grid>
                    </AppHeaderStart>
                    </>
                )}
            </BlueGraditentBox>
        </div>
        </>
    ));
};