import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import "./Home.css";
import iconContactByPhone from "../Content/icon-contact-byPhone.svg";
import iconContactByChat from "../Content/icon-contact-viaChat.svg";
import iconContactByEmail from "../Content/icon-contact-byEmail.svg";
import { UseRouter } from "../Shoothill.Core/Utils";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "../Shoothill.Core/Utils/Media";
import { ContactFormModel } from "../Models/FormModels/ContactFormModel";
import { useValidation, UseDataApi } from "../Shoothill.Core/Utils";
import validator from "validator";
import * as AppUrls from "../Globals/AppUrls";
import { LargeWhiteFormControl } from "../Components/Controls/Forms/LargeWhiteFormControl";
import ReactGA from "react-ga";

import {
    BtnGrid,
    useStyles,
    HeaderBox,
    ContactAnchor,
    LeftBox,
    RightBox,
    BRFormBox,
    FullBox,
    ContactBoxRow,
    ContactRowBox
} from "../Shoothill.Components/Styles/ContactStyling";

declare var purechatApi: any;

function scrollToContact(): void {
    var contactForm: HTMLElement | undefined | null = document.getElementById("contactForm");
    if (contactForm) {
        contactForm.scrollIntoView();
    }
}

export const Header: any  = React.memo(() => {
    const classes: any  = useStyles("contact");
    const { history } = UseRouter();
    ReactGA.pageview(window.location.pathname + window.location.search);
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 1023px)") ? true : false;

    const gotoContactForm: () => void = (): void => {
        history.push("/contact#contactform", false);
        scrollToContact();
    };

    return (
        <>
        <Helmet>
            <title>Contact Us - Available to Property Owners and Industry
             Professionals | My Flood Risk</title>
            <meta name="description" content="My Flood Risk is available to property owners and industry
             professionals throughout the United States. Our flood experts are available by phone, chat or email." />
        </Helmet>
        <HeaderBox>
            <div className="container">
                <Grid container>
                    <Grid item xs={12}>
                        <Box pt={12} textAlign="center" className={classes.contactHeadingBlue} fontSize="h2.fontSize">
                            <Typography variant="h1">
                                Contact My Flood Risk
                            </Typography>
                        </Box>
                        <Box pt={3} textAlign="center" className={classes.colorWhite} fontSize={21}>
                            <Typography className={classes.subTitleWhite}>
                                We pride ourselves in not only providing you access to our nationwide capabilities,
                                <br /> but in doing it with the attention and customer service of a small company.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

            <ContactBoxRow>
                <ContactRowBox>
                    <div className="ContactIconBox">
                        <img src={iconContactByPhone} alt={"By phone"} />
                    </div>
                    <div className="grouped">
                        <h2 className="full">
                            By phone
                        </h2>
                        <p>
                        { !isMobile &&
                        <>Call us now on <br /><strong>(833) 723-7322</strong></>
                        }
                        { isMobile &&
                        <>(833) 723-7322</>
                        }
                        </p>
                    </div>
                </ContactRowBox>
                <ContactRowBox onClick={() => purechatApi.set("chatbox.expanded" , true)} style={{cursor: "pointer"}}>
                    <div className="ContactIconBox">
                        <img src={iconContactByChat} alt={"Via Chat"} />
                    </div>
                    <div className="grouped">
                        <h2>
                            Via Chat
                        </h2>
                        <p>
                        { !isMobile &&
                        <>Speak directly with us <br /><strong>Chat now</strong></>
                        }
                        { isMobile &&
                        <>Chat now</>
                        }
                        </p>
                    </div>
                </ContactRowBox>
                <ContactRowBox onClick={gotoContactForm}>
                        <div className="ContactIconBox">
                            <img src={iconContactByEmail} alt={"By email"} />
                        </div>
                        <div className="grouped">
                            <h2>
                                By email
                            </h2>
                            <p>
                            { !isMobile &&
                            <>Drop us a line at <br /><strong>info@myfloodrisk.org</strong></>
                            }
                            { isMobile &&
                            <>info@myfloodrisk.org</>
                            }
                            </p>
                        </div>
                </ContactRowBox>
            </ContactBoxRow>
            <div className={classes.hoursMargin}>
                <Grid container justify="center">
                    <Typography className={classes.subTitleWhite}>
                        <strong>Hours of operation</strong>
                        <br /> Monday – Friday between 9:00 a.m. and 5:00 p.m. (EST).
                    </Typography>
                </Grid>
            </div>
            </div>
        </HeaderBox>
        </>
    );
});

export const Contact: React.FC = () => {
    const classes: any  = useStyles("contact");
    const [isSent, SetIsSent] = useState(false);

    const [formState, { text, /* checkbox, select, password,  */email }] = useValidation<ContactFormModel>(
        new ContactFormModel()
    );

    const { data, isLoading, /* isError, */ doRequest } = UseDataApi();

    // aJ: Can't use useEffect if I put this into the ServerApi folder following that structure.
    useEffect(() => {
        if (data === 200) {
            SetIsSent(true);
            formState.wipeFormData();
        }
	}, [data]);

    const onSubmit: (e:any) => void = (e: any): void => {
        e.preventDefault();
        if (formState.isFormValid()) {
            doRequest(AppUrls.Server.Api.Inquiry.PostContactFormAsync, formState.formData);
        }
    };

    useEffect(() => {
        if (window.location.href.indexOf("#contractform") < 0) {
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        if (window.location.href.indexOf("#contactform") > 0) {
            scrollToContact();
        }
    }, [window.location.hash]);

    return (
        <>
            <div className={classes.contact}>
                <div className={classes.blueGradient}>
                    <header className="App-header">
                        <Header/>
                        {/* <div className={classes.blueGradient}> */}
                        <Grid container className={classes.noMargin}>
                            <Grid item xs={12}>
                                <ContactAnchor id="contactForm" />
                                <BRFormBox textAlign="center" width={1}>
                                    <Box mt={"42px"} ml={"48px"} mr={"48px"} textAlign="center" className={classes.headingBlue}>
                                        Would you like us to contact you?
									</Box>
                                    <Box mt={3} ml={"48px"} mr={"48px"} fontSize={32} textAlign="center" className={classes.subHeaderGrey}>
                                        Fill out the form below and we will be in touch.
									</Box>

                                    <form className={classes.form} onSubmit={onSubmit}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <LeftBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        {formState.showErrors("firstName", classes.error)}
                                                        <TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="fname"
                                                            placeholder="What is your first name? (required)"
                                                            {...text("firstName", {
                                                                validationRules: [(v: string) => (v && v.length > 0) || "* Your first name is required"]
                                                            })}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </LeftBox>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <RightBox textAlign="center" >
                                                    <LargeWhiteFormControl>
                                                        {formState.showErrors("lastName", classes.error)}
                                                        <TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="lastName"
                                                            placeholder="What is your last name? (required)"
                                                            {...text("lastName", {
                                                                validationRules: [(v: string) => (v && v.length > 0) || "* Your last name is required"]
                                                            })}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </RightBox>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <LeftBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        <TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="phone"
                                                            placeholder="Your phone number"
                                                            {...text("phoneNumber")}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </LeftBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <RightBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        {formState.showErrors("emailAddress", classes.error)}
                                                        <TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="email"
                                                            placeholder="Your email address (required)"
                                                            {...email("emailAddress", {
                                                                validationRules: [
                                                                    (v: string) => validator.isEmail(v) || "* Email address is invalid",
                                                                    (v: string) => (v && v.length > 0) || "* Email address is required"
                                                                ]
                                                            })}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </RightBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FullBox textAlign="center" className={classes.textAreaBoxContainer}>
                                                    {formState.showErrors("message", classes.error)}
                                                    <LargeWhiteFormControl>
                                                        <TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="message"
                                                            rows={9}
                                                            multiline
                                                            placeholder="Your message (required)"
                                                            {...text("message", {
                                                                validationRules: [(v: string) => (v && v.length > 0) || "* Your message is required",
                                                                (v: string) => (v && v.length > 20) || "* Your message must be at least 20 characters"] // aJ: It'll just be worthless spam otherwise.
                                                            })}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </FullBox>
                                            </Grid>
                                            {isSent &&
                                                <div className={classes.width100}>
                                                    <p className={classes.successSentTxt}>We have recieved your message. A member of our team will be in touch with you shortly to discuss your inquiry. Thank you.</p>
                                                </div>
                                            }
                                            <BtnGrid>
                                                <Box mt={3} mb={3} textAlign="center">
                                                    <Button variant="outlined" className={classes.contactBtn} type="submit" disabled={isLoading}>
                                                        {isLoading ? "Sending.." : "Send"}
													</Button>
                                                </Box>
                                            </BtnGrid>
                                        </Grid>
                                    </form>
                                </BRFormBox>
                            </Grid>
                        </Grid>
                        {/* </div> */}
                    </header>
                </div>
            </div>
        </>
    );
};
