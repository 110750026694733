import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { flexbox/* , maxHeight */ } from "@material-ui/system";
import { GenericModalBox } from "./ModalStyles";

export const useReportStyles: any = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  reportList: {},
  dateRow: {
    flexDirection: "row",
    display: flexbox
  },
  headingBlue34: {
    color: "#4190CA",
    fontSize: "34px",
    fontWeight: "bold"
  },
  datePicker: {

  },
  datePickerGridContainer: {
    minWidth: "165px",
    maxWidth: "98%"
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "15px"
  },
  toggleContainerSummary: {
    display: "flex",
    flexGrow: 1,
    padding: "10px 15px"
  },
  table: {
    width: "100%"
  },
  tableHeader: {
    backgroundColor: "#00002A"
  },
  tableHeaderCell:{
    color: "white",
    fontWeight: 700,
    fontSize: "12px",
    margin: "0 auto",
    paddingRight: "20px"
  },
  tableRowClickable:{
    cursor: "pointer"
  },
  tableRowCell:{
    color: "#000000",
    fontWeight: 300,
    fontSize: "12px"
  },
  tableRowCellClickable:{
    color: "#000000",
    fontWeight: 300,
    fontSize: "12px",
    cursor: "pointer"
  },  
  tableRowCellDate: {
    color: "#000000",
    fontWeight: 300,
    fontSize: "12px",
    minWidth: "130px",
    paddingRight: "14px"
  },
  tableRowCellAddress:{
    color: "#000000",
    fontWeight: 300,
    fontSize: "12px",
    minWidth: "160px"
  },
  editIcon: {
    height: "18px",
    width: "18px"
  },
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "15px",
    height: "auto",
    flexBasis: "auto",

    p: {
      flex: "1 0 auto",
    }
  },
  buffer: {
    flex: "1 0 auto",
  },
  modalRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexBasis: "auto",

    p: {
      flex: "1 0 auto",
    }

  },
  modalLabel: {
    fontWeight: 700,
    display: "flex",
    flex: 1,
    maxWidth: "100px"
  },
  modalDataLabel: {
    fontWeight: 700,
    display: "flex",
    flex: 1,
  },
  modalActionLabel: {
    fontWeight: 700,
    display: "flex",
    flex: 1,
    maxWidth: "150px"
  },
  modalValue: {
    display: "flex",
    fontWeight: 300,
    flex: 1,
  },
  modalValueBreak: {
    display: "flex",
    fontWeight: 300,
    flex: 1,
    wordWrap: "break-word",
    overflowWrap: "break-word",
    maxWidth: "95%",
  },

  list: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    paddingInlineStart: 0,
    justifyContent: "space-around"
  },
  listItem: {
    display: "flex",
    padding: "10px",
    flex: 1,
    maxWidth: "49%",
    borderRadius: "4px",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",

    "@media (max-width: 768px)": {
      minWidth: "100%",
      width: "100%",
    }
  },
  listContent: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "1em",
    width: "100%",

    p: {
      flex: "1 0 auto",
    }
  },
  quoteRow: {
    backgroundColor: "rgba(0,128,255,0.2)"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  horizontalScroll: {
    overflowX: "scroll"
  },
  aCursor: {
    cursor: "pointer"
  }
}));

export const ReportModalBox: any = styled(GenericModalBox)`
  outline: none;
  margin: 10% auto;

  height: auto;
  max-height: 960px;

  div {
    width: 100%;
  }

  .button-row {
    width: 100%;
    text-align: center;
  }
`;