import styled from "styled-components";
import { Box, Grid } from "@material-ui/core";
import Masonry from "react-masonry-css";
import Background from "Content/BlogRefLibBackground.png";

export const AppHeader: any = styled(Box)`
  height: 0;
  min-height: 232px;
  @media screen and (min-width: 1040px) {
    min-height: 253px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url(${Background});
  background-position: 0 40%;
  max-width: 100%;
  margin: 0 auto;
`;

export const Row1040: any = styled(Grid)`
  max-width: 1040px;
  margin: 0 auto;
  padding: 0;
`;

export const BtnGrid: any = styled(Grid)`
  margin: 0 auto;
  button {
    max-width: 295px;
  }
`;

export const LeftBox: any = styled(Box)`
  margin: 20px 22px 20px 61px;
  @media (max-width: 1040px) {
    margin: 10px auto;
    max-width: 295px;
  }
`;

export const RightBox: any = styled(LeftBox)`
  margin: 20px 61px 20px 22px;
`;

export const Row1076: any = styled(Row1040)`
  max-width: 1076px;
`;

export const MasonryCustom: any = styled(Masonry)`
  width: 100% !important;
  display: flex;
  height: 100% !important;
  padding: 0px !important;
  @media (min-width: 1024px) {
    .my-masonry-grid_column {
      > li {
        margin: 14px auto;
        margin-top: 0px;
      }
    }
  }
`;

export const BRFormBox: any = styled(Box)`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  border: none;
  margin: 30px auto 200px;
  @media screen and (max-width: 1024px) {
    margin: 0px auto 60px;
  }
`;
