import styled from "styled-components";
import { Grid, /* Paper,  */ Box /* , Typography */ } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { PromptBox, contactimage } from "./Styles";

export const useStyles: any = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  error: {
    color: "#464545",
    textAlign: "left",
    fontSize: "13px",
    position: "absolute",
    top: "-2px"
  },
  rootMargin: {
    flexGrow: 1,
    marginBottom: "70px"
  },
  hoursMargin: {
    flexGrow: 1,
    paddingTop: "30px"
  },
  contact: {
    textAlign: "center",
    backgroundImage: `url(${contactimage})`,
    minHeight: "100vh",
    backgroundPosition: "center 16%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  blueGradient: {
    /*         backgroundImage:
                    "linear-gradient(0deg, rgba(65,144,202,1) 23%, rgba(255,255,255,0.5) 79%, rgba(255,255,255,0) 100%)})", */
    backgroundImage:
      "linear-gradient(0deg, rgba(65,144,202,1) 30%, rgba(255,255,255,0.3) 73%, rgba(255,255,255,0) 96%)",
    "@media (max-width: 767px)": {
      marginTop: "70px"
    }
  },
  contactBottom: {
    flexGrow: 1,
    paddingTop: "30px",
    marginBottom: "30px",
    textAlign: "center"
  },
  contactHeadingBlue: {
    color: "#4190CA",
    fontSize: "72px",
    lineHeight: "80px",
    "@media (max-width: 767px)": {
      fontSize: "32px",
      lineHeight: "1"
    },
    padding: "0px"
  },
  contactSubHeadingBlue: {
    color: "#4190CA",
    fontSize: "28px"
  },
  titleBlue: {
    color: "#4190CA"
  },
  titleWhite: {
    color: "#FFFFFF",
    fontSize: "28px"
  },
  headingWhite: {
    color: "#FFFFFF",
    fontSize: "52px"
  },
  headingBlue: {
    color: "#4190CA",
    fontSize: "52px",
    opacity: 1,
    paddingTop: "30px",
    "@media (max-width: 767px)": {
      fontSize: "32px",
      lineHeight: "1"
    }
  },
  headingBlue34: {
    color: "#4190CA",
    fontSize: "34px"
  },
  titleBlack: {
    color: "#000000",
    fontSize: "28px"
  },
  subTitleWhite: {
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 400,
    "@media (max-width: 767px)": {
      fontSize: "12px",
      lineHeight: "1",
      width: "278px",
      margin: "0 auto"
    }
  },
  subTitleBlack: {
    color: "#000000",
    fontSize: "18px"
  },
  colorWhite: {
    color: "#FFFFFF"
  },
  colorBlack: {
    color: "#000000"
  },
  fs14: {
    fontSize: "14px"
  },
  paper: {
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperLeft: {
    textAlign: "left",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperRight: {
    textAlign: "right",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  formContainer: {
    backgroundColor: "rgba(255,255,255,0.5)"
  },
  form: {
    width: "100%"
  },
  text21: {
    fontSize: "21px"
  },
  marginTop: {
    marginTop: "40px"
  },
  backroundLight: {
    backgroundColor: "rgba(255,25,,255,0.5)"
  },
  contactBtn: {
    borderRadius: "10px",
    border: "none",
    backgroundColor: "#4190CA",
    width: "318px",
    height: "54px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#4190CA",
      color: "#000000"
    },
    textTransform: "none",
    fontWeight: "bold"
  },
  rootSelect: {
    textAlign: "left",
    fontSize: "14px  !important",
    lineHeight: "24px"
  },
  selectMenu: {
    height: "24px",
    backgroundColor: "#FFFFFF",
    textAlignt: "left",
    fontSize: "14px !imporant",
    lineHeight: "24px"
  },
  focused: {
    backgroundColor: "white !important"
  },
  selectBoxContainer: {
    position: "relative",
    paddingTop: "16px"
  },
  textAreaBoxContainer: {
    position: "relative"
  },
  successSentTxt: {
    fontSize: "14px",
    color: "black",
    marginBottom: "0"
  },
  width100: {
    width: "100%"
  },
  subHeaderGrey: {
    color: "#464545",
    fontWeight: 400,
    "@media (max-width: 1040px)": {
      fontSize: "12px",
      lineHeight: "1"
    }
  },
  noLinkStyling: {
    "&:hover": {
      textDecoration: "none"
    }
  },
  noMargin: {
    margin: "0",
    "@media (min-width: 768px)": {
      padding: "0 15%"
    }
  }
}));

export const BtnGrid: any = styled(Grid)`
  margin: 0 auto;
  button {
    max-width: 295px;
  }
`;

export const LeftBox: any = styled(Box)`
  margin: 20px 22px 20px 61px;
  @media (max-width: 1040px) {
    margin: 10px auto;
    max-width: 295px;
  }
`;

export const RightBox: any = styled(LeftBox)`
  margin: 20px 61px 20px 22px;
`;

export const FullBox: any = styled(LeftBox)`
  margin: 20px 61px 20px;
  @media (max-width: 1040px) {
    margin: 10px auto;
    max-width: 295px;
  }
`;

export const BRFormBox: any = styled(Box)`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  border: none;
  margin: 30px auto 200px;
  @media screen and (max-width: 767px) {
    margin: 0px auto 60px;
  }
`;

export const ImageBox: any = styled(PromptBox)`
  display: flex;

  img {
    width: 100%;
  }
`;

export const HeaderBox: any = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  height: 0;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin: 0px auto 60px;
  }
`;

export const ContactAnchor: any = styled.a`
  position: relative;
  top: -64px;
`;

export const ContactBoxRow: any = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
    align-content: center;
    padding-top: 24px;
    width: 214px;
    margin: auto;
  }
`;

export const ContactRowBox: any = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  margin: 6px 0;
  color: #ffffff;
  flex-direction: column;
  width: 340px;
  text-align: center;
  padding: 44px 12px 12px;

  @media (max-width: 1050px) {
    width: 33vw;
  }

  @media (min-width: 768px) {
    height: 300px;
  }

  .wiz {
    color: #222120;
    font-weight: bold;
  }

  div,
  p,
  h2 {
    margin: 0 auto;
    padding: 0;
    font-size: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
  }

  h2 {
    color: #4190ca;
    font-size: 28px;
    width: 80%;
  }

  h2.full {
    width: 100%;
  }
  .grouped,
  .ContactIconBox {
    width: 100%;
  }

  .ContactIconBox {
    display: flex;
    justify-content: center;
    height: 131.45px;
    img {
      align-self: center;
    }
  }

  @media (max-width: 767px) {
    width: 214px;
    padding: 0;
    flex-direction: row;
    flex: 1;

    .grouped,
    .ContactIconBox {
      margin: 0;
      height: 80px;
    }

    .grouped {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding-left: 20px;

      p,
      h2 {
        padding: 0;
        width: 100%;
        margin: 0;
      }

      p {
        font-size: 12px;
      }

      h2 {
        font-size: 18px;
      }
    }

    .ContactIconBox {
      width: 80px;
      img {
        width: 100%;
      }
    }
  }
`;
