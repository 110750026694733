// import { createContext } from "react";
import * as Utils from "Shoothill.Core/Utils/Utils";
import { InitialState } from "./../../Models/InitialState";
import * as ServerApi from "../../ServerApi";
import { Stores } from "./../Stores";
// import * as Models from "Models";

import { ProUserModel } from "Models/ProUserModel";

import { BaseStore } from "./BaseStore";
import { observable, action } from "mobx";
import { getCookie } from "Shoothill.Core/Utils/Utils";

export class AccountStore extends BaseStore {
	@observable public IsLoggedIn: boolean = false;
	@observable public UserName: string = "";
	@observable public DisplayName: string = "";
	@observable public UserRoles: string[] = [];

	@observable public FirstName: string = "";
	@observable public LastName: string = "";
	@observable public Email: string = "";
	@observable public CompanyName: string = "";
	@observable public CompanyType: string = "";
	@observable public CompanyLogo: string = "";
	@observable public imageFile: File | undefined;
	@observable public fileBase64: string = "";

	private jwt: string | null = null;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState): void {
		this.getLoginState();
	}

	@action
	public populate(store: any): void {
		this.IsLoggedIn = store.IsLoggedIn;
		this.UserName = store.UserName;
		this.DisplayName = store.DisplayName;
		this.UserRoles = store.UserRoles;
		this.FirstName = store.FirstName;
		this.LastName = store.LastName;
		this.Email = store.Email;
		this.CompanyName = store.CompanyName;
		this.CompanyType = store.CompanyType;
		this.CompanyLogo = store.CompanyLogo;
		this.imageFile = store.imageFile;
		this.fileBase64 = store.fileBase64;
	}

	@action
	public setIsLoggedIn(state: boolean): void {
		this.IsLoggedIn = state;
	}

	public isInRole = (role: string): boolean => {
		if (this.UserRoles && this.UserRoles.length > 0) {
			return this.UserRoles.includes(role);
		}

		return false;
	};

	@action
	public setCompanyLogo(logo: string): void {
		this.CompanyLogo = logo;
	}

	@action
	public Logout = (): void => {
		document.cookie = ".auth=";
		/* localStorage.removeItem(".auth"); */
		this.IsLoggedIn = false;
		this.UserName = "";
		this.DisplayName = "";
		this.UserRoles = [];

		this.LastName = "";
		this.FirstName = "";
		this.Email = "";
		this.CompanyLogo = "";
		this.CompanyName = "";
		this.CompanyType = "";

		(window as any).jwt = null;
	};

	@action
	public getLoginState = (jwt: string | undefined | null = null) => {
		if (!jwt || jwt.length === 0) {
			this.jwt = getCookie(".auth");
			/* this.jwt = localStorage.getItem(".auth") as string; */
		} else {
			this.jwt = jwt;
		}

		if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
			const data: any = Utils.ParseJwt(this.jwt);
			/* localStorage.setItem(".auth", this.jwt); */
			document.cookie = `.auth=${this.jwt}`;
			this.IsLoggedIn = true;
			this.UserName = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
			] as string;
			this.DisplayName = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
			] as string;
			this.UserRoles = data[
				"http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
			] as string[];
			this.Email = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
			] as string;
			this.LastName = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"
			] as string;
			this.FirstName = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
			] as string;
			this.CompanyLogo = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri"
			] as string;
			this.CompanyName = data[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality"
			] as string;
			this.CompanyType = data[
				"http://www.w3.org/2001/XMLSchema#string"
			] as string;
			// en: For Debugging
			/* (window as any).jwt = this.jwt; */
		}
	};

	@action
	public forgotPassword = (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
		this.setIsLoading();
		return ServerApi.Account.forgotPassword({ confirmNewPassword, currentPassword, newPassword });
	}

	@action
	public resetImageData = (): void => {
		this.imageFile = undefined;
		this.fileBase64 = "";
	};

	@action
	public SetImageFile(selectedImageFile: File): void {
		this.imageFile = selectedImageFile;
	}

	@action
	public SetFileBase64(fileBase64: string): void {
		this.fileBase64 = fileBase64;
	}

	@action GetImageFile(): File | undefined {
		return this.imageFile;
	}

	@action GetFileBase64(): string {
		return this.fileBase64;
	}

	@action
	public CreateProUser = () => {
		let retVal: ProUserModel = {
			userGuId: this.UserName,
			emailAddress: this.Email,
			companyLogo: this.CompanyLogo
		};
		return retVal;
	};
}
// export const AccountStoresContext = createContext(new AccountStore());

// // Libs
// import * as MobX from "mobx";
// import * as Models from "../../Models";

// // App
// import { BaseStore } from "./BaseStore";
// // import * as Globals from "../../Globals";
// import * as ServerApi from "../../ServerApi";
// import * as Stores from "..";

// export class AccountStore extends BaseStore {
// 	public models = MobX.observable<Models.Domain.IdentityUser>([]);

// 	@MobX.observable public id: string | undefined;
// 	@MobX.observable public displayName = "";
// 	@MobX.observable public isLoggedIn = false;
// 	@MobX.observable public jwt: string | undefined = "";

// 	@MobX.computed
// 	public get hasGlobalPermission() {
// 		const hasGlobalPermission = this.hasPermission("global");
// 		return hasGlobalPermission;
// 	}

// 	@MobX.computed
// 	public get isSysAdminViewMode() {
// 		const hasGlobalPermission = this.hasGlobalPermission;
// 		const isSysAdminViewMode = hasGlobalPermission === true;

// 		return isSysAdminViewMode;
// 	}

// 	public permissions = MobX.observable<string>([]);

// 	public constructor(stores: Stores.StoresContext, initialState: Models.InitialState) {
// 		super(stores);

// 		if (initialState) {
// 			this.id = initialState.accountStatus.id!;
// 			this.isLoggedIn = initialState.accountStatus.isLoggedIn;
// 			this.displayName = initialState.accountStatus.displayName;
// 		}
// 		if (initialState && initialState.accountStatus.permissions) {
// 			this.permissions.replace(initialState.accountStatus.permissions);
// 		}
// 	}
// 	@MobX.action
// 	public getLoginState = () => {
// 		this.jwt = localStorage.getItem("jwt") as string;
// 		if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
// 			const data = this.parseJwt(this.jwt);
// 			const accountStatus: Models.Dto.AccountStatus = {} as Models.Dto.AccountStatus;
// 			accountStatus.id = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
// 			accountStatus.isLoggedIn = true;
// 			accountStatus.displayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
// 			accountStatus.jwt = this.jwt;
// 			accountStatus.permissions = [];
// 			accountStatus.permissions.push(data["http://shoothill.com/identity/claims/permission"][0] as string);
// 			(window as any).jwt = this.jwt;
// 			this.getUserData();
// 			this.updateFromAccountStatus(accountStatus);
// 		}
// 	}

// 	public hasPermission = (target: string, level?: "read" | "write") => {
// 		for (const userPermission of this.permissions) {
// 			const userPermissionComponents = userPermission.split(":");
// 			const userPermissionTarget = userPermissionComponents[0];

// 			if (userPermissionTarget === "global") {
// 				return true;
// 			}

// 			if (userPermissionTarget === target) {
// 				if (userPermissionComponents.length === 1) {
// 					return true;
// 				}

// 				if (level == null) {
// 					return true;
// 				}

// 				const userPermissionLevel = userPermissionComponents[1];

// 				if (level === userPermissionLevel) {
// 					return true;
// 				}

// 				if (level === "write" && userPermissionLevel === "read") {
// 					return true;
// 				}
// 			}
// 		}

// 		return false;
// 	}

// 	@MobX.action
// 	public login = (emailAddress: string, password: string, rememberMe: boolean) => {
// 		this.setIsLoading();

// 		return ServerApi.Account.login({ emailAddress, password, rememberMe })
// 			.catch(this.handleHttpError)
// 			.then(apiResult => {
// 				return MobX.runInAction(() => {
// 					if (apiResult.wasSuccessful) {
// 						const accountStatus = apiResult.payload;
// 						(window as any).jwt = accountStatus.jwt;
// 						this.getUserData();
// 						this.updateFromAccountStatus(accountStatus);
// 						// axios.defaults.headers.common["Authorization"] = "Bearer " + accountStatus.jwt;
// 						// don't do this yet this.unsetIsLoading();
// 					} else {
// 						this.unsetIsLoading();
// 					}
// 					return apiResult;
// 				});
// 			});
// 	}

// 	@MobX.action
// 	public logout = () => {
// 		this.setIsLoading();

// 		return ServerApi.Account.logout()
// 			.catch(this.handleHttpError)
// 			.then(apiResult => {
// 				return MobX.runInAction(() => {
// 					if (apiResult.wasSuccessful) {
// 						const accountStatus = apiResult.payload;
// 						this.updateFromAccountStatus(accountStatus);
// 						this.unsetIsLoading();
// 						localStorage.removeItem("jwt");
// 						(window as any).jwt = "";
// 					} else {
// 						this.unsetIsLoading();
// 					}
// 					return apiResult;
// 				});
// 			});
// 	}

// 	@MobX.action
// 	public resetPassword = (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
// 		this.setIsLoading();

// 		return ServerApi.Account.resetPassword({ confirmNewPassword, currentPassword, newPassword })
// 			.catch(this.handleHttpError)
// 			.then(apiResult => {
// 				this.unsetIsLoading();
// 			});
// 	}

// 	@MobX.action
// 	public setPassword = (setPassword: Models.Dto.SetPassword) => {
// 		this.setIsLoading();

// 		return ServerApi.Account.setPassword(setPassword)
// 			.catch(this.handleHttpError)
// 			.then(apiResult => {
// 				return MobX.runInAction(() => {
// 					if (apiResult.wasSuccessful) {
// 						// #2945 temp stopping auto login, since not working
// 						/// const accountStatus = apiResult.payload;
// 						// this.getUserData();
// 						// this.updateFromAccountStatus(accountStatus);
// 						this.unsetIsLoading();
// 					} else {
// 						this.unsetIsLoading();
// 					}
// 					return apiResult;
// 				});
// 			});
// 	}

// 	@MobX.action
// 	private getUserData = () => {
// 		this.setIsLoading();

// 		return ServerApi.ClientUser.getUserAndRelated()
// 			.catch(this.handleHttpError)
// 			.then(apiResult => {
// 				return MobX.runInAction(() => {
// 					if (apiResult.wasSuccessful) {
// 						this.stores!.domain.clientUserStore.syncDto(apiResult.payload);
// 						this.unsetIsLoading();
// 					}
// 					this.unsetIsLoading();
// 					return apiResult;
// 				});
// 			});
// 	}

// 	@MobX.action
// 	private updateFromAccountStatus = (accountStatus: Models.Dto.AccountStatus) => {
// 		const { id, permissions, displayName, isLoggedIn, jwt } = accountStatus;

// 		this.id = id;
// 		this.isLoggedIn = isLoggedIn;
// 		this.displayName = displayName;
// 		this.jwt = jwt;
// 		localStorage.setItem("jwt", this.jwt as string);

// 		if (permissions) {
// 			this.permissions.replace(permissions);
// 		} else {
// 			this.permissions.clear();
// 		}
// 	}

// 	private parseJwt = (token: string) => {
// 		const base64Url = token.split(".")[1];
// 		const base64 = decodeURIComponent(
// 			atob(base64Url)
// 				.split("")
// 				.map(c => {
// 					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
// 				})
// 				.join("")
// 		);

// 		return JSON.parse(base64);
// 	}
// }
