import React, { useEffect } from "react";
import { Footer, Header } from "../Shoothill.Components/General";
import { UseRouter } from "Shoothill.Core/Utils";

// tslint:disable-next-line: comment-format
// EN: use empty generic type to get default react props
export const Layout: React.FC<{}> = props => {
	return (
		<React.Fragment>
			<Header />
			{props.children}
			<Footer />
		</React.Fragment>
	);
};
