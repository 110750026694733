import React, { useContext, useEffect, useState } from "react";
import { UseDataApi, useValidation, InputValues } from "Shoothill.Core/Utils";
import { AddressSelectorGoogle } from "Shoothill.Components/Forms";
import { Loader } from "../General/Loader";
import { MapStore } from "Stores/Domain";
import { Stores, StoresContext } from "Stores";
import { ReportFormModel } from "Models";
import { TextField, InputLabel, Link, Button } from "@material-ui/core";
import validator from "validator";
import {
	DatePicker,
	MaterialUiPickersDate,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as AppUrls from "../../Globals/AppUrls";
import { AccountStore } from "Stores/Domain/AccountStores";

import { ReportOrderBox, PrevNextForm, BtnDiv } from "./ReportRequestStyling";

import navArrow from "../../Content/navArrow.svg";
import navArrowRight from "../../Content/navArrowRight.svg";

interface IReportData {
	type: number;
	reportStep: [number, React.Dispatch<React.SetStateAction<number>>];
	reportOutcome: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const ReportRequest: React.FC<IReportData> = props => {
	const mapStoreContext: MapStore = useContext<Stores>(StoresContext).domain
		.MapStore;
	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
		.AccountStore;
	const { data, doRequest, status, isLoading, isError } = UseDataApi();

	const [formState, { text, email }] = useValidation<ReportFormModel>({
		reportEmail: accountStore.Email,
		payeeEmail: accountStore.Email,
		...(JSON.parse(window.sessionStorage.getItem(
			"reportRequestForm"
		) as string) || new ReportFormModel())
	});

	const [date, dateChange] = useState(new Date());

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var isIE: boolean =
		msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

	const [reportStep, setReportStep] = props.reportStep;
	const [reportOutcome, setreportOutcome] = props.reportOutcome;

	const proOrder = formState.formData as ReportFormModel;

	const saveForm = () => {
		window.sessionStorage.setItem(
			"reportRequestForm",
			JSON.stringify(formState.formData)
		);
	};

	useEffect(() => {
		window.addEventListener("beforeunload", saveForm);

		return () => window.removeEventListener("beforeunload", saveForm);
	});

	const addressCallBack = () => {
		setReportStep(reportStep + 1);
	};

	const maxSteps: number = 5;

	useEffect(() => {
		formState.resetValidationCallbacks();
		formState.remount();
	}, [reportStep]);

	function resetStep(): void {
		setReportStep(1);
	}

	const stopStep = () => {
		setReportStep(0);
	};

	/* 	function stopStep(): void {
		setReportStep(0);
	} */

	function nextStep(): number {
		if (reportStep < maxSteps) {
			setReportStep(reportStep + 1);
		}
		return reportStep;
	}

	function prevStep(): number {
		if (reportStep === 6) {
			setReportStep(0);
		} else if (reportStep > 0) {
			setReportStep(reportStep - 1);
		}
		return reportStep;
	}

	function onSubmit(e: any) {
		e.preventDefault();
		if (formState.isFormValid() && formState.formData.reportEmail != "") {
			setReportStep(reportStep + 1);
		}
	}

	function onSubmitCardDetail(e: any) {
		e.preventDefault();
		if (formState.isFormValid()) {
			proOrder.reportType = props.type;
			proOrder.cardExpMonth = (date.getMonth() + 1).toString();
			proOrder.cardExpYear = date.getFullYear().toString();
			if (reportStep === 6) {
				doRequest(
					AppUrls.Server.Api.ProAccountReportRequest.MakePayment,
					proOrder
				);
				setReportStep(maxSteps);
			} else {
				proOrder.address = mapStoreContext.address;
				setReportStep(reportStep + 1);
			}
		}
	}

	function getResultPageContent() {
		if (status === 402) {
			// Payment required
			// No data: request made when not allowed due to previous failed payment
			// Yes data: payment failed after requesting report

			return (
				<>
					<h3>Something went wrong!</h3>
					{data ? (
						<>
							<p>
								Failed to charge your card. Please try again
								<br />
								Your report will be available once it has been paid for.
								<br />
								You will not be able to purchase any more reports until this
								report has been paid for.
								<br />
								<br />
								<p>Error: {data.errors[0].message}</p>
							</p>
						</>
					) : (
						<>
							<p>Please pay for your last report before ordering a new one.</p>
						</>
					)}
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		} else if (status === 422) {
			// Unprocessable
			// Failed to make payment intent.  data will contain error msg

			return (
				<>
					<h3>Something went wrong!</h3>
					<p>
						We could not accept payment with these details
						<br />
						Problem:
						{" " + data.errors[0].message}
					</p>
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		} else if (status === 200) {
			// Ok
			// Successful, report already available in dashboard and no email will be sent.

			return (
				<>
					<h3>Success!</h3>
					<p>
						Your report has been generated and will shortly be available for
						download on your My Flood Risk Pro dashboard.
					</p>
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		} else if (status === 202) {
			// Accepted
			// Successful, report will be available within an hour

			return (
				<>
					<h3>Success!</h3>
					<p>
						Your request for a report has been accepted and you will receive an
						email when it is ready.
					</p>
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		} else if (status === 503) {
			// Service unavailable
			// Couldn't make report request to MC, refunded

			return (
				<>
					<h3>Sorry!</h3>
					<p>
						A report for this address could not be created. You have not been
						charged.
					</p>
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		} else if (status === 400) {
			// Bad request
			// Stripe payment failed (should never occur, unexpected error)

			return (
				<>
					<h3>Something went wrong!</h3>
					<p>
						An unknown error occured charging your card.
						<br />
						<br />
						Error: {data.errors[0].message}
					</p>
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		} else if (status !== 501) {
			// :shrug:
			// Unexpected error, *should* not have been charged (should never occur)

			return (
				<>
					<h3>Something went wrong!</h3>
					<p>
						An unknown error occured. Please contact us if you have been charged
						without recieving your report.
						<br />
						<br />
						Error: {data.errors[0].message}
					</p>
					<Button className={"outcomeBtn"} onClick={stopStep}>
						Back to Dashboard
					</Button>
				</>
			);
		}
	}

	function onSubmitPlaceOrder(e: any) {
		e.preventDefault();
		if (formState.isFormValid()) {
			setReportStep(reportStep + 1);
			doRequest(
				AppUrls.Server.Api.ProAccountReportRequest.GetMCReport,
				proOrder
			);
		}
	}

	return (
		<>
			<ReportOrderBox>
				{reportStep === 1 && (
					<>
						<h2>What address is the Flood Zone Determination Report for?</h2>
						<p>
							Please enter the address of the property you wish to get a report
							for...
						</p>
						<div className={"PlaceHolderPart nlp addrSelect"}>
							<AddressSelectorGoogle callback={addressCallBack} />
						</div>
						<div className="report-order">
							<div className={"PlaceHolderPart"}></div>
						</div>
						<div className={"ReportBtnGroups"}>
							<PrevNextForm TextAlign={"left"} className={"reportBtn"}>
								<Link onClick={prevStep} className={"previous"}>
									<img src={navArrow} alt="previous" />
									<span>Back to Dashboard</span>
								</Link>
							</PrevNextForm>
						</div>
					</>
				)}

				{reportStep === 2 && (
					<>
						<h4>
							Report for: <span>{mapStoreContext.address}</span>
						</h4>
						<h2>
							Who is the
							{props.type === 1 && <> Guaranteed </>}
							{props.type === 2 && <> Non-Guaranteed </>}
							Flood Zone Determination Report for?
						</h2>
						<p>
							We just need a few bits of information about who is receiving this
							report...
						</p>
						<form onSubmit={onSubmit} className="report-order">
							{/* 							<div className={"fullwidth"}>
								<InputLabel>What is your name?</InputLabel>
							</div>
							<div className={"PlaceHolderPart nlp"}>
								<TextField
									defaultValue={accountStore.FirstName}
									placeholder="First name (required)"
									{...text("firstname", {
										validationRules: [
											(v: string) =>
												(v && v.length > 0) || "* First name is required"
										]
									})}
								/>
							</div>

							<div className={"PlaceHolderPart"}>
								<TextField
									defaultValue={accountStore.LastName}
									placeholder="Last name (required)"
									{...text("lastname", {
										validationRules: [
											(v: string) =>
												(v && v.length > 0) || "* Last name is required"
										]
									})}
								/>
							</div> */}

							<div className={"PlaceHolderPart nlp"}>
								<InputLabel>Your email address?</InputLabel>
								<TextField
									variant={(isIE ? undefined : "outlined") as "outlined"}
									placeholder="Email address (required)"
									{...email("reportEmail", {
										validationRules: [
											(v: string) =>
												validator.isEmail(v) || "* Email address is invalid",
											(v: string) =>
												(v && v.length > 0) || "* Email address is required"
										]
									})}
								/>
								{/* formState.showErrors("reportEmail", "errormessage", 2) */}
							</div>

							<div className={"PlaceHolderPart nlp"}></div>

							<div className={"PlaceHolderPart nlp"}>
								<InputLabel>Secondary email address?</InputLabel>
								<TextField
									variant={(isIE ? undefined : "outlined") as "outlined"}
									placeholder="Email address (optional)"
									{...email("secondaryEmail", {
										validationRules: [
											(v: string) =>
												validator.isEmail(v) ||
												!(v && v.length > 0) ||
												"* Email address is invalid"
										]
									})}
								/>
								{/* formState.showErrors("secondaryEmail", "errormessage", 1) */}
							</div>

							<div className={"PlaceHolderPart nlp"}></div>

							<div className={"PlaceHolderPart"}></div>
						</form>

						<div className={"ReportBtnGroups"}>
							<PrevNextForm TextAlign={"left"} className={"reportBtn"}>
								<Link onClick={prevStep} className={"previous"}>
									<img src={navArrow} alt="previous" />
									<span>Report address</span>
								</Link>
							</PrevNextForm>
							<PrevNextForm TextAlign={"right"} className={"reportBtn"}>
								<Link onClick={onSubmit} className={"next"}>
									<span>Payment details</span>
									<img src={navArrowRight} alt="next" />
								</Link>
							</PrevNextForm>
						</div>
					</>
				)}

				{(reportStep === 3 || reportStep === 6) && (
					<>
						<h4>
							Report for: <span>{mapStoreContext.address}</span>
						</h4>
						<h2>Payment details:</h2>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<form onSubmit={onSubmitCardDetail} className="report-order">
								<div className={"PlaceHolderPart nlp"}>
									<InputLabel>Payee email address?</InputLabel>
									<TextField
										placeholder="Email address (required)"
										variant={(isIE ? undefined : "outlined") as "outlined"}
										value={accountStore.Email}
										{...email("payeeEmail", {
											validationRules: [
												(v: string) =>
													validator.isEmail(v) || "* Email address is invalid",
												(v: string) =>
													(v && v.length > 0) || "* Email address is required"
											]
										})}
									/>
									{/* formState.showErrors("payeeEmail", "errormessage", 2) */}
								</div>

								<div className={"PlaceHolderPart"}>
									<InputLabel></InputLabel>
								</div>

								<div className={"PlaceHolderPart nlp"}>
									<InputLabel>Card details</InputLabel>
									<TextField
										placeholder="Your card number"
										variant={(isIE ? undefined : "outlined") as "outlined"}
										className="cardNumber"
										{...text("cardNumber", {
											validationRules: [
												(v: string) =>
													(v && v.length > 0) || "* Card number is required",
												(v: string) =>
													(v && v.length > 12) || "* Card number is invalid"
											]
										})}
									/>
									{/* formState.showErrors("cardNumber", "errormessage", 1) */}
								</div>

								<div className={"PlaceHolderPart"}>
									<InputLabel className={"hidden"}>
										Expiry Date and CVC code
									</InputLabel>
									<div className={"WithChild"}>
										<div className={"PlaceHolderChild"}>
											<DatePicker
												variant="inline"
												openTo="year"
												className="cardExpiry"
												views={["year", "month"]}
												label="Expiry Month & Year"
												value={date}
												onChange={(changedDate: MaterialUiPickersDate) => {
													if (changedDate) {
														dateChange(changedDate);
													}
												}}
											/>
										</div>

										<div className={"PlaceHolderChild WithChildPad"}>
											<TextField
												placeholder="CVC"
												variant={(isIE ? undefined : "outlined") as "outlined"}
												className="cardCVC"
												{...text("cardCVV2", {
													validationRules: [
														(v: string) => (v && v.length > 0) || "* required",
														(v: string) =>
															(v && v.length >= 3) || "* CVC is invalid"
													]
												})}
											/>
										</div>
									</div>
								</div>

								<div className={"PlaceHolderPart nlp"}>
									<InputLabel>Name on card</InputLabel>
									<TextField
										placeholder="Name as it appears on the card"
										variant={(isIE ? undefined : "outlined") as "outlined"}
										{...text("cardHoldername", {
											validationRules: [
												(v: string) =>
													(v && v.length > 0) ||
													"* Card Holders name is required"
											]
										})}
									/>
									{/* formState.showErrors("cardHoldername", "errormessage", 1) */}
								</div>

								<div className={"PlaceHolderPart"}>
									<InputLabel></InputLabel>
								</div>
							</form>
						</MuiPickersUtilsProvider>

						<div className={"ReportBtnGroups"}>
							<PrevNextForm TextAlign={"left"} className={"reportBtn previous"}>
								<Link onClick={prevStep} className={"previous"}>
									<img src={navArrow} alt="previous" />
									<span>{reportStep === 6 ? "Back" : "Your details"}</span>
								</Link>
							</PrevNextForm>
							<PrevNextForm TextAlign={"right"} className={"reportBtn"}>
								<Link onClick={onSubmitCardDetail} className={"next"}>
									<span>
										{reportStep === 6 ? "Make payment" : "Complete order"}
									</span>
									<img src={navArrowRight} alt="next" />
								</Link>
							</PrevNextForm>
						</div>
					</>
				)}

				{reportStep === 4 && (
					<>
						<h2>Order Summary</h2>
						<div className="report-order purchase-summary">
							<h4>
								<strong>Report address:</strong>
							</h4>
							<h4>
								<span>{mapStoreContext.address}</span>
							</h4>
							<h4>
								<strong>Report for:</strong>
							</h4>
							<h4>
								<span>
									{proOrder.reportEmail +
										(proOrder.secondaryEmail &&
											" & " + proOrder.secondaryEmail)}
								</span>
							</h4>
							<h4>
								<strong>Payment details:</strong>
							</h4>
							<h4>
								<span>
									Card details: **** **** ****{" "}
									{proOrder.cardNumber.substr(
										proOrder.cardNumber.length - 4,
										4
									)}{" "}
									Card expiry:{" "}
									{proOrder.cardExpMonth + "/" + proOrder.cardExpYear} Name on
									card: {proOrder.cardHoldername}
								</span>
							</h4>
							<h4>
								<strong>Order Total:</strong>
							</h4>
							<h4>
								<span>
									$
									{new Intl.NumberFormat("en-US").format(
										proOrder.reportType === 1 ? 9.99 : 4.99
									)}
								</span>
							</h4>
							<div className={"ReportBtnGroups"}>
								<BtnDiv>
									<Button
										onClick={onSubmitPlaceOrder}
										className={"purchaseBtn"}
									>
										Purchase
									</Button>
								</BtnDiv>
								<BtnDiv>
									<Button
										className={"cancelBtn"}
										onClick={() => {
											mapStoreContext.ResetLocation();
											resetStep();
										}}
									>
										Cancel
									</Button>
								</BtnDiv>
							</div>
						</div>
					</>
				)}

				{reportStep === 5 && isLoading && (
					<>
						<Loader
							loadingText="Processing your order..."
							delayBeforeShow={0}
							className={"api-loader"}
						/>
					</>
				)}

				{reportStep === 5 && !isLoading && <>{getResultPageContent()}</>}
			</ReportOrderBox>
		</>
	);
};
