import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { useMediaQuery } from "Shoothill.Core/Utils/Media";
import dropBackground from "../../Content/tearDrop.svg";
import iconCoverageGap from "../../Content/iconCoverageGap.svg";
import myFloodRiskTool from "../../Content/icon-MyFloodRiskTool.svg";
import potentialFloodRiskTool from "../../Content/icon-PotentialFloodRiskTool2.svg";

export const DropRowBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 335px;
  background-color: rgba(187, 231, 255, 0.5);
  margin: 6px 0;
  color: #222120;
  div,
  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
  p {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  @media (min-width: 809px) {
    flex-direction: column;
    width: 270px;
    background-image: url(${dropBackground});
    background-color: transparent;
    background-repeat: no-repeat;
    height: 380px;
    text-align: center;
    padding-top: 72px;
    div,
    p,
    h2 {
      margin: 0 auto 12px;
    }
    p {
      width: 70%;
    }
    h2 {
      width: 80%;
    }
    h2.full {
      width: 100%;
    }
  }

  @media (max-width: 809px) {
    border-radius: 5px;
    padding: 10px 20px;
    height: 80px;
    opacity: 1;
    backdrop-filter: blur(undefined);
    -webkit-backdrop-filter: blur(undefined);
    .grouped {
      width: 225px;
      text-align: left;
      margin: 0px;
      padding: 0px;
      h2 {
        text-align: left;
        font-weight: Bold;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        letter-spacing: -0.4px;
        color: #222120;
      }
      p {
        text-align: left;
        font-weight: normal;
        font-size: 11px;
        font-family: "Lato", sans-serif;
        letter-spacing: -0.3px;
        line-height: 1.2em;
        color: #222120;
      }
    }
  }
`;

export const IconBox = styled(Box)`
  width: 18%;

  @media (min-width: 809px) {
    width: 93px;
    height: 93px;
    text-align: center;
    position: relative;
  }
  img {
    width: 100%;
    height: auto;
    @media (min-width: 809px) {
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }
  @media (max-width: 809px) {
    width: 80px;
    text-align: left;
    img {
      width: 60px;
      padding-top: 3px;
    }
  }
`;

export const HeaderBox = styled(Box)`
  width: 80%;
  @media (min-width: 809px) {
    width: 70%;
    height: 60px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
`;

export const RowDropsCard: React.FC = () => {
  let isMobile: boolean;
  isMobile = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

  return (
    <>
      <DropRowBox>
        <IconBox>
          <img src={myFloodRiskTool} alt={"My Flood Risk Tool"} />
        </IconBox>
        <div className="grouped">
          <HeaderBox>
            <h2 className="full">My Flood Risk Tool</h2>
          </HeaderBox>
          <p>
            Determine your TRUE flood risk, using cutting-edge data that
            directly impacts your property.
          </p>
        </div>
      </DropRowBox>
      <DropRowBox>
        <IconBox>
          <img src={potentialFloodRiskTool} alt={"Potential Flood Loss Tool"} />
        </IconBox>
        <div className="grouped">
          <HeaderBox>
            <h2>
              {!isMobile && (
                <>
                  Potential Flood <br />
                  Loss Tool
                </>
              )}
              {isMobile && <>Potential Flood Loss Tool</>}
            </h2>
          </HeaderBox>
          <p>
            One inch of water can cause $25,000 in damage to your property.
            Calculate your potential flood loss.
          </p>
        </div>
      </DropRowBox>
      <DropRowBox>
        <IconBox>
          <img src={iconCoverageGap} alt={"Coverage Gap"} />
        </IconBox>
        <div className="grouped">
          <HeaderBox>
            <h2>Reducing the {!isMobile && <br />}Coverage Cap</h2>
          </HeaderBox>
          <p>
            Thirty-five million properties in the U.S. carry a substantial flood
            risk, but only five million of these are protected against rising
            water.
          </p>
        </div>
      </DropRowBox>
    </>
  );
};
