import React, {useRef} from "react";
import {
	InputLabel,
	TextField/* ,
	useMediaQuery */
} from "@material-ui/core";

import {forgotPasswordModel} from "../../Models/FormModels/forgotPasswordModel";

import {
	useValidation,
	usePercentageHeightOfWidth
} from "../../Shoothill.Core/Utils";
import validator from "validator";

interface IProps {
    onSubmit: (forgotpasswordModel: forgotPasswordModel) => void;
    token1: string;
    token2: string;
    UserEmail: string;
}

export const ForgotPasswordResetForm: React.FC<IProps> = props => {
    const { onSubmit } = props;
    const btnElement = useRef<HTMLButtonElement>(null);
    const divElement = useRef<HTMLDivElement>(null);
    usePercentageHeightOfWidth(
		[btnElement, divElement],
		0.124
    );

    const [formState, { password, email }] = useValidation<forgotPasswordModel>(
        {
            UserEmail: props.UserEmail,
            Email: "",
            Token1: props.token1,
            Token2: props.token2,
            Password: "",
            ConfirmPassword: ""
        }
    );

    const doSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		if (formState.isFormValid()) {
            onSubmit({ ...formState.formData });
        }
    };

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var isIE: boolean = msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

    return (
        <>
        <form onSubmit={doSubmit} className="register fullw">
            <h2>Reset my password</h2>

            <div className={"PlaceHolderPart nlp"}>
                <InputLabel>What is your login email address?</InputLabel>
                <TextField
                    label=""
                    fullWidth
                    variant={(isIE ? undefined : "outlined") as "outlined"}
                    placeholder="Email address (required)"
                    ref={divElement}
                    {...email("Email", {
                        validationRules: [
                            (v: string) =>
                                validator.isEmail(v) || "* Email address is invalid",
                            (v: string) =>
                                (v && v.length > 0) || "* Email address is required"
                        ]
                    })}
                />
            </div>
            <div className={"PlaceHolderPart nlp"}></div>

            <div className={"PlaceHolderPart nlp"}>
                <InputLabel>Set your new password</InputLabel>
            </div>
            <div className={"PlaceHolderPart nlp"}></div>

            <div className={"PlaceHolderPart nlp"}>
                <TextField
                    label=""
                    fullWidth
                    variant={(isIE ? undefined : "outlined") as "outlined"}
                    placeholder="Password (required)"
                    ref={divElement}
                    {...password("Password", {
                        validationRules: [
                            (v: string) => (v && v.length > 0) || "Password required"
                        ]
                    })}
                />
            </div>
            <div className={"PlaceHolderPart"}>
                <TextField
                    label=""
                    fullWidth
                    variant={(isIE ? undefined : "outlined") as "outlined"}
                    placeholder="Confirm password (required)"
                    ref={divElement}
                    {...password("ConfirmPassword", {
                        validationRules: [
                            (v: string) =>
                                (v && v.length > 0) || "Confirm password required",
                            (v: string, vs: any[]) =>
                                (v && v === vs["Password"]) || "Password does not match"
                        ]
                    })}
                />
            </div>

            <div className={"PlaceHolderPart"}>
                <button type="submit" className={"submitBtn"} ref={btnElement}>
                    Reset my password
                </button>
            </div>
        </form>
        </>
    )
};