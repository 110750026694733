import styled from "styled-components";
/* import {

} from "Shoothill.Components/Styles/Styles"; */
import { Box } from "@material-ui/core";

export const PageContainer: any = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const BlueGradientBox: any = styled(Box)`
	background-image: linear-gradient(
		0deg,
		rgba(65, 144, 202, 1) 0.5%,
		rgba(255, 255, 255, 0.3) 16%,
		rgba(255, 255, 255, 0) 29%
	);
	background-position-y: bottom;
	min-height: calc(100vh - 70px);
	display: flex;
	width: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	padding: 0;
`;

export interface IFloodBackgroundProps {
	imagesrc: string;
}

export const TopFloodBackground: any = styled(Box)<IFloodBackgroundProps>`
	position: absolute;
	background-image: url(${(props) => props.imagesrc});
	height: 101vh /* 115vh */;
	z-index: -1;
	background-repeat: no-repeat;
	background-position-x: center;
	background-size: cover;
	top: 0;
	width: 120vw;
	@media (min-width: 768px) {
		left: -130px;
	}

	div {
		background-image: linear-gradient(
			0deg,
			rgba(255, 255, 255, 1) -0.5%,
			rgba(255, 255, 255, 0.5) 22%,
			rgba(255, 255, 255, -0.95) 45%
		);
		height: 100%;
		width: 100%;
	}

	@media (max-width: 767px) {
		top: -25px;
	}
`;

export const SecondBackground: any = styled(Box)`
	min-height: unset;
	padding: 0px 0px 207px;
	text-align: center;
	width: 100%;
`;

const AppBase: any = styled(Box)`
	color: white;
	width: 906px;

	@media (max-width: 1024px) {
		width: 100%;
		display: block;
		padding-top: 30px;
	}
`;

export const AppHeading: any = styled(AppBase)`
	height: 85vh;
	margin: 15% auto 0;
	@media (max-width: 1024px) {
		margin: 70px auto 0px;
	}

	@media (max-width: 767px) {
		width: 90%;
	}

	h1 {
		font-size: 72px;
		font-weight: bold;
		letter-spacing: -1.8px;
		margin-bottom: 30px;
		@media (max-width: 767px) {
			font-size: 32px;
			margin-top: 0;
			line-height: 24px;
		}
	}

	p {
		line-height: 1.2;
		font-weight: normal;
		font-family: "Lato", sans-serif;
		font-size: 21px;
		width: 100%;
		letter-spacing: -0.53px;
		@media (max-width: 767px) {
			font-size: 12px;
			letter-spacing: -0.3px;
		}

		@media (max-width: 1024px) {
			max-width: 700px;
			margin: 0px auto;
		}
	}
`;

export const AppHead1020: any = styled(AppHeading)`
	@media (min-width: 769px) {
		width: 1020px;

		h1,
		p {
			width: 906px !important;
			margin-left: auto;
			margin-right: auto;
		}

		img {
			display: block;
			margin-top: 45px;
		}
	}

	.MFIBanner {
		margin: 40px 0px;

		@media (min-width: 769px) {
			display: block;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.MFIBannerMobile {
		margin: 40px 0px;

		@media (min-width: 769px) {
			display: none;
		}

		@media (max-width: 768px) {
			display: block;
			img {
				width: 100%;
			}
		}
	}
`;

export const KYOptions: any = styled(AppBase)`
	margin: 30px auto 0px;
	h2 {
		color: #4190ca;
		font-size: 52px;
		margin: 0px 0px 20px;
		@media (max-width: 767px) {
			font-size: 32px;
		}
	}

	p {
		font-size: calc(4px + 2vmin);
		font-weight: normal;
		color: #222120;
	}
`;

export const BoxButton: any = styled(Box)`
	width: 318px;
	margin-left: auto;
	margin-right: auto;
	.rowabout-btnrow {
		display: flex;
		flex: 1;
		align-self: flex-end;
		margin-top: 30px;
	}

	.rowabout-btn {
		display: flex;
		justify-content: center;
		flex-direction: row;
		text-align: center;
		flex: 1;
		flex-basis: 100%;
		border: 4px solid #4190ca;
		border-radius: 10px;
		font-size: 14px;
		height: 72px;
		vertical-align: middle;
		cursor: default;
		width: 318px;
	}

	.rowabout-btnrow:hover,
	.rowabout-btn:hover {
		cursor: pointer;
		text-decoration: none;
	}

	.getquote-btn {
		background-color: #4190ca;
		color: #ffffff;
		padding: 0 25px;
		img,
		div {
			padding: 0;
			margin: 0;
		}
		img {
			width: 35px;
			height: 64px;
		}
		div {
			width: 185px;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 1;
			margin: auto;
		}
	}
`;
