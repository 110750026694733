export class ReportFormModel {
	public reportType: number = 0;
	public address: string = "";
	public reportEmail: string = "";
	public secondaryEmail: string = "";
	public payeeEmail: string = "";
	public firstname: string = "";
	public lastname: string = "";
	public cardNumber: string = "";
	public cardExpMonth: string = "";
	public cardExpYear: string = "";
	public cardCVV2: string = "";
	public cardHoldername: string = "";
}
