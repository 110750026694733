import React, { useContext/* , useEffect */ } from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { Stores, StoresContext } from "../../Stores";
import { useObserver } from "mobx-react-lite";

import { ReportMap } from "../ReportMap/ReportMap";
import MapboxGl from "mapbox-gl";
import ReportMapStyle from "../ReportMap/ReportMapStyle";
import { mapLayers } from "../ReportMap/ReportMapLayers";
import useCountUp from "Shoothill.Core/Utils/Hooks/UseCountUp";

const DefaultCenter: [number, number] = [-80.603523, 28.079929];

const SurgeBox: any = styled(Box)`
  @media (min-width: 768px) {
    margin-right: -60px;
    margin-left: -60px;
    height: 100%;
  }
  h1 {
    font-size: 32px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 18px;
      margin: 20px 0;
    }
  }

  p {
    font-weight: normal;
  }

  /* HACK for IE only */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .IEHACK {
      margin-top: -15px;
    }
  }

  .legends {
    h3 {
      font-size: 14px;
      margin: 0 0 21px;
    }
    div {
      font-size: 10px;
      margin: 0px 0px 15px;
      .b9,
      .b6,
      .b3,
      .b0 {
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: 10px;
        vertical-align: middle;
      }

      .b9 {
        background-color: #e02001;
      }

      .b6 {
        background-color: #ffaa02;
      }

      .b3 {
        background-color: #e8d800;
      }

      .b0 {
        background-color: #0271fe;
      }
    }

    .legendContainer {
      @media (max-width: 767px) {
        margin-bottom: 0;

        .legendItem {
          margin-bottom: 0;
        }
      }
    }

    @media (min-width: 768px) {
      width: 280px;
    }

    margin: 0;
    padding: 0;
    padding-top: 10px;
  }
`;

export const TabletPadding: any = styled.div`
  padding-left: 60px;
  padding-right: 60px;
`;

const MapGroupBox: any = styled(Box)`
  display: inline-flex;
  width: 100%;

  @media (max-width: 1179px) {
    margin-top: 7.5px;
  }

  .Map {
    width: 100%;
  }
`;

export const ReportStormSurge: React.FC = () => {
  const floodDataContext: any = useContext<Stores>(StoresContext).domain
    .FloodAndClaimDataStore;

  function getMapStyle(): MapboxGl.Style {
    const mapStyle: typeof ReportMapStyle = JSON.parse(
      JSON.stringify(ReportMapStyle)
    ) as typeof ReportMapStyle;

    for (const mapLayer of mapLayers) {
      const style: any = mapLayer.styles.find(s => s.id === mapLayer.styleId)!;
      style.apply(mapStyle, mapLayer.isVisible, mapLayer.opacity);
    }

    return mapStyle;
  }

  let mobileLayout: boolean;
  mobileLayout = useMediaQuery("screen and (max-width: 767px)") ? true : false;

  let tabletLayout: boolean;
  tabletLayout = useMediaQuery("screen and (max-width: 1179px)") ? true : false;

  const ZoomCounter = useCountUp({
    end: 12.75,
    duration: 0.5,
    start: 8,
    capDecimals: false
  });
  const DefaultZoom: [number] = [ZoomCounter.counter];

  return useObserver(() => {
    const center: [number, number] = DefaultCenter;
    const _zoom: [number] = DefaultZoom;

    const _mapStyle: MapboxGl.Style = getMapStyle();

    const text: any = (
      <>
        <h1>Storm Surge</h1>
        <p>
          Storm surge occurs when a hurricane moves over tidal water. As the
          storm approaches land, the winds push the water forward, creating
          waves of a greater height than normal tides.
        </p>
        <p>
          Using data from the National Oceanic and Atmospheric Administration
          (NOAA), this map shows the areas at risk of storm surge in the event
          of a Category 5 hurricane or sustained winds greater than 157 MPH.
          NOAA’s data demonstrates that storm surge is not just a beachfront
          concern, with the risk of surge extending several miles inland from
          the immediate coastline in many areas.
        </p>
      </>
    );

    const legend: any = (
      <>
        <div className="legends">
          <h3>Height of storm surge:</h3>
          <Grid container className=" legendContainer">
            <Grid item xs={6} className=" legendItem">
              <div className="row">
                <span className="b9"></span>Greater than 9ft
              </div>
              <div className="row">
                <span className="b6"></span>6 - 9ft
              </div>
            </Grid>
            <Grid item xs={6} className=" legendItem">
              <div className="row">
                <span className="b3"></span>3 - 6ft
              </div>
              <div className="row">
                <span className="b0"></span>less than 3ft
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );

    const map: any = (
      <>
        <MapGroupBox>
          <div className="Map">
            {/* HACK CMR: slice is used to return a "real" array
                            https://mobx.js.org/refguide/array.html#array-limitations-in-mobx-4-and-below */}
            <ReportMap
              className="flex-fill"
              center={center.slice() as [number, number]}
              zoom={_zoom.slice() as [number]}
              style={_mapStyle}
              datastore={floodDataContext}
            />
          </div>
        </MapGroupBox>
      </>
    );

    return (
      <>
        <SurgeBox>
          {!mobileLayout && !tabletLayout && (
            <>
              <Grid container style={{ height: "100%" }}>
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    height: "100%"
                  }}
                >
                  {text}
                  {legend}
                </Grid>
                <Grid item xs={6} className="IEHACK">
                  {map}
                </Grid>
              </Grid>
            </>
          )}
          {mobileLayout && (
            <>
              {text}
              {map}
              {legend}
            </>
          )}
          {tabletLayout && !mobileLayout && (
            <>
              <TabletPadding>
                {text}
                {map}
                {legend}
              </TabletPadding>
            </>
          )}
        </SurgeBox>
      </>
    );
  });
};
