import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import styled from "styled-components";
import { Box } from "@material-ui/core";
/* import { isNullorWhiteSpace } from "../../Shoothill.Core/Utils/Validation"; */

export const FloodZoneBox: any = styled(Box)`
  @media (min-width: 1180px) {
    width: 1020px;
  }
  display: inline-flex;
  font-weight: normal;

  @media (max-width: 767px) {
    width: 100%;
    display: block;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 18px;
      margin: 20px 0;
    }
  }

  .floodzone {
    width: 100%;

    p {
      @media (max-width: 767px) {
        margin: 20px 0;
      }
    }
  }

  .FloodZoneMap {
    @media (max-width: 767px) {
      max-width: 280px;
      max-height: 280px;
      margin: auto;
    }

    @media (min-width: 768px) {
      width: 280px;
      margin-right: 30px;
      margin-top: 30px;
    }

    img {
      width: 100%;
      height: auto;
    }
    .fyi {
      font-size: 4px;
    }
  }
  .FloodZoneText {
    width: 100%;
    padding: 0;
    margin: 30px 0;
    @media (max-width: 767px) {
      margin: 20px 0;
    }
    margin-bottom: 0;
    .ResultHeading {
      color: #222120;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
      font-size: 21px;
      letter-spacing: 0px;
      margin-top: 0;
      @media (max-width: 767px) {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .Result {
      text-align: left;
      font: Bold 92px "Lato", sans-serif;
      letter-spacing: 0;
      color: #ee00ee;
      line-height: 0.75;
      @media (max-width: 767px) {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
    .UnMapped {
      font-size: 69px;
      text-transform: uppercase;
    }
    p {
      text-align: left;
      font: normal 14px "Lato", sans-serif;
      letter-spacing: 0;
      color: #292b28;
      overflow-wrap: break-word;
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #222120;
      line-height: 17px;
      letter-spacing: 0px;
      @media (max-width: 767px) {
        margin: 30px 0 20px;
      }
    }
  }
`;

export const ReportFloodZone: React.FC = () => {
  const floodDataContext: any = useContext<Stores>(StoresContext).domain
    .FloodAndClaimDataStore;

  let floodzoneStr: string = floodDataContext.fldZone.substring(0, 1);
  floodzoneStr = floodzoneStr.toLowerCase();

  return (
    <>
      <FloodZoneBox>
        <div className="floodzone">
          <h1>Flood Zone</h1>
          <p>
            Flood zones are geographic areas designated by FEMA, largely based
            on historical data about the severity and type of flooding that has
            previously occurred in the area. Over 50% of FEMA flood zone maps
            are out of date.
          </p>
        </div>
      </FloodZoneBox>
      {floodzoneStr === "" && (
        <>
          <FloodZoneBox>
            <div className="FloodZoneText">
              <h3 className="ResultHeading">Your flood zone is...</h3>
              <span className="Result UnMapped">Unmapped</span>
              <h4>Undetermined Risk Area</h4>
              <p>
                Areas that have either not been mapped by FEMA, or that do not
                participate in the National Flood Insurance Program, sometimes
                also referred to as Zone D areas. Flood insurance is not
                federally required for properties in unmapped areas, but it is
                available and recommended.
              </p>
            </div>
          </FloodZoneBox>
        </>
      )}

      {floodzoneStr !== "" && (
        <>
          <FloodZoneBox>
            <div className="FloodZoneMap">
              <img src={floodDataContext.mappng} alt="Map" />
              {/* <div className="fyi">lat { floodDataContext.lat }, lng { floodDataContext.lng } </div> */}
            </div>
            <div className="FloodZoneText">
              <h3 className="ResultHeading">Your flood zone is...</h3>
              <span className="Result">{floodDataContext.fldZone}</span>
              {(floodzoneStr === "b" ||
                floodzoneStr === "c" ||
                floodzoneStr === "x") && (
                <>
                  <h4>Moderate to Low Risk Areas</h4>
                  <p>
                    Areas that FEMA considers to be at moderate-to-low risk of
                    flooding. More than 20 percent of National Flood Insurance
                    Program claims and one-third of federal disaster assistance
                    for flooding comes from these zones. Flood insurance is not
                    federally required for properties in moderate-to-low risk
                    zones, but it is recommended.
                  </p>
                </>
              )}
              {floodzoneStr === "d" && (
                <>
                  <h4>Undetermined Risk Areas</h4>
                  <p>
                    Unstudied areas where flood risk has not been assessed by
                    FEMA. Flooding is possible. Flood insurance is not federally
                    required for properties in D zones, but it is recommended.
                  </p>
                </>
              )}
              {floodzoneStr === "a" && (
                <>
                  <h4>High Risk Areas</h4>
                  <p>
                    Areas that are FEMA-designated special flood hazard areas.
                    There is a 1 percent chance of flooding in any given year,
                    and a 26 percent chance of flooding during a 30-year
                    mortgage. All property owners in A zones with
                    federally-backed mortgages are required to carry flood
                    insurance.
                  </p>
                </>
              )}
              {floodzoneStr === "v" && (
                <>
                  <h4>High Risk Coastal Areas</h4>
                  <p>
                    Areas that are FEMA-designated special flood hazard areas
                    with additional hazards due to velocity wave action (storm
                    surge). There is a 1 percent chance of flooding in any given
                    year, and a 26 percent chance of flooding during a 30-year
                    mortgage. All property owners in V zones with
                    federally-backed mortgages are required to carry flood
                    insurance.
                  </p>
                </>
              )}
            </div>
          </FloodZoneBox>
        </>
      )}
    </>
  );
};
