import React from "react";
import {
    Grid
} from "@material-ui/core";

interface IProps {
    children: any;
}

// type is React.ComponentType<GridProps> but GridProps is not exported from "@material-ui/core"
export const Centered: any = ({ children }: IProps) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
        >
            <Grid item>{children}</Grid>
        </Grid>
    );
};
