import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { PromptBox } from "../../Shoothill.Components/Styles";

import iconAboutOurMission from "../../Content/icon-about-ourMission.svg";
import iconAboutOurValues from "../../Content/icon-about-ourValues.svg";
import iconAboutOurVision from "../../Content/icon-About-ourVision.svg";

export const MissionRowBox: any = styled(Box)`
    margin-left: 36px;
    margin-right: 36px;
    max-width: 340px;
    text-align: center;
    width: 100%;

    img {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    h3 {
        color: #4190ca;
        font-size: 28px;
        margin-top: 25px;
        margin-bottom: 17px;
    }

    p {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        margin-top: 17px;
    }

    .paragraphContainer {
        p:first-child {
            margin-bottom: 0;
        }
        p:last-child {
            margin-top: 0;
        }
    }

    @media (max-width: 414px) {
        background-color: rgba(77, 89, 101, 0.62);
        border-radius: 5px;
        display: flex;
        margin: 7px 0;
        min-height: 95px;
        padding: 8px 20px;
        text-align: left;

        > div {
            display: flex;

            > div:first-child {
                display: flex;
                height: auto;
                margin: auto 20px auto 0;
                width: auto;

                img {
                    width: 58px;
                }
            }

            > div:last-child {
                margin: auto 0;

                h3 {
                    font-size: 16px;
                    margin: 0;
                }

                p {
                    font-size: 12px;
                    line-height: 1.15;
                    margin: 0;
                }

                .paragraphContainer {
                    display: flex;
                    margin: 0 0 5px 0;

                    p:first-child {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
`;

export const ImageBox: any = styled(PromptBox)``;

export const RowMissionCard: React.FC = () => {
    return (
        <>
            <MissionRowBox>
                <Box fontSize="h2.fontSize">
                    <ImageBox>
                        <img
                            src={iconAboutOurMission}
                            alt={"About our mission"}
                        />
                    </ImageBox>
                    <Box>
                        <h3>Our Mission</h3>
                        <Box
                            className="paragraphContainer">
                            <p>
                                To raise flood awareness by providing vital tools that
                                empower you to protect yourself and your property from
                                rising waters.
                            </p>
                        </Box>
                    </Box>
                </Box>
            </MissionRowBox>
            <MissionRowBox>
                <Box fontSize="h2.fontSize">
                    <ImageBox>
                        <img
                            src={iconAboutOurVision}
                            alt={"About our vision"}
                        />
                    </ImageBox>
                    <Box>
                        <h3>Our Vision</h3>
                        <Box
                            className="paragraphContainer">
                            <p>
                                Our goal is to change the perception of true flood risk in America.
                            </p>
                        </Box>
                    </Box>
                </Box>
            </MissionRowBox>
            <MissionRowBox>
                <Box fontSize="h2.fontSize">
                    <ImageBox>
                        <img
                            src={iconAboutOurValues}
                            alt={"About our values"}
                        />
                    </ImageBox>
                    <Box>
                        <h3>Our Values</h3>
                        <Box
                            className="paragraphContainer">
                            <p>
                                People
                                <br />
                                Awareness
                            </p>
                            <p>
                                Preparation
                                <br />
                                Empowerment
                            </p>
                        </Box>
                    </Box>
                </Box>
            </MissionRowBox>
        </>
    );
};
