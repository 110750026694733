import { BaseStore } from "./BaseStore";
import { observable, action } from "mobx";

export class UserStore extends BaseStore {
	@observable public EmailAddress: string = "";
	@observable public DisplayName: string = "";
	@observable public IsProfessional: boolean = false;

	public constructor() {
		super();
	}

	@action
	public reset(): void {
		this.EmailAddress = "";
		this.DisplayName = "";
		this.IsProfessional = false;
	}

	@action
	public populate(store: any): void {
		this.EmailAddress = store.EmailAddress;
		this.DisplayName = store.DisplayName;
		this.IsProfessional = store.IsProfessional;
	}
}
