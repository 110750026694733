import styled from "styled-components";
import { Box } from "@material-ui/core";


export const ResultBox : any = styled(Box)`
    width: 650px;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #e0daf0;
    div{
        width: 100%;
        >span{
            display: inline-block;
            width: 20%;
            vertical-align: top;
            padding: 5px;
        }
        span.curr{
            text-align: right;
            padding-right: 90px;
        }

        >span:first-child{
            width: 33%;
        }
    }
    div:nth-child(odd){
        background-color: #eeeeee;
    }
`;

export const SummaryBox: any = styled(ResultBox)`
    text-align: center;
    font-size: 22px;
`;

export const DetailBox: any = styled(ResultBox)`
    margin: 20px 20px 0px;
    padding: 20px 20px 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
`;