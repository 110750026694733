import { BaseStore } from "./BaseStore";
import { observable, action, computed, ObservableMap } from "mobx";
import { QuoteCalcData } from "../../Models/QuoteCalcData";

export class QuoteStore extends BaseStore {
	@observable private _coverageData: Map<number, number> = new Map();
	@observable private _buildingCoverageIndex: number = 4;
	private prp?: boolean;
	private garage?: boolean;
	private _premium: number[] = [];

	public constructor() {
		super();
	}

	@action
	public setCalcData(calcData: QuoteCalcData[]): void {
		calcData.forEach(calcDat =>
			this._coverageData.set(calcDat.building, calcDat.contents)
		);
		this._premium = calcData.map(cD => cD.premium);
		this.prp = calcData[0].prp;
		this.garage = calcData[0].garage;
	}

	@computed
	public get contentsCoverage(): number {
		return this._coverageData.get(
			Array.from(this._coverageData.keys())[this._buildingCoverageIndex]
		)!;
	}

	@action
	public setBuildingCoverageIndex(coverage: number): void {
		this._buildingCoverageIndex = coverage;
	}

	@computed
	public get buildingCoverageIndex(): number {
		return this._buildingCoverageIndex;
	}

	public getHasChanged(prp: boolean, garage: boolean) {
		return prp !== this.prp || garage !== this.garage;
	}

	public get valueOfIndex(): number {
		return Array.from(this._coverageData.keys())[this._buildingCoverageIndex];
	}

	public get premium(): number {
		return this._premium[this._buildingCoverageIndex];
	}
}
