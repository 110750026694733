import styled from "styled-components";
import { GenericModalBox } from "../Styles/ModalStyles";

export const TermsModalBox: any = styled(GenericModalBox)`
    outline: none;

    .termsmodal-wrapper {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
    }

    div {
        width: 100%;
    }

    .termsmodal-title {
        height: 70px;

        .termsmodal-title-text {
            position: relative;
            display: inline-block;
            color: #4190ca;
            font-weight: 700;
            font-size: 21px;
            height: 56px;
            line-height: 56px;
            vertical-align: center;
        }
    }

    @media screen and (max-width: 768px) {
        .termsmodal-title {
            height: 60px;

            .termsmodal-title-text {
                font-size: 18px;
                height: 46px;
                line-height: 46px;
            }
        }
    }

    @media screen and (max-width: 576px) {
        .termsmodal-title {
            height: 45px;

            .termsmodal-title-text {
                font-size: 15px;
                height: 32px;
                line-height: 32px;
            }
        }
    }

    @media screen and (max-width: 388px) {
        .termsmodal-title {
            height: 45px;

            .termsmodal-title-text {
                font-size: 14px;
                height: 32px;
                line-height: 32px;
            }
        }
    }

    .termsmodal-text {
        padding: 15px;
        height: 252px;
        overflow-y: scroll;
        border: 1px solid #e0daf0;
        opacity: 0.95;
    }

    .button-row {
        width: 100%;
        text-align: center;
    }
`;

export const PrivacyModalBox: any = styled(GenericModalBox)`
    outline: none;

    .privacymodal-wrapper {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
    }

    div {
        width: 100%;
    }

    .privacymodal-title {
        height: 70px;

        .privacymodal-title-text {
            position: relative;
            display: inline-block;
            color: #4190ca;
            font-weight: 700;
            font-size: 21px;
            height: 56px;
            line-height: 56px;
            vertical-align: center;
        }
    }

    @media screen and (max-width: 768px) {
        .privacymodal-title {
            height: 60px;

            .privacymodal-title-text {
                font-size: 18px;
                height: 46px;
                line-height: 46px;
            }
        }
    }

    @media screen and (max-width: 576px) {
        .privacymodal-title {
            height: 45px;

            .privacymodal-title-text {
                font-size: 15px;
                height: 32px;
                line-height: 32px;
            }
        }
    }

    @media screen and (max-width: 388px) {
        .privacymodal-title {
            height: 45px;

            .privacymodal-title-text {
                font-size: 14px;
                height: 32px;
                line-height: 32px;
            }
        }
    }

    .privacymodal-text {
        padding: 15px;
        height: 252px;
        overflow-y: scroll;
        border: 1px solid #e0daf0;
        opacity: 0.95;
    }

    .button-row {
        width: 100%;
        text-align: center;
    }
`;
