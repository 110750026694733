import { useEffect, RefObject } from "react";

export function usePercentageHeightOfWidth(
	els: RefObject<HTMLElement>[],
	perc: number,
	enabled?: boolean
) {
	enabled = enabled === undefined ? true : enabled;
	var height: number = -1;

	function onResize() {
		if (enabled) {
			if (window.innerWidth <= 1140) {
				els.forEach(el => {
					if (el.current) {
						var par: HTMLElement = el.current.parentElement as HTMLElement;
						var style = window.getComputedStyle(par);
						if (style.width && style.paddingLeft && style.paddingRight) {
							var parWid = +style.width.replace("px", "");
							parWid -=
								+style.paddingLeft.replace("px", "") +
								+style.paddingRight.replace("px", "");
							height = parWid * perc;
							el.current.style.height = `${height}px`;
						}
					}
				});
			} else {
				els.forEach(el => {
					if (el.current) {
						el.current.style.height = null;
					}
				});
			}
		}
	}

	useEffect(
		() => {
			onResize();
		},
		(() => {
			var els1: HTMLElement[] = [];
			els.forEach(el => {
				els1.push(el.current as HTMLElement);
			});
			return els1;
		})()
	);

	useEffect(() => {
		window.addEventListener("resize", onResize);

		return () => window.removeEventListener("resize", onResize);
	});
}
