import styled from "styled-components";
import { Box, Grid, Container } from "@material-ui/core";
import ArrowDown from "../../Content/ArrowDown.svg";
import house1 from "../../Content/smallHouse.jpg";
import house2 from "../../Content/MediumHouse.jpg";
import house3 from "../../Content/bigHouse.jpg";
import pflbackground from "../../Content/PFL-BG.jpg";
import riskwizardbackground from "../../Content/RiskWizardBG.jpg";
import aboutbackground from "../../Content/aboutBG.jpg";
import contactbackground from "../../Content/contactBG.jpg";
import homebackground from "../../Content/home-topBG.jpg";
import middlehomebackground from "../../Content/homeNewMidBG2.png";
import chevron from "../../Content/Chevron-unselected.svg";
import chevronSelected from "../../Content/Chevron-selected.svg";
import chevronLong from "../../Content/Chevron-long-unselected.svg";
import chevronLongSelected from "../../Content/Chevron-long-selected.svg";
import chevronMed from "../../Content/Chevron-med-unselected.svg";
import chevronMedSelected from "../../Content/Chevron-med-selected.svg";
import chevronMob from "../../Content/Chevron-mobile.svg";
import chevronMobLight from "../../Content/Chevron-mobile-light.svg";

export const House1 = house1;
export const House2 = house2;
export const House3 = house3;

export const pflimage = pflbackground;
export const riskwizardimage = riskwizardbackground;
export const aboutimage = aboutbackground;
export const contactimage = contactbackground;
export const homeimage1 = homebackground;
export const homeimage2 = middlehomebackground;

export const Chevron = chevron;
export const ChevronSelected = chevronSelected;
export const ChevronLong = chevronLong;
export const ChevronLongSelected = chevronLongSelected;
export const ChevronMed = chevronMed;
export const ChevronMedSelected = chevronMedSelected;
export const ChevronMob = chevronMob;
export const ChevronMobLight = chevronMobLight;

export const LdrImg = styled(Box)`
	background-image: url(${pflimage});
	background-image: url(${aboutimage});
	background-image: url(${contactimage});
	background-image: url(${homeimage1});
	background-image: url(${homeimage2});
`;

export const StyledButton = styled.button`
	font-size: 1em;
	margin: 1em;
	padding: 0.25em 1em;
	border-radius: 3px;
	background-color: cyan;

	/* Color the border and text with theme.main */
	color: ${props => props.theme.main};
	border: 2px solid ${props => props.theme.main};
`;

export const Uppercase = styled.div`
	* {
		text-transform: uppercase;
	}
`;

export const Centered = styled.div`
	text-align: center;
`;

export interface StyledProps {
	pb?: string;
	pt?: string;
	mt?: string;
	mb?: string;
	inline?: boolean;
	color?: string;
}
//&& bumps up the importance of the style to override global styles. The more you use the higher it will be
export const Header4 = styled.h4<StyledProps>`
	text-shadow: rgb(255, 255, 255) 2px 2px 5px;
	text-transform: uppercase;
	padding-bottom: ${props => props.pb};
	padding-top: ${props => props.pt};
	margin-top: ${props => props.mt};
	margin-bottom: ${props => props.mb};
	color: ${props => props.color};
	margin: 0px;
	font-size: 52px;
	font-weight: 700;
	display: ${props => (props.inline ? "inline" : "block")};
`;

export const FancyBox = styled(Box)`
	display: inline-block;
	transition: background-color 0.5s ease-in-out;
	&:hover {
		background-color: #f2893e;
		/* animation: color 4s ease-in-out; */
	}
`;

export const BackGroundBase = styled(Grid)`
	text-align: center;
	min-height: 100vh;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const PromptBox = styled(Box)`
	margin: 0 auto;
	width: 126px;
	height: 126px;
	img {
		margin: auto;
	}
`;

export const GridSmall = styled(Grid)`
	max-width: 826px;
`;

export const GridLarge = styled(Grid)`
	max-width: 1040px;
`;

export const Container1040 = styled(Container)`
	max-width: 1040px;
`;

export const FilterBox = styled(Box)`
	padding-bottom: 61px;
	p {
		padding-bottom: 23px;
	}
	@media screen and (max-width: 1024px) {
		padding-bottom: 0px;
		padding: 0px 32px 20px;
		p {
			font-size: 16px;
			padding-bottom: 10px !important;
		}
	}
	input {
		text-align: left;
	}
	.MuiSelect-icon {
		top: 0 !important;
		width: 54px;
		height: 100%;
		color: transparent;
		background-image: url(${ArrowDown});
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #e0daf0;
	}
`;

export const TermsOL = styled.ol`
	ol,
	> li,
	li h6,
	li p {
		margin: 0;
		padding: 0;
	}
	ol {
		width: 100%;
	}
	> li {
		margin-top: 5px;
		font-weight: bold;
	}
	li,
	li p {
		padding-left: 10px;

		h6 {
			font-size: 0.875rem;
		}
	}
`;

export const PageBasis: any = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
`;

export interface StyledBox {
	/*   width?: string;
  height?: string; */
	mg?: string;
	pd?: string;
	color?: string;
	bgcolor?: string;
	radius?: string;
	minht?: string;
}

export const StyledRadiusBox: any = styled(Box)<StyledBox>`
/*   width: ${props => props.width};
  min-height: ${props => props.height}; */
	border-radius: ${props => props.radius};
	background-color: ${props => props.bgcolor};
	color: ${props => props.color};
	padding: ${props => props.pd};
	margin: ${props => props.mg};
	min-height: ${props => props.minht}px;
`;