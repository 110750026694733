export const StringToArray = (contentString: string, delimiter: string) => {
	let delimiterChar = "";

	if (delimiter != null) {
		delimiterChar = delimiter;
	}

	const occurencies = contentString.indexOf(delimiterChar);

	if (occurencies < 0) {
		return [];
    }
    
    let retArr = contentString.split(delimiterChar);
    // returns array with the whitespaces trimmed from each value
	return retArr.map(arr => arr.trim());
};