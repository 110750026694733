import { AuditProUserModel, AuditProOrderModel, AuditSearch } from "../../Models";
import { reportAdminModel } from "../../Models/FormModels/";
import { BaseStore } from "./BaseStore";
import { observable, action, computed, ObservableSet } from "mobx";

import { isNullorEmpty } from "Shoothill.Core/Utils/Validation/isNullorEmpty";

type Order = 'asc' | 'desc';

export class AuditProStores extends BaseStore {
    @observable public auditProUsers: AuditProUserModel[] = [];
    @observable public auditProOrders: AuditProOrderModel[] = [];
    @observable public auditProMFRrep: AuditSearch[] = [];

    @observable private isAPILoading: boolean = false;
    @observable public orderBy: string = "createdOn";
    @observable public order: Order = "desc";
    @observable public moreUserCounter: number = 10;
    @observable public tableUserCount: number = this.moreUserCounter;

    @observable public moreOrderCounter: number = 10;
    @observable public tableOrderCount: number = this.moreOrderCounter;

    @observable public moreMFRCounter: number = 10;
    @observable public tableMFRCount: number = this.moreMFRCounter;

    @observable public displayName: reportAdminModel | undefined;
/* 
    @observable public startDateUTC: string = "";
    @observable public endDateUTC: string = ""; */

    @observable public userQty: number = 0;
    @observable public MFRQty: number = 0;
    @observable public FZDQty: number = 0;

    // Users
    @action
    public SetProUsers(users: AuditProUserModel[]): void {
        this.auditProUsers = users;
        this.userQty = users.length > 0 ? users[0].userQty : 0;
        this.isAPILoading = false;
    }

    @action
    public ResetUser(): void {
        this.auditProUsers.length = 0;
        this.auditProUsers = [];
        this.tableUserCount = this.moreUserCounter;
    }

    @action
    public sortUserHandler = (property: keyof AuditProUserModel) => {
        if (property === this.orderBy) {
            if (this.order === "asc") {
                this.order = "desc";
            } else {
                this.order = "asc";
            }
        } else {
            this.orderBy = property;
            this.order = "asc";
        }
    };

    @action
    public getMoreUserRecords(): void {
        if ((this.tableUserCount + this.moreUserCounter) > this.auditProUsers.length) {
            this.tableUserCount = this.auditProUsers.length;
        } else {
            this.tableUserCount += this.moreUserCounter;
        }
    }

    @action
    public setMoreUserCounter(value: number | string | null): void {
        if (value !== null && value !== undefined) {
            let counter: number = 0;
            if (this.isNumber(value) === true)
            {
                counter = <number>value;
            } else {
                counter = parseFloat(value.toString()); 
            }
    
            this.moreUserCounter = counter
            this.tableUserCount = 0;
            this.getMoreUserRecords();
        }
    }

    @computed
    public get countUserRecords(): number {
        return this.userQty;
    }

    @computed
    public get filteredUserRecords(): AuditProUserModel[]  {

        let sortedRecords: AuditProUserModel[] = this.auditProUsers;

        let a: any = this.getSorting(this.order, this.orderBy);

        sortedRecords = this.stableSort<AuditProUserModel>(sortedRecords, a);

        return sortedRecords.slice(0, this.tableUserCount);
    }

    // MFR Orders
    @action
    public SetProMFRorder(mfr: AuditSearch[]): void {
        this.auditProMFRrep = mfr;
        this.isAPILoading = false;
    }

    @computed get getMFR(): AuditSearch[] {
		if (this.auditProMFRrep) {
			return this.auditProMFRrep;
		}
		return [];
	}

    @action
    public ResetMFR(): void {
        this.auditProMFRrep.length = 0;
        this.auditProMFRrep = [];
        this.tableMFRCount = this.moreMFRCounter;
    }

    @action
    public sortMFRHandler = (property: keyof AuditSearch) => {
        if (property === this.orderBy) {
            if (this.order === "asc") {
                this.order = "desc";
            } else {
                this.order = "asc";
            }
        } else {
            this.orderBy = property;
            this.order = "asc";
        }
    };

    @action
    public getMoreMFRRecords(): void {
        if ((this.tableMFRCount + this.moreMFRCounter) > this.auditProMFRrep.length) {
            this.tableMFRCount = this.auditProMFRrep.length;
        } else {
            this.tableMFRCount += this.moreMFRCounter;
        }
    }

    @action
    public setMoreMFRCounter(value: number | string | null): void {
        if (value !== null && value !== undefined) {
            let counter: number = 0;
            if (this.isNumber(value) === true)
            {
                counter = <number>value;
            } else {
                counter = parseFloat(value.toString()); 
            }
    
            this.moreMFRCounter = counter
            this.tableMFRCount = 0;
            this.getMoreMFRRecords();
        }
    }

    @computed
    public get filteredMFRRecords(): AuditSearch[]  {

        let sortedRecords: AuditSearch[] = this.auditProMFRrep;

        let a: any = this.getSorting(this.order, this.orderBy);

        sortedRecords = this.stableSort<AuditSearch>(sortedRecords, a);

        return sortedRecords.slice(0, this.tableOrderCount);
    }

    // FZD Orders
    @action
    public SetProOrders(orders: AuditProOrderModel[]): void {
        this.auditProOrders = orders;
        this.isAPILoading = false;
    }
    
    @computed get getFZD(): AuditProOrderModel[] {
		if (this.auditProOrders) {
			return this.auditProOrders;
		}
		return [];
	}

    @action
    public ResetOrder(): void {
        this.auditProOrders.length = 0;
        this.auditProOrders = [];
        this.tableOrderCount = this.moreOrderCounter;
    }

    @action
    public sortOrderHandler = (property: keyof AuditProOrderModel) => {

        if (property === this.orderBy) {
            if (this.order === "asc") {
                this.order = "desc";
            } else {
                this.order = "asc";
            }
        } else {
            this.orderBy = property;
            this.order = "asc";
        }
    };

    @action
    public getMoreOrderRecords(): void {
        if ((this.tableOrderCount + this.moreOrderCounter) > this.auditProOrders.length) {
            this.tableOrderCount = this.auditProOrders.length;
        } else {
            this.tableOrderCount += this.moreOrderCounter;
        }
    }

    @action
    public setMoreOrderCounter(value: number | string | null): void {
        if (value !== null && value !== undefined) {
            let counter: number = 0;
            if (this.isNumber(value) === true)
            {
                counter = <number>value;
            } else {
                counter = parseFloat(value.toString()); 
            }
    
            this.moreOrderCounter = counter
            this.tableOrderCount = 0;
            this.getMoreOrderRecords();
        }
    }

    @computed
    public get filteredOrderRecords(): AuditProOrderModel[]  {

        let sortedRecords: AuditProOrderModel[] = this.auditProOrders;

        let a: any = this.getSorting(this.order, this.orderBy);

        sortedRecords = this.stableSort<AuditProOrderModel>(sortedRecords, a);

        return sortedRecords.slice(0, this.tableOrderCount);
    }

    // Common
    private isNumber(value: string | number): boolean
    {
        return ((value != null) && !isNaN(Number(value.toString())));
    }

    private stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order: number = cmp(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    private getSorting<K extends keyof any>(
        order: Order,
        orderBy: K,
        ): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
            return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    private desc<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    @action
    public setAPIIsLoading(isAPILoading: boolean): void {
        this.isAPILoading = isAPILoading;
    }

    @action
    public getAPICall(url: string, data: any): string {
        let retVal: string = url + "?";

        let dataString: string = Object.keys(data)
        .map((key: any) => {
            return [key, data[key]].map(encodeURIComponent).join("=");
        })
        .join("&");

        return retVal + dataString;
    }
}
