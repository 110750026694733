import styled from "styled-components";
import { Box, Button } from "@material-ui/core";

import acceptedCards from "../../Content/acceptedCards.png";
import calendarIcon from "../../Content/calendarIcon.png";
import cvcCardback from "../../Content/cvcCardback.png";

export const BtnDiv: any = styled.div`
	/* @media (max-width: 1140px) { */
	:nth-of-type(2) {
		@media (min-width: 1141px) {
			text-align: right;
		}
	}
	width: 50%;
	button {
		width: 97% !important;
		margin-right: 0 !important;

		:nth-of-type(2) {
			text-align: right;
		}
	}
	/* } */
`;

export const ReportOrderBox: any = styled(Box)`
	margin: 0;
	padding: 0;
	text-align: left;
	margin-top: 20px;
	height: 514px;
	position: relative;

	@media (min-width: 1141px) {
		padding: 0 60px 30px;
	}

	@media (max-width: 1140px) {
		height: unset;
		/* min-height: 350px; */

		.addrSelect {
			height: 34px;
		}

		form input {
			border-radius: 10px;
			padding: 10px;
		}
	}

	.report-order {
		padding-left: 0px;
		padding-right: 0px;

		@media (max-width: 1140px) {
			.PlaceHolderPart {
				min-height: 0px;
				margin-bottom: 0;
			}

			label {
				margin: 0px 0px 5px !important;
			}

			.cardExpiry,
			.cardCVC,
			.cardNumber {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}

	@media (min-width: 1141px) {
		.WithChild {
			height: 52px;
			margin-top: -16px;
		}

		.WithChildPad {
			margin-left: 30px !important;
		}
	}

	h2,
	h4,
	p {
		color: #292b28;
		padding: 0;
		span {
			color: #4190ca;
		}
	}

	h2 {
		font-size: 32px;
		margin: 18px 0px;
	}

	h4 {
		font-size: 21px;
	}

	label {
		margin: 15px 0px 10px !important;
		height: 21px;
	}

	@media (max-width: 1140px) {
		input,
		.addrSelect input {
			height: 34px !important;
			padding: 9px 15px;
			::placeholder {
				font-size: 12px;
				color: #464545;
			}
		}
	}

	.fullwidth {
		width: 100%;
	}

	.nlp {
		padding-left: 0px;
	}

	.ReportBtnGroups {
		display: inline-flex;
		width: 100%;
		padding: 0;
		margin: 0;
		@media (min-width: 1141px) {
			left: 60px;
			position: absolute;
			bottom: 30px;
		}

		div,
		button {
			@media (min-width: 1141px) {
				margin-right: 30px;
			}
			cursor: pointer;
		}
		> div:last-child {
			@media (min-width: 1141px) {
				margin-left: 30px;
			}
			margin-right: 0px;
		}

		button {
			width: 495px;
		}
	}

	.cardNumber input,
	.cardExpiry input,
	.cardCVC input {
		background-repeat: no-repeat;
		background-position: 95% center;
	}

	@media (min-width: 1141px) {
		.cardNumber input,
		.cardExpiry input,
		.cardCVC input {
			background-image: url(${acceptedCards});
			padding-right: 15px;
		}
	}

	.cardExpiry input {
		background-image: url(${calendarIcon});
	}

	.cardCVC input {
		background-image: url(${cvcCardback});
	}

	.api-loader {
		vertical-align: middle;
		/* margin-top: 15%; */
		padding-top: 15%;
		> div {
			h5 {
				color: #292b28;
				font-size: 24pt;
			}
			> div {
				background-color: #005792 !important;
				div {
					background-color: #79b2da !important;
				}
			}
		}
		img {
			display: none;
		}
	}

	.purchase-summary {
		h4 {
			margin: 10px 0;
		}
		.ReportBtnGroups {
			margin-top: 65px;
		}
		@media (max-width: 1140px) {
			h4 {
				margin: 5px 0;
				span {
					line-height: 1;
				}
			}

			.ReportBtnGroups {
				button {
					margin: 0 !important;
				}
				margin-top: 30px;
				position: static;
			}
		}

		.ReportBtnGroups {
			position: static;
		}
	}
`;

export interface IPrevNextButtonProps {
	TextAlign: string;
}

export const PrevNextForm: any = styled(Box)<IPrevNextButtonProps>`
	max-width: 480px;
	width: 100%;
	text-align: ${props => props.TextAlign};
	font-size: 32px;
	padding: 0px;

	a {
		color: #464545;
		background: no-repeat;
		width: 100%;
		img {
			vertical-align: top;
		}
	}

	a:hover {
		text-decoration: none;
	}
	a.previous img {
		margin: -3px 0px 0px -10px;
	}
	a.next img {
		margin: -3px -10px 0px 0px;
	}

	@media (max-width: 1140px) {
		font-size: 14px;
		width: 45% !important;
		a.next img {
			margin: -3px -38px 0px 0px;
		}

		a img,
		a span {
			display: inline-block;
		}

		a span {
			width: 60%;
			word-wrap: wrap;
		}
	}
`;
