import React, { useState, useContext } from "react";
import { Theme, Button } from "@material-ui/core";
import Select from "react-select";
import { useTheme, makeStyles } from "@material-ui/styles";
import { GeocodeResult } from "../../Models";
import { StoresContext, Stores } from "../../Stores";
import formChevronRight from "../../Content/Chevron-right.svg";
/* import { useValidation } from "../../Shoothill.Core/Utils"; */
// import _clone from "lodash/clone";
import styled from "styled-components";
import { MapStore } from "../../Stores/Domain/MapStore";
import { UseRouter } from "../../Shoothill.Core/Utils";

const useStyles: any = makeStyles((theme: Theme) => ({
  yourriskForm: {
    display: "flex",
    width: "100%",
    padding: "0",
    margin: "0"
  },
  textField: {
    backgroundColor: "#FFFFFF",
    height: "54px",
    marginRight: "5px",
    maxWidth: "100%",
    textAlign: "left",
    width: "100%",
    "@media (min-width: 1466px)": {
      minWidth: "305px"
    },
    placeholder: {
      color: "#000000"
    },
    color: "#000000"
  },
  textFieldZip: {
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    paddingLeft: "5px",
    height: "54px",
    marginRight: "5px",
    "@media (min-width: 1466px)": {
      maxWidth: "177px",
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px"
    },
    "& placeholder": {
      color: "#000000"
    }
  },
  yourRiskButton: {
    width: "54px",
    height: "54px",
    margin: "0px",
    backgroundColor: "#e0daf0",
    color: "#4190ca",
    "@media (min-width: 1466px)": {
      maxWidth: "54px",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px"
    }
  }
}));

export const StyledForm: any = styled.form`
  input {
    color: #464545 !important;
    font-weight: normal;
  }

  div[class$="-container"] {
    font-weight: normal;
    > div {
      padding: 0 0 0 4px;
      border: none;
      font-size: 21px;
      cursor: text;
      span {
        background-color: transparent;
      }
      div {
        padding: 0 0 0 4px;
        border: none;
      }
    }
  }

  div[class$="-menu"] {
    margin: 0;
    border-radius: 0;
    div {
      div {
        cursor: pointer;
        font-size: 16px;
        border-bottom: solid 1px #efefef;
        padding: 5px;
      }
    }
  }

  svg {
    top: 0 !important;
    width: 54px;
    height: 54px;
    color: transparent;
    cursor: pointer;
  }
`;

/* const accessToken: string =
    "pk.eyJ1IjoiYW5keWp1bXAiLCJhIjoiY2pudnYzYWI4MDJ5eDNxbzhldWNhc3ZlZiJ9.fD-fnggIKvMR5QQLlb1M3Q"; */

export interface IAddressOptionsModel {
  label: string;
  value: string;
}

export interface IRiskModel {
  zipCode: string;
}

export interface IAddressSelectorProps {
  gotoMyRisk?: boolean | undefined;
}

/* export const AddressSelector: React.FC = () => { */
export const AddressSelector: React.FC<IAddressSelectorProps> = props => {
  const { gotoMyRisk } = props;
  const { history } = UseRouter();
  const theme: any = useTheme();
  const classes: any = useStyles(theme);
  const mapStoreContext: MapStore = useContext<Stores>(StoresContext).domain
    .MapStore;
  /*     const [formState] = useValidation<IRiskModel>({
        zipCode: ""
    } as any); */
  const [address1, setAddress1] = useState(mapStoreContext.address);
  /*     const [searchAddress, setSearchAddress] = useState(""); */
  const [geocoderResults /*, setGeocoderResults */] = useState(
    [] as GeocodeResult[]
  );
  const [selectorResults /*, setSelectorResults*/] = useState(
    [] as IAddressOptionsModel[]
  );
  (window as any).selectorResults = selectorResults;

  // const mapboxClient: MapiClient = MapboxClient({ accessToken: accessToken });
  // const mapboxGeocoder: GeocoderService = MapboxGeocoder(mapboxClient);

  // const buildGeoCodeResults: any = (searchText: string) => {
  //     const zipCode: string = formState.formData.zipCode;
  //     setSearchAddress(searchText);

  //     if (searchText !== "" && searchText.length >= 3) {
  //         searchText = zipCode + " " + searchText;
  //         mapboxGeocoder!
  //             .forwardGeocode({
  //                 query: searchText,
  //                 countries: ["us"],
  //                 mode: "mapbox.places",
  //                 types: ["address"]
  //             })
  //             .send()
  //             .then(
  //                 response => {
  //                     const features: any = _clone(response.body.features);
  //                     features.sort((f: { relevance: any }) => f.relevance);
  //                     let localResults: GeocodeResult[] = [];
  //                     let localOptionsResults: IAddressOptionsModel[] = [];

  //                     if (features.length > 0) {
  //                         for (let feature of features) {
  //                             if (feature.place_type.includes("address")) {
  //                                 let result: GeocodeResult = {
  //                                     bBox: feature.bbox,
  //                                     center: feature.center,
  //                                     placeName: feature.place_name,
  //                                     placeType: feature.place_type,
  //                                     text: feature.text,
  //                                     type: feature.type,
  //                                     region: ""
  //                                 };

  //                                 for (let contextItem of feature.context) {
  //                                     let contextItemId: string =
  //                                         contextItem.id;
  //                                     if (
  //                                         contextItemId.startsWith("region")
  //                                     ) {
  //                                         result.region = contextItem.short_code.replace(
  //                                             "US-",
  //                                             ""
  //                                         );
  //                                     }
  //                                 }

  //                                 localResults.push(result);

  //                                 let selectorResult: IAddressOptionsModel = {
  //                                     label: feature.place_name,
  //                                     value: feature.place_name
  //                                 };
  //                                 localOptionsResults.push(selectorResult);
  //                             }
  //                         }

  //                         setGeocoderResults(localResults);
  //                         setSelectorResults(localOptionsResults);
  //                     }
  //                 },
  //                 error => {
  //                     alert(
  //                         "Something went wrong while searching for a location."
  //                     );
  //                 }
  //             );
  //     }
  // };

  const setLocation: (e: any) => void = (e: any): void => {
    e.preventDefault();

    if (address1 !== "") {
      const selectedGeoCodeResult: GeocodeResult | undefined = geocoderResults
        ? geocoderResults.find(g => g.placeName === address1)
        : undefined;

      if (selectedGeoCodeResult) {
        mapStoreContext.SetLocation(selectedGeoCodeResult);
      }
    }

    if (gotoMyRisk !== undefined && gotoMyRisk) {
      redirectToRisk();
    }
  };

  const setSelected: (e: any) => void = (e: any): void => {
    setAddress1(e.value);
  };

  function redirectToRisk(): void {
    history.push("/MyRisk");
  }

  return (
    <StyledForm onSubmit={setLocation} className={classes.yourriskForm}>
      <Select
        placeholder={"Type your address here..."}
        className={classes.textField}
        inputId="react-select-single"
        TextFieldProps={{
          label: "Country",
          InputLabelProps: {
            htmlFor: "react-select-single",
            shrink: true
          },
          placeholder: ""
        }}
        // onInputChange={(e: any) => buildGeoCodeResults(e)}
        onChange={(e: any) => setSelected(e)}
        options={selectorResults}
      />

      <Button
        variant="contained"
        type="submit"
        className={classes.yourRiskButton}
      >
        <img src={formChevronRight} alt="Click to determine your risk now" />
      </Button>
    </StyledForm>
  );
};
