import styled from "styled-components";
import { Box, Button, Grid } from "@material-ui/core";

export const AddrBox: any = styled.div`
	@media (max-width: 1140px) {
		div {
			height: 100%;
		}
		input,
		.addrSelect input {
			height: 100% !important;
			padding: 9px 15px;
			::placeholder {
				font-size: inherit;
				color: #464545;
				overflow: visible;
			}
		}
	}
`;

export const LogoHeaderDiv: any = styled.div`
	@media (min-width: 1141px) {
		width: 495px;
		position: relative;
	}
`;

export const DownloadReportGrid: any = styled(Grid)`
	margin-top: 40px;
	margin-bottom: 60px;

	> div {
		:first-of-type {
			margin: 0 0 40px;
		}

		@media (max-width: 1140px) {
			margin: 0 0 10.2%;
		}
	}

	img {
		display: block;
		width: 100%;
		max-width: 320px;
		height: auto;
	}

	@media (max-width: 1140px) {
		margin-top: calc((100% + 60px) * 0.06);
		margin-bottom: 0;

		p,
		h3 {
			/* display: none; */
		}
		* > div > div > img {
			padding-right: 20px;
		}

		.btnGrid {
			display: flex;
			align-items: center;
		}
	}
`;

export const NGTypeGrid: any = styled(Grid)`
	@media (max-width: 1140px) {
		margin-bottom: 10.2% !important;
	}
`;

export const DownloadReportButton: any = styled(Button)`
	width: 100%;
	background-color: #4190ca;
	border-radius: 10px;
	color: white;
	font-weight: bold;
	font-size: 21px;
	display: block;
	line-height: initial;

	@media (min-width: 1141px) {
		margin-top: 20px;
		line-height: 24px;
		width: 365px;
		height: 95px;
	}

	@media (max-width: 1140px) {
		margin: 0;
	}

	:hover {
		background-color: #4190ca;
	}
	.MuiTouchRipple-root {
		display: none;
	}

	> span {
		@media (min-width: 1141px) {
			display: flex;
			justify-content: flex-start;
		}

		@media (max-width: 1140px) {
			height: 100%;

			> div:first-of-type {
				float: left;
				height: 100%;
				display: flex;
				width: 16%;
				margin: 0 2%;

				img {
					width: 100%;
				}
			}

			> div:nth-of-type(2) {
				display: grid;
				height: 100%;
			}
		}

		span {
			margin: auto;

			@media (min-width: 1141px) {
				padding-right: 8px;
			}
		}
	}
	img {
		@media (min-width: 1141px) {
			padding-left: 22px;
		}
		margin: 0 !important;
	}
`;

export const FZDReportGrid: any = styled(Grid)`
	@media (max-width: 1140px) {
		:nth-of-type(1) {
			img {
				width: 40.7%;
				margin: auto;
				padding-right: 0;
				margin-bottom: calc(6.8% - 10px);
			}
		}

		:nth-of-type(2) {
			> div {
				width: 100%;
				h3 {
					text-align: center;
					margin-bottom: 3.4%;
				}
				p {
					margin-bottom: 6.8%;
				}
				.sub1 {
					margin-bottom: 3.4%;
					line-height: 115%;
				}
			}
			p {
				font-size: inherit !important;
			}
			flex-direction: column;
		}

		width: 100%;
		max-width: initial;
		flex-basis: initial;
	}
`;
