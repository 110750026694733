import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";

import { LoginEmbed, LoginModel } from "../../Shoothill.Components/Account";
import { accountNameModel } from 'Models/FormModels/accountNameModel';
import { ForgotPassword } from "../../Shoothill.Components/Account";

import homepageLogo from "../../Content/myfloodprologo.svg";
import {
	ProBackground,
	PageContainer,
	ProRadiusBox
} from "../../Shoothill.Components/Styles/ProAccStyling";
import { Link } from '@material-ui/core';
import { LogoHeaderDiv } from 'Shoothill.Components/Pro/BrandedStyling';
import { UseDataApi, UseRouter } from 'Shoothill.Core/Utils';


export const SetPassword: React.FC = () => {
    const [viewStep, setViewStep] = useState(0);
    const [rer, forcerer] = (window as any).rer;
    const { history } = UseRouter();

    // Submit request to API
    const { data: userSet, doRequest: userRequest } = UseDataApi();
	const handlePostback: (emailModel: accountNameModel) => void = (
		emailFormModel: accountNameModel
	): void => {
		userRequest("/api/account/forgotpasswordemail", emailFormModel);
    };

    useEffect(() => {
        forcerer();
		if (userSet !== null) {
            setViewStep(1);
		}
    }, [userSet, viewStep]);

    function onClickGoPro(): void {
        history.push("/pro");
    }

    const logoHeader = (
        <>
            <Link
                component={RouterLink}
                to="/Pro"
                className={"Logo"}
            >
                <img src={homepageLogo} alt="MyFlood Risk Pro" />
            </Link>
        </>
    );

    return (
        <>
			<Helmet>
				<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
				<title>My Flood Risk Pro</title>
			</Helmet>
			<ProBackground className="mui-fixed">
				<PageContainer pageHeightIE={"580"}>
					<div className="mobMargins">
						<ProRadiusBox
							color="#464545"
							background={"rgba(230,246,255,0.7)"}
							radius="30px"
							mg="auto"
							pd={"30px"}
							mobdesignheight={"557"}
							minheight={507}
						>
                            {logoHeader}
                            { viewStep === 0 &&
                            <>
                            <ForgotPassword
                                onSubmit={handlePostback}
                            />
                            </>
                            }
                            { viewStep === 1 && 
                            <>
                            <div className="register">
                            {userSet && userSet.wasSuccessful ? (
								<>
                                <h2>Email sent succesfully!</h2>
                                <h3 className={"req"}>
                                    <span>
                                    An email has been sent to you containing a link to reset your password.
                                    <br />Remember to check your junk/spam folder if nothing has appeared in your inbox.
                                    </span>
                                </h3>
                                <div className={"PlaceHolderPart"}>
                                    <button className={"submitBtn"} onClick={onClickGoPro}>
                                        Return to Login
                                    </button>
                                </div>
                                </>
                            ) : (
                                <>
                                <h2>Error</h2>
                                <h3 className={"req"}>
                                    <span>{userSet && userSet.errors[0].message}</span>
                                </h3>
                                <div className={"PlaceHolderPart"}>
                                    <button className={"submitBtn"} onClick={onClickGoPro}>
                                        Return to Login
                                    </button>
                                </div>
                                </>
                            )}
                            </div>
                            </>
                            }
                        </ProRadiusBox>
                    </div>
                </PageContainer>
            </ProBackground>
        </>
    )
};