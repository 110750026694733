import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import { PageRoot /* , WizGrid */ } from "./MyRiskWizardStyles";
import { AppHeader } from "./FloodLossStyles";

export const AppHeaderStart: any = styled(AppHeader)`
	height: calc(100vh - 70px);
	@media (min-width: 768px) {
		display: flex;
	}
	/*   margin-top: -20px;
  @media screen and (min-width: 1040px) {
    min-height: 50vh !important;
  }

  .AddressSelector {
    margin: 30px auto;
    max-width: 603px;
  }

  .image {
    display: none;
  } */
	min-height: 50vh !important;
	.AddressSelector {
		margin: 30px auto;
		max-width: 350px;
		@media screen and (min-width: 1040px) {
			max-width: 603px;
		}
	}
	.image {
		display: none;
	}
	.gridContainer {
		@media (max-width: 767px) {
			margin-top: 30px;
		}
	}
`;

interface IWhiteGradientBoxProps {
	gradient: string;
}

export const WhiteGraditentBox: any = styled(Box)<IWhiteGradientBoxProps>`
	background-image: ${props => props.gradient};
	min-height: calc(100vh - 70px);
	display: flex;
	align-items: center;
	width: 100%;
	height: auto;

	/* @media (min-width: 768px) and (min-height: 744px) {
		height: 0;
	} */

	@media (max-width: 767px) {
		/* margin-top: 70px; */
	}
`;

export const MyRiskMain: any = styled.main`
	width: 100%;

	.loginprompt {
		text-align: center;
	}

	.regSuccess {
		text-align: center;
		margin: 30px auto 20px !important;
		line-height: 100%;
		font-size: 34px;
	}
`;

export const WelcomeBox: any = styled(Box)`
	text-align: center;
	h1,
	.image,
	p {
		display: inline-flex;
		margin: 0;
		padding: 0;
	}
	h1 {
		color: #ffffff;
		font-size: 32px;
		margin-bottom: 11px;
		@media (min-width: 728px) {
			font-size: 72px;
			margin-bottom: 24px;
		}
	}
	.image {
		width: 100%;
		margin-bottom: 14px;
		img {
			width: 61px;
			height: 100%;
			margin: 0 auto;
		}
		@media (min-width: 728px) {
			width: 94px;
			height: 94px;
			padding-top: 26px;
			img {
				width: 100%;
			}
		}
	}
	p {
		font-family: "Lato", sans-serif;
		display: block;
		margin: 0 auto;
		width: 277px;
		font-size: 11px;
		font-weight: normal;
		line-height: 15px;
		@media (min-width: 728px) {
			font-size: 21px;
			width: 100%;
			line-height: 1.43;
		}
	}
`;

export const MyRiskRoot: any = styled(PageRoot)`
	height: 100% !important;
	padding: 0px !important;
	.information,
	.mapPlaceholder {
		margin: 30px 0;
	}

	.information {
		margin-top: 14px;
		text-align: left;
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 12px;
		}
		h1,
		p {
			margin: 0;
			padding: 0;
		}
		p.red {
			margin-top: 20px;
		}
		h1 {
			text-align: left;
			font: Bold 18px/20px "Lato", sans-serif;
			letter-spacing: -0.45px;
			color: #464545;
			opacity: 1;
			height: 34px;
		}
		p {
			text-align: left;
			letter-spacing: 0;
			color: #292b28;
			opacity: 1;
			font-weight: normal;
		}

		@media (max-width: 1024px) {
			margin-top: 0;
		}
	}
`;

export const MyLocationBox: any = styled(Box)`
	display: block;
	border-radius: 10px;
	border: none;
	/* width: 318px; */
	height: 54px;
	text-align: center;
	font-weight: normal;
	background-color: #4190ca;
	color: #ffffff;
	padding: 0;
	margin: 5px auto;
	min-width: 210px;

	@media (max-width: 1179px) {
		width: 100%;
	}

	@media (max-width: 960px) {
		margin-top: 39px;
		margin-bottom: 27px;
	}

	a,
	button {
		/* width: 318px; */
		height: 54px;
		display: block;
		padding: 16px;
		font-size: 16px;
		color: white;
		margin: auto;
		width: 100%;
		background-color: transparent;

		span {
			height: 100%;
			display: flex;
		}
	}
	a:hover {
		text-decoration: none;
	}
`;

export const NotMyLocationBox: any = styled(MyLocationBox)`
	background-color: #464545;

	@media (max-width: 1179px) {
		margin-bottom: 48px;
		margin-top: 0;
	}
`;

export const PlacementBox: any = styled(Box)`
	/* margin-top: -70px; */
	.paddingBox {
		@media (max-width: 767px) {
			padding-top: 0;
		}
	}

	.flexContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.addressButtons {
			margin-bottom: 48px;
			display: flex;
			padding: 0 48px;

			@media (max-width: 1179px) {
				display: contents;
			}
		}
	}

	@media (max-width: 960px) {
		.mapRoot {
			min-height: unset;
			height: 304px;
			border-radius: unset;
		}

		.mobMap {
			min-height: unset;
			border-radius: unset;
		}
	}

	h2 {
		margin-bottom: 37px;
		text-align: left;
	}
`;

export const TitleHeader: any = styled.h1`
	font-size: 32px;
	text-align: left;
	font-family: "Lato", sans-serif;
	font-weight: bold;
	letter-spacing: 0;
	color: #292b28;
	margin-top: 0px;

	@media (max-width: 767px) {
		font-size: 24px;
		margin-bottom: 0;
	}
`;

export const TallGrid: any = styled(Grid)`
	height: 100%;
	margin: 0px;
	width: 100%;
`;

export const Pad60LeftBox: any = styled(Box)`
	@media (min-width: 768px) {
		padding: 48px 48px 0;
	}

	@media (max-width: 767px) {
		h2 {
			font-size: 15px;
		}
	}
	display: flex;
	flex-direction: column;
`;
