import React, { useContext, useEffect } from "react";
import moment from "moment";

import { StoresContext, Stores, DomainStores } from "../../Stores";
import { AuditSearch } from 'Models';

import { Box, Grid, Button, Modal, Typography, Table, TableBody, TableCell, TableSortLabel, TableHead, TableRow, Paper, Link} from "@material-ui/core";
import { useReportStyles, ReportModalBox } from "../../Shoothill.Components/Styles/ReportStyles";
import { useAdminStyles} from "../../Shoothill.Components/Styles/AdminStyles";
import { Row1040 } from "../../Shoothill.Components/Styles/BlogRefStyles";

import { useRefLibStyles } from "../../Shoothill.Components/Styles/RefLibStyles";
import editIcon from "../../Content/visibility-24px.svg";
import { GenericCancelButton } from "../../Shoothill.Components/Styles/ModalStyles";
import { isNullorEmpty } from "Shoothill.Core/Utils/Validation";
import { useObserver } from 'mobx-react-lite';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

interface IProps {
    userGuId: string;
    sDate: string;
    eDate: string;
}

export interface headCell {
    id: keyof AuditSearch;
    label: string;
    allowSorting: boolean;
}

export const MFRListComp: React.FC<IProps> = props => {
    const classes: any = useReportStyles();
    const adminClasses: any = useAdminStyles();
    const refClasses: any = useRefLibStyles("contact");
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState<number | null>(null);
    const [selectedSearch, setSelectedSearchItem] = React.useState<AuditSearch | null>(null);
  
    useEffect(() => {
      setSelectedSearchItem(null);
      if (!isNullorEmpty(selectedRow)) {
  
        if (selectedRow != null && selectedRow >= 0 && selectedRow < AuditProStoresContext.filteredMFRRecords.length) {
          setSelectedSearchItem(AuditProStoresContext.filteredMFRRecords[selectedRow]);
        }
      }
    }, [selectedRow]);

    const AuditProStoresContext: DomainStores["AuditProStores"] = useContext<Stores>(StoresContext).domain.AuditProStores;

    function downloadCSV(): void {
      window.open(
          AuditProStoresContext.getAPICall("/api/auditprouser/getmfrcsv", {
              start: formatDate(new Date(props.sDate)),
              end: formatDate(new Date(props.eDate), true),
              guid: props.userGuId
          })
      );
    }

    function formatDate(date: Date, isEndDate: boolean = false): string {
      let count: number = 0;
      if (isEndDate) {
          count = 1;
      }
      const utc: moment.Moment = moment(date).add(count, "days").startOf("day").utc();
      return utc.toISOString();
    }

    function formatDateTime(date: string): string {
        const mom: moment.Moment = moment.utc(date);
        return mom.local().format("MM/DD/YYYY HH:mm");
    }

    function formatYesNo(value: boolean): string {
        return value === true ? "Yes" : "No";
    }

    function handleLoadMore(): void {
        AuditProStoresContext.getMoreMFRRecords();
    }

    function showLoadMore(): boolean {
        return (
            AuditProStoresContext.auditProMFRrep.length >
            AuditProStoresContext.tableMFRCount
        );
    }

    const handleCounterChange: (event: any, newValue: number) => void = (event: any, newValue: number): void => {
        AuditProStoresContext.setMoreMFRCounter(newValue);
    };

    const createSortHandler = (property: keyof AuditSearch) => (event: React.MouseEvent<unknown>) => {
        AuditProStoresContext.sortMFRHandler(property);
    };

    const headCells: headCell[] = [
        { id: 'createdDateTime', label: 'Date', allowSorting: true},
        { id: 'name', label: 'Name', allowSorting: true},
        { id: 'address1',  label: 'Address 1', allowSorting:true },
        { id: 'state', label: 'State', allowSorting: true },
        { id: 'downloadedReport', label: 'PDF', allowSorting:true },
        { id: 'quoteClicked',  label: 'Quoted', allowSorting: true },
        { id: 'stormSurge', label: 'Storm Surge', allowSorting: true },
        { id: 'hurricanes', label: 'Hurricanes', allowSorting: true },
        { id: 'mfrScore', label: 'Score', allowSorting:true }
    ];

    const handleOpen: () => void = (): void => {
        setOpen(true);
      };
    
      const handleClose: () => void = (): void => {
          setOpen(false);
      };

    const rowClicked: (rowId: number) => void = (rowId: number): void => {
        setSelectedRow(rowId);
        handleOpen();
    };

    return useObserver(() => (
        <>
        <div className="buttons">
          <Button
            className={adminClasses.adminBtn}
            variant="outlined"
            onClick={downloadCSV}
          >
            {"Download CSV"}
          </Button>
        </div>
        <div className="adminReportResults">
          {  AuditProStoresContext.isLoading === true &&
            <div>Loading!!!!!!!!!!!!!!!</div>
          }

          { (AuditProStoresContext.isLoading === false && AuditProStoresContext.filteredMFRRecords.length > 0) &&
            <>
              <div className={classes.toggleContainer}>
                <ToggleButtonGroup
                  value={AuditProStoresContext.moreMFRCounter.toString()}
                  exclusive
                  onChange={handleCounterChange}
                  aria-label="text alignment"
                    >
                      <ToggleButton value="10" aria-label="left aligned">
                        10
                      </ToggleButton>
                      <ToggleButton value="50" aria-label="centered">
                        50
                      </ToggleButton>
                      <ToggleButton value="100" aria-label="right aligned">
                        100
                      </ToggleButton>
                    </ToggleButtonGroup>
                <div className={classes.toggleContainerSummary}>
                    Showing 1-{AuditProStoresContext.filteredMFRRecords.length}
                    &nbsp;of {AuditProStoresContext.filteredMFRRecords.length} Records.
                </div>
              </div>
              <div>
              <div className={classes.horizontalScroll}>
                <Table className={classes.table}>
                  <TableHead>
                      <TableRow className={classes.tableHeader}>
                          {headCells.map(headCell => (
                            <TableCell
                              key={headCell.id}
                              className={classes.tableHeaderCell}
                              sortDirection={AuditProStoresContext.orderBy === headCell.id ? AuditProStoresContext.order : false}
                            >
                              <TableSortLabel
                                active={AuditProStoresContext.orderBy === headCell.id}
                                direction={AuditProStoresContext.order}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                                {AuditProStoresContext.orderBy === headCell.id ? (
                                  <span className={classes.visuallyHidden}>
                                    {AuditProStoresContext.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                        <TableCell className={classes.tableHeaderCell} component="th" scope="row">View</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {(AuditProStoresContext.filteredMFRRecords != null && AuditProStoresContext.filteredMFRRecords.length > 0) &&
                        <>
                          {AuditProStoresContext.filteredMFRRecords.map((n: AuditSearch, index: number) => {
                            return (
                              <TableRow key={n.id}
                                className={n.quoteClicked === true ? classes.quoteRow : ""}>
                                <TableCell className={classes.tableRowCellDate}>
                                  {formatDateTime(n.createdDateTime).substring(0,10)}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  { !isNullorEmpty(n.name) && 
                                  <>
                                    {n.name.substring(0,10)}
                                  </>
                                  }
                                </TableCell>
                                <TableCell className={classes.tableRowCellAddress}>
                                  {n.address1}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  {n.state}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  {formatYesNo(n.downloadedReport)}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  {formatYesNo(n.quoteClicked)}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  {formatYesNo(n.stormSurge)}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  {formatYesNo(n.hurricanes)}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                  {n.mfrScore}
                                </TableCell>
                                <TableCell 
                                  className={classes.tableRowCellClickable}
                                  onClick={() => rowClicked(index)}
                                >
                                  <img className={classes.editIcon} src={editIcon} alt="Edit Row"/>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      }
                  </TableBody>
                </Table>
              </div>
              <div className={classes.whiteToBlueGrad}>
                {showLoadMore() && (
                    <Row1040 container>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                className={refClasses.loadMoreBtn}
                                onClick={handleLoadMore}
                            >
                                Load more
                            </Button>
                        </Grid>
                    </Row1040>
                )}
              </div>
              </div>
            </>
          }

        </div>
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            className={adminClasses.modalScroll}
        >
            <ReportModalBox>
                <div >
                  {selectedSearch == null &&
                    <div>
                      No item selected. Please try again.
                    </div>
                  }

                  {selectedSearch != null &&
                    <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Search Date :</div>
                            <div className={classes.modalValue}> {formatDateTime(selectedSearch.createdDateTime)}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Name :</div>
                            <div className={classes.modalValue}> {selectedSearch.name}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Email :</div>
                            <div className={classes.modalValue}> {selectedSearch.email}</div>
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Latitude :</div>
                            <div className={classes.modalValue}> {selectedSearch.latitude}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Longitude :</div>
                            <div className={classes.modalValue}> {selectedSearch.longitude}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>&nbsp;</div>
                            <div className={classes.modalValue}>&nbsp;</div>
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Address1 :</div>
                            <div className={classes.modalValue}>{selectedSearch.address1}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Town :</div>
                            <div className={classes.modalValue}>{selectedSearch.town}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>County :</div>
                            <div className={classes.modalValue}>{selectedSearch.county}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>State :</div>
                            <div className={classes.modalValue}>{selectedSearch.state}</div>
                          </div>
                          <div className={classes.modalRow} >
                            <div className={classes.modalLabel}>Zip :</div>
                            <div className={classes.modalValue}>{selectedSearch.zipCode}</div>
                          </div>
                          <p className={classes.buffer}>&nbsp;</p>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className={classes.modalRow} >
                              <div className={classes.modalDataLabel}>Flood Zone :</div>
                              <div className={classes.modalValue}>{selectedSearch.floodZone}</div>
                          </div>
                          <div className={classes.modalRow} >
                              <div className={classes.modalDataLabel}>Elevation Difference :</div>
                              <div className={classes.modalValue}>{selectedSearch.elevationDifference}</div>
                          </div>
                          <div className={classes.modalRow} >
                              <div className={classes.modalDataLabel}>Storm Surge :</div>
                              <div className={classes.modalValue}>{formatYesNo(selectedSearch.stormSurge)}</div>
                          </div>
                          <div className={classes.modalRow} >
                              <div className={classes.modalDataLabel}>Hurricanes :</div>
                              <div className={classes.modalValue}>{formatYesNo(selectedSearch.hurricanes)}</div>
                          </div>

                          <div className={classes.modalRow} >
                            <div className={classes.modalDataLabel}>MFR Score :</div>
                            <div className={classes.modalValue}>{selectedSearch.mfrScore}</div>
                        </div>
                        <p className={classes.buffer}>&nbsp;</p>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <div className={classes.modalRow} >
                              <div className={classes.modalActionLabel}>Quote Clicked:</div>
                              <div className={classes.modalValue}>{formatYesNo(selectedSearch.quoteClicked)}</div>
                          </div>
                          {selectedSearch.quoteClicked === true &&
                          <>
                            <div className={classes.modalRow} >
                                <div className={classes.modalActionLabel}>Click Origin:</div>
                                <div className={classes.modalValue}>{
                                  decodeURIComponent(
                                    !isNullorEmpty(selectedSearch.clickSource) ? 
                                    selectedSearch.clickSource : ""
                                    )}</div>
                            </div>

                            <div className={classes.modalRow} >
                                <div className={classes.modalActionLabel}>Sent Quote String :</div>
                                <div className={classes.modalValueBreak}>{decodeURIComponent(selectedSearch.quoteClickThrough)}</div>
                            </div>
                          </>
                          }
                          <div className={classes.modalRow} >
                              <div className={classes.modalActionLabel}>PDF Downloaded :</div>
                              <div className={classes.modalValue}>{formatYesNo(selectedSearch.downloadedReport)}</div>
                          </div>
                          {selectedSearch.downloadedReport === true &&
                            <div className={classes.modalRow} >
                                <div className={classes.modalActionLabel}>View PDF :</div>
                                <div className={classes.modalValue}>
                                  <a href={selectedSearch.lastPDFURL} target="blank">View Here</a>
                                </div>
                            </div>
                          }
                        </Paper>
                      </Grid>
                    </Grid>
                    </>
                  }
                </div>
                <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <div className="button-row">
                          <GenericCancelButton onClick={handleClose}>
                            Cancel
                          </GenericCancelButton>
                      </div>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </ReportModalBox>
        </Modal>
      </>
    ));
}