import styled from "styled-components";
import {
	Chevron,
	ChevronSelected,
	riskwizardimage,
	ChevronLongSelected,
	ChevronLong,
	ChevronMedSelected,
	ChevronMed,
	ChevronMob,
	ChevronMobLight
} from "./Styles";
import { GenericModalBox } from "../Styles/ModalStyles";
import {
	Button,
	Box,
	Grid,
	Step,
	Stepper,
	StepButton,
	TextField,
	/* FormLabel, */
	Fade,
	Checkbox
} from "@material-ui/core";

/* Fixed for Location Button */

import navArrow from "../../Content/navArrow.svg";
import navArrowRight from "../../Content/navArrowRight.svg";
import navRestart from "../../Content/navRestart.svg";
import backgroundProAccount from "Content/ProBackground.png";

interface ISignupBackground {
	signup: boolean;
}

export const PageRoot: any = styled.div<ISignupBackground>`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;

	main {
		@media (max-width: 767px) {
			min-height: calc(100vh - 70px);
			margin-top: 70px;
		}
	}

	@media (min-width: 768px) {
		height: unset;
		padding: 20px;
		min-height: calc(100vh - 70px);
	}

	/* @media (min-width: 1180px) and (min-height: 744px) {
		height: 0;
	} */

	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	/*//! MUST BE ON ONE LINE: (Save without formatting) */
	background-image: url(${props => props.signup ? backgroundProAccount : riskwizardimage});

	.pdf-download {
		display: flex;

		@media (max-width: 856px) {
			flex-wrap: wrap;
		}
	}
`;

export const WizardMain: any = styled.main`
	width: 100%;
`;

export const RootGrid: any = styled(Grid)``;

export const WizGrid: any = styled(Grid)<{ quotes?: boolean }>`
	min-height: 634px;
	@media (min-width: 1180px) {
		/* height: 634px; */
		height: ${props => (props.quotes ? "auto" : "634px")};
	}
	width: 100%;
	max-width: 1140px;
	background-color: #ffffff;
	margin: 0px auto;

	@media (min-width: 768px) {
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
	}

	@media (max-width: 767px) {
		margin-bottom: 0;
		background: linear-gradient(#ffffff 50%, #4190ca 135%);
		height: 100%;
		min-height: inherit;
	}

	.WizardInner {
		@media (min-width: 1366px) {
			min-height: 634px;
			height: ${props => (props.quotes ? "auto" : "100%")};
		}
		height: 100%;

		@media (max-width: 767px) {
			min-height: inherit;
		}

		.contentAndButtons {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media (max-width: 767px) {
				padding-bottom: 100px;
			}
		}

		.api-loader {
			vertical-align: middle;
			/* margin-top: 15%; */
			padding-top: 15%;
			> div {
				h5 {
					color: #292b28;
					font-size: 24pt;
				}
				> div {
					background-color: #005792 !important;
					div {
						background-color: #79b2da !important;
					}
				}
			}
		}
	}
`;

export const TextFieldWizard: any = styled(TextField)``;

export const SubmitWizard: any = styled(Button)`
	border-radius: 10px;
	border: none;
	background-color: #4190ca;
	width: 318px;
	height: 54px;
	color: #ffffff;
	:hover {
		background-color: #4190ca;
	}
	.MuiTouchRipple-root {
		opacity: 0;
	}
	text-transform: none;
	margin: 0 auto;
	:disabled {
		opacity: 0.5;
		color: #ffffff;
	}
`;

export const VideoPlayer: any = styled.video`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	object-fit: cover;
`;

export const ReportStepper: any = styled(Stepper)`
	div.Step0 {
		@media (min-width: 768px) {
			padding-left: 0;
		}
	}
	width: 100%;
	padding: 14px 24px;
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;

	border-bottom: 2px solid #eeeeee;

	@media (min-width: 1366px) {
		min-height: 90px;
	}

	@media (max-width: 767px) {
		div:first-child {
			margin-left: calc(((982px - 100vw) / 2) * -1);
		}
	}

	.MuiStepConnector-root {
		position: absolute;
	}

	div:last-child {
		@media (max-width: 1179px) {
			padding-right: 0;
		}
		@media (max-width: 767px) {
			padding-right: 8px;
		}
	}

	/* box-shadow: 0px 0px 6px #cccccc; */
	z-index: 10;

	@media (max-width: 1179px) {
		width: fit-content;
		padding-right: 58px;
		margin: auto;

		::before {
			content: "";
			position: absolute;
			width: calc(100% - 42px);
			height: 92px;
			left: 21px;
			display: inline-block;
			border-bottom: 2px solid #eeeeee;
		}
	}
`;

export const ReportStep: any = styled(Step)`
	@media (min-width: 768px) {
		margin-right: -34px;
	}

	button.longChevron {
		padding-left: 36px;
		padding-right: 36px;
	}
	button.chevron {
		background-image: url(${Chevron});
	}
	button.medChevron.chevron {
		background-image: url(${ChevronMed});
	}
	button.longChevron.chevron {
		background-image: url(${ChevronLong});
	}
	button.chevronSelected {
		background-image: url(${ChevronSelected});
	}
	button.chevronSelected.medChevron {
		background-image: url(${ChevronMedSelected});
	}
	button.chevronSelected.longChevron {
		background-image: url(${ChevronLongSelected});
	}
	@media (max-width: 767px) {
		margin-right: 2px;
		button.Step1 {
			width: 254px;
			background-image: url(${ChevronMob}) !important;
			span:first-child {
				width: 100%;
				margin-left: 0;
				text-align: left;
				max-width: 100%;
			}
		}
		button.Step0,
		button.Step2 {
			width: 254px;
			background-image: url(${ChevronMobLight}) !important;
		}
	}

	button.mobileScore {
		border-radius: 50%;
		width: 30px;
		height: 14px;
		margin-left: 116px;
		padding-left: 16px;
		padding-right: 16px;
		background-color: #005792;
		.MuiStepLabel-label {
			margin-left: 1px;
			margin-top: -8px;
		}
	}

	button.tabletScore,
	button.tabletScore.chevronSelected {
		border-radius: 50%;
		background-color: #7faac8;
		width: 30px;
		height: 14px;
		margin-left: 50px;
		margin-right: 50px;
		.MuiStepLabel-label {
			margin-left: 1px;
			margin-top: -8px;
		}
	}

	button.tabletScore.chevronSelected {
		background-color: #005792;
	}

	@media (min-width: 1180px) {
		:last-child {
			button.chevron,
			button.chevronSelected {
				padding-left: 16px;
				padding-right: 16px;
				background-image: none;
				width: 30px;
				height: 14px;
				background-color: #7faac8;
				border-radius: 50%;
				margin-left: 23px;
				margin-right: 3px;
			}
			button.medChevron {
				margin-left: 17px;
			}
			button.longChevron {
				margin-left: 19px;
			}
			button.chevronSelected {
				background-color: #005792;
			}
			/* only Chrome & Opera need this. */
			@supports (-webkit-appearance: none) and (font-variant-caps: normal) and
				(not (-ms-accelerator: true)) and (not (overflow: -webkit-marquee)) and
				(not (-moz-appearance: none)) {
				.MuiStepLabel-label {
					margin-top: -8px;
				}
			}

			.MuiStepLabel-label {
				margin-left: 1px;
			}

			margin-right: 0px;
		}

		button.medChevron {
			margin: -24px -3px;
		}

		button.longChevron {
			margin: -24px -7px;
		}
	}
	button.Step0.longChevron,
	button.Step0.medChevron {
		@media (min-width: 768px) {
			margin-left: -8px;
		}
	}
	.hide {
		opacity: 0;
	}
	z-index: 10;
`;

export const SemiCircle: any = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #7faac8;
	position: absolute;
	top: 84px;
	right: -30px;
	overflow: hidden;

	@media (max-width: 396px) {
		left: calc(50vw + 163px + 5px);
	}
`;

export const ChevronButton: any = styled(StepButton)`
	background-position: center center;
	background-repeat: no-repeat;
	width: 162px;
	height: 60px;
	opacity: 1;

	.MuiStepLabel-iconContainer {
		display: none;
	}
	.MuiStepLabel-label {
		color: rgb(255, 255, 255);
		text-align: center;
		font-weight: Bold;
		font-size: 10px;
		font-family: "Lato", sans-serif;
		text-transform: uppercase;
		max-width: 96px;
		margin-left: 10px;
	}

	.Step7 {
		.MuiStepLabel-label {
			margin-left: 8px;
			margin-top: 1px;
		}
	}
`;

export const DetailsWrapper: any = styled.div`
	display: flex;
	flex-direction: row;
	> div {
		width: 100%;
		flex-direction: column;
		div {
			width: 100%;
		}
	}
	> div:first-child {
		margin-right: 10px;
	}
	@media (max-width: 830px) {
		flex-direction: column;
	}
`;

export const FormCheckbox: any = styled(Checkbox)`
	padding: 0;
`;

export const ReportResultBox: any = styled(Box)<{ quotes?: boolean }>`
	width: calc(100% - 120px);
	max-width: 1020px;
	@media (min-width: 1180px) {
		/* height: /* 498px * / 435px; */
		height: ${props => (props.quotes ? "auto" : "435px")};
	}
	margin: 0 60px;
	padding: 0;
	/* z-index: 5; */

	text-align: left;
	font-weight: Regular;
	font-size: 14px/20px;
	font-family: "Lato", sans-serif;
	letter-spacing: 0;
	color: #292b28;

	@media (max-width: 767px) {
		width: 100%;
		margin: 0;
		padding: 0 20px;
		height: unset;
	}

	.error {
		color: red;
		margin: -10px 0 10px;
		@media (min-width: 768px) {
			margin: -25px 0px 0px;
		}
	}

	.parcelNotFoundBox {
		padding-top: 60px;

		@media (max-width: 767px) {
			padding-top: 24px;
		}
	}

	h2 {
		margin-bottom: 37px;

		@media (max-width: 767px) {
			font-size: 15px;
		}
	}

	form {
		padding-top: 60px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 767px) {
			padding-top: 12px;
		}

		p {
			@media (max-width: 767px) {
				margin-top: 0;
				margin-bottom: 20px;
				font-size: 12px;
			}
		}

		input,
		textarea {
			border: solid 1px #e0daf0;
			margin-bottom: 30px;
			border-radius: 5px;
			background-color: #ffffff;

			@media (max-width: 767px) {
				margin-bottom: 20px;
			}
		}
		.details {
			@media (min-width: 831px) {
				/* width: 49%; */
				:first-child {
					margin-right: 2%;
				}
			}
		}
		.submission {
			text-align: center;
			margin-top: 40px;
			margin-bottom: 60px;
		}
		.terms {
			text-align: left;
			@media (min-width: 1900px) {
				margin-top: -100px;
			}
		}
		.termsText {
			font-size: 0.875rem;
		}
		.termsLink {
			color: #4190ca;
			margin-left: 5px;
			text-decoration: underline;
		}
		.inputs {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@media (max-width: 767px) {
				justify-content: flex-start;
			}
		}
	}
`;

export const LastNextBox: any = styled(Box)`
	display: inline-flex;
	width: 100%;
	text-align: left;

	.MuiButton-contained,
	.MuiButton-containedPrimary {
		box-shadow: none !important;
		background-color: #ffffff !important;
	}

	.lastBtnHolder,
	.nextBtnHolder,
	.lastBtnHolderStep5,
	.nextBtnHolderStep5 {
		width: 50%;
		text-align: left;
		margin: 20px 60px 10px;

		@media (max-width: 767px) {
			margin: 20px 20px 10px;
		}

		button {
			font: Bold 32px/42px "Lato", sans-serif;
			letter-spacing: 0;
			color: #464545;
			opacity: 1;
			background-image: url(${navArrow});
			background-repeat: no-repeat;
			padding-left: 50px;
			background-position: left center;
			margin-left: -9px;

			span.MuiTouchRipple-root {
				display: none;
			}

			:hover {
				background-color: transparent;
			}
		}
	}
	.lastBtnHolderStep5 {
		@media (min-width: 1180px) {
			width: 18.5%;
		}
	}
	.nextBtnHolder,
	.nextBtnHolderStep5 {
		text-align: right;
		button {
			background-image: url(${navArrowRight});
			padding-left: 8px;
			padding-right: 50px;
			margin-right: -9px;
			background-position: right center;
		}
		button.restart {
			background-image: url(${navRestart});
		}
	}
	.nextBtnHolderStep5 {
		@media (min-width: 1180px) {
			text-align: left;
		}
	}

	.restart-btn {
		width: auto;

		button {
			padding-right: 8px;
			padding-left: 50px;
			background-position: left center;
		}
	}
`;

export const TermsModalBox: any = styled(GenericModalBox)`
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin: auto;

	.termsmodal-wrapper {
		@media (min-width: 1366px) {
			padding-left: 40px;
			padding-right: 40px;
			padding-bottom: 30px;
		}
	}

	div {
		width: 100%;
		::-webkit-scrollbar-button {
			width: 28px;
			height: 34px;
			transform: matrix(0, 1, -1, 0, 0, 0);
			background: #e0daf0;
			opacity: 0.95;
			color: #ffffff;
		}
		::-webkit-scrollbar {
			width: 34px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-left: 1px solid #e0daf0;
			opacity: 0.95;
		}
		::-webkit-scrollbar-thumb {
			background: #e0daf0;
			height: 60px;
			margin-left: 1px;
		}
		::-webkit-scrollbar-thumb:hover {
			background: #e0daf0;
		}
		/* Up */
		::-webkit-scrollbar-button:single-button:vertical:decrement {
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAABHNCSVQICAgIfAhkiAAAAMtJREFUWIXt1l0KgzAMwPHG1MTEispwRfT+d9rTjjDYCfY0GEFEcEwZ+T/2A36FFgr32+MZTlBxNOCdQ2wOsTnE5hCbQ2z/A8ECIE+1YgFwGAQAQp6TSF1inpPADssuyDCqUIUYQghUIQ6jys8hl6ysqYyfY5rKeMnKP4O0HVPTEi3NNS1R2/Hi3Fchmij2V1k9dX8V1kRxbc0uCFcRh1E23YNhFOEq4tchxAh5qje/DAAIeaqFGDdtAP88mxxic4jNITaH2BxiOw3kBamNDJBAO25MAAAAAElFTkSuQmCC);
		}
		/* Down */
		::-webkit-scrollbar-button:single-button:vertical:increment {
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAABHNCSVQICAgIfAhkiAAAAQFJREFUWIXtlMFOwzAMhv9uCA7wABx4/3fixKVVyaqpK0vixo1nThyIypZCJXrId43z64vluHp77T+wAXb/LfBFEUkpIilFJKWIpBSRlCKSshmRu9zC+4d99fzy9LjbV1XunYuovtfWcRC9VZvdEQ6ipnGkejMTAKCqMI2jHIlFIgAQxihdS5RT27VEYYySm714Rrzl2B8oXKvpDxS85bgk91fDOpwCnwfmubPzwDycwuzZ6iIAcDQ+eDt9e7W3Uzwaf7Vbq4sAQNd64lEEAHgU6VqfNT+ri6gqTG2J3CSmttk/ao7sPfITclE1jfN/zdnMZi0iKUUkpYikFJGUzYh8Aq1Uis0pl2jAAAAAAElFTkSuQmCC);
		}
		@media screen and (max-width: 388px) {
			margin-bottom: 18px;
		}
	}

	.termsmodal-title {
		height: 70px;
		text-align: center;

		.termsmodal-title-text {
			position: relative;
			display: inline-block;
			color: #4190ca;
			font-weight: 700;
			font-size: 21px;
			height: 56px;
			line-height: 56px;
			vertical-align: center;
		}
	}

	@media screen and (max-width: 768px) {
		.termsmodal-title {
			/* height: 60px; */

			.termsmodal-title-text {
				/* font-size: 18px; */
				/* height: 46px; */
				line-height: 46px;
			}
		}
	}

	@media screen and (max-width: 576px) {
		.termsmodal-title {
			/* height: 45px; */

			.termsmodal-title-text {
				/* font-size: 15px; */
				height: 32px;
				line-height: 32px;
			}
		}
	}

	@media screen and (max-width: 388px) {
		.termsmodal-title {
			/* height: 45px; */

			.termsmodal-title-text {
				/* font-size: 14px; */
				height: 32px;
				line-height: 25px;
			}
		}
	}

	.termsmodal-text {
		padding: 15px;
		height: 252px;
		overflow-y: scroll;
		border: 1px solid #e0daf0;
		opacity: 0.95;
		@media screen and (max-width: 388px) {
			font-size: 12px;
		}
	}

	.button-row {
		width: 100%;
		text-align: center;
		@media screen and (max-width: 388px) {
			button {
				margin-left: 0px;
				margin-right: 0px;
				border-radius: 5px;
				/* max-height: 34px; */
				padding: 10px;
			}
		}
	}
`;

export const WizardFadeTransition: any = styled(Fade)`
	@media (min-width: 1180px) {
		/* position: absolute; */
		top: 0;
		width: inherit;
		height: inherit;
	}
`;
