import styled from "styled-components";

export const ReportsRoot: any = styled.div`
	max-width: 1040px;
	margin: 0 auto;
	padding-top: 30px;

	@media screen and (max-width: 1141px) {
		padding: 0 15px;
		padding-top: 30px;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 1040px;
		margin: 0 auto;
		padding: 0;
	}

	.dateRow {
		padding-top: 24px;
	}

	.buttons {
		display: flex;
		justify-content: center;
	}

	.adminReportResults {
		display: flex;
		flex: 1;
		flex-direction: column;
		margin-bottom: 60px;
		height: auto;
	}

	.dateRow {
		display: flex;
		flex: 1;
		width: 100%;
	}

	.dateRowContainer {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: "space-between";
	}

	.totalQuotesClicked {
		display: flex;
		flex: 1;
	}
	.totalQuotesClicked p {
		margin: 0 auto;
		font-size: 24px;
		padding: 9px 0;
	}

	.MuiTableSortLabel-root:focus {
		color: rgba(255, 255, 255, 0.87) !important;
	}
	.MuiTableSortLabel-root:hover {
		color: rgba(255, 255, 255, 0.87) !important;
	}
	.MuiTableSortLabel-root.MuiTableSortLabel-active {
		color: rgba(255, 255, 255, 0.87) !important;
	}
	.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
		.MuiTableSortLabel-icon {
		color: rgba(255, 255, 255, 0.87) !important;
		opacity: 1;
	}
	.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
		color: rgba(255, 255, 255, 0.54) !important;
		opacity: 1;
	}
	.MuiTableSortLabel-icon {
		width: 18px;
		height: 18px;
		opacity: 0;
		transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
			transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		margin-left: 4px;
		user-select: none;
		margin-right: 4px;
	}
	.MuiTableSortLabel-iconDirectionDesc {
		transform: rotate(0deg);
		fill: rgb(255, 255, 255) !important;
	}
	.MuiTableSortLabel-iconDirectionAsc {
		transform: rotate(180deg);
		fill: rgb(255, 255, 255) !important;
	}
`;
