import React, { useContext/* , useEffect */ } from "react";
import { Stores, StoresContext } from "../../Stores";
import styled from "styled-components";
import { Box, useMediaQuery } from "@material-ui/core";
import useCountUp from "Shoothill.Core/Utils/Hooks/UseCountUp";

export const TotalAssistBox: any = styled(Box)`
  text-align: left;
  letter-spacing: 0;
  color: #292b28;

  .imgPara {
    display: flex;
    img {
      width: 130px;
      height: 112px;
      margin: auto 0;
      flex-shrink: 0;
    }

    p {
      margin: 0;
    }
  }

  .group {
    width: 100%;
    display: inline-flex;
    .textblock {
      width: 613px;
      margin-right: 30px;
      font-weight: normal;

      @media (max-width: 1180px) {
        width: 50vw;
      }

      @media (max-width: 767px) {
        margin-right: 0;
        width: 100%;
      }

      h1 {
        font-size: 32px;
        @media (max-width: 767px) {
          font-size: 24px;
          line-height: 18px;
          margin: 20px 0;
        }
      }

      .summary {
        width: 100%;
        h2 {
          font-weight: Bold;
          font-size: 32px;
          font-family: Lato;
          padding: 0;
          margin: 0;

          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
        .bigText {
          text-align: left;
          font-weight: Bold;
          font-size: 72px;
          font-family: Lato;
          padding: 0;
          margin: 0;
          color: #ee00ee;

          @media (max-width: 767px) {
            font-size: 32px;
          }
        }
      }
    }

    .imageblock {
      width: 346px;
      padding-top: 16px;
      img {
        @media (max-width: 1190px) {
          width: 25vw;
        }
      }
    }
  }
`;

export const TADisclaimerText: any = styled(Box)`
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
  padding: 10px;
  @media (max-width: 767px) {
    padding: 14px 0 0;
  }
`;

export const ReportTotalAssistance: React.FC = () => {
  const floodDataContext: any = useContext<Stores>(StoresContext).domain
    .FloodAndClaimDataStore;

  const totalAssistance = useCountUp({
    end: floodDataContext.getAssistanceTotal.split(",").join(""),
    duration: 2,
    easeStart: false
  });

  let mobileLayout: boolean;
  mobileLayout = useMediaQuery("screen and (max-width: 767px)") ? true : false;

  const img: any = (
    <img
      src={floodDataContext.statessvg}
      alt={"Total assistance for " + floodDataContext.state_abbr}
    />
  );

  return (
    <>
      <TotalAssistBox>
        <div className="group">
          <div className="textblock">
            <h1>Total Assistance ($)</h1>
            <div className="imgPara">
              <p>
                FEMA Disaster Assistance helps support recovery from a major
                disaster, and assists with temporary housing, emergency home
                repairs, uninsured and underinsured personal property losses and
                medical expenses caused by the disaster.
              </p>
              {mobileLayout ? img : null}
            </div>
            <p>
              ${floodDataContext.getAssistanceTotal} is the total amount of FEMA
              disaster assistance in your state, to date. This number includes
              assistance paid to flood victims who were not covered under the
              National Flood Insurance Program. FEMA assistance is usually in
              the form of a loan that must be repaid, with interest.
            </p>
            <div className="summary">
              <h2>Total assistance in {floodDataContext.state_abbr}</h2>
              <div className="bigText">
                $
                {new Intl.NumberFormat("en-US").format(totalAssistance.counter)}
              </div>
            </div>
          </div>
          {mobileLayout ? null : <div className="imageblock">{img}</div>}
        </div>
      </TotalAssistBox>
      <TADisclaimerText>
        *Disclaimer: Figure includes FEMA disaster assistance paid for Flood,
        Coastal Storm, Severe Storms, Hurricane, Tsunami, Typhoon, and Dam/Levee
        Break event types.
      </TADisclaimerText>
    </>
  );
};
