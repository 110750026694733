import styled from "styled-components";
import { Box } from "@material-ui/core";

export const ParcelBox: any = styled(Box)`
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 11px;
  }

  h2 {
    color: #4190ca;
    margin-top: 0;
    margin-bottom: 14px;
  }
`;

export const ParcelContactBox: any = styled(Box)`
  width: 789px;
  height: 224px;
  margin: 50px auto 0;

  .card {
    width: 166px;
    height: 224px;
    display: inline-block;
    img,
    h2,
    h3 {
      display: block;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    img {
      width: 140px;
      height: auto;
      margin: 0 auto;
    }

    h2 {
      font-weight: Bold;
      font-size: 28px;
      font-family: "Lato", sans-serif;
      letter-spacing: 0;
      color: #4190ca;
    }

    h3 {
      font-weight: Bold;
      font-size: 18px;
      font-family: "Lato", sans-serif;
      letter-spacing: 0;
      color: #292b28;
    }
  }

  .card:first-child {
    margin-right: 140px;
  }

  .card:last-child {
    margin-left: 140px;
  }
`;
