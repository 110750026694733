import "./Home.css";
import { Helmet } from "react-helmet";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";

import {
    useStyles,
    Methodology,
    PageContainer,
    WhiteGradient,
    FooterLinksSpacing
} from "../Shoothill.Components/Styles/AboutStyling";

import { IncLogos } from "Shoothill.Components/RowTools/IncLogos";

export const MethodologyPage: React.FC = () => {
    const classes: any = useStyles("about");
    ReactGA.pageview(window.location.pathname + window.location.search);

    var whiteGrad: HTMLElement | null;
    var secondBackground: HTMLElement | null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function positionSecondBackground() {
        if (whiteGrad && secondBackground) {
            var style = window.getComputedStyle(whiteGrad);
            if (style) {
                secondBackground.style.top = style.height;
            }
        }
    }

    return useObserver(() => (
        <>
            <Helmet>
                <title>My Flood Risk Methodology | My Flood Risk</title>
            </Helmet>
            <PageContainer>
                <FooterLinksSpacing>
                    <div className={classes.blueGradient}>
                        <div className={classes.subHeadingSpacing}>
                            <Methodology>
                                <h1>My Flood Risk Methodology</h1>
                                <IncLogos
                                    miscCSS={"margin: 30px auto;"}
                                />
                                <p>With flooding on the rise across America, every American homeowner,
                                business owner, and renter has the right to accurate information to help assess
                                flood risk. My Flood Risk provides flood risk information for all 50 states.</p>
                                <h3>Overview</h3>
                                <p>Flood risk scores are created by combining publicly available data sets,
                                including property elevation data from the United States Geologic Survey and county
                                governments, base flood elevation data, flood zone data, claims history and disaster
                                assistance history from the Federal Emergency Management Agency, and storm surge
                                predictions, hurricane history and flooding event history from the National Weather
                                Service / National Oceanic and Atmospheric Administration.</p>
                                <p>Future data sets to be added include riverine flood data and rainfall data.</p>

                                <h3>Data sets</h3>
                                <h4>Elevation Difference</h4>
                                <p>Elevation Difference is defined as the difference between a property’s actual
                                elevation, and the Base Flood Elevation (BFE) or the expected height of floodwaters.
                                            <a href="https://www.usgs.gov/core-science-systems/national-geospatial-program/national-map" target="_blank" rel="noopener noreferrer" title="United States Geological Survey">
                                        Property elevation is provided by the United States Geological Survey</a>.
                                            BFEs are provided by FEMA: <a href="https://msc.fema.gov/portal/home" target="_blank" rel="noopener noreferrer" title="FEMA">https://msc.fema.gov/portal/home</a>  BFEs do not exist for every
                                    property in the United States. Where BFEs can be reasonably extrapolated from existing data,
                                    results are returned. All other factors being equal, the further above the BFE a property sits,
                                            the lower its flood risk.</p>
                                <h3>Flood Zones</h3>
                                <p><a href="https://www.fema.gov/national-flood-hazard-layer-nfhl" target="_blank" rel="noopener noreferrer" title="Flood zones are geographic areas designated by FEMA">Flood zones are
                                            geographic areas designated by FEMA</a>, largely based on historical data about
                                    the severity and type of flooding that has previously occurred in the area. Over 50 percent of FEMA
                                            flood zone maps are out of date.</p>
                                <p>In general, FEMA has identified properties in A and V zones as being at higher flooding risk,
                                and flood insurance is required for properties in these zones with federally-backed mortgages. For properties
                                            in zones B, C, and X, flood insurance is recommended.</p>
                                <h3>Flood Claims</h3>
                                <p>At a minimum, <a href="https://www.fema.gov/policy-claim-statistics-flood-insurance" target="_blank" rel="noopener noreferrer" title="FEMA flood claim data">FEMA flood claim data</a> is available at the county level, and values are provided for building and
                                contents loss amounts, as well as the number of claims, total claim payout, and average claim payout. In some
                                            instances, data may be even more precise, down to the street or property level.</p>
                                <h3>Flood Disaster Assistance Data</h3>
                                <p><a href="https://www.fema.gov/disasters" target="_blank" rel="noopener noreferrer" title="FEMA Disaster Assistance">FEMA Disaster Assistance</a> helps support recovery from a major disaster, and assists with temporary housing,
                                emergency home repairs, uninsured and underinsured personal property losses and medical expenses caused by
                                the disaster. Ninety percent of natural disasters in the United States involve flooding, so not only is
                                            flood the largest underinsured risk in America, it is also one of the most common.</p>
                                <p><a href="https://www.fema.gov/disasters" target="_blank" rel="noopener noreferrer" title="FEMA Disaster Assistance">FEMA Disaster Assistance</a> figures are provided at the county level. Totals
                                include disaster assistance paid for the following event types: Flood, Coastal Storm, Severe Storm, Hurricane,
                                            Tsunami, Typhoon, and Dam/Levee Break.</p>

                                <h3>Storm surge data</h3>
                                <p>From NOAA:</p>
                                <p><i>The National Oceanic and Atmospheric Administration (NOAA), specifically the National Weather Service's
                                (NWS) National Hurricane Center (NHC), utilizes the hydrodynamic Sea, Lake, and Overland Surges from Hurricanes
                                            (<a href="https://www.nhc.noaa.gov/surge/slosh.php" target="_blank" rel="noopener noreferrer" title="SLOSH">SLOSH</a>) model to simulate storm surge from tropical cyclones. Storm surge information is provided to federal, state,
                                    and local partners to assist in a range of planning processes, risk assessment studies, and operational decision-making.
                                    In regards to the former, tens of thousands of climatology-based hypothetical tropical cyclones are simulated in each
                                    SLOSH basin (or grid), and the potential storm surges are calculated. Storm surge composites – Maximum Envelopes of Water (MEOWs)
                                            and Maximum of MEOWs (MOMs) – are created to assess and visualize storm surge risk under varying conditions.</i></p>

                                <p>For the My Flood Risk scoring, a property is considered to be at risk of storm surge if it lies within a county affected
                                            by NOAA’s storm surge predictions.</p>

                                <div className="invert">
                                    <h3>Flooding events</h3>
                                    <p>Flooding events from 1950 to present are logged in the
                                                    <a href="https://www.ncdc.noaa.gov/news/storm-events-database-version-30"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="National Weather Service’s Storm Events Database">
                                            National Weather Service’s Storm Events Database</a>
                                        . Only floods of sufficient intensity to cause
                                                loss of life, injuries, significant property damage and/or disruption to commerce are included.</p>
                                    <h3>Hurricanes</h3>
                                    <p>Hurricanes can cause severe damage from wind, rain, and storm surge. Developed by NOAA’s National Climatic Data Center,
                                                    <a href="https://www.ncdc.noaa.gov/ibtracs/index.php"
                                            target="_blank" rel="noopener noreferrer"
                                            title="the International Best Track Archive for Climate Stewardship">
                                            the International Best Track Archive for Climate Stewardship (IBTrACS)</a> combines best track data
                                from over ten international forecast centers. The dataset provides, in six hour intervals, the position, nature, maximum sustained winds,
                                and minimum central pressure for every tropical cyclone around the globe. The data period is from 1848 to present, with annual updates
                                                every August.</p>

                                    <h3>IMPORTANT INFORMATION AND DISCLAIMER</h3>
                                    <p>My Flood Risk tools and My Flood Risk presents information from a calculation based upon assumptions and assigned weighted factors applied publicly available data, does not account for future degradation or improvements in infrastructure and flood control that could reduce or increase flood risk.  The information presented does not reflect actual flood risk rather it is the result of a calculation taking into account publicly available data and assumptions by AB Risk Corporation & Shoothill Ltd. and is not based upon a scientific method. The methodology associated with the calculation is found at the following site: https://www.myfloodrisk.org/methodology and should be reviewed carefully before making any decisions regarding the information presented and flood risk.  My Flood Risk information should not be the sole factor in any decision regarding the necessity or otherwise of the acquisition of flood insurance coverage and shall not be used for property sale, mortgage, lease, valuation, or any related purpose. All information presented at MyFloodRisk.org is subject to the 
                                    &nbsp;<Link
                                            color="inherit"
                                            component={RouterLink}
                                            to="/terms-of-use"
                                        >
                                        MyFloodRisk.org Terms of Use
                                    </Link>
                                        &nbsp;and your use of this website acts as acceptance of such 
                                        MyFloodRisk.org Terms of Use.
                                        </p>

                                </div>
                            </Methodology>
                        </div>
                    </div>
                    <WhiteGradient
                        ref={(grad: HTMLElement | null) => {
                            whiteGrad = grad;
                            positionSecondBackground();
                        }}
                    ></WhiteGradient>
                </FooterLinksSpacing>
            </PageContainer>
        </>
    ));
};
