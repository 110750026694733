import styled from "styled-components";
import blueStar from "Content/blueStar.svg";
import whiteStar from "Content/whiteStar.svg";

export const AboutDiv: any = styled.div`
	h2,
	h3 {
		margin-top: 0;
		color: #ffffff;
		line-height: 1.2em;
		text-align: left;
		font-weight: 500;

		span {
			color: #ffffff;
			font-weight: 700;
		}

		@media (max-width: 1140px) {
			text-align: center;
		}
	}

	@media (min-width: 1141px) {
		h2 {
			font-size: 28px;
			margin-bottom: 20px;
		}

		h3 {
			font-size: 21px;
			margin-bottom: 35px;
		}
	}

	@media (min-width: 1141px) {
		margin-top: -50px;
	}
`;

export const ProUSPul: any = styled.ul`
	width: 100%;
	list-style-image: url(${whiteStar});
	text-align: left;
	font-family: "Lato" sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.3em;
	letter-spacing: -0.4px;
	margin: auto;
	padding: 0px 0px 0px 16px;
	color: #fff;

	@media (min-width: 1141px) {
		width: fit-content;
		margin-bottom: 30px;
	}

	@media (max-width: 1140px) {
		padding-left: 24px;
		line-height: normal;

		li {
			:not(:last-of-type) {
				padding-bottom: 5px;
			}
		}
	}
`;
