import { AppErrorBoundary } from "./AppErrorBoundary";
import * as AppUrls from "./Globals/AppUrls";
import CssBaseline from "@material-ui/core/CssBaseline";
import { hot } from "react-hot-loader";
import { Layout } from "./Pages/Layout";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { PrivateRoute } from "./Shoothill.Core/Utils";
import React, { useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { StoresContext, Stores } from "./Stores";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { About } from "./Pages/About";
import { Admin } from "./Pages/Admin";
import { Blog } from "./Pages/Blog";
import { BlogPost } from "./Pages/BlogPost";
import { BlogPostAdmin } from "./Pages/BlogPostAdmin";
import { ArticleItemAdmin } from "./Pages/ArticleItemAdmin";
import { Contact } from "./Pages/Contact";
import { FloodLoss } from "./Pages/FloodLoss";
import { FloodInsurance } from "./Pages/FloodInsurance";
import { Home } from "./Pages/Home";
import { LoginPage } from "./Pages/LoginPage";
import { MethodologyPage } from "./Pages/Methodology";
import { MoreAboutFlood } from "./Pages/MoreAboutFlood";
import { MyRisk } from "./Pages/MyRisk";
import { MyRiskWizard } from "./Pages/MyRiskWizard";
import { Privacy } from "./Pages/Privacy";
import { Pro } from "./Pages/Pro";
import { SetPassword } from "./Pages/ProUser/SetPassword";
import { SetPasswordComplete } from "./Pages/ProUser/SetPasswordComplete";
import ReactGA from "react-ga";
import { ReferenceLibrary } from "./Pages/ReferenceLibrary";
import { Terms } from "./Pages/Terms";
import { User } from "./Pages/User";

import { defaultTheme } from "Shoothill.Core/Styling/AppsStyling";
import { AccountStore } from 'Stores/Domain/AccountStores';

// tslint:disable-next-line: typedef
function useToggle(): [boolean, () => void] {
	const [_, set] = useState(true);
	return [_, () => set(value => !value)];
}

const App: React.FC = () => {
    const store: Stores = useContext<Stores>(StoresContext);
    const rerObj = useToggle();
    const [rer, _] = rerObj;
    (window as any).rer = rerObj;
	(window as any).propopped = false;

	onpopstate = (ev: PopStateEvent) => {
        (window as any).propopped = true;
        if ((window as any).proSetStates) {
            (window as any).proSetStates(ev.state);
        }
    };

    const saveStores = () => {
        var saveStore: Stores = JSON.parse(JSON.stringify(store));
        saveStore.domain.AccountStore = (undefined as unknown) as AccountStore;
        window.sessionStorage.setItem("stores", JSON.stringify(saveStore));
    }

    useEffect(() => {
        window.addEventListener("beforeunload", saveStores);

        return () => window.removeEventListener("beforeunload", saveStores);
    })

    useEffect(() => {
        (window as any).newload = true;
        var json = window.sessionStorage.getItem("stores");
        if (json) {
            store.populate(json);
        }
        store.domain.AccountStore.getLoginState();
    }, []);

    ReactGA.initialize("UA-1852243-69");
    ReactGA.initialize("UA-144228225-1");

    /* ReactGA.initialize([
        {
            trackingId: "UA-1852243-69",
            gaOptions: {
                name: "Shoothill"
            }
        },
        {
            trackingId: "UA-144228225",
            gaOptions: {
                name: "MyFloodRisk"
            }
        }
    ]); */
    /* ReactGA.pageview(window.location.pathname + window.location.search); */

    return (
        <AppErrorBoundary>
            {rer}
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={defaultTheme}>
                    <ThemeProvider theme={defaultTheme}>
                        <CssBaseline>
                            <Router>
                                <Layout>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            component={Home}
                                        />
                                        <Route
                                            exact
                                            path="/about"
                                            component={About}
                                        />
                                        <Route
                                            exact
                                            path="/blog"
                                            component={Blog}
                                        />
                                        <Route
                                            exact
                                            path="/floodloss"
                                            component={FloodLoss}
                                        />
                                        <Route
                                            exact
                                            path="/floodinsurance"
                                            component={FloodInsurance}
                                        />
                                        <Route
                                            exact
                                            path="/moreaboutflood"
                                            component={MoreAboutFlood}
                                        />
                                        <Route
                                            exact
                                            path="/myrisk"
                                            component={MyRisk}
                                        />
                                        <Route
                                            exact
                                            path="/myriskwizard"
                                            component={MyRiskWizard}
                                        />
                                        <Route
                                            exact
                                            path={AppUrls.Client.BlogPost}
                                            component={BlogPost}
                                        />
                                        <Route
                                            exact
                                            path="/library"
                                            component={ReferenceLibrary}
                                        />
                                        <Route
                                            exact
                                            path="/contact"
                                            component={Contact}
                                        />
                                        <Route
                                            exact
                                            path="/login"
                                            component={LoginPage}
                                        />
                                        <Route
                                            exact
                                            path="/terms-of-use"
                                            component={Terms}
                                        />
                                        <Route
                                            exact
                                            path="/privacy-policy"
                                            component={Privacy}
                                        />
                                        <Route
                                            exact
                                            path="/methodology"
                                            component={MethodologyPage}
                                        />
                                        <Route
                                            exact
                                            path="/Pro"
                                            component={Pro}
                                        />                                      
                                        <Route 
                                            exact
                                            path="/ProUser/SetPassword"
                                            component={SetPassword}
                                        />
                                        <Route 
                                            exact
                                            path={AppUrls.Client.SetPassword}
                                            component={SetPasswordComplete}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/user"
                                            component={User}
                                            isAllowed={() => {
                                                return (
                                                    store.domain.AccountStore
                                                        .IsLoggedIn &&
                                                    store.domain.AccountStore.isInRole(
                                                        "admin"
                                                    )
                                                );
                                            }}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/admin"
                                            component={Admin}
                                            isAllowed={() => {
                                                return (
                                                    store.domain.AccountStore
                                                        .IsLoggedIn &&
                                                    store.domain.AccountStore.isInRole(
                                                        "admin"
                                                    )
                                                );
                                            }}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={AppUrls.Client.BlogPostAdmin}
                                            component={BlogPostAdmin}
                                            isAllowed={() => {
                                                return (
                                                    store.domain.AccountStore
                                                        .IsLoggedIn &&
                                                    store.domain.AccountStore.isInRole(
                                                        "admin"
                                                    )
                                                );
                                            }}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={
                                                AppUrls.Client.ArticleItemAdmin
                                            }
                                            component={ArticleItemAdmin}
                                            isAllowed={() => {
                                                return (
                                                    store.domain.AccountStore
                                                        .IsLoggedIn &&
                                                    store.domain.AccountStore.isInRole(
                                                        "admin"
                                                    )
                                                );
                                            }}
                                        />
                                        <Redirect to="/" />
                                    </Switch>
                                </Layout>
                            </Router>
                        </CssBaseline>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </AppErrorBoundary>
    );
};

export default hot(module)(App);
