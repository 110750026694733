import React from "react";

import {
    TermsOL
} from "Shoothill.Components/Styles/Styles";

export const TermsComponent: React.FC = () => {
    return (
    <>
        <p>MyFloodRisk.org Terms of Use</p>
        <p>(Version 1 : September 17, 2019)</p>
        <TermsOL start={1}>
            <li>
                <h6>Acceptance of Terms of Use.</h6>
                <ol type="a">
                    <li>AB Risk Corporation, a Florida corporation (&ldquo;ABR&rdquo;), owns and maintains the website www.myfloodrisk.org (the &ldquo;Site&rdquo;).&nbsp; The following terms and conditions (&ldquo;Terms&rdquo;) govern all access to and use of including but not limited to all content, functions, information, results and/or services provided, offered or extracted from, on or through the use of the Site whether as a guest or registered user. Please read carefully and understand all the Terms before your use of the Site. By using the Site and/or by clicking to &ldquo;I Accept&rdquo; when the option is/was made available to you, you: (i) represent that you are at eighteen (18) years old or older, and (ii) accept and agree to be bound and abide by these Terms. If you do not agree with all of the Terms, you must not access or use the Site.</li>
                    <li>All information ABR collected by the Site is subject to the then current AB Risk Corporation Privacy Policy (https://www.myfloodrisk.org/privacy-policy) (&ldquo;Privacy Policy&rdquo;). By using the Site or by clicking &ldquo;I Accept&rdquo; when the option is/was made available to you, you accept and agree to be bound and abide by our Privacy Policy. If you do not want to agree to our Privacy Policy, you must not provide us any personal information.</li>
                    <li>ABR reserves the right to revise and update the Terms and/or Privacy Policy from time to time in its sole discretion. All changes are effective immediately when such revised Terms and/or Privacy Policy are posted. Your continued use of the Site following the posting of revised Terms means that you accept and agree to the revised Terms and/or Privacy Policy in their entirety. You are solely responsible to check this page frequently and before each use so that you are aware of any changes and may immediately discontinue access or use of the Site should you determine that the revised Terms and/or Privacy Policy are not acceptable.&nbsp; Use of the Site and/or by clicking to &ldquo;I Accept&rdquo; when the option is/was made available to you after revised Terms and/or Privacy Policy are posted constitutes acceptance by you of the revised Terms and/or Privacy Policy.</li>
                    <li>The Site is hosted in the United States and our services are provided from the United States. It is possible that certain information will be stored on servers in multiple other countries on the &ldquo;cloud&rdquo; or other similar distributed hosting platforms. If you are a user accessing our Site or services from the European Union, Asia or any other region with laws governing personal data collection, use, and disclosure that differ from United States laws, you are expressly and knowingly consenting to the transfer of your personal information to the United States and other jurisdictions as indicated above, and to our use of your personal information in accordance with our Privacy Policy.</li>
                </ol>
            </li>
            <li>
                <h6>Site Access; Account Security.</h6>
                <ol type="a">
                    <li>ABR reserves the right to make any changes, modifications, additions, deletions, or corrections to this Site and/or remove or withdraw any services, product, functions and/or information from the Site its sole and absolute discretion without prior notice.</li>
                    <li>ABR is not be liable if, for any reason, all or any part of the Site is not accessible to you or becomes unavailable at any time or for any period. From time to time, ABR may restrict access to some parts of the Site, or the entire Site, to guests and/or users, including registered users.</li>
                    <li>You are solely responsible for making all arrangements necessary for you to have access to the Site and are responsible to ensure any and all third party(ies) for which you allow access to the Site through your internet connection are aware of these Terms and Privacy Policy and that such Terms and Privacy Policy is acceptable in their entirety by said third party(ies).</li>
                    <li>To access the Site or some of the resources it offers, you may be asked to provide certain registration details or other information. You must provide information that is correct, current and complete. You agree that all information you provide to register with this Site or otherwise, including any and all interactive features on the Site, is governed by our Privacy Policy (https:// www.myfloodrisk.org/privacy-policy) and consent to all actions ABR takes with respect to your information consistent with the Privacy Policy. Your user name, password or any other information provided as part of Site security procedures shall be treated by you as confidential, shall not be disclosed or shared with any third party(ies) and is personal to you and shall not be used by any third party(ies) to access the Site. You agree to: (i) notify ABR immediately of any unauthorized access to or use of your user name or password or any other breach of security or your suspicion of such, (ii) ensure that you exit from your account at the end of each session, and (iii) use caution when accessing your account from a public or shared computer to avoid acquisition and use of your username, password or other personal information. ABR reserves the right to disable your account, user name, password or other identifier, at any time in its sole discretion if it determines use would subject you, your information or the Site to unauthorized or inappropriate use.</li>
                </ol>
            </li>
            <li>
                <h6>Intellectual Property Rights; Use; Your Data.</h6>
                <ol type="a">
                    <li>The Site and all of the content, features and functionality, including but not limited to all algorithms, information, data, material, software, text, illustrations, displays, images, design, arrangement, trademarks, trade dress, designs, icons, photographs, video and audio, and any other visible content that appear on the Site (collectively, the &ldquo;Content&rdquo;), are owned by ABR and its licensors or other providers of the Content.&nbsp; ABR reserves all rights in the Content and the Content is protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. You agree to abide by any and all copyright notices, information, or restrictions contained in or relating to this Site or any Content. You may not: (i) reproduce, use or copy this Site, in whole or in part, or any Content for any purpose other than for your personal, noncommercial use, (ii) use any information, services, product or items on the Site for any commercial purpose, and (iii) cause any of the Content on this website to be framed or embedded into another website.</li>
                    <li>MyFloodRisk.org&rdquo; and the MyFloodRisk logo are registered trademarks of ABR and you are prohibited from using either for any purpose.</li>
                    <li>These Terms and the Privacy Policy apply to each guest and user of the Site, whether registered or unregistered (a &ldquo;User&rdquo;). ABR has no duty to review, screen or access in any way data you provide in connection with your use of the Site (&ldquo;User Data&ldquo;).&nbsp; All User Data submitted by you is solely your responsibility. You understand that ABR does not control and is not responsible for User Data and hereby specifically disclaims any liability related thereto. You hereby represent and warrant that you own or otherwise control and have all legal rights to the content submitted by you to the Site and the name or other identifier used in connection with such submission including, but not limited to, all the rights necessary to provide, post, upload, input or submit the User Data.</li>
                    <li>You are only authorized to visit, view, and retain a copy of pages of the Site for your personal, non-commercial use. You agree that you shall not duplicate, download, publish, modify or otherwise distribute the Content for any other purpose.</li>
                    <li>You agree that you shall not with respect to the use of the Site: (i) infringe another person&rsquo;s rights or violate applicable law, (ii) be unlawful, harmful, threatening, abusive, harassing, defamatory, libelous, or invade the privacy of another person; (iii) use obscene, abusive, discriminatory or illegal communications or acts; (iv) undertake false or misleading conduct, communications or acts; and/or (v) resulting the upload and/or distribution of any viruses, cancel bots, Trojan horses, harmful code, or other computer software or program designed to interrupt the website or the proper functioning of any software, hardware, equipment or materials used in connection with this website.</li>
                    <li>You hereby ABR and its affiliates and related entities an irrevocable, perpetual, non-exclusive right to use, copy, perform, display and distribute your User Data and to prepare derivative works of, or incorporate into other works, such User Data, and to grant and authorize sublicenses (through multiple tiers) of the foregoing. You also grant ABR the ability to copyright and protect the User Data, including the images, copy, and content, from the unauthorized use by unaffiliated third parties who may, from time to time, attempt to pirate such information via electronic or other means. This includes, but is not limited to, the right to file suit to seek injunctive relief to protect such material. You further agree to assist ABR, at our expense and control, to protect such copyrighted material from unauthorized redistribution.</li>
                </ol>
            </li>
            <li>
                <h6>Links to Other Sites</h6>
                <p>The Site contains links to other sites and resources provided by third parties which are provided for your convenience only and ABR has no control over the contents of such third party sites or resources.&nbsp; ABR makes no representation or warranty with respect to the products and/or services available from such third party sites or resources and you acknowledge and agree that ABR has no obligation, liability or responsibility of any kind with respect to the products and/or services available from such third party sites or resources or for any loss or damage that may arise from your use of them. Your access to such third party sites and/or resources and acquisition of such products and/or services are entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
                <p>If any link of the Site directs your or redirects you to a linked site, you should read the linked site&rsquo;s terms, conditions and privacy policy before accepting to be directed or redirected to such site disclosing any personal information.&nbsp; ABR (including its directors, affiliates, officers, employees, agents, contractors, successors and assigns) makes no warranty or representation regarding, and does not endorse, any linked websites or the information appearing thereon or any of the products or services available therefrom.</p>
            </li>
            <li>
                <h6>Disclaimer of Warranties; Limitation of Liability</h6>
                <ol type="a">
                    <li>ABR DOES NOT GUARANTEE OR WARRANT THAT ANY INFORMATION AND/OR FILES AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE SITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES AND SAFEGUARDS TO SATISFY YOUR PARTICULAR REQUIREMENTS FOR ANTI-VIRUS PROTECTION AND ACCURACY OF DATA INPUT AND OUTPUT, AND FOR MAINTAINING A MEANS EXTERNAL TO THE SITE FOR ANY RECONSTRUCTION OF ANY LOST DATA.</li>
                    <li>ABR IS NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THIS SITE OR ANY INFORMATION, DATA, FILE, SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</li>
                    <li>YOUR USE OF THE SITE AND ITS INFORMATION AND CONTENTS IS AT YOUR OWN RISK. THE SITE, AND ITS INFORMATION AND CONTENT ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER ABR NOR ANY PERSON ASSOCIATED WITH ABR MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE AND/OR AND ITS INFORMATION AND CONTENT. WITHOUT LIMITING THE FOREGOING, NEITHER ABR NOR ANYONE ASSOCIATED WITH ABR REPRESENTS OR WARRANTS THAT THE SITE, AND ITS INFORMATION AND CONTENT WILL BE ACCURATE, RELIABLE, SECURE, COMPLETE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE AND ITS INFORMATION AND CONTENT WILL OTHERWISE MEET YOUR NEEDS, EXPECTATIONS OR ANY PERFORMANCE RESULTS. ABR HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</li>
                    <li>IN NO EVENT WILL ABR, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH (i) YOUR USE, OR INABILITY TO USE, THE SITE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER SITES OR ANY SERVICES, PRODUCTS OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER WEBSITES, (ii) THESE TERMS OR ANY BREACH THEREOF BY YOU OR A THIRD PARTY, AND/OR (iii) ANY USER DATA. THIS DISCLAIMER INCLUDES ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.&nbsp; YOU AGREE THAT THE TOTAL LIABILITY OF ABR PURSUANT TO THE USE OF THE SITE SHALL BE LIMITED TO $500 REGARDLESS OF WHETHER (1) LIABILITY RESULTED FROM A FAILURE BY ABR, (2) A COURT OR ARBITRATION AUTHORITY DECIDES THAT ABR BREACHED ITS OBLIGATIONS TO YOU, OR (3) ABR&rsquo;S NEGLIGENCE CAUSED OR ALLOWED ANY HARM OR DAMAGE (WHETHER PERSONAL INJURY, DEATH OR PROPERTY LOSS) TO YOU OR ANY THIRD PARTY.&nbsp; YOU FURTHER AGREE THAT THIS SHALL BE THE ONLY REMEDY REGARDLESS OF WHAT LEGAL THEORY (INCLUDING WITHOUT LIMITATION, NEGLIGENCE, BREACH OF CONTRACT, BREACH OF WARRANTY OR PRODUCT LIABILITY) IS USED TO DETERMINE LIABILITY.&nbsp; THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</li>
                </ol>
            </li>
            <li>
                <h6>Indemnification</h6>
                <p>You agree to defend, indemnify and hold harmless ABR, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys&rsquo; fees) arising out of or relating to your violation of these Terms, the Privacy Policy or your use of the Site, including, but not limited to, your User Data, any use of the Site, its Content, or items other than as expressly authorized in these Terms or your use of any information obtained from the Site.</p>
            </li>
            <li>
                <h6>Miscellaneous</h6>
                <ol type="a">
                    <li>The use of the Site is limited to persons located within the United States of America.&nbsp; If you access the Site from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</li>
                    <li>All matters relating to the Site, the Terms, the Privacy Policy and any dispute or claim arising therefrom or related thereto, including non-contractual disputes or claims, shall be governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule (whether of the State of Florida or any other jurisdiction).&nbsp; You hereby agree: (i) that the exclusive venue for legal suit, action or proceeding arising out of or relating to the Site, the Terms, the Privacy Policy and any dispute or claim arising therefrom or related thereto, shall be instituted exclusively in the federal courts of the United States in and for the Middle District of Florida, or the courts of the State of Florida located within Brevard County, Florida, and (ii) to irrevocably submit to the exclusive jurisdiction of each such court in any such action and waives any objection it may now or hereafter have to venue or personal jurisdiction in each such court.</li>
                    <li>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THE SITE, THE TERMS, THE PRIVACY POLICY AND ANY DISPUTE OR CLAIM ARISING THEREFROM OR RELATED THERETO MUST BE COMMENCED IN FLORIDA WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</li>
                    <li>No waiver by ABR of any term or condition set forth in the Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of ABR to assert a right or provision under the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</li>
                    <li>The Terms and Privacy Policy constitute the sole and entire agreement between you and ABR with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site.</li>
                    <li>The Site is operated by AB Risk Corporation, 150 Interlachen Road, Melbourne FL 32940.&nbsp;&nbsp; Feedback, comments, requests for technical support and other communications relating to the Site should be directed to info@MyFloodRisk.org.</li>
                </ol>
            </li>
        </TermsOL>
    </>
    );
};