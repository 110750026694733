import { BaseStore } from "./BaseStore";
import { BlogPost } from "../../Models";
import { observable, action, computed } from "mobx";
import { Stores } from "../Stores";

export class BlogPostStore extends BaseStore {
    @observable public blogPosts: BlogPost[] = [];
    @observable public articles: BlogPost[] = [];
    @observable public blogPostsForAdmin: BlogPost[] = [];
    @observable public articlesForAdmin: BlogPost[] = [];
    @observable public activeCategory: string = "Show all articles";
    @observable public activeFilter: string = "Newest first";
    @observable public activePageSize: number = 12;
    @observable public activeBlogCount: number = 12;
    @observable public imageFile: File | undefined;
    @observable public fileBase64: string = "";
    @observable public creatingBlog: boolean = false;

    public constructor() {
        super();
    }

    @action
    public resetImageData(): void {
        this.imageFile = undefined;
        this.fileBase64 = "";
    }

    // tslint:disable-next-line: no-empty
    public init(stores: Stores): void {}

    @action
    public SetCreatingBlog(creatingBlog: boolean): void {
        this.creatingBlog = creatingBlog;
    }

    @action
    public SetImageFile(selectedImageFile: File): void {
        this.imageFile = selectedImageFile;
    }

    @action
    public SetFileBase64(fileBase64: string): void {
        this.fileBase64 = fileBase64;
    }

    @action
    public SetBlogPosts(blogPosts: BlogPost[]): void {
        this.blogPosts = blogPosts;
    }

    @action
    public SetArticles(articles: BlogPost[]): void {
        this.articles = articles;
    }

    @action
    public SetBlogPostsForAdmin(blogPosts: BlogPost[]): void {
        this.blogPostsForAdmin = blogPosts;
    }

    @action
    public SetArticlesForAdmin(blogPosts: BlogPost[]): void {
        this.articlesForAdmin = blogPosts;
    }

    @action
    public AddBlogPost(blogPosts: BlogPost): void {
        this.blogPosts.push(blogPosts);
    }

    @action
    public SetActiveCategory(activeCategory: string): void {
        this.activeCategory = activeCategory;
    }

    @action
    public SetActiveFilter(activeFilter: string): void {
        this.activeFilter = activeFilter;
    }

    @action
    public SetActivePageSize(activePageSize: number): void {
        this.activePageSize = activePageSize;
        this.activeBlogCount = activePageSize;
    }

    @action
    public SetActiveBlogCount(): void {
        this.activeBlogCount = this.activeBlogCount + this.activePageSize;
    }

    @action GetBlogPost(id: string): BlogPost | undefined {
        return this.blogPosts.find(b => b.id === id);
    }

    @action GetCreatingBlog(): boolean {
        return this.creatingBlog;
    }

    @action GetBlogPostForAdmin(id: string): BlogPost | undefined {
        return this.blogPostsForAdmin.find(b => b.id === id);
    }

    @action GetArticleForAdmin(id: string): BlogPost | undefined {
        return this.articlesForAdmin.find(b => b.id === id);
    }

    @action GetImageFile(): File | undefined {
        return this.imageFile;
    }

    @action GetFileBase64(): string {
        return this.fileBase64;
    }

    @action
    public SetBlogPost(blogPost: BlogPost): void {
        if (blogPost.isBlog) {
            let index: number = this.blogPostsForAdmin.findIndex(
                b => b.id === blogPost.id
            );
            if (index > -1) {
                this.blogPostsForAdmin.splice(index, 1);
            }
            this.blogPostsForAdmin.push(blogPost);
        } else {
            let index: number = this.articlesForAdmin.findIndex(
                b => b.id === blogPost.id
            );
            if (index > -1) {
                this.articlesForAdmin.splice(index, 1);
            }
            this.articlesForAdmin.push(blogPost);
        }
        let index: number = this.blogPosts.findIndex(b => b.id === blogPost.id);
        if (index > -1) {
            this.blogPosts.splice(index, 1);
        }
        this.blogPosts.push(blogPost);
    }

    @action ClearFilters(): void {
        this.activeCategory = "Show all articles";
        this.activeFilter = "Newest first";
        this.activePageSize = 12;
        this.activeBlogCount = 12;
    }

    @computed get GetBlogPosts(): BlogPost[] {
        var filteredBlogPosts: BlogPost[] = [];

        if (this.activeCategory === "Show all articles") {
            filteredBlogPosts = this.blogPosts;
        } else {
            this.blogPosts.forEach(blogPost => {
                if (
                    blogPost.categoryId === this.activeCategory ||
                    blogPost.isBlog
                ) {
                    filteredBlogPosts.push(blogPost);
                }
            });
        }

        switch (this.activeFilter) {
            case "Newest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(b.startDate).getTime() -
                            new Date(a.startDate).getTime()
                        );
                    });
            case "Oldest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(a.startDate).getTime() -
                            new Date(b.startDate).getTime()
                        );
                    });
            case "Title A to Z":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
            case "Title Z to A":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (b.name < a.name) {
                            return -1;
                        }
                        if (b.name > a.name) {
                            return 1;
                        }
                        return 0;
                    });
        }

        return filteredBlogPosts;
    }

    @computed get GetArticles(): BlogPost[] {
        var filteredBlogPosts: BlogPost[] = [];

        if (this.activeCategory === "Show all articles") {
            filteredBlogPosts = this.articles;
        } else {
            this.articles.forEach(article => {
                if (
                    article.categoryId === this.activeCategory ||
                    article.isBlog
                ) {
                    filteredBlogPosts.push(article);
                }
            });
        }

        switch (this.activeFilter) {
            case "Newest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(b.startDate).getTime() -
                            new Date(a.startDate).getTime()
                        );
                    });
            case "Oldest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(a.startDate).getTime() -
                            new Date(b.startDate).getTime()
                        );
                    });
            case "Title A to Z":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
            case "Title Z to A":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (b.name < a.name) {
                            return -1;
                        }
                        if (b.name > a.name) {
                            return 1;
                        }
                        return 0;
                    });
        }

        return filteredBlogPosts;
    }

    @computed get GetBlogPostsForAdmin(): BlogPost[] {
        var filteredBlogPosts: BlogPost[] = [];
        filteredBlogPosts = this.blogPostsForAdmin;

        switch (this.activeFilter) {
            case "Newest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(b.startDate).getTime() -
                            new Date(a.startDate).getTime()
                        );
                    });
            case "Oldest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(a.startDate).getTime() -
                            new Date(b.startDate).getTime()
                        );
                    });
            case "Title A to Z":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
            case "Title Z to A":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (b.name < a.name) {
                            return -1;
                        }
                        if (b.name > a.name) {
                            return 1;
                        }
                        return 0;
                    });
        }

        return filteredBlogPosts.filter(b => b.isBlog === true);
    }

    @computed get GetArticlesForAdmin(): BlogPost[] {
        var filteredBlogPosts: BlogPost[] = [];

        if (this.activeCategory === "Show all articles") {
            filteredBlogPosts = this.articlesForAdmin;
        } else {
            this.articlesForAdmin.forEach(article => {
                if (
                    article.categoryId === this.activeCategory ||
                    article.isBlog
                ) {
                    filteredBlogPosts.push(article);
                }
            });
        }

        switch (this.activeFilter) {
            case "Newest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(b.startDate).getTime() -
                            new Date(a.startDate).getTime()
                        );
                    });
            case "Oldest first":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        return (
                            new Date(a.startDate).getTime() -
                            new Date(b.startDate).getTime()
                        );
                    });
            case "Title A to Z":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
            case "Title Z to A":
                return filteredBlogPosts
                    .slice(0, this.activeBlogCount)
                    .sort((a: BlogPost, b: BlogPost) => {
                        if (b.name < a.name) {
                            return -1;
                        }
                        if (b.name > a.name) {
                            return 1;
                        }
                        return 0;
                    });
        }

        return filteredBlogPosts;
    }
}
