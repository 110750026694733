import React, {useRef} from "react";
import { UseRouter } from 'Shoothill.Core/Utils';
import {
	InputLabel,
	TextField/* ,
	useMediaQuery */
} from "@material-ui/core";

import {
	useValidation,
	usePercentageHeightOfWidth
} from "../../Shoothill.Core/Utils";
import validator from "validator";
import { accountNameModel } from 'Models/FormModels/accountNameModel';

interface IProps {
	onSubmit: (emailFormModel: accountNameModel) => void;
}

export const ForgotPassword: React.FC<IProps> = props => {
    const { onSubmit } = props;

    const btnElement = useRef<HTMLButtonElement>(null);
    const divElement = useRef<HTMLDivElement>(null);
    usePercentageHeightOfWidth(
		[btnElement, divElement],
		0.124
	);
    const { history } = UseRouter();

    const [formState, { email }] = useValidation<accountNameModel>(new accountNameModel());

    function onCancelClick(): void {
        history.push("/pro");
    }

    const doSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		if (formState.isFormValid()) {
            onSubmit({ ...formState.formData });
        }
	};

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var isIE: boolean =
		msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

    return (
        <>
        <form onSubmit={doSubmit} className="register fullw">
            <h2>I've forgotten my password</h2>
            <h3 className={"req"}>
                <span>So we can reset your password please enter your login email address below:</span>
            </h3>

            <div className={"PlaceHolderPart nlp"}>
                <TextField
                    label=""
                    fullWidth
                    variant={(isIE ? undefined : "outlined") as "outlined"}
                    placeholder="Email address (required)"
                    ref={divElement}
                    {...email("email", {
                        validationRules: [
                            (v: string) =>
                                validator.isEmail(v) || "* Email address is invalid",
                            (v: string) =>
                                (v && v.length > 0) || "* Email address is required"
                        ]
                    })}
                />
            </div>
            <div className={"PlaceHolderPart nlp"}></div>
            <div className={"PlaceHolderPart nlp"}>
                <button type="submit" className={"submitBtn"} ref={btnElement}>
                    Send me my link
                </button>
            </div>
            <div className="PlaceHolderPart cancelPlaceHolder">
                <button onClick={onCancelClick} ref={btnElement} className="cancelBtn">
                    Cancel
                </button>
            </div>
        </form>
        </>
    )
};