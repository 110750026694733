import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import "./Home.css";
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";
import aboutbackgroundTop from "../Content/about-topBG.jpg";
import aboutbackgroundMiddle from "../Content/about-middleBG.jpg";

import {
	useStyles,
	SecondBackground,
	ThirdBackground,
	GridContainer,
	AppHeading,
	TopStormBackground,
	MiddleStormBackground,
	PageContainer,
	WhiteGradient
} from "../Shoothill.Components/Styles/AboutStyling";

import { RowToolsCard } from "../Shoothill.Components/RowTools/RowToolsCard";
import { HurricaneStatsCard } from "../Shoothill.Components/RowTools/HurricaneStats";
import { RowMissionCard } from "../Shoothill.Components/RowTools/RowMissionCard";

export const About: React.FC = () => {
	const classes: any = useStyles("about");
	ReactGA.pageview(window.location.pathname + window.location.search);
	var knownTop: number = 0;
	var bgPositioner: number;
	var removeRule: (() => void) | undefined;

	let isMobile: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

	var whiteGrad: HTMLElement | null;
	var secondBackground: HTMLElement | null;
	var impactHeading: HTMLElement | null;

	useEffect(() => {
		window.scrollTo(0, 0);
		bgPositioner = window.setInterval(positionSecondBackground, 10);
	}, []);

	var addRule: (
		selector: string,
		css: { [x: string]: any }
	) => (() => void) | undefined = (function(style: any) {
		var sheet: CSSStyleSheet = document.head.appendChild(style).sheet;
		return function(selector: string, css: { [x: string]: any }) {
			var propText: string =
				typeof css === "string"
					? css
					: Object.keys(css)
							.map(function(p: string) {
								return (
									p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p])
								);
							})
							.join(";");
			if (sheet) {
				var id: number = sheet.insertRule(
					selector + "{" + propText + "}",
					sheet.cssRules.length
				);
				return function() {
					sheet.removeRule(id);
				};
			}
		};
	})(document.createElement("style"));

	function getOffset(el: HTMLElement): number {
		var _y: number = 0;
		while (el && !isNaN(el.offsetTop)) {
			_y += el.offsetTop - el.scrollTop;
			el = el.offsetParent as HTMLElement;
		}
		return _y;
	}

	function positionSecondBackground(): void {
		if (removeRule) {
			removeRule();
			removeRule = undefined;
		}
		var height: number = 0;
		var style: CSSStyleDeclaration;
		if (whiteGrad && secondBackground && impactHeading) {
			/* var style: CSSStyleDeclaration = window.getComputedStyle(whiteGrad); */
			style = window.getComputedStyle(whiteGrad);
			if (style.height && style.top) {
				height = +style.height.replace("px", "");
				if (knownTop !== 0 && knownTop !== +style.height.replace("px", "")) {
					window.clearInterval(bgPositioner);
				}
				if (style) {
					secondBackground.style.top = `${+style.height.replace("px", "") +
						+style.top.replace("px", "")}px`;
				}
				knownTop = +style.height.replace("px", "");
			}
			if (!isMobile) {
				/* var style: CSSStyleDeclaration = window.getComputedStyle(impactHeading); */
				style = window.getComputedStyle(impactHeading);
				var top: number = getOffset(impactHeading) - 70;
				if (style.height) {
					top = top + +style.height.replace("px", "");
					var percentage: number = (top / height) * 100;
					removeRule = addRule(".whiteGrad::after", {
						background: `linear-gradient( rgba(237,237,236,0) 54%, rgba(237,237,236,1) ${percentage}% )`
					});
				}
			}
		}
	}

	return useObserver(() => (
		<>
			<Helmet>
				<title>
					About Us - Educating Property Owners and Industry Professionals About
					Their True Flood Risk | My Flood Risk
				</title>
				<meta
					name="description"
					content="Developed by a group of flood experts, My Flood Risk is an 
       interactive, risk assessment platform focused on educating property owners and industry
       professionals about their true flood risk."
				/>
			</Helmet>
			<PageContainer>
				<SecondBackground>
					<AppHeading>
						<div>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Grid item xs={12}>
									<Box
										pt={12}
										textAlign="center"
										className={classes.aboutMainHeading}
										fontSize="h2.fontSize"
									>
										<Typography variant="h1">About My Flood Risk</Typography>
									</Box>
								</Grid>
							</Grid>
							<div className={classes.rowMissionCardContainer}>
								<RowMissionCard />
							</div>
						</div>
					</AppHeading>

					<div className={classes.impactMargin}>
						<Grid container>
							<Grid item xs={12}>
								<Typography
									variant="h2"
									className={classes.aboutSecondaryHeading}
									ref={h2 => {
										impactHeading = h2 as HTMLElement;
										positionSecondBackground();
									}}
								>
									Impact of major flooding events
								</Typography>
							</Grid>
						</Grid>
						<GridContainer container direction="row" justify="center">
							<HurricaneStatsCard />
						</GridContainer>
						<Grid container>
							<Grid item xs={12}>
								<Typography className={classes.text21}>
									Did you know that all fifty states have experienced economic
									losses from flooding
									<br /> in the last five years?
								</Typography>
							</Grid>
						</Grid>
					</div>

					<ThirdBackground>
						<div className={classes.blueGradient}>
							<div className={classes.subHeadingSpacing}>
								<Grid className={classes.waterIsPowerfulContainer} container>
									<Typography
										variant="h2"
										className={classes.aboutSecondaryHeading}
									>
										Water is Powerful. Knowledge is Stronger.
									</Typography>
									<Typography
										className={`${classes.text21} ${classes.waterIsPowerfulParagraph}`}
									>
										To us, it’s more than a flood, it’s our goal to educate the
										public on their true risk of flooding.
									</Typography>
									<Grid
										container
										direction="row"
										justify="center"
										alignItems="center"
										alignContent="center"
									>
										<RowToolsCard colorSet={true} />
									</Grid>
								</Grid>
							</div>
						</div>
					</ThirdBackground>
				</SecondBackground>
				<WhiteGradient
					className="whiteGrad"
					ref={(grad: HTMLElement | null) => {
						whiteGrad = grad;
						positionSecondBackground();
					}}
				>
					<TopStormBackground src={aboutbackgroundTop} />
				</WhiteGradient>
				<MiddleStormBackground
					src={aboutbackgroundMiddle}
					ref={(bg: HTMLElement | null) => {
						secondBackground = bg;
						window.addEventListener("resize", positionSecondBackground);
						positionSecondBackground();
					}}
				/>
			</PageContainer>
		</>
	));
};
