import React from "react";
import { Box } from "@material-ui/core";
import Spin from "react-reveal/Spin";
import styled from "styled-components";

export const StatsCard = styled(Box)`
  margin-left: 55px;
  margin-right: 55px;

  h3 {
    color: #464545;
    font-size: 28px;
    line-height: 1em;
    padding: 0;
    margin: 15px 0 15px;
  }

  .blueDisc {
    border-radius: 50%;
    border: solid 8px #4190ca;
    background-color: #ffffff;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    font-size: 40px;
    color: #4190ca;
    padding-top: 22px;
    text-align: center;
  }

  p {
    font-size: 21px;
    color: #464545;
    padding: 0;
    margin: 8px 0;
    line-height: 1.05em;
    font-size: 21px;
    font-weight: 400;
  }

  @media (max-width: 414px) {
    margin-left: 20px;
    margin-right: 20px;

    h3 {
      font-size: 18px;
      margin-bottom: 8px;
      margin-top: 18px;
    };
    p {
      font-size: 14px;
    };
  }
`;

export const HurricaneStatsCard: React.FC = () => {
  return (
    <>
      <StatsCard>
        <h3>
          Hurricane
          <br /> Harvey
        </h3>
        <Spin>
          <div className="blueDisc">26</div>
        </Spin>
        <p>
          Feet of
          <br /> Flooding
        </p>
      </StatsCard>
      <StatsCard>
        <h3>
          Hurricane
          <br /> Katrina
        </h3>
        <Spin>
          <div className="blueDisc">80</div>
        </Spin>
        <p>
          Billion $<br /> in total losses
        </p>
      </StatsCard>
      <StatsCard>
        <h3>
          Hurricane
          <br /> Florence
        </h3>
        <Spin>
          <div className="blueDisc">765</div>
        </Spin>
        <p>
          Counties
          <br /> affected
        </p>
      </StatsCard>
      <StatsCard>
        <h3>
          Hurricane
          <br /> Michael
        </h3>
        <Spin>
          <div className="blueDisc">17</div>
        </Spin>
        <p>
          Feet of
          <br /> storm surge
        </p>
      </StatsCard>
    </>
  );
};
