/*global google*/
import React, { useEffect, useContext, useRef } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import { GeocodeResult /* , ContactFormModel */ } from "../../Models";
import { StoresContext, Stores } from "../../Stores";
import styled from "styled-components";
import { MapStore } from "../../Stores/Domain/MapStore";
import { UseRouter, UseForceUpdate } from "../../Shoothill.Core/Utils";
import { useObserver } from "mobx-react-lite";
import { UserStore } from "../../Stores/Domain";

const useStyles: any = makeStyles((theme: Theme) => ({
	yourriskForm: {
		display: "flex",
		width: "100%",
		padding: "0",
		margin: "0"
	},
	textField: {
		backgroundColor: "#FFFFFF",
		height: "54px",
		marginRight: "5px",
		maxWidth: "100%",
		textAlign: "left",
		width: "100%",
		fontSize: "20px",
		padding: "10px",
		"@media (min-width: 1466px)": {
			minWidth: "305px"
		},
		placeholder: {
			color: "#000000"
		},
		color: "#000000",
		"@media (max-width: 767px)": {
			height: "34px",
			fontSize: "14px",
			fontFamily: "'Lato', sans-serif"
		}
	},
	textFieldZip: {
		maxWidth: "100%",
		backgroundColor: "#FFFFFF",
		paddingLeft: "5px",
		height: "54px",
		marginRight: "5px",
		"@media (min-width: 1466px)": {
			maxWidth: "177px",
			borderTopLeftRadius: "5px",
			borderBottomLeftRadius: "5px"
		},
		"& placeholder": {
			color: "#000000"
		}
	},
	yourRiskButton: {
		width: "54px",
		height: "54px",
		margin: "0px",
		backgroundColor: "#e0daf0",
		color: "#4190ca",
		"@media (min-width: 1466px)": {
			maxWidth: "54px",
			borderTopRightRadius: "5px",
			borderBottomRightRadius: "5px"
		}
	}
}));

export const StyledFormGoogle: any = styled.form`
	input {
		color: #464545 !important;
		font-weight: normal;
	}

	div[class$="-container"] {
		font-weight: normal;
		> div {
			padding: 0 0 0 4px;
			border: none;
			font-size: 21px;
			cursor: text;
			span {
				background-color: transparent;
			}
			div {
				padding: 0 0 0 4px;
				border: none;
			}
		}
	}

	div[class$="-menu"] {
		margin: 0;
		border-radius: 0;
		div {
			div {
				cursor: pointer;
				font-size: 16px;
				border-bottom: solid 1px #efefef;
				padding: 5px;
			}
		}
	}

	svg {
		top: 0 !important;
		width: 54px;
		height: 54px;
		color: transparent;
		cursor: pointer;
	}

	/*   @media screen and (max-width: 767px) {

  } */
`;

export interface IAddressSelectorGoogleProps {
	gotoMyRisk?: boolean | undefined;
	pageSource?: string;
	callback?: () => void;
}

export const AddressSelectorGoogle: React.FC<IAddressSelectorGoogleProps> = props => {
	const theme: any = useTheme();
	const classes: any = useStyles(theme);
	const { history } = UseRouter();
	const rerender = UseForceUpdate();
	const userStoreContext: UserStore = useContext<Stores>(StoresContext).domain
		.UserStore;
	const mapStoreContext: MapStore = useContext<Stores>(StoresContext).domain
		.MapStore;
	const { gotoMyRisk, callback, pageSource } = props;

	let searchLocationAutoComplete: google.maps.places.Autocomplete;
	let searchLocationInput: HTMLInputElement;
	let searchEventListener: google.maps.MapsEventListener;

	let inputReference: React.MutableRefObject<any> = useRef(null);

	const redirectToRisk: () => void = (): void => {
		history.push("/myrisk");
	};

	const setLocation: any = (result: GeocodeResult) => {
		mapStoreContext.SetLocation(result);

		callback && callback();

		if (pageSource !== undefined && pageSource.length > 1) {
			mapStoreContext.SetPageSource(pageSource);
		}

		if (gotoMyRisk !== undefined && gotoMyRisk) {
			redirectToRisk();
		}
	};

	useEffect(() => {
		if (inputReference.current != null) {
			searchLocationInput = inputReference.current!;

			searchLocationAutoComplete = new google.maps.places.Autocomplete(
				searchLocationInput,
				{
					/* types: ["address"], */
					componentRestrictions: { country: "us" }
				}
			);

			searchEventListener = google.maps.event.addListener(
				searchLocationAutoComplete,
				"place_changed",
				() => {
					/* userStoreContext.reset(); */

					const location: google.maps.places.PlaceResult = searchLocationAutoComplete!.getPlace();

					if (
						location &&
						location.geometry &&
						location.formatted_address &&
						location.types
					) {
						let result: GeocodeResult = {
							bBox: [],
							center: [
								location.geometry!.location.lng(),
								location.geometry!.location.lat()
							],
							placeName: location.formatted_address!,
							placeType: location.types!,
							text: location.name,
							type: "",
							region: "",
							county: ""
						};

						if (location.address_components) {
							location.address_components.forEach(
								(comp: google.maps.GeocoderAddressComponent) => {
									if (comp.types.indexOf("administrative_area_level_1") >= 0) {
										result.region = comp.short_name;
									} else if (
										comp.types.indexOf("administrative_area_level_2") >= 0
									) {
										result.county = comp.long_name;
									}
								}
							);
						}

						/* let region: string =
							location.address_components![4] === undefined
								? ""
								: location.address_components![4].short_name;

						let county: string =
							location.address_components![3] === undefined
								? ""
								: location.address_components![3].long_name;

						if (region.length > 2 && region !== "US") {
							region = location.address_components![5].short_name;
							county = location.address_components![4].long_name;
						}

						if (region.length === 2 && region === "US") {
							region = location.address_components![3].short_name;
							county = location.address_components![2].long_name;
						}

						result.region = region;
						result.county = county; */
						setLocation(result);
					}
				}
			);
		}

		return () => {
			google.maps.event.removeListener(searchEventListener!);
		};
	}, []);

	let isMobile: boolean = useMediaQuery("screen and (max-width: 767px)")
		? true
		: false;

	return useObserver(() => {
		return (
			<input
				ref={inputReference}
				onKeyPress={(e: any) => {
					if (e.keyCode === 13 || e.charCode === 13 || e.which === 13) {
						e.preventDefault();
						return false;
					}
				}}
				onChange={(e: any) => {
					mapStoreContext.SetDummyAddress(e.target.value);
				}}
				onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
					isMobile &&
						window.scrollTo({
							top: e.currentTarget.offsetTop - 90,
							left: 0,
							behavior: "smooth"
						});
				}}
				placeholder={"Please enter your address here..."}
				className={classes.textField}
				value={mapStoreContext.address}
				style={{
					borderRadius: "5px",
					borderWidth: "1px",
					borderStyle: "solid",
					borderColor: "#E0DAF0"
				}}
			/>
		);
	});
};
