import styled from "styled-components";
import { Box, Button } from "@material-ui/core";

export const GenericModalBox: any = styled(Box)`
    margin: 15% auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000054;
    border-radius: 15px;
    opacity: 1;

    width: 90%;
    max-width: 730px;

    height: auto;
    max-height: 730px;

    padding: 30px;

    @media screen and (min-width: 768px) {

    }

    @media screen and (max-width: 767px) {

    }
`;

export const GenericModalButton: any = styled(Button)`
    box-shadow: 0px 3px 6px #00000054;
    border-radius: 15px;
    opacity: 1;
    width: 100%;
    max-width: 290px;
    max-height: 54px;
    padding: 18px;
    display: inline-block;
    margin-right: 7px;
    margin-left: 7px;
    text-align: center;
`;

export const GenericCancelButton: any = styled(GenericModalButton)`
    background: #464545 0% 0% no-repeat padding-box;
    color: white;

    :hover {
        background: #464545 0% 0% no-repeat padding-box;
    }
`;

export const GenericAcceptButton: any = styled(GenericModalButton)`
    background: #4190CA 0% 0% no-repeat padding-box;
    color: white;

    :hover {
        background: #4190CA 0% 0% no-repeat padding-box;
    }
`;