/* import { BlogPostStore } from "../../Stores/Domain/BlogPostStore";
import { Stores, StoresContext } from "../../Stores"; */
import { Box, GridListTile, Link/* , Typography */ } from "@material-ui/core";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import * as AppUrls from "Globals/AppUrls";
/* import moment from "moment"; */
import React/* , { useContext } */ from "react";

export interface IArticleListItemAdminProps {
    id: string | undefined;
    title: string;
    category?: string;
    excerpt: string;
    publishDate: string;
    link: string;
    linkText: string;
    imagePath: string;
    key: string;
    isBlog: boolean;
    isDeleted: boolean;
}

export const ArticleGridListTileAdmin: any = styled(GridListTile)`
    border: solid 1px #e0daf0;
    max-width: 320px;
    width: 90%;
    margin: 8px auto;
    text-align: left;
    font-weight: 100;
    font-size: 18px;
    p {
        font-weight: 100;
        font-size: 18px;
    }
    .articleitem,
    .blogitem {
        text-align: center;
        .articleDecor {
            display: block;
            width: 80%;
            height: 4px;
            background-color: #4190ca;
            margin: -36px auto 32px;
        }
    }

    .articleitem.item-deleted,
    .blogitem.item-deleted {
        > div {
            opacity: 0.4;
        }
    }

    @media (min-width: 768px) {
        width: 100%;
        margin: 10px auto;
    }
    @media (min-width: 1024px) {
        max-width: 318px;
        width: 100%;
        margin: 14px 42px;
        :nth-child(3n + 3),
        :nth-child(3n + 1) {
            margin: 14px 0px;
        }
    }
`;

export const ImageBoxAdmin: any = styled(Box)`
    max-width: 320px;
    width: 90%;
    @media (min-width: 768px) {
        width: 100%;
        max-width: 318px;
        max-height: 172px;
        :nth-child(2n + 1) {
            max-height: 230px;
        }
        overflow: hidden;
    }
    img {
        max-width: 316px;
        width: 100%;
    }
`;

export const ArticleBoxAdmin: any = styled(Box)`
    padding: 20px;
    color: #464545;
    font-size: 18px;
    padding-bottom: 40px;
    padding-top: 16px;

    h2 {
        font-size: 21px;

        padding: 0px;
        margin: 9px 0px;
    }
    .articleitem {
        h2 {
            color: #4190ca;
        }
    }

    span.linktext {
        font-weight: normal;
        color: #4190ca;
        text-decoration: underline;
    }
    .category {
        font-weight: 700;
    }
`;

export const ArticleListItemAdmin: React.FC<
    IArticleListItemAdminProps
> = props => {
    const {
        id,
        title,
        category,
/*         excerpt,
        publishDate,
        linkText, */
        key,
        isBlog,
        isDeleted
    } = props;

/*     const blogPostStoreContext: BlogPostStore = useContext<Stores>(
        StoresContext
    ).domain.BlogPostStore; */

    //blogPostStoreContext.SetCreatingBlog(isBlog);

    const isDeletedClass = isDeleted ? "item-deleted" : "";

    return (
        <ArticleGridListTileAdmin key={key} className={isDeletedClass}>
            {isBlog && (
                <>
                    <Link
                        title={title}
                        component={RouterLink}
                        to={AppUrls.Client.BlogPostAdmin.replace(
                            ":Id?",
                            id ? id : ""
                        )}
                        className={`blogitem ${isDeletedClass}`}
                    >
                        <ImageBoxAdmin>
                            <img src={props.imagePath} alt={props.title} />
                        </ImageBoxAdmin>
                        <ArticleBoxAdmin>
                            <h2>{title}</h2>
                        </ArticleBoxAdmin>
                        <div className="articleDecor"></div>
                    </Link>
                </>
            )}
            {!isBlog && (
                <>
                    <Link
                        title={title}
                        component={RouterLink}
                        to={AppUrls.Client.ArticleItemAdmin.replace(
                            ":Id?",
                            id ? id : ""
                        )}
                        className={`articleitem ${isDeletedClass}`}
                    >
                        <ImageBoxAdmin>
                            <img src={props.imagePath} alt={props.title} />
                        </ImageBoxAdmin>
                        <ArticleBoxAdmin>
                            <div className="category">{category}</div>
                            <h2>{title}</h2>
                        </ArticleBoxAdmin>
                        <div className="articleDecor"></div>
                    </Link>
                </>
            )}
        </ArticleGridListTileAdmin>
    );
};
