import styled from "styled-components";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

export const useStyles: any = makeStyles(theme => ({}));

export const PageContainer: any = styled.div`
  position: relative;
  width: 100%;
  display: block;
`;

export const BlueGradientBox: any = styled(Box)`
  @media (max-width: 1024px) {
    background-image: linear-gradient(
      0deg,
      rgba(65, 144, 202, 1) 2%,
      rgba(255, 255, 255, 0.05) 44%
    );
  }
  position: relative;
  overflow: hidden;
`;

export interface IFloodBackgroundProps {
  imagesrc: string;
}

export const TopFloodBackground: any = styled(Box)<IFloodBackgroundProps>`
  position: absolute;
  background-image: url(${props => props.imagesrc});
  height: 116vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  top: 0;
  @media (min-width: 1025px) {
    left: -130px;
  }
  width: 120vw;

  div {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1024px) {
    top: -25px;
    height: 70vh;

    div {
      background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 28%,
        rgba(255, 255, 255, 0.05) 44%
      );
    }
  }
`;

export const SecondBackground: any = styled.div`
  text-align: center;
  padding: 20px 0px;
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  @media (min-width: 1025px) {
    height: 660px;
  }
  @media (max-width: 1024px) {
    height: 1420px;
  }
`;

export const AppHeading: any = styled(Box)`
  align-items: center;
  color: #ffffff;
  width: 1040px;
  margin: auto auto;

  @media (max-width: 1024px) {
    width: 100%;
    display: block;
    padding-top: 30px;
    margin-top: 70px;
  }

  h1 {
    font-size: 72px;
    font-weight: bold;
    letter-spacing: -1.8px;
    margin: 0;
    @media (max-width: 1024px) {
      font-size: 32px;
      letter-spacing: -0.8px;
      margin: 0 auto;
      line-height: 1;
      max-width: 230px;
    }
  }
`;

export const FloodOptions: any = styled(Box)`
  margin: 0 auto;
  padding: 30px 0px;
`;
