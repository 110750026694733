import React from "react";
import { Button } from "@material-ui/core";
//import { UseDataApi } from "Shoothill.Core/Utils";
//import { ApiResult } from "Models";

export const User: React.FC = () => {
    // const { data, isLoading, isError, doRequest } = UseDataApi<ApiResult>();

    return (
        <div className="App">
            <header className="App-header">
                <h1>User</h1>
                <Button
                    onClick={() => {
                        // doRequest("/api/account/getdata");
                    }}
                >
                    Test
                </Button>
            </header>
        </div>
    );
};
