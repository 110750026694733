import React, { useContext, useRef, useMemo } from "react";
import { Stores, StoresContext } from "../../Stores";

import { FemaTotalBox, FemaPieBox } from "./reportTotalFEMAstyling";

import totalfemblueSofa from "../../Content/totalFem-blueSofa.svg";
import totalfemblueHouse from "../../Content/TotFem_bluehouse.svg";

import { PieChart } from "react-charts-d3";
import { useMediaQuery } from "@material-ui/core";
import useCountUp from "Shoothill.Core/Utils/Hooks/UseCountUp";

function getLabelXY(
	percentage: number,
	radius: number,
	imageWidth: number,
	imageHeight: number,
	image2: boolean = false
) {
	var retVal = getDotXY(percentage, radius, image2);
	retVal.retX -= imageWidth / 2;
	retVal.retY -= imageHeight / 2;
	return retVal;
}

function getDotXY(percentage: number, radius: number, image2: boolean = false) {
	var retVal;
	var offset: number = 1.5;
	if (!image2) {
		retVal = getArcXY(percentage / 2, radius / offset);
		retVal.retX += radius / offset / 2;
		retVal.retY += radius / offset / 2;
	} else {
		retVal = getArcXY(percentage / 2 + 50, radius / offset);
		retVal.retX += radius / offset / 2;
		retVal.retY += radius / offset / 2;
	}
	return retVal;
}

function getArcXY(percentage: number, radius: number) {
	var retX: number, retY: number;
	var a: number, aRad: number, z: number, x: number, y: number, aCalc: number;

	a = 360 * (percentage / 100);
	aCalc = a > 0 ? 360 - a : a;
	aRad = (aCalc * Math.PI) / 180;

	z = Math.sqrt(2 * radius * radius - 2 * radius * radius * Math.cos(aRad));

	if (aCalc <= 90) {
		x = radius * Math.sin(aRad);
	} else {
		x = radius * Math.sin(((180 - aCalc) * Math.PI) / 180);
	}

	y = Math.sqrt(z * z - x * x);
	retY = y;

	if (a <= 180) {
		retX = radius + x;
	} else {
		retX = radius - x;
	}

	return { retX, retY };
}

export const ReportTotalFEMAClaims: React.FC = () => {
	const floodDataContext: any = useContext<Stores>(StoresContext).domain
		.FloodAndClaimDataStore;

	const pieData: any[] = [
		{ label: "Content", value: floodDataContext.paidContentClaims },
		{ label: "Building", value: floodDataContext.paidBuildingClaims }
	];

	const claimsContainerEl = useRef<HTMLDivElement>(null);

	const totalClaims = useCountUp({
		end:
			floodDataContext.paidContentClaims + floodDataContext.paidBuildingClaims,
		duration: 2.5,
		autoStart: false,
		easeStart: false
	});

	const buildingClaims = useCountUp({
		end: floodDataContext.paidBuildingClaims,
		duration: 2.5,
		autoStart: false,
		callback: totalClaims.start,
		callbackOverlap: 0.55,
		easeStart: false
	});

	const contentClaims = useCountUp({
		end: floodDataContext.paidContentClaims,
		duration: 2.5,
		autoStart: false,
		callback: () => {
			if (seperateClaimCountersStart()) {
				buildingClaims.start();
			}
		},
		callbackOverlap: 0.55,
		easeStart: false
	});

	const claimCount = useCountUp({
		end: floodDataContext.claims,
		duration: 3,
		callback: () => {
			contentClaims.start();
			if (!seperateClaimCountersStart()) {
				buildingClaims.start();
			}
		},
		callbackOverlap: 0.4,
		easeStart: false
	});

	const contentPercent = useMemo(() => {
		var pc =
			(floodDataContext.paidContentClaims * 100) /
			(floodDataContext.paidBuildingClaims +
				floodDataContext.paidContentClaims);
		return pc;
	}, [floodDataContext]);

	const sofa = useMemo(() => {
		return getLabelXY(contentPercent, 143.5, 50, 50);
	}, [contentPercent]);

	const house = useMemo(() => {
		return getLabelXY(contentPercent, 143.5, 50, 50, true);
	}, [contentPercent]);

	let mobileLayout: boolean;
	mobileLayout = useMediaQuery("screen and (max-width: 767px)") ? true : false;

	function seperateClaimCountersStart(): boolean {
		if (contentClaims.containerEl.current && claimsContainerEl.current) {
			var containerStyle = window.getComputedStyle(claimsContainerEl.current);
			var contentStyle = window.getComputedStyle(
				contentClaims.containerEl.current
			);
			if (containerStyle.height && contentStyle.height) {
				return !(
					Math.floor(+containerStyle.height.replace("px", "")) ===
					Math.floor(+contentStyle.height.replace("px", ""))
				);
			}
		}
		return true;
	}

	return (
		<>
			<FemaTotalBox className="Header">
				<h1>Total FEMA Insurance Claims</h1>
			</FemaTotalBox>
			<FemaTotalBox>
				{floodDataContext.claims === 0 && (
					<>
						<p>No Data!</p>
					</>
				)}
				{floodDataContext.claims > 0 && (
					<>
						{!mobileLayout && (
							<div className="OutcomeDiagram">
								<FemaPieBox
									sofaX={sofa.retX}
									sofaY={sofa.retY}
									houseX={house.retX}
									houseY={house.retY}
								>
									<PieChart
										data={pieData}
										width={327}
										height={327}
										displayLabels={false}
									/>
									<div className="contentClaims"></div>
									<div className="buildingClaims"></div>
								</FemaPieBox>
							</div>
						)}

						<div className="Outcome">
							<h2>{floodDataContext.county}</h2>
							Building claims (County)
							<br />
							<span className="magenta">
								{claimCount.counter}
								<br />
							</span>
							<div className="claimsContainer" ref={claimsContainerEl}>
								<div className="claims" ref={contentClaims.containerEl}>
									<div className="claimPadding">
										<img src={totalfemblueSofa} alt="Content Claims" /> Content
										claims (County)
										<br />
										<span className="lightblue">
											{new Intl.NumberFormat("en-US", {
												style: "currency",
												currency: "USD"
											}).format(contentClaims.counter)}
											<br />
										</span>
									</div>
								</div>
								<div className="claims" ref={buildingClaims.containerEl}>
									<div className="claimPadding buildingClaimsPadding">
										<img src={totalfemblueHouse} alt="Building Claims" />{" "}
										Building claims (County)
										<br />
										<span className="darkblue">
											{new Intl.NumberFormat("en-US", {
												style: "currency",
												currency: "USD"
											}).format(buildingClaims.counter)}
											<br />
										</span>
									</div>
								</div>
							</div>
							<div className="total">
								Total claims (County)
								<br />
								<span className="magenta">
									{new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: "USD"
									}).format(totalClaims.counter)}
								</span>
							</div>
						</div>
						{mobileLayout && (
							<div className="OutcomeDiagram">
								<FemaPieBox>
									<div className="pieContainer">
										<PieChart
											data={pieData}
											width={149}
											height={149}
											displayLabels={false}
										/>
									</div>
								</FemaPieBox>
							</div>
						)}
					</>
				)}
			</FemaTotalBox>
		</>
	);
};
