import React, {
	useState,
	useContext,
	useEffect,
	useRef,
	RefObject,
	MutableRefObject,
	useLayoutEffect
} from "react";
import ReactImageCrop from "react-image-crop";

import { AccountStore } from "../../Stores/Domain/AccountStores";
import { StoresContext, Stores } from "../../Stores";
import { ProUserModel } from "Models/ProUserModel";

import { AddressSelectorGoogle } from "../../Shoothill.Components/Forms";
import { UploadableImage } from "../../Shoothill.Components/Forms/UploadableImage";

import {
	UseDataApi,
	usePercentageHeightOfWidth
} from "../../Shoothill.Core/Utils";
import * as AppUrls from "../../Globals/AppUrls";
import awaitingImage from "../../Content/uploadBox.svg";
import awaitingImageMob from "../../Content/uploadBoxMob.svg";

import reportImage from "../../Content/reportImage.png";
import ngMassiveCert from "../../Content/ngMassiveCert.png";
import gMassiveCert from "../../Content/gMassiveCert.png";
import gMassiveCertMob from "../../Content/gMassiveCertMob.png";
import reportIcon from "../../Content/reportIcon.svg";
import reportIconGuarenteed from "../../Content/reportIconGuarenteed.svg";

import objectFitImages from "object-fit-images";
import { Link, Grid, useMediaQuery } from "@material-ui/core";

import {
	DownloadReportGrid,
	DownloadReportButton,
	FZDReportGrid,
	NGTypeGrid,
	AddrBox,
	LogoHeaderDiv
} from "./BrandedStyling";

/* import { DownloadReportButton } from "Shoothill.Components/Styles/ProAccStyling"; */
import { ApiResult, CropResizeInfo, CropResizeResult } from "../../Models";

import FitText from "@kennethormandy/react-fittext";

interface IProps {
	brandLogo?: string;
	reportType: MutableRefObject<number>;
	startReport: () => void;
}

export const BrandedComp: React.FC<IProps> = props => {
	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
		.AccountStore;

	const { data: imageResponse, doRequest: userRequest } = UseDataApi();

	const el1 = useRef<HTMLButtonElement>(null);
	const el2 = useRef<HTMLButtonElement>(null);

	const el = useRef<HTMLDivElement>(null);

	usePercentageHeightOfWidth([el1, el2], 0.183);
	usePercentageHeightOfWidth([el], 0.115);

	let useMobile = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	const { brandLogo } = props;

	const [hasDesktopPhoto, setHasDesktopPhoto] = React.useState<boolean>(
		brandLogo ? brandLogo.length > 0 : false
	);
	const [originalImageURL, setOriginalImageURL] = React.useState<string>(
		brandLogo ? brandLogo : useMobile ? awaitingImageMob : awaitingImage
	);
	const { data: fileUrlResponse, doRequest: uploadFile } = UseDataApi<
		ApiResult<CropResizeResult>
	>();

	const [crop, setCrop] = React.useState<ReactImageCrop.Crop>({
		x: 0,
		y: 0,
		aspect: 4 / 3,
		width: 100,
		height: 100
	});

	const [percentCrop, setPercentCrop] = React.useState<
		ReactImageCrop.PercentCrop
	>({
		x: 0,
		y: 0,
		aspect: 4 / 3,
		width: 100,
		height: 100
	});

	useEffect(() => {
		if (imageResponse) {
			if (imageResponse.wasSuccessful && imageResponse.payload.succeeded) {
				accountStore.getLoginState(imageResponse.payload.token);
			}
		}
	}, [imageResponse]);

	useEffect(() => {
		if (fileUrlResponse !== null && fileUrlResponse !== undefined) {
			if (fileUrlResponse.wasSuccessful) {
				setOriginalImageURL(fileUrlResponse.payload.croppedAndResizedResult);
				accountStoreUpdate(fileUrlResponse.payload.croppedAndResizedResult);
				setHasDesktopPhoto(true);
			}
		}
	}, [fileUrlResponse]);

	function desktopItemImage(): string {
		return originalImageURL;
	}

	function handleImageSelect(selectedImageFile: File): void {
		accountStore.resetImageData();
		accountStore.SetImageFile(selectedImageFile);

		handleServerCropClick();
	}

	const handleRemovePhotoClick = (e: any) => {
		e.preventDefault();
		setHasDesktopPhoto(false);
		setOriginalImageURL(awaitingImage);
	};

	function handleServerCropClick(): void {
		const fileToSave: File | undefined = accountStore.GetImageFile();
		if (fileToSave) {
			processFile(fileToSave);
		}
	}

	function processFile(imageFile: File): void {
		try {
			loadImageDataUrl(imageFile);

			setTimeout(() => {
				const fileBase64: string = accountStore.GetFileBase64();

				if (fileBase64 !== "" && fileBase64 !== null) {
					const longestSideLength: number = 600;
					const imageSubDirectory: string = "proaccountimages/";
					/* const storeThumbnail: boolean = false; */

					const request: CropResizeInfo = {
						fileBase64: fileBase64 as string,
						fileName: accountStore.UserName + "_" + imageFile.name,
						aspect: percentCrop.aspect!,
						x: percentCrop.x!,
						y: percentCrop.y!,
						width: percentCrop.width!,
						height: percentCrop.height!,
						resizeImage: true,
						longestSideLength,
						imageSubDirectory
					};

					uploadFile(AppUrls.Server.Api.Image.cropAndResize, request);
				}
			}, 1000);
		} catch (err) {
			console.log(err);
		}
	}

	function loadImageDataUrl(imageFile: File): void {
		var reader: FileReader = new FileReader();
		reader.readAsDataURL(imageFile);
		reader.onload = function(): void {
			let result: any = reader.result;
			accountStore.SetFileBase64(result);
		};
		reader.onerror = function(error: any): void {
			console.log("Error: ", error);
		};
	}

	function accountStoreUpdate(logoPath: string): void {
		accountStore.setCompanyLogo(logoPath);
		let proUser: ProUserModel = accountStore.CreateProUser();
		handleUpdateLogo(proUser);
	}

	const handleUpdateLogo: (prouserModel: ProUserModel) => void = (
		prouserModel: ProUserModel
	): void => {
		userRequest("/api/account/UpdateImage", prouserModel);
	};

	function changeDefUploadImg() {
		if (!hasDesktopPhoto) {
			setOriginalImageURL(
				window.innerWidth < 1140 ? awaitingImageMob : awaitingImage
			);
		}
	}

	useEffect(() => {
		objectFitImages();
		window.addEventListener("resize", changeDefUploadImg);

		return () => window.removeEventListener("resize", changeDefUploadImg);
	}, []);

	const uploadedHeader = (
		<>
			<h3>
				<span>Your uploaded logo</span>
			</h3>
		</>
	);

	const brandedReportHeader = (
		<>
			<h2 className="brandedReportHeader">
				Create your branded report with liability waiver included:
			</h2>
		</>
	);

	const noImgReport = (
		<>
			<p>Please upload your logo to create your branded report</p>
		</>
	);

	const orderReportHeader = (
		<>
			<h2 className="FZDHeader">
				Order a Flood Zone Determination Report from just $4.99
			</h2>
		</>
	);

	const FZDText = (
		<>
			<p className="FZDText">
				Our free My Flood Risk report includes the property's flood zone. If you
				need an official FEMA-stamped Flood Zone Determination, you can purchase
				these at preferred PRO pricing below.
			</p>
		</>
	);

	const textBlock = (
		<>
			<p className="textBlock">
				We offer two types of paid flood zone determination reports. A
				guaranteed flood zone determination includes an official FEMA-stamped
				determination and comes with a warranty. Should the guaranteed
				determination give a false reading, the property owner will be partially
				reimbursed for flood losses or insurance premiums resulting from this
				determination. Our non-guaranteed report does not come with any such
				warranty and does not include a FEMA-stamped flood zone determination.
				Remember, our My Flood Risk reports contain much of the same
				information, and are available at no cost to you.
			</p>
		</>
	);

	const btn1Text = (
		<>
			<span>
				Get a Guaranteed <br />
				FZD Report $9.99
			</span>
		</>
	);

	const btn2Text = (
		<>
			<span>
				Get a Non-Guaranteed <br />
				FZD Report $4.99
			</span>
		</>
	);

	const ngHeading = (
		<>
			<h3>Non-Guaranteed</h3>
		</>
	);

	const gHeading = (
		<>
			<h3>Guaranteed</h3>
		</>
	);

	const ngSub1 = (
		<>
			<p className="sub1">
				Includes current and previous flood zones, next nearest flood zone and
				distance, distance to salt and fresh water, estimated ground and base
				flood elevations, and the community name, number, initial FIRM date, map
				panel information, participation status, historic flood losses, and
				policies in force.
			</p>
		</>
	);

	const gSub1 = (
		<>
			<p className="sub1">
				Includes a non-guaranteed report, PLUS a guaranteed, official
				FEMA-stamped Flood Zone Determination.
			</p>
		</>
	);

	const ngSub2 = (
		<>
			<p className="subText">
				No warranty or guarantee from either AB Risk Corporation or MassiveCert,
				Inc. is either expressed or implied for Non-Guaranteed Flood Zone
				Determinations.
			</p>
		</>
	);

	const gSub2 = (
		<>
			<p className="subText">
				When necessary, manual mapping will be performed to ensure accuracy.
				MassiveCert, Inc. provides a guarantee against false positives that
				result in the purchase of a flood insurance policy, and false negatives
				that result in uninsured losses. No warranty or guarantee from AB Risk
				Corporation is either expressed or implied
			</p>
		</>
	);

	const brandedReportHeaderFittext = (
		<>
			{useMobile ? (
				<>
					<FitText compressor={/* 1.53 */ 1.63} debounce={0}>
						{brandedReportHeader}
					</FitText>
				</>
			) : (
				<>{brandedReportHeader}</>
			)}
		</>
	);

	const addrBarText = (
		<>
			<p className="addrBarText">
				Enter an address below to start creating your branded My Flood Risk
				report:
			</p>
		</>
	);

	const addrBar = (
		<>
			<AddrBox ref={el}>
				<div className="PlaceHolderPart nlp addrSelect">
					<AddressSelectorGoogle gotoMyRisk pageSource={"/pro"} />
				</div>
			</AddrBox>
		</>
	);

	return (
		<>
			{hasDesktopPhoto && (
				<>
					<LogoHeaderDiv>
						{useMobile ? (
							<>
								<FitText compressor={/* 1.3 */ 1.71} debounce={0}>
									{uploadedHeader}
								</FitText>
							</>
						) : (
							<>
								{uploadedHeader}
								<Link
									href="/pro"
									onClick={handleRemovePhotoClick}
									className="changeImageBtn"
								>
									Change logo
								</Link>
							</>
						)}
					</LogoHeaderDiv>
					<div className="companyLogoContainer">
						<span />
						<img
							src={brandLogo}
							alt={"Your Company Logo"}
							/* onClick={handleRemovePhotoClick} */
						/>
					</div>
					{useMobile ? (
						<>
							<FitText compressor={/* 1.95 */ 2.3} debounce={0}>
								<Link
									href="/pro"
									onClick={handleRemovePhotoClick}
									className="changeImageBtn"
								>
									Change logo
								</Link>
							</FitText>
						</>
					) : (
						<></>
					)}
				</>
			)}

			{!hasDesktopPhoto && (
				<>
					<h3>
						{useMobile ? (
							<>
								<FitText compressor={/* 1.3 */ 1.71} debounce={0}>
									{noImgReport}
								</FitText>
							</>
						) : (
							<>{noImgReport}</>
						)}
					</h3>
					<UploadableImage
						imageUrl={desktopItemImage()}
						isDefaultImage={hasDesktopPhoto}
						className={"photoUploaderSelect"}
						onImageSelected={handleImageSelect}
					/>

					{/* 	{brandedReportHeaderFittext}
					{useMobile ? (
						<>
							<FitText compressor={1.96} debounce={0}>
								{noImgReport}
							</FitText>
						</>
					) : (
						<>{noImgReport}</>
					)}
					<span className="spc" /> */}
				</>
			)}

			{brandedReportHeaderFittext}
			{useMobile ? (
				<>
					<FitText compressor={2.118} debounce={0}>
						{addrBarText}
					</FitText>
				</>
			) : (
				<>{addrBarText}</>
			)}

			{useMobile ? (
				<>
					<FitText compressor={2.118} debounce={0}>
						{addrBar}
					</FitText>
				</>
			) : (
				<>{addrBar}</>
			)}

			{useMobile ? (
				<>
					<FitText compressor={2.118} debounce={0}>
						{FZDText}
					</FitText>
				</>
			) : (
				<>{FZDText}</>
			)}

			{useMobile ? (
				<>
					<FitText compressor={1.63} debounce={0}>
						{orderReportHeader}
					</FitText>
				</>
			) : (
				<>{orderReportHeader}</>
			)}
			{useMobile ? (
				<>
					<FitText compressor={2.118} debounce={0}>
						{textBlock}
					</FitText>
				</>
			) : (
				<>{textBlock}</>
			)}
			<DownloadReportGrid container>
				<NGTypeGrid item xs={12}>
					<Grid container>
						<FZDReportGrid item xs={4} md={4} className=" btnGrid">
							<img
								src={ngMassiveCert}
								className="reportImg"
								alt="Non-Guaranteed"
							/>
						</FZDReportGrid>
						<FZDReportGrid item xs={8} md={8} className=" btnGrid">
							{useMobile ? (
								<>
									<FitText compressor={1.84} debounce={0}>
										{ngHeading}
									</FitText>
								</>
							) : (
								<>{ngHeading}</>
							)}

							{useMobile ? (
								<>
									<FitText compressor={2.118} debounce={0}>
										{ngSub1}
									</FitText>
								</>
							) : (
								<>{ngSub1}</>
							)}
							{useMobile ? (
								<>
									<FitText compressor={2.45} debounce={0}>
										{ngSub2}
									</FitText>
								</>
							) : (
								<>{ngSub2}</>
							)}
							<DownloadReportButton
								className="reportBtn"
								onClick={() => {
									props.reportType.current = 2;
									props.startReport();
								}}
								ref={el2}
							>
								<div>
									<img src={reportIcon} alt="" />
								</div>
								{useMobile ? (
									<>
										<FitText compressor={1.6} debounce={2000}>
											{btn2Text}
										</FitText>
									</>
								) : (
									<>{btn2Text}</>
								)}
							</DownloadReportButton>
						</FZDReportGrid>
					</Grid>
				</NGTypeGrid>
				<Grid item xs={12}>
					<Grid container>
						<FZDReportGrid item xs={4} md={4} className=" btnGrid">
							{useMobile ? (
								<>
									<img
										src={ngMassiveCert}
										className="reportImg"
										alt="Supplemental"
									/>
									<img
										src={gMassiveCertMob}
										className="reportImg"
										alt="Guaranteed"
									/>
								</>
							) : (
								<>
									<img
										src={gMassiveCert}
										className="reportImg"
										alt="Guaranteed"
									/>
								</>
							)}
						</FZDReportGrid>
						<FZDReportGrid item xs={8} md={8} className=" btnGrid">
							{useMobile ? (
								<>
									<FitText compressor={1.84} debounce={0}>
										{gHeading}
									</FitText>
								</>
							) : (
								<>{gHeading}</>
							)}
							{useMobile ? (
								<>
									<FitText compressor={2.118} debounce={0}>
										{gSub1}
									</FitText>
								</>
							) : (
								<>{gSub1}</>
							)}
							{useMobile ? (
								<>
									<FitText compressor={2.45} debounce={0}>
										{gSub2}
									</FitText>
								</>
							) : (
								<>{gSub2}</>
							)}

							<DownloadReportButton
								className="reportBtn"
								onClick={() => {
									props.reportType.current = 1;
									props.startReport();
								}}
								ref={el1}
							>
								<div>
									<img src={reportIconGuarenteed} alt="" />
								</div>
								{useMobile ? (
									<>
										<FitText compressor={1.6} debounce={2000}>
											{btn1Text}
										</FitText>
									</>
								) : (
									<>{btn1Text}</>
								)}
							</DownloadReportButton>
						</FZDReportGrid>
					</Grid>
				</Grid>
			</DownloadReportGrid>
		</>
	);
};
