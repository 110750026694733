export class WizardContactFormModel {
	public name: string = "";
	public email: string = "";

	public constructor(name?: string, email?: string) {
		if (name !== undefined) {
			this.name = name;
		}
		if (email !== undefined) {
			this.email = email;
		}
	}
}
