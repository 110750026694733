export const ReportMapStyle: any = {
    version: 8,
    name: "PR - Cat5 - Vapourwave",
    metadata: {
        "mapbox:origin": "basic-template",
        "mapbox:autocomposite": true,
        "mapbox:type": "template",
        "mapbox:sdk-support": {
            js: "0.49.0",
            android: "6.5.0",
            ios: "4.4.0"
        },
        "mapbox:trackposition": false
    },
    sources: {
        "mapbox://mapbox.satellite": {
            url: "mapbox://mapbox.satellite",
            type: "raster",
            tileSize: 256
        },
        composite: {
            url:
                "mapbox://mapbox.mapbox-streets-v7,andyjump.abq8ycfk,andyjump.6vzf9mrv,andyjump.6mrfhthw,andyjump.9f4pjs0r,andyjump.5xw3u13m",
            type: "vector"
        }
    },
    sprite: "mapbox://sprites/andyjump/cjnzuqs4s1fgg2sqll2umt0o5",
    glyphs: "mapbox://fonts/andyjump/{fontstack}/{range}.pbf",
    layers: [
        {
            id: "background",
            type: "background",
            layout: {},
            paint: {
                "background-color": "#343A40"
            }
        },
        {
            id: "mapbox-satellite",
            type: "raster",
            source: "mapbox://mapbox.satellite",
            layout: {},
            paint: {}
        },
        {
            id: "SLOSH-MOM-4",
            type: "fill",
            source: "composite",
            "source-layer": "US_Category5_MOM_Inundation_HighTide4",
            layout: {},
            paint: {
                "fill-color": "#b967ff",
                "fill-outline-color": "#fe0202",
                "fill-opacity": 0.35
            }
        },
        {
            id: "SLOSH-MOM-3",
            type: "fill",
            source: "composite",
            "source-layer": "US_Category5_MOM_Inundation_HighTide3",
            layout: {},
            paint: {
                "fill-color": "#01cdfe",
                "fill-outline-color": "#feaa02",
                "fill-opacity": 0.35
            }
        },
        {
            id: "SLOSH-MOM-2",
            type: "fill",
            source: "composite",
            "source-layer": "US_Category5_MOM_Inundation_HighTide2",
            layout: {},
            paint: {
                "fill-color": "#05ffa1",
                "fill-outline-color": "#fefe02",
                "fill-opacity": 0.35
            }
        },
        {
            id: "SLOSH-MOM-1",
            type: "fill",
            source: "composite",
            "source-layer": "US_Category5_MOM_Inundation_HighTide1",
            layout: {},
            paint: {
                "fill-color": "#fffb96",
                "fill-outline-color": "#0271fe",
                "fill-opacity": 0.35
            }
        },
        {
            id: "Loss",
            type: "fill",
            source: "composite",
            "source-layer": "County",
            layout: {},
            paint: {
                "fill-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "TotalPayments"],
                    0,
                    "hsl(115, 100%, 62%)",
                    1,
                    "hsl(206, 100%, 49%)",
                    10000000,
                    "hsl(56, 100%, 52%)",
                    500000000,
                    "hsl(36, 100%, 49%)",
                    1000000000,
                    "hsl(0, 100%, 49%)"
                ],
                "fill-opacity": 0.35
            }
        }
        /*
        ,{
            "id": "tunnel",
            "type": "line",
            "source": "composite",
            "source-layer": "road",
            "filter": [
                "all",
                ["==", "$type", "LineString"],
                [
                    "all",
                    ["!=", "type", "service:parking_aisle"],
                    ["==", "structure", "tunnel"],
                    [
                        "in",
                        "class",
                        "link",
                        "motorway",
                        "motorway_link",
                        "primary",
                        "secondary",
                        "service",
                        "street",
                        "street_limited",
                        "tertiary",
                        "track",
                        "trunk"
                    ]
                ]
            ],
            "layout": { "line-join": "round" },
            "paint": {
                "line-width": [
                    "interpolate",
                    ["exponential", 1.5],
                    ["zoom"],
                    5,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        0.5,
                        "tertiary",
                        0.01,
                        [
                            "street",
                            "street_limited",
                            "motorway_link",
                            "service",
                            "track",
                            "link"
                        ],
                        0,
                        0
                    ],
                    12,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        3,
                        ["secondary", "tertiary"],
                        2,
                        ["street", "street_limited", "motorway_link"],
                        0.5,
                        ["service", "track", "link"],
                        0,
                        0
                    ],
                    18,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        30,
                        ["secondary", "tertiary"],
                        24,
                        ["street", "street_limited", "motorway_link"],
                        12,
                        ["service", "track", "link"],
                        10,
                        10
                    ]
                ],
                "line-color": [
                    "match",
                    ["get", "class"],
                    ["street", "street_limited", "service", "track", "link"],
                    "hsl(38, 100%, 98%)",
                    "hsl(0, 0%, 100%)"
                ],
                "line-dasharray": [0.2, 0.2]
            }
        },
        {
            "id": "road",
            "type": "line",
            "source": "composite",
            "source-layer": "road",
            "filter": [
                "all",
                ["==", "$type", "LineString"],
                [
                    "all",
                    ["!=", "type", "service:parking_aisle"],
                    ["!in", "structure", "bridge", "tunnel"],
                    [
                        "in",
                        "class",
                        "link",
                        "motorway",
                        "motorway_link",
                        "primary",
                        "secondary",
                        "service",
                        "street",
                        "street_limited",
                        "tertiary",
                        "track",
                        "trunk"
                    ]
                ]
            ],
            "layout": { "line-join": "round", "line-cap": "round" },
            "paint": {
                "line-width": [
                    "interpolate",
                    ["exponential", 1.5],
                    ["zoom"],
                    5,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        0.5,
                        "tertiary",
                        0.01,
                        [
                            "street",
                            "street_limited",
                            "motorway_link",
                            "service",
                            "track",
                            "link"
                        ],
                        0,
                        0
                    ],
                    12,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        3,
                        ["secondary", "tertiary"],
                        2,
                        ["street", "street_limited", "motorway_link"],
                        0.5,
                        ["service", "track", "link"],
                        0,
                        0
                    ],
                    18,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        30,
                        ["secondary", "tertiary"],
                        24,
                        ["street", "street_limited", "motorway_link"],
                        12,
                        ["service", "track", "link"],
                        10,
                        10
                    ]
                ],
                "line-color": [
                    "match",
                    ["get", "class"],
                    ["street", "street_limited", "service", "track", "link"],
                    "hsl(38, 100%, 98%)",
                    "hsl(0, 0%, 100%)"
                ]
            }
        },
        {
            "id": "bridge-case",
            "type": "line",
            "source": "composite",
            "source-layer": "road",
            "filter": [
                "all",
                ["==", "$type", "LineString"],
                [
                    "all",
                    ["!=", "type", "service:parking_aisle"],
                    ["==", "structure", "bridge"],
                    [
                        "in",
                        "class",
                        "link",
                        "motorway",
                        "motorway_link",
                        "primary",
                        "secondary",
                        "service",
                        "street",
                        "street_limited",
                        "tertiary",
                        "track",
                        "trunk"
                    ]
                ]
            ],
            "layout": { "line-join": "round" },
            "paint": {
                "line-width": [
                    "interpolate",
                    ["exponential", 1.5],
                    ["zoom"],
                    10,
                    1,
                    16,
                    2
                ],
                "line-color": "hsl(38, 48%, 86%)",
                "line-gap-width": [
                    "interpolate",
                    ["exponential", 1.5],
                    ["zoom"],
                    5,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        0.5,
                        "tertiary",
                        0.01,
                        [
                            "street",
                            "street_limited",
                            "motorway_link",
                            "service",
                            "track",
                            "link"
                        ],
                        0,
                        0
                    ],
                    12,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        3,
                        ["secondary", "tertiary"],
                        2,
                        ["street", "street_limited", "motorway_link"],
                        0.5,
                        ["service", "track", "link"],
                        0,
                        0
                    ],
                    18,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        30,
                        ["secondary", "tertiary"],
                        24,
                        ["street", "street_limited", "motorway_link"],
                        12,
                        ["service", "track", "link"],
                        10,
                        10
                    ]
                ]
            }
        },
        {
            "id": "bridge",
            "type": "line",
            "source": "composite",
            "source-layer": "road",
            "filter": [
                "all",
                ["==", "$type", "LineString"],
                [
                    "all",
                    ["!=", "type", "service:parking_aisle"],
                    ["==", "structure", "bridge"],
                    [
                        "in",
                        "class",
                        "link",
                        "motorway",
                        "motorway_link",
                        "primary",
                        "secondary",
                        "service",
                        "street",
                        "street_limited",
                        "tertiary",
                        "track",
                        "trunk"
                    ]
                ]
            ],
            "layout": { "line-join": "round", "line-cap": "round" },
            "paint": {
                "line-width": [
                    "interpolate",
                    ["exponential", 1.5],
                    ["zoom"],
                    5,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        0.5,
                        "tertiary",
                        0.01,
                        [
                            "street",
                            "street_limited",
                            "motorway_link",
                            "service",
                            "track",
                            "link"
                        ],
                        0,
                        0
                    ],
                    12,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        3,
                        ["secondary", "tertiary"],
                        2,
                        ["street", "street_limited", "motorway_link"],
                        0.5,
                        ["service", "track", "link"],
                        0,
                        0
                    ],
                    18,
                    [
                        "match",
                        ["get", "class"],
                        ["motorway", "trunk", "primary"],
                        30,
                        ["secondary", "tertiary"],
                        24,
                        ["street", "street_limited", "motorway_link"],
                        12,
                        ["service", "track", "link"],
                        10,
                        10
                    ]
                ],
                "line-color": [
                    "match",
                    ["get", "class"],
                    ["street", "street_limited", "service", "track", "link"],
                    "hsl(38, 100%, 98%)",
                    "hsl(0, 0%, 100%)"
                ]
            }
        },
        {
            "id": "admin-state-province",
            "type": "line",
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 2,
            "filter": ["all", ["==", "maritime", 0], [">=", "admin_level", 3]],
            "layout": { "line-join": "round", "line-cap": "round" },
            "paint": {
                "line-dasharray": [
                    "step",
                    ["zoom"],
                    ["literal", [2, 0]],
                    7,
                    ["literal", [2, 2, 6, 2]]
                ],
                "line-width": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    7,
                    0.75,
                    12,
                    1.5
                ],
                "line-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    2,
                    0,
                    3,
                    1
                ],
                "line-color": [
                    "step",
                    ["zoom"],
                    "hsl(0, 0%, 80%)",
                    4,
                    "hsl(0, 0%, 65%)"
                ]
            }
        },
        {
            "id": "admin-country",
            "type": "line",
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 1,
            "filter": [
                "all",
                ["<=", "admin_level", 2],
                ["==", "disputed", 0],
                ["==", "maritime", 0]
            ],
            "layout": { "line-join": "round", "line-cap": "round" },
            "paint": {
                "line-width": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    3,
                    0.5,
                    10,
                    2
                ],
                "line-color": "hsl(0, 0%, 50%)"
            }
        },
        {
            "id": "admin-country-disputed",
            "type": "line",
            "source": "composite",
            "source-layer": "admin",
            "minzoom": 1,
            "filter": [
                "all",
                ["<=", "admin_level", 2],
                ["==", "disputed", 1],
                ["==", "maritime", 0]
            ],
            "layout": { "line-join": "round" },
            "paint": {
                "line-color": "hsl(0, 0%, 50%)",
                "line-width": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    3,
                    0.5,
                    10,
                    2
                ],
                "line-dasharray": [1.5, 1.5]
            }
        },
        {
            "id": "road-label",
            "type": "symbol",
            "source": "composite",
            "source-layer": "road_label",
            "minzoom": 12,
            "filter": [
                "in",
                "class",
                "link",
                "motorway",
                "pedestrian",
                "primary",
                "secondary",
                "street",
                "street_limited",
                "tertiary",
                "trunk"
            ],
            "layout": {
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    9,
                    [
                        "match",
                        ["get", "class"],
                        [
                            "trunk",
                            "primary",
                            "secondary",
                            "tertiary",
                            "motorway"
                        ],
                        10,
                        9
                    ],
                    20,
                    [
                        "match",
                        ["get", "class"],
                        [
                            "motorway",
                            "trunk",
                            "primary",
                            "secondary",
                            "tertiary"
                        ],
                        15,
                        14
                    ]
                ],
                "text-max-angle": 30,
                "text-font": ["Roboto Regular", "Arial Unicode MS Regular"],
                "symbol-placement": "line",
                "text-padding": 1,
                "text-rotation-alignment": "map",
                "text-pitch-alignment": "viewport",
                "text-field": ["get", "name_en"]
            },
            "paint": {
                "text-color": "hsl(0, 0%, 0%)",
                "text-halo-color": "hsl(0, 0%, 100%)",
                "text-halo-width": 1
            }
        },
        {
            "id": "place-neighborhood-suburb-label",
            "type": "symbol",
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 12,
            "maxzoom": 15,
            "filter": ["in", "type", "neighbourhood", "suburb"],
            "layout": {
                "text-field": ["get", "name_en"],
                "text-transform": "uppercase",
                "text-letter-spacing": 0.15,
                "text-max-width": 8,
                "text-font": ["Roboto Regular", "Arial Unicode MS Regular"],
                "text-padding": 3,
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    12,
                    11,
                    16,
                    16
                ]
            },
            "paint": {
                "text-halo-color": "#000000",
                "text-halo-width": 1,
                "text-color": "#ffffff"
            }
        },
        {
            "id": "place-town-village-hamlet-label",
            "type": "symbol",
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 6,
            "maxzoom": 14,
            "filter": ["in", "type", "hamlet", "town", "village"],
            "layout": {
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    5,
                    ["match", ["get", "type"], "town", 9.5, 8],
                    16,
                    ["match", ["get", "type"], "town", 20, 16]
                ],
                "text-font": [
                    "step",
                    ["zoom"],
                    ["literal", ["Roboto Regular", "Arial Unicode MS Regular"]],
                    12,
                    [
                        "match",
                        ["get", "type"],
                        "town",
                        [
                            "literal",
                            ["Roboto Medium", "Arial Unicode MS Regular"]
                        ],
                        [
                            "literal",
                            ["Roboto Regular", "Arial Unicode MS Regular"]
                        ]
                    ]
                ],
                "text-max-width": 7,
                "text-field": ["get", "name_en"]
            },
            "paint": {
                "text-color": "#ffffff",
                "text-halo-blur": 0.5,
                "text-halo-color": "#000000",
                "text-halo-width": 1
            }
        },
        {
            "id": "place-city-label-minor",
            "type": "symbol",
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 1,
            "maxzoom": 14,
            "filter": ["all", ["!has", "scalerank"], ["==", "type", "city"]],
            "layout": {
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    5,
                    12,
                    16,
                    22
                ],
                "text-font": [
                    "literal",
                    ["Roboto Medium", "Arial Unicode MS Regular"]
                ],
                "text-max-width": 10,
                "text-field": ["get", "name_en"]
            },
            "paint": {
                "text-halo-width": 1.25,
                "text-halo-blur": 0.5,
                "text-halo-color": "#000000",
                "text-color": "#ffffff"
            }
        },
        {
            "id": "place-city-label-major",
            "type": "symbol",
            "source": "composite",
            "source-layer": "place_label",
            "minzoom": 1,
            "maxzoom": 14,
            "filter": ["all", ["==", "type", "city"], ["has", "scalerank"]],
            "layout": {
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    5,
                    ["step", ["get", "scalerank"], 14, 4, 12],
                    16,
                    ["step", ["get", "scalerank"], 30, 4, 22]
                ],
                "text-font": [
                    "step",
                    ["zoom"],
                    ["literal", ["Roboto Medium", "Arial Unicode MS Regular"]],
                    10,
                    [
                        "step",
                        ["get", "scalerank"],
                        ["literal", ["Roboto Bold", "Arial Unicode MS Bold"]],
                        5,
                        [
                            "literal",
                            ["Roboto Medium", "Arial Unicode MS Regular"]
                        ]
                    ]
                ],
                "text-max-width": 10,
                "text-field": ["get", "name_en"]
            },
            "paint": {
                "text-halo-width": 1.25,
                "text-halo-blur": 0.5,
                "text-halo-color": "#000000",
                "text-color": "#ffffff"
            }
        },
        {
            "id": "state-label",
            "type": "symbol",
            "source": "composite",
            "source-layer": "state_label",
            "minzoom": 4,
            "maxzoom": 8,
            "layout": {
                "text-line-height": 1.2,
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    4,
                    ["step", ["get", "area"], 8, 20000, 9, 80000, 10],
                    9,
                    ["step", ["get", "area"], 14, 20000, 18, 80000, 23]
                ],
                "text-transform": "uppercase",
                "text-font": ["Roboto Black", "Arial Unicode MS Bold"],
                "text-padding": 1,
                "text-field": [
                    "step",
                    ["zoom"],
                    [
                        "step",
                        ["get", "area"],
                        ["get", "abbr"],
                        80000,
                        ["get", "name_en"]
                    ],
                    5,
                    ["get", "name_en"]
                ],
                "text-letter-spacing": 0.2,
                "text-max-width": 6
            },
            "paint": {
                "text-color": "#ffffff",
                "text-halo-width": 1,
                "text-halo-color": "#000000"
            }
        },
        {
            "id": "country-label",
            "type": "symbol",
            "source": "composite",
            "source-layer": "country_label",
            "minzoom": 1,
            "maxzoom": 8,
            "layout": {
                "text-field": ["get", "name_en"],
                "text-max-width": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    5,
                    3,
                    6
                ],
                "text-font": [
                    "step",
                    ["zoom"],
                    ["literal", ["Roboto Medium", "Arial Unicode MS Regular"]],
                    4,
                    ["literal", ["Roboto Bold", "Arial Unicode MS Bold"]]
                ],
                "text-size": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    2,
                    ["step", ["get", "scalerank"], 13, 3, 11, 5, 9],
                    9,
                    ["step", ["get", "scalerank"], 35, 3, 27, 5, 22]
                ]
            },
            "paint": {
                "text-halo-width": 1.5,
                "text-halo-color": "#000000",
                "text-color": "#ffffff"
            }
        } */
    ],
    created: "2018-11-02T10:04:25.727Z",
    id: "cjnzuqs4s1fgg2sqll2umt0o5",
    modified: "2018-11-02T10:10:40.218Z",
    owner: "andyjump",
    visibility: "private",
    draft: false
};

export default ReportMapStyle;
