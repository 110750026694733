import { Box, Button } from "@material-ui/core";
import styled from "styled-components";
/* import blueStar from "Content/blueStar.svg"; */

import backgroundProAccount from "Content/ProBackground.png";

import {
	BackGroundBase,
	PageBasis,
	StyledRadiusBox /* ,
	StyledBox */
} from "./Styles";

export const ProBackground: any = styled(BackGroundBase)`
	background-attachment: fixed;
	background-image: url(${backgroundProAccount});
	/*height: 580px;*/
	padding-right: 0 !important;
	min-height: calc(100vh - 70px);
	@media (max-width: 767px) {
		margin-top: 70px;
	}
`;

interface IEPageHeight {
	pageHeightIE: number;
}

export const PageContainer: any = styled(PageBasis)<IEPageHeight>`
	min-height: calc(100vh - 70px);
	height: ${props => props.pageHeightIE}px;
	height: fit-content;
	display: flex;

	@media (min-width: 1141px) {
		/* min-height: 95vh / * ${props => props.pageHeightIE}px * /; */
		align-items: center;
	}

	.mobMargins {
		width: 100%;
		display: flex;
		@media (max-width: 1140px) {
			margin: 20px;
			width: calc(100% - 40px);
		}
	}
`;

export const ProBranding: any = styled(Box)`
	width: 100%;
	img {
		margin: 0 auto;
	}
`;

interface IMobDesignHeights {
	mobdesignheight: number;
	minheight: number;
	background: string;
	width?: number;
	nanwidth?: boolean;
}

export const ProRadiusBox: any = styled(StyledRadiusBox)<IMobDesignHeights>`
	width: ${props => props.width || 1140}px;
	/* height: fit-content; */
	margin: 20px auto;
	background: ${props => props.background};

	img {
		max-width: 100%;
	}

	.signupLogo {
		display: block;
		margin: auto;
	}

	@media (min-width: 1141px) {
		min-height: ${props => props.minheight}px;
	}
	
	@media (max-width: 1140px) {
		padding-bottom: 0;
		padding: 20px;
		min-height: auto;
	}

	@media (orientation: landscape) and (max-width: 1140px) {
		width: 100%;
		width: calc(
			(100vh - 70px - 40px) * ${props =>
				props.nanwidth ? "NaN" : 335 / props.mobdesignheight}
		);
		min-height: 100%;
	}

	@media (orientation: portrait) and (max-width: 1140px/* 529px */) {
		width: 100%;
		min-height: calc((100vw - 40px) * ${props => props.mobdesignheight / 335});
		height: fit-content;
	}

	@media (min-aspect-ratio: 375/${props =>
		props.mobdesignheight +
		70 +
		20 +
		20}) and (orientation: portrait) and (max-width: 1140px) {
		width: calc(
			(100vh - 70px - 40px) * ${props => 335 / props.mobdesignheight}
		);
		min-height: 100%;
	}

	@media (max-width: 1140px) {
		.Logo > img {
			width: 66.9%;
			margin-bottom: 8.5%;
		}
		margin: 0 auto;
		.PlaceHolderPart.signup {
			/* height: 45%; */
			margin-top: 10%;

			button: {
				height: 9.54%;
			}
		}
	}

	.lm30 {
		@media (min-width: 1141px) {
			margin-left: 30px;
		}
	}

	.loginSignup {
		width: 100%;
		display: flex;

		.ALink {
			color: #247fc2;
			text-decoration: underline;
			@media (min-width: 1141px) {
				margin: -6px auto;
			}
			cursor: pointer;
		}

		@media (max-width: 1140px) {
			flex-direction: column;
			height: 100%;
		}

		@media (min-width: 1141px) {
			justify-content: space-around;
		}

		.lineContainer {
			width: 1px;
			height: 360px;
			position: absolute;
			left: 49.95%;
			display: flex;

			.line {
				width: 1px;
				height: 318px;
				background-color: white;
				align-self: center;

				@media (max-width: 1140px) {
					display: none;
				}
			}
		}
	}

	input {
		background: white;

		@media (max-width: 1140px) {
			height: 100%;
			padding-top: 0;
			padding-bottom: 0;
		}

		::placeholder {
			color: #464545;
			opacity: 1;
			font-size: 21px;
			font-weight: normal;
			line-height: 24px;
			letter-spacing: -0.53px;
		}
	}
	form {
		display: flex;
		flex-direction: column;
		.inputField:nth-child(1) {
			margin: 8px 0 0;

			@media (max-width: 1140px) {
				margin: 0;

				> div:first-child {
					height: 100%;
				}
			}
		}

		.inputField:nth-child(2) {
			margin-top: 4px;
			margin-bottom: 18px;

			@media (max-width: 1140px) {
				margin: 0;
				margin-top: 3%;

				> div:first-child {
					margin-top: 0;
					margin-bottom: 0;
					height: 100%;

					> div:first-child {
						height: 100%;
					}
				}
			}
		}

		.inputField {
			@media (max-width: 1140px) {
				height: 100%;
			}
		}

		.multi-part .inputField{
			width: 40%;
		}

		@media (max-width: 1140px) {
			height: 100%;

			input,
			fieldset {
				border-radius: 10px;
			}
		}

		button {
			margin-top: 15px;
			@media (max-width: 1140px) {
				margin-top: 3%;
				height: 100%;
				margin-bottom: 0;
			}
		}
	}

	.logoutBtn {
		background-color: #4190ca;
		padding: 5px 10x;
		color: #ffffff;
	}

	.PlaceHolderPart {
		display: inline-flex;
		flex-direction: column;
		width: 100%;

		@media (min-width: 1141px) {
			width: 500px;
			justify-content: space-between;
		}

		/* @media (max-width: 1140px) {
			height: 30%;
		} */

		.btnContainer {
			height: 100%;
			align-items: center;
			display: flex;
			width: fit-content;
		}

		@media (min-width: 1141px) {
			padding: 0px 30px;

			.LoginPane {
				padding: 40px 0px 0px;
				margin-left: -60px;

				.loginprompt{
					margin-top: 24px!important;
				}
			}
		}
	}

	.PlaceHolderPart.signup {
		@media (min-width: 1141px) {
			position: relative;
			padding: 0;
			margin: 0px -30px 0px 30px;

			.getALogin {
				position: absolute;
				top: -25px;
				left: 30px;
				width: 442px;
			}
		}

		@media (max-width: 1140px) {
			.getALogin {
				margin: -20px;
				background: rgb(65, 144, 202);
				padding: 10px 20px 20px;
				border-bottom-left-radius: 30px;
				border-bottom-right-radius: 30px;
			}
		}

		button {
			text-transform: uppercase;

			@media (max-width: 1140px) {
				margin-top: calc((100% + 40px) * 0.06);
				height: 14.1%;
			}
		}
	}

	button {
		cursor: pointer;
		:not(.reportBtn)/* , :not(.signmeup) */ {
			max-width: 525px;
			width: /* 438px */ 100%;
			height: 54px;
			border-radius: 10px;
			opacity: 0.95;
			background-color: #4190ca;
			border: none;
			color: white;
			font-weight: bold;
			font-size: 16px;
			letter-spacing: 0;
			line-height: 19px;
			margin-bottom: 60px;
			box-shadow: none;
			align-self: center;

			:hover {
				background-color: #4190ca;
			}

			@media (max-width: 1140px) {
				width: 100%;
				height: 34px;
				margin-bottom: 0;
			}
		}
	}

	button.cancelBtn, button.cancelBtn:hover {
		background-color: #222120;
	}

	button.signmeup, button.signmeup:hover {
		background-color: #ffffff;
		color: #4190ca;
		margin-top: 20px;
	}

	.contactCards button {
		margin-left: 0;
		margin-right: 0;

		@media (max-width: 1140px) {
			width: 100% !important;

			:nth-child(2) {
				margin: 3.4% 0;

				@media (min-width: 611px) {
					margin: 18px 0;
				}
			}
		}
	}

	h2,
	h3,
	h4,
	h2 {
		font-size: 28px;
		letter-spacing: -0.7px;
		margin-top: 40px;
		margin-bottom: 30px;
		font-weight: bold;
		color: #222120;
		line-height: 23px;
		span {
			color: #4190ca;
		}

		@media (max-width: 1140px) {
			margin: 0;
			margin-bottom: calc((100% + 20px) * 0.06);
			line-height: 100%;
		}
	}

	h2 {
		@media (max-width: 1140px) {
			margin-bottom: 0;
		}
	}

	h3 {
		margin: 0 0 30px 0;
		font-size: 21px;
		letter-spacing: -0.53px;
		font-weight: bold;
		color: #222120;
		line-height: 21px;

		@media (max-width: 1140px) {
			margin-bottom: calc((100% + 20px) * 0.06);
			line-height: 100%;
		}
	}

	.regSuccess {
		margin-top: 95px;
	}

	.logoutBtn, .changeImageBtn {
		background-color: #4190ca;
		padding: 1px 10px 2px;
		border-radius: 5px;
		color: #ffffff;
		font-size: 18px;
		@media (max-width: 1140px) {
			padding: 5px 25px;
		}
	}

	.loggedIn {
		text-align: left;
		padding: 0 60px;

		@media (max-width: 1140px) {
			padding: 0;
		}

		@media (max-width: 1140px) {
			div:nth-of-type(6) h2 {
				margin-top: calc((100% + 20px) * 0.09);
			}

			div:nth-of-type(4) h2 {
				margin-top: calc((100% + 20px) * 0.06);
			} 

			div:first-of-type h2 {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		h2 {
			margin-top: 30px;
			text-align: left;
			color: #292B28;
			position: relative;

			@media (max-width: 1140px) {
				margin-top: 6.8%;
			}
		}
		h3 {
			color: #4190CA;
			font-size: 21px;
			line-height: 100%;
			margin-bottom: 10px;
			text-align: left;
			position: relative;
		}
		p {
			font-weight: normal;
			font-size: 18px;
			margin: 0;

			@media (min-width: 1141px) {
				line-height: 24px;
			}
		}
		p.subText {
			@media (min-width: 1141px) {
				line-height: 14px;
			}
		}
		a {
			/* font-size: 13px; */
			/* color: #4190CA; */
			/* text-decoration: underline; */
			right: 0;
			top: 2px;
			/* line-height: 100%; */

			@media (min-width: 1141px) {
				position: absolute;
			}

			@media (max-width: 1140px) {
				display: block;
				margin-bottom: calc((100% + 20px) * 0.06);
				width: fit-content;
			}
		}
		input {
			@media (min-width: 1141px) {
				margin-bottom: 30px;
			}
		}

		/* .addrSelect {
			@media (max-width: 1140px) {
				input {
					height: 34px !important;
				}
			}
		} */

		.contactP {
			margin-bottom: 30px;

			@media (max-width: 1140px) {
				margin-bottom: 6.8%;
			}
		}

		.speakHeading {
			@media (max-width: 1140px) {
				margin-top: 10.2%;
				margin-bottom: 6.8%;
			}
		}

		.form-group {
			width: 100%;

			@media (min-width: 1141px) {
				width: 495px;
				height: 169px;
				margin-bottom: 60px;
			}
			
			*:focus {
				outline-style: none;
			}

			div {
				width: 100%;

				@media (min-width: 1141px) {
					width: 495px;
					height: 169px;
				}

				img {
					width: 100%;

					@media (min-width: 1141px) {
						width: 495px;
						height: 169px;
					}
				}
			}
		}
		.contactCards {
			display: flex;
			justify-content: space-between;

			@media (max-width: 1140px) {
				flex-direction: column;
			}
		}
		.companyLogoContainer {
			@media (min-width: 1141px) {
				height: 169px;
				width: 495px;
				margin-bottom: 60px;
			}
			@media (max-width: 1140px) {
				display: flex;
			}
			background: white;
			border: 1px solid #E0DAF0;
			padding: 20px;

			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
				font-family: 'object-fit: contain;'; /* Required for polyfill */
			}
		}

		@media (max-width: 1140px) {
			P {
				line-height: 100%;
			}
		}

		.addrBarText {
			margin: 30px 0;

			@media (max-width: 1140px) {
				margin: 6.8% 0;
			}
		}
	}

	.subText {
		font-size: 12px!important;
	}

	.register, .report-order {
		padding-left: 30px;
		padding-right: 60px;
		display: block;

		h3.req {
			text-align: left;
			margin: 10px 0px 30px;
			font-size: 21px;
			line-height: 1.2;
		}

		.dropDownContainer {
			text-align: left;
			position: relative;
		}

		@media screen {
			@media (min-width: 0px) {
				/* NOT IE HACK */

				.dropDown {
					transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
					border-color: rgba(0, 0, 0, 0.23);
					border-style: solid;
					border-width: 1px;
					border-right-style: none;

					:hover {
						border-color: rgba(0, 0, 0, 0.87);
					}
				}
			}
		}

		.dropDown {
			background-color: white;
			width: calc(100% - 50px);
			height: 50px;

			> div {
				height: 22px;
			}
		}

		.WithChild {
			flex-direction: row;
		}

		.PlaceHolderPart {
			padding-right: 0;
			width: 50%;
			position: relative;

			.PlaceHolderChild {
				display: inline-flex;
				padding: 0;
				margin: 0;
				width: 45%;

				label {
					margin: 0;
					padding: 0;
				}

				@media (max-width: 1140px) {
					width: 100%;
				}
			}

			.dropDownArrow {
				position: absolute;
				left: calc(100% - 50px);
				width: 50px;
				cursor: pointer;
			}
		}

		p {
			text-align: left;
		}

		h2 {
			margin-top: 38.45px;
			text-align: left;
			margin-bottom: 0;
		}
		label {
			font-size: 21px;
			color: #4190ca;
			line-height: 100%;
			text-align: left;
			margin-bottom: 10px;
			margin-top: 30px;
		}
		select {
			background-color: white;
			color: #959595;
			text-align: left;
			font-size: 18px;
			font-weight: normal;
			line-height: 115%;
		}
		button {
			margin-top: 30px;
			margin-bottom: 60px;
		}

		@media (max-width: 1140px) {
			padding: 0;
			img: {
				margin-bottom: 0;
			}
			h2 {
				margin-top: 0;
				text-align: center;
				padding-left: 0;
				margin-bottom: 3.6%;
			}
			label {
				margin-top: 3.6%;
				margin-bottom: 3.6%;
			}

			.dropDown {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			.PlaceHolderPart {
				padding-left: 0;
				width: 100%;
				margin-bottom: 3.6%;
				height: auto;

				> div > div {
					:not(#select-ShowFilter) {
						height: 100%;
					}
				}

				img {
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}

				button {
					margin-top: 5.5%;
					margin-bottom: 0;
				}
				button.cancelBtn {
					margin-top: 0;
					margin-bottom: 3.6%;
					background: #222120;

					@media (max-width: 1140px) {
						margin-bottom: 0;
					}
				}
			}

			.cancelPlaceHolder {
				margin-bottom: 0;
			}
		}
	}
		
	.fullw {
		padding-right: 30px;
	}

	.spc {
		@media (min-width: 1141px) {
			margin-bottom: 60px;
			display: block;
		}
	}

	@media (max-width: 1140px) {
		*:not(div) {
			font-size: inherit !important;
		}
	}

	.nlp { padding-left: 0px; }

	.np { padding: 0; }

	.pending {
		> * {
			opacity: 0.7;
		}
	}
	.unpaid {
		a {
			color: red;
		}
	}
	.FZDText {
		@media (max-width: 1140px) {
			margin-top: 6.8% !important;
		}
	}
	.FZDHeader {
		@media (max-width: 1140px) {
			margin-bottom: 6.8%;
		}
	}
	.brandedReportHeader {
		@media (max-width: 1140px) {
			margin-bottom: 6.8%;
		}
	}
	.textBlock {
		@media (max-width: 1140px) {
			line-height: 100%;
		}
	}
	.mobBold {
		@media (max-width: 1140px) {
			font-weight: bold;
		}
	}
`;
