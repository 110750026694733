import { AuditSearch } from "../../Models/AuditSearch";
import { BaseStore } from "./BaseStore";
import { observable, action, computed } from "mobx";
import { isNullOrUndefined } from "util";

type Order = "asc" | "desc";

export class AuditSearchStore extends BaseStore {
	@observable public moreCounter: number = 10;
	@observable public auditRecords: AuditSearch[] = [];
	@observable private isAPILoading: boolean = false;
	@observable public tableCount: number = this.moreCounter;

	@observable public orderBy: string = "createdDateTime";
	@observable public order: Order = "desc";

	@action
	public SetAuditRecords(records: AuditSearch[]): void {
		this.auditRecords = records;
		this.isAPILoading = false;
	}

	@computed
	public get quoteClickedCount(): number {
		let retVal: number = 0;
		if (this.auditRecords.length > 0) {
			const quoteClicked: AuditSearch[] = this.auditRecords.filter(
				(a) => a.quoteClicked === true
			);
			if (quoteClicked != undefined && quoteClicked != null) {
				retVal = quoteClicked.length;
			}
		}

		return retVal;
	}

	@action
	public sortHandler = (property: keyof AuditSearch) => {
		if (property === this.orderBy) {
			if (this.order === "asc") {
				this.order = "desc";
			} else {
				this.order = "asc";
			}
		} else {
			this.orderBy = property;
			this.order = "asc";
		}
	};

	@action
	public getMoreRecords(): void {
		if (this.tableCount + this.moreCounter > this.auditRecords.length) {
			this.tableCount = this.auditRecords.length;
		} else {
			this.tableCount += this.moreCounter;
		}
	}

	@action
	public setMoreCounter(value: number | string | null): void {
		if (value != null && value != undefined) {
			let counter: number = 0;
			if (this.isNumber(value) === true) {
				counter = <number>value;
			} else {
				counter = parseFloat(value.toString());
			}

			this.moreCounter = counter;
			this.tableCount = 0;
			this.getMoreRecords();
		}
	}

	private isNumber(value: string | number): boolean {
		return value != null && !isNaN(Number(value.toString()));
	}

	@computed
	public get filteredAuditRecords(): AuditSearch[] {
		let sortedRecords: AuditSearch[] = this.auditRecords;

		let a: any = this.getSorting(this.order, this.orderBy);

		sortedRecords = this.stableSort<AuditSearch>(sortedRecords, a);

		return sortedRecords.slice(0, this.tableCount);
		/* 		return sortedRecords
			.filter((consumer) => consumer.realtorGuId == null)
			.slice(0, this.tableCount); */
	}

	private stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
		stabilizedThis.sort((a, b) => {
			const order: number = cmp(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	private getSorting<K extends keyof any>(
		order: Order,
		orderBy: K
	): (
		a: { [key in K]: number | string },
		b: { [key in K]: number | string }
	) => number {
		return order === "desc"
			? (a, b) => this.desc(a, b, orderBy)
			: (a, b) => -this.desc(a, b, orderBy);
	}

	private desc<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	@action
	public Reset(): void {
		this.auditRecords.length = 0;
		this.auditRecords = [];
		this.tableCount = this.moreCounter;
	}

	@action
	public setAPIIsLoading(isAPILoading: boolean): void {
		this.isAPILoading = isAPILoading;
	}
}
