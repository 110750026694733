import clsx from "clsx";
import { Drawer, Hidden, AppBar } from "@material-ui/core";
import homepageLogo from "../../Content/homepageLogo.svg";
import IconButton from "@material-ui/core/IconButton";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { makeStyles /* , useTheme */ } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useContext, useEffect } from "react";
import { StoresContext, Stores } from "../../Stores";
import { Theme, createMuiTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

import blueStar from "../../Content/blueStar.svg";
import { AccountStore } from "Stores/Domain/AccountStores";
import { UseRouter } from "Shoothill.Core/Utils";

const useStyles: any = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		"@media (min-width: 768px)": {
			marginTop: "70px"
		}
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		zIndex: 1301
	},
	appBarShift: {},
	menuButton: {
		[theme.breakpoints.up("lg")]: {
			marginRight: theme.spacing(2),
			display: "none"
		}
	},
	mobileMenu: {
		marginTop: "70px"
	},
	menuUl: {
		listStyle: "none",
		backgroundColor: "#00002A",
		[theme.breakpoints.up("lg")]: {
			display: "inline-flex",
			height: "70px"
		},
		[theme.breakpoints.down("md")]: {
			padding: "10px",
			margin: "0px"
		}
	},
	menuLi: {
		color: "#ffffff",
		[theme.breakpoints.up("lg")]: {
			//borderRight: "solid 1px #fff",
			padding: "0px 0px 0px 12px",
			"&:last-child": {
				borderRight: "none"
			},
			height: "44px",
			marginTop: "13px"
		},
		[theme.breakpoints.down("md")]: {
			width: "100%",
			textAlign: "center",
			margin: "15px 0px",
			fontSize: "1.5em"
		}
	},
	title: {
		flexGrow: 1
	},
	logo: {
		height: "50px",
		marginRight: "30px",
		[theme.breakpoints.down("md")]: {
			marginRight: "20px"
		}
	},
	routerLink: {
		// color: theme.palette.common.white,
		// textDecoration: "none"
	},
	a: {
		// color: theme.palette.common.white
	}
}));

export const MenuUl: any = styled.ul`
	/* outline: red solid 1px; */
	margin: 0px;
	@media (min-width: 1280px) {
		li {
			padding: 12px 0px 12px 12px;

			a {
				padding-right: 12px;
				border-right: solid 1px #ffffff;
			}
		}
	}
	.noborder,
	li:last-child a {
		border-right: none;
	}
	li.proaccount {
		background-color: rgba(255, 255, 255, 0.9);
		a {
			color: #00002a;
			border-right: none;
		}
		.dash {
			display: none;
		}
		img {
			margin: 0px 5px 0px 0px;
			width: 14px;
		}
		@media (min-width: 1280px) {
			margin-top: 0px;
			height: 100%;
			padding: 25px 18px 0px;
			a {
				height: 44px;
			}
		}

		.dash {
			display: inline-block;
		}

		@media (min-width: 1280px) and (max-width: 1400px) {
			.dash {
				display: none;
			}
		}
	}

	li a:hover {
		text-decoration: none;
	}
`;

export const CustomToolbar: any = styled(Toolbar)`
	min-height: 70px !important;
	@media (min-width: 1280px) {
		padding-right: 0px;
	}
`;

export const Header: React.FC = () => {
	const classes: any = useStyles();
	/* const theme: Theme = useTheme(); */
	const [open, setOpen] = useState(false);
	const storesContext: Stores = useContext<Stores>(StoresContext);
	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
		.AccountStore;
	const [rer, forceReR] = (window as any).rer;
	var [reportStep, setReportStep]: any[] = [];

	if ((window as any).reportStep) {
		[reportStep, setReportStep] = (window as any).reportStep;
	}

	const router = UseRouter();

	function handleDrawerToggle(): void {
		setOpen(!open);
	}

	function closeDraw(): void {
		setOpen(false);
	}

	useEffect(() => {
		window.addEventListener("resize", closeDraw);

		return () => {
			window.removeEventListener("resize", closeDraw);
		};
	}, []);

	const drawer: any = (
		<>
			{rer}
			<MenuUl className={classes.menuUl}>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/" onClick={closeDraw}>
						Home
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/myrisk" onClick={closeDraw}>
						My Flood Risk
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/floodloss" onClick={closeDraw}>
						Potential Flood Loss
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/floodinsurance" onClick={closeDraw}>
						Insurance
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/moreaboutflood" onClick={closeDraw}>
						Protection
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/blog" onClick={closeDraw}>
						Blog
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/library" onClick={closeDraw}>
						Reference Library
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link component={RouterLink} to="/about" onClick={closeDraw}>
						About Us
					</Link>
				</li>
				<li className={classes.menuLi}>
					<Link
						component={RouterLink}
						to="/contact"
						onClick={closeDraw}
						className={"noborder"}
					>
						Contact
					</Link>
				</li>
				<li className={classes.menuLi + " proaccount"}>
					<Link
						component={RouterLink}
						to="/Pro"
						onClick={() => {
							closeDraw();
							if (router.location.pathname === "/Pro") {
								setReportStep(0);
							}
						}}
					>
						<img src={blueStar} alt="Pro Account Login" />
						Pro{" "}
						<span className="dash">
							Account {accountStore.IsLoggedIn ? "Dashboard" : "Login"}
						</span>
					</Link>
				</li>

				{/*         {storesContext.domain.AccountStore.IsLoggedIn ? (
          <>
            <li className={classes.menuLi}>
              <Link onClick={() => storesContext.domain.AccountStore.Logout()}>
                Logout
              </Link>
            </li>
          </>
        ) : (
          <></>
        )} */}
			</MenuUl>
		</>
	);

	return useObserver(() => (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open
				})}
			>
				<CustomToolbar>
					<Link component={RouterLink} to="/">
						<img
							src={homepageLogo}
							className={classes.logo}
							alt={"My Flood Risk"}
						/>
					</Link>
					<Typography variant="h6" className={classes.title} noWrap>
						Call (833) 723-7322
					</Typography>

					<IconButton
						color="inherit"
						aria-label="Open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>

					<Hidden mdUp implementation="css">
						<Drawer
							anchor="top"
							open={open}
							onClose={handleDrawerToggle}
							classes={{
								paper: classes.mobileMenu
							}}
							ModalProps={{
								keepMounted: true
							}}
						>
							{drawer}
						</Drawer>
					</Hidden>

					<Hidden mdDown implementation="css">
						{drawer}
					</Hidden>
				</CustomToolbar>
			</AppBar>
		</div>
	));
};
