import styled from "styled-components";
import { Box, Button, Slider, Grid /* , Tooltip */ } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import floodWaterImg from "../../Content/PFL-newWave.svg";
import FloodLossBox from "../../Content/FloodLossBox.svg";
import Reset from "../../Content/ResetIcon.svg";
/* import sliderBit from "../../Content/sliderBit.svg"; */
import { House1 /* , House2, House3 */, pflimage } from "../Styles";

export const useStyles: any = makeStyles(theme => ({
  root: {},
  rootMargin: {
    flexGrow: 1,
    paddingTop: "30px",
    marginBottom: "30px"
  },
  hide: {
    display: "none"
  },
  floodloss: {
    textAlign: "center",
    backgroundImage: `url(${pflimage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  floodlossBottom: {
    flexGrow: 1,
    paddingTop: "30px",
    marginBottom: "30px",
    textAlign: "center"
  },
  floodlossHeadingBlue: {
    color: "#4190CA",
    fontSize: "72px"
  },
  floodlossSubHeadingBlue: {
    color: "#4190CA",
    fontSize: "28px"
  },
  titleBlue: {
    color: "#4190CA"
  },
  colorWhite: {
    color: "#FFFFFF"
  },
  titleWhite: {
    color: "#FFFFFF",
    fontSize: "28px"
  },
  headingWhite: {
    color: "#FFFFFF",
    fontSize: "52px"
  },
  headingBlue: {
    color: "#4190CA",
    fontSize: "52px"
  },
  headingBlue34: {
    color: "#4190CA",
    fontSize: "34px"
  },
  titleBlack: {
    color: "#000000",
    fontSize: "28px"
  },
  subTitleWhite: {
    color: "#FFFFFF",
    fontSize: "18px"
  },
  subTitleBlack: {
    color: "#000000",
    fontSize: "18px"
  },
  paper: {
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperLeft: {
    textAlign: "left",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperRight: {
    textAlign: "right",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  form: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  textField: {
    backgroundColor: "#FFFFFF",
    height: "54px"
  },
  text21: {
    fontSize: "21px"
  },
  link16Blue: {
    fontSize: "16px",
    color: "#4190CA",
    cursor: "pointer"
  },
  text12Blue: {
    fontSize: "12px",
    color: "#4190CA"
  },
  text14Blue: {
    fontSize: "14px",
    color: "#4190CA"
  },
  text21Blue: {
    fontSize: "21px",
    color: "#4190CA"
  },
  text21White: {
    fontSize: "21px",
    color: "#FFFFFF"
  },
  text18Black: {
    fontSize: "18px",
    color: "#000000"
  },
  textBlack: {
    color: "#000000"
  },
  text21Black: {
    fontSize: "21px",
    color: "#000000"
  },
  text42White: {
    fontSize: "42px",
    color: "#FFFFFF"
  },
  replayIcon: {
    color: "#000000",
    verticalAlign: "middle"
  },
  marginTop: {
    marginTop: "40px"
  },
  potentialFloodRiskTool: {
    width: "90px",
    paddingRight: "10px"
  },
  colorBlack: {
    color: "#000000"
  },
  backGroundWhite: {
    backgroundColor: "#FFFFFF",
    height: "148px",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px"
  },
  slider: {
    height: "400px"
  },
  houseBackground: {
    backgroundImage: `url(${House1})`,
    height: "451px",
    width: "100%"
  },
  stats: {
    borderRadius: "16px",
    backgroundColor: "#00002A",
    width: "300px"
  }
}));

export interface IHouseBoxProps {
  ImageUrl: string;
  PaddingBottom: string;
  PaddingLeft: string;
  PaddingRight: string;
  PaddingTop: string;
  MarginTop: string;
  TextAlign: string;
}
export const HouseBox: any = styled.div<IHouseBoxProps>`
  padding-bottom: ${props => props.PaddingBottom};
  padding-left: ${props => props.PaddingLeft};
  padding-right: ${props => props.PaddingRight};
  padding-top: ${props => props.PaddingTop};
  margin-top: ${props => props.MarginTop};
  text-align: ${props => props.TextAlign};
  height: 163px;
  background-image: url(${props => props.ImageUrl});
  background-size: cover;
  background-position-x: center;
  position: relative;
  -webkit-transition: background-image 0.25s ease-in-out;
  transition: background-image 0.25s ease-in-out;
  @media screen and (max-width: 1023px) {
    background-position: right 0 bottom 28%;
  }
  @media screen and (min-width: 1023px) {
    height: 100%;
  }
`;

export const ResetButton: any = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
  text-align: right;
  height: 22px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  background-image: url(${Reset});
  background-repeat: no-repeat;
  color: #ffffff;
  span {
    padding: 0px;
    span {
      margin-top: -5px;
      padding: 0px;
    }
  }
`;

export const AppHeader: any = styled(Box)`
  min-height: calc(100vh - 70px);
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    height: 0px;
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 1040px;
  margin: 0 auto;
  h1 {
    font-size: 72px;
  }
`;

export const AppHeaderStart: any = styled(AppHeader)`
  .AddressSelector {
    margin: 30px auto;
    max-width: 350px;
    @media screen and (min-width: 1040px) {
      max-width: 603px;
    }
  }
  .image {
    display: none;
  }
  .gridContainer {
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
`;

export const BlueGraditentBox: any = styled(Box)`
  background-image: linear-gradient(
    0deg,
    rgba(65, 144, 202, 1) 2%,
    rgba(255, 255, 255, 0.05) 44%
  );
  min-height: calc(100vh - 70px);
  display: flex;

  header {
    width: 100%;
  }

  @media (min-width: 768px) {
    height: 0;
  }

  @media (max-width: 767px) {
    margin-top: 70px;
  }
`;

export const WelcomeBox: any = styled(Box)`
  text-align: center;
  h1,
  .image,
  p {
    display: inline-flex;
    margin: 0;
    padding: 0;
  }
  h1 {
    color: #4190ca;
    font-size: 32px;
    margin-bottom: 11px;
    @media (min-width: 728px) {
      font-size: 72px;
      margin-bottom: 24px;
    }
  }
  .image {
    width: 100%;
    margin-bottom: 14px;
    img {
      width: 61px;
      height: 100%;
      margin: 0 auto;
    }
    @media (min-width: 728px) {
      width: 94px;
      height: 94px;
      padding-top: 26px;
      img {
        width: 100%;
      }
    }
  }
  p {
    font-family: "Lato", sans-serif;
    display: block;
    margin: 0 auto;
    width: 277px;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
    @media (min-width: 728px) {
      font-size: 21px;
      width: 100%;
      line-height: 1.43;
    }
  }
`;

export const InstructionBox: any = styled(Box)`
  text-align: center;
  font-size: 15px;
  margin: 16px 14px 0px;
  color: #4190ca;
  @media (min-width: 1023px) {
    text-align: left;
    font-size: 14px;
    color: #ffffff;
    font-weight: normal;
    margin: 0px;
  }
`;

export const FormBox: any = styled(Box)`
  background-color: rgba(255, 255, 255, 0.75);
  @media screen and (max-width: 1023px) {
    /* max-width: 375px; */
    margin: 0 auto 0;
  }
  margin: 0;

  @media (min-width: 1023px) {
    background-color: #00002a;
    border-radius: 20px;
    border: none;
    height: 598px;
    overflow: hidden;
    padding: 0;
    display: block;
    position: relative;
    .information,
    .diagram {
      display: inline-block;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 0px;
    }

    .information {
      left: 0px;
      width: 302px;
      color: white;
      padding: 26px 30px;
      p {
        padding: 0;
        margin: 0 0 20px;
        text-align: left;
        font-weight: normal;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        letter-spacing: -0.35px;
      }
      h5 {
        font-size: 16px;
        padding: 0;
        margin: 0;
        text-align: left;
      }
    }

    .diagram {
      right: 0px;
      width: 738px;
      height: 100%;
    }
  }
`;

export const WhiteGrid: any = styled(Grid)`
  @media screen and (max-width: 1023px) {
    /* max-width: 375px; */
    margin: 0 auto;
  }
  /* background-color: rgba(255, 255, 255, 0.75); */
  @media (min-width: 728px) {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
`;

export const FormInfoBox: any = styled(Box)`
  padding: 16px 14px;
  @media (min-width: 728px) {
    padding: 30px;
  }
  p,
  p.black {
    font-size: 20px;
    color: #4190ca;
    line-height: 1.25em;
    margin: 8px 0px;
    padding: 0px;
    text-align: left;
  }
  p.black {
    color: #464545;
    padding: 16px 0px;
    .guidance {
      font-size: 12px;
    }
  }
`;

export const NFIBox: any = styled(Box)`
  display: block;
  width: 345px;
  border-radius: 50px;
  height: 34px;
  background-color: #ffffff;
  @media (min-width: 1023px) {
    width: 242px;
    height: 35px;
  }
`;

export const NFIinnerBox: any = styled(Box)`
  width: 335px;
  margin: 0px 15px 0px 9px;
  padding: 5px 0 0 2px;
  @media (min-width: 1023px) {
    width: 242px;
    margin: 0px 15px 0px 10px;
  }
`;

export const NFISlider: any = styled(Slider)`
  width: 305px !important;
  .MuiSlider-rail {
    color: #4190ca;
  }
  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-thumb {
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border: solid 2px #4190ca;
    margin-top: -13px;
    span {
      top: -20px !important;
      left: 22px !important;
      padding-top: 15px;
      width: 104px;
      height: 43px;
      text-align: center;
      background-color: rgba(255, 255, 255, 0);
      color: #464545;
    }
    span span {
      background-color: rgba(255, 255, 255, 0);
    }
    span span span {
      color: #464545;
      background-color: rgba(255, 255, 255, 0);
      background-image: url(${FloodLossBox});
      background-repeat: no-repeat;
      background-position: center center;
      ::before {
        content: "$";
      }
    }
  }
  @media (min-width: 1023px) {
    width: 210px !important;
    .MuiSlider-thumb {
      span {
        width: 112px;
      }
    }
  }
`;

export const WaterLevelBox: any = styled(Box)`
  z-index: 99;
  display: block;
  margin: 15px;
  background-color: #00002a;
  color: #ffffff;
  border-radius: 50px;
  height: 499px !important;
  width: 90px !important;
  right: 15px;
  top: 65px;
  position: absolute;
  text-align: center;
  > span {
    display: block;
    margin: 6px 0;
    font-size: 14px;
  }
`;

export const WaterLevelInnerBox: any = styled(Box)`
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 50px;
  height: 430px !important;
  width: 35px !important;
  margin-left: 28px;
  padding: 20px 0 0;
`;

export const WaterLevelSlider: any = styled(Slider)`
  width: 14px !important;
  height: 90% !important;
  padding: 0 23px !important;
  margin: 35px 0 0 -15px;
  .MuiSlider-mark {
    background-color: transparent;
  }
  .MuiSlider-rail {
    opacity: 1;
    margin: -30px 0 0 8px;
    color: #4190ca;
    height: 105%;
  }
  .MuiSlider-thumb {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border: solid 5px #4190ca;
    margin-left: -20px !important;
    span {
      color: #000000;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 50%;
      top: 19px;
      left: 3px;
      width: 45px;
      text-align: center;
      font-size: 21px;

      span span {
        ::after {
          content: '"';
        }
      }
    }
  }
`;

export const MobWaterLevelBox: any = styled(Box)`
  display: block;
  width: 355px;
  border-radius: 50px;
  height: 34px;
  background-color: #ffffff;
`;

export const MobWaterLevelInnerBox: any = styled(Box)`
  width: 355px;
  margin: 0px;
  padding: 5px 0 0 0;
`;

export const MobWaterLevelSlider: any = styled(Slider)`
  width: 314px !important;
  margin-left: -18px;
  .MuiSlider-rail {
    color: #4190ca;
  }
  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-thumb {
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border: solid 2px #4190ca;
    margin-top: -14px;
    span {
      top: 6px !important;
      left: -47px !important;
      padding-top: 22px;
      width: 104px;
      height: 43px;
      text-align: center;
      background-color: rgba(255, 255, 255, 0);
      color: #464545;
    }
    span span {
      background-color: rgba(255, 255, 255, 0);
    }
  }
`;

export interface IFloodProps {
  FloodValue: number;
}

export const FloodWaters: any = styled.div<IFloodProps>`
  position: absolute;
  left: -1px;
  width: 101%;
  bottom: 0px;
  height: ${props => props.FloodValue}px;
  -webkit-transition: height 2s;
  transition: height 2s;
  background-image: url(${floodWaterImg});
`;

export const PFLBox: any = styled(Box)`
  width: 100%;
  background-color: #00002a;
  color: white;
  padding: 15px;
  text-align: center;
  .value,
  .subText,
  h3,
  h4 {
    display: block;
    padding: 5px 0px;
    margin: 0;
  }
  h3 {
    color: #4190ca;
    font-size: 22px;
    border-bottom: 1px solid #cccccc;
    .value {
      color: #ffffff;
      font-size: 42px;
      line-height: 0.85em;
    }
    .subText {
      color: #4190ca;
      font-size: 14px;
      line-height: 0.85em;
    }
  }
  h4 {
    display: inline-block;
    width: 48%;
    border-right: 1px solid #cccccc;
    color: #ffffff;
    font-size: 21px;
    .subText {
      color: #4190ca;
      font-size: 12px;
      line-height: 0.85em;
    }
  }
  h4.nobo {
    border-right: none;
  }
  @media (min-width: 1023px) {
    width: 242px; /*
        margin: 15px 30px;
        border-radius: 30px; */
    margin: 0px;
    padding: 0px;
  }
`;

export const QuoteButtonLink: any = styled(Button)`
  border-radius: 10px;
  border: none;
  background-color: #4190ca;
  width: 348px;
  color: #ffffff;
  margin-bottom: 30px;
  padding-top: 11px !important;
  /* font-family: "Lato", sans-serif; */
  :hover {
    background-color: #4190ca;
    color: #ffffff;
  }
  .floodIcon {
    width: 46px;
    height: 38px;
    margin: 15px 20px 15px -15px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .floodText {
    width: 195px;
    text-align: left;
    text-transform: none;
    font-size: 14px;
    line-height: 1em;
  }

  @media (min-width: 728px) {
    margin-top: 30px;
    padding: 17px;
    width: 242px;
    height: 58px;
    .floodText {
      width: 169px;
      font-size: 16px;
      margin: 0px 17px;
    }
    .floodIcon {
      width: 60px;
      width: 42px;
      margin: 0;
      padding: 6px 0px;
    }
  }
`;

export const FloodGrid: any = styled(Grid)`
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  span {
    font-weight: 100;
  }
  p {
    text-align: left;
    font-size: 11px;
    font-weight: 100;
    margin: 0 15px 10px;
  }
  .floodDepth {
    background-color: #00002a;
    color: #ffffff;
    padding: 7px 10px 16px;
  }
`;
