import { UseDataApi } from "./../Shoothill.Core/Utils/Axios/UseDataApi";

// App
import * as AppUrls from "../Globals/AppUrls";
import * as Models from "../Models";

export const login = (loginDto: Models.Login) => {
	const { data, isLoading, isError, doRequest } = UseDataApi(AppUrls.Server.Api.Account.Login);
	console.log(isLoading);
};

export const forgotPassword = (resetPasswordDto: Models.ResetPassword) => {
	const { data, isLoading, isError, doRequest } = UseDataApi(AppUrls.Server.Api.Account.ForgotPassword);
	console.log(isLoading);
};

/* export const resetPassword = (resetPasswordDto: Models.ResetPassword) => {
	const { data, isLoading, isError, doRequest } = UseDataApi(AppUrls.Server.Api.Account.ResetPassword);
	console.log(isLoading);
}; */

// export const login = (loginDto: Models.Login) =>
// 	Utils.Axios.post<Models.AccountStatus>(AppUrls.Server.Api.Account.Login, loginDto);

// export const logout = () => Utils.Axios.post<Models.AccountStatus>(AppUrls.Server.Api.Account.Logout);
// export const setPassword = (setPasswordModel: Models.SetPassword) =>
// 	Utils.Axios.post<Models.AccountStatus>(AppUrls.Server.Api.Account.SetPassword, setPasswordModel);
