import "./Home.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";
import moreaboutbackgroundTop from "../Content/warningflood.png";

import {
	/* useStyles, */
	FloodOptions,
	TopFloodBackground,
	SecondBackground,
	AppHeading,
	BlueGradientBox,
	PageContainer
} from "../Shoothill.Components/Styles/MoreAboutFloodStyles";

import { RowAboutFloodCard } from "../Shoothill.Components/RowTools/RowAboutFloodCard";

export const MoreAboutFlood: React.FC = () => {
	/* const classes: any = useStyles("about"); */
	ReactGA.pageview(window.location.pathname + window.location.search);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return useObserver(() => (
		<>
			<Helmet>
				<title>
					More About Flood Protection - Protect Your Property From Flood Damage
					| My Flood Risk
				</title>
				<meta
					name="description"
					content="Learn how to protect your property from flood damage through flood mitigation, flood insurance, and flood restoration options."
				/>
			</Helmet>
			<PageContainer>
				<BlueGradientBox>
					<TopFloodBackground imagesrc={moreaboutbackgroundTop}>
						<div>&nbsp;</div>
					</TopFloodBackground>
					<SecondBackground>
						<AppHeading>
							<h1>More About Flood Protection</h1>
							<FloodOptions>
								<RowAboutFloodCard />
							</FloodOptions>
						</AppHeading>
					</SecondBackground>
				</BlueGradientBox>
			</PageContainer>
		</>
	));
};
