export const feetinches: (someValue: number) => string = (someValue: number): string => {

    if (someValue >= 0) {

    let feet: number = Math.floor(someValue);
    let inches: number = Math.round((someValue - feet) * 12);

    if (inches >= 12) {
        feet += 1;
        inches = inches - 12;
    }

    return feet + "'" + inches + "\"";
    } else {
        let feet: number = Math.ceil(someValue);
        let inches: number = Math.round((someValue - feet) * 12);

        let retVal: string = "";

        if (inches <= -12) {
            feet -= 1;
            inches = inches + 12;
        }

        if (Math.abs(feet) !== 0 || Math.abs(inches) !== 0) {
            retVal = "-";
        }

        retVal = retVal + Math.abs(feet) + "'" + Math.abs(inches) + "\"";
        return retVal;
    }
};

export interface IFeetInches {
    feet: number;
    inches: number;
    totalInches : number;
}

export const getFeetInchesFromDecimal: (someValue: number) => IFeetInches = (someValue: number): IFeetInches => {

    let retVal : IFeetInches = {
        feet: 0,
        inches: 0,
        totalInches: 0
    };

    if (someValue >= 0) {
        retVal.feet = Math.floor(someValue);
    } else {
        retVal.feet = Math.ceil(someValue);
    }
    retVal.inches = Math.round((someValue - retVal.feet) * 12);
    retVal.totalInches = (retVal.feet * 12) + retVal.inches;

    return retVal;
};

export const getFeetInchesStringFromInteger: (totalInches: number) => string = (totalInches: number): string => {

    let absInches: number = Math.abs(totalInches);
    let feet: number = Math.floor(absInches / 12);
    let inches: number = (absInches - (feet * 12));

    let retVal: string = "";

    if (totalInches < 0) {
        retVal = "-";
    }

    retVal += feet + "'" + inches + "\"";
    return retVal;
};
