import {
	Button,
	/* Checkbox, */
	FormLabel,
	Link,
	Modal,
	useMediaQuery,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
	ReportElevationDifference,
	ReportHurricanes,
	ReportStormSurge,
	ReportTotalFloodEvents,
	ReportTotalAssistance,
	ReportFloodZone,
	ReportTotalFEMAClaims,
	ReportScores,
	ParcelNotFound,
} from "../Shoothill.Components/AppSpecific";
import {
	GenericAcceptButton,
	GenericCancelButton,
} from "../Shoothill.Components/Styles";
import { Loader } from "../Shoothill.Components/General/Loader";
import {
	HurricaneDataModel,
	WizardContactFormModel,
	DatasetWeights,
	HurricaneSummaryModel,
	AuditSearchInsert,
	AuditProOrderModel,
} from "../Models";
import {
	isNullorWhiteSpace,
	isNullorEmpty,
} from "../Shoothill.Core/Utils/Validation";
import { ApiResult } from "../Models";
import React, {
	useState,
	useContext,
	useEffect /* , useRef, RefObject */,
} from "react";
import { Stores, StoresContext, DomainStores } from "../Stores";
import { AccountStore } from "../Stores/Domain/AccountStores";
import { Contact } from "../Models";
import { useValidation, UseDataApi } from "../Shoothill.Core/Utils";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";
import {
	PageRoot,
	WizGrid,
	ChevronButton,
	ReportStepper,
	ReportResultBox,
	ReportStep,
	LastNextBox,
	TermsModalBox,
	TextFieldWizard,
	SubmitWizard,
	DetailsWrapper,
	SemiCircle,
	WizardFadeTransition,
	WizardMain,
	FormCheckbox,
} from "../Shoothill.Components/Styles/MyRiskWizardStyles";
import { TitleHeader } from "../Shoothill.Components/Styles/MyRiskStyles";
/* import { getCookie } from "../Shoothill.Core/Utils/Utils"; */
import Hammer from "hammerjs";
import { Redirect } from "react-router-dom";

import { TermsComponent } from "Shoothill.Components/Components/Terms";

import validator from "validator";
import {
	GlobalStore,
	UserStore,
	MapStore,
	FloodAndClaimDataStore,
	HurricaneDataStore,
	HurricaneSummaryStore,
	DatasetWeightsStore,
} from "Stores/Domain";
import { QuoteComplete } from "../Shoothill.Components/AppSpecific/QuoteComplete";

import * as AppUrls from "../Globals/AppUrls";

// tslint:disable-next-line: typedef
function useToggle(): [boolean, () => void] {
	const [_, set] = useState(true);
	return [_, () => set((value) => !value)];
}

export const MyRiskWizard: React.FC = () => {
	const globalStoresContext: GlobalStore = useContext<Stores>(StoresContext)
		.domain.GlobalStore;
	const userStoresContext: UserStore = useContext<Stores>(StoresContext).domain
		.UserStore;
	const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain
		.MapStore;
	const floodDataContext: FloodAndClaimDataStore = useContext<Stores>(
		StoresContext
	).domain.FloodAndClaimDataStore;
	const hurricaneDataContext: HurricaneDataStore = useContext<Stores>(
		StoresContext
	).domain.HurricaneDataStore;
	const hurricaneSummaryContext: HurricaneSummaryStore = useContext<Stores>(
		StoresContext
	).domain.HurricaneSummaryStore;
	const weightsDataContext: DatasetWeightsStore = useContext<Stores>(
		StoresContext
	).domain.DatasetWeightsStore;
	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
		.AccountStore;
	let isLoggedIn: boolean = accountStore.IsLoggedIn;
	let realtorGuId: string = accountStore.UserName;

	const { data: floodDataSet, doRequest: floodSetRequest } = UseDataApi();
	const { doRequest: auditSearchInsert } = UseDataApi();
	const { doRequest: submitFormRequest } = UseDataApi<
		ApiResult<WizardContactFormModel>
	>();
	const [activeWizard, setActiveWizard] = useState(false);
	const [formScreen, setformScreen] = useState(true);
	const [canProceed, setCanProceed] = useState(false);
	const [showTerms, setShowTerms] = useState(false);
	const [showSteps, setShowSteps] = useState(true);
	const [transition, setTransition] = useState(false);
	const [lastTransition, setLastTransition] = useState(true);
	const [tranisitonFinished, setTransitionFinished] = useState(false);
	const [registeredGestures, setRegisteredGestures] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [shouldHandleNext, makeHandleNext] = useToggle();
	const [shouldHandlePrev, makeHandlePrev] = useToggle();
	const [quoteFinished, setQuoteFinished] = useState(false);

	const [femaError, setFemaError] = useState(false);

	const [__, forceUpdate] = useToggle();

	const [formState, { text, email }] = useValidation<WizardContactFormModel>(
		userStoresContext.IsProfessional
			? new WizardContactFormModel(
				userStoresContext.DisplayName,
				userStoresContext.EmailAddress
			)
			: new WizardContactFormModel()
	);

	ReactGA.pageview(window.location.pathname + window.location.search);
	let latitude: string = "34.071783";
	let longitude: string = "-118.2596";
	let hasAddress: boolean = mapStoresContext.hasAddress;

	let contentButtons: HTMLElement | null;

	if (hasAddress) {
		latitude = mapStoresContext.latitude.toString();
		longitude = mapStoresContext.longitude.toString();
	}

	let useMobileStepper: boolean;
	useMobileStepper = useMediaQuery("screen and (max-width: 767px)")
		? true
		: false;

	let useTabletStepper: boolean;
	useTabletStepper =
		(useMediaQuery("screen and (max-width: 1179px)") ? true : false) &&
		!useMobileStepper;

	let moved: number = 0;

	useEffect(() => {
		if (!registeredGestures) {
			var content: Element = document.getElementsByClassName(
				"contentAndButtons"
			)[0];
			var chevrons: Element = document.getElementsByClassName(
				"chevronStepper"
			)[0];
			if (content && chevrons && (useTabletStepper || useMobileStepper)) {
				setRegisteredGestures(true);
				delete Hammer.defaults.cssProps.userSelect;
				var hammer = new Hammer(content as HTMLElement);
				var chevronHammer = new Hammer(chevrons as HTMLElement);
				hammer.on("swipeleft", makeHandleNext);
				hammer.on("swiperight", makeHandlePrev);
				chevronHammer.on("panend", () => {
					moved = 0;
				});
				chevronHammer.on("panmove", (e: HammerInput) => {
					var elem: HTMLDivElement = document.getElementsByClassName(
						"Step0"
					)[0] as HTMLDivElement;
					var s3: HTMLDivElement = document.getElementsByClassName(
						"Step2"
					)[0] as HTMLDivElement;
					var circ: HTMLCollectionOf<Element> = document.getElementsByClassName(
						"circle"
					);
					if (elem && useMobileStepper) {
						var style = window.getComputedStyle(elem);
						if (style.marginLeft) {
							if (
								e.offsetDirection === Hammer.DIRECTION_RIGHT &&
								elem.children[0].classList.contains("hide")
							)
								return;
							if (
								e.offsetDirection === Hammer.DIRECTION_LEFT &&
								s3.children[0].classList.contains("hide") &&
								circ.length === 0
							)
								return;
							elem.style.marginLeft =
								e.offsetDirection === Hammer.DIRECTION_LEFT
									? `${+style.marginLeft.replace("px", "") - (e.distance - moved)
									}px`
									: `${+style.marginLeft.replace("px", "") + (e.distance - moved)
									}px`;
							moved = e.distance;
						}
						if (s3) {
							var right: number = getPageLeft(s3) + 302;
							if (right < window.innerWidth) {
								chevronHammer.stop(false);
								elem.style.marginLeft = null;
								moved = 0;
								makeHandleNext();
							}
						}
						var left: number = getPageLeft(elem);
						if (left > 0) {
							chevronHammer.stop(false);
							elem.style.marginLeft = null;
							moved = 0;
							makeHandlePrev();
						}
					}
				});
			}
		}
	});

	useEffect(() => {
		handleNext();
	}, [shouldHandleNext]);

	useEffect(() => {
		handleBack();
	}, [shouldHandlePrev]);

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener("resize", forceUpdate);

		return () => window.removeEventListener("resize", forceUpdate);
	}, []);

	useEffect(() => {
		window.setTimeout(setContentButtonHeight, 100);
	});

	useEffect(() => {
		floodDataContext.ResetFloodClaimsData();
		setFemaError(false);
		floodDataContext.setAPIIsLoading(true);
		const address: string =
			mapStoresContext.hasAddress === true ? mapStoresContext.address : "";
		const region: string =
			mapStoresContext.hasAddress === true ? mapStoresContext.region : "";
		const county: string =
			mapStoresContext.hasAddress === true ? mapStoresContext.county : "";
		floodSetRequest(
			"/api/NFIData/GetFloodAndClaimsAPI/?IncBFE=true&lat=" +
			latitude +
			"&lon=" +
			longitude +
			"&useloma=false" +
			"&address=" +
			address +
			"&county=" +
			county +
			"&state=" +
			region +
			"&real=" +
			realtorGuId
		);
	}, [floodDataSet]);

	useEffect(() => {
		if (!isNullorEmpty(floodDataSet)) {
			floodDataContext.SetFloodClaimsDataResult(floodDataSet.payload);
			if (!!!floodDataSet.payload) {
				setFemaError(true);
			} else if (!isNullorWhiteSpace(floodDataSet.payload.errors)) {
				setFemaError(true);
			} else if (floodDataSet.payload.error === "InternalServerError") {
				floodDataContext.setAPIIsLoading(false);
				setFemaError(true);
			} else {
				populateWeights();
				populateHurricaneAll();
				setFemaError(false);
			}
		}
	}, [floodDataSet]);

	function keyPressed(e: KeyboardEvent): void {
		if (
			!activeWizard ||
			floodDataContext.getIsAPILoading ||
			floodDataContext.fldZone === undefined
		) {
			return;
		}
		switch (e.keyCode) {
			case 37:
				handleBack();
				break;
			case 39:
				handleNext();
				break;
		}
	}

	function populateHurricaneAll(): void {
		const tempWind: HurricaneDataModel[] = floodDataContext.getriskHurricane;
		hurricaneDataContext.SetHurricaneDataResults(tempWind);

		const windSummary: HurricaneSummaryModel[] =
			floodDataContext.gethurricaneSummary;
		hurricaneSummaryContext.SetHurricaneSummaryResults(windSummary);
	}

	function populateWeights(): void {
		const tempRisk: DatasetWeights[] = floodDataContext.getriskWeights;
		weightsDataContext.SetDatasetWeightsResults(tempRisk);
	}

	/*     function errorsDetected(parcel: any): void {
		let retVal: boolean = false;
		if (!isNullorEmpty(parcel)) {
			if (!!!parcel.payload) {
				retVal = true;
			}
			if (!isNullorWhiteSpace(parcel.payload.errors)) {
				retVal = true;
			}
		}
		setFemaError(retVal);
	} */

	const [prevStep, setPrevStep] = React.useState(-1);
	const [activeStep, setTheActiveStep] = React.useState(-1);

	/* Intentionally nonsensical, fixes tranitions. */

	useEffect(() => {
		setActiveStep(activeStep);

		var elem: HTMLDivElement = document.getElementsByClassName(
			"Step0"
		)[0] as HTMLDivElement;
		if (elem) {
			elem.style.marginLeft = null;
		}
	}, [activeStep]);

	/* */

	function setActiveStep(step: number): void {
		setTransitionFinished(false);
		setTransition(false);
		setLastTransition(true);
		setPrevStep(activeStep);
		setTheActiveStep(step);
	}

	/**
	 * Starts AuditSearch for reporting
	 */
	function startAudtiSearch(contact: Contact, realtorId: string | undefined): void {
		const auditData: AuditSearchInsert = {
			floodClaims: floodDataContext.facData,
			address: mapStoresContext.address,
			realtor: realtorId,
			name: contact.name,
			email: contact.email,
		};
		auditSearchInsert('/api/NFIData/StartAuditSearch', auditData);
	}

	const onSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		userStoresContext.reset();

		if (
			formState.isFormValid() &&
			formState.formData.email !== "" &&
			formState.formData.name !== ""
		) {
			userStoresContext.DisplayName = formState.formData.name;
			userStoresContext.EmailAddress = formState.formData.email;

			let auditSearchId = "";

			if (floodDataContext && floodDataContext.auditSearchId) {
				auditSearchId = floodDataContext.auditSearchId;
			}

			const data: Contact = {
				name: formState.formData.name,
				email: formState.formData.email,
				auditSearchId: auditSearchId || (null as any),
			};

			startAudtiSearch(data, undefined);

			submitFormRequest("/api/contact/upsert", data);

			setActiveWizard(true);
			setformScreen(false);
			setActiveStep(0);
		}
	};

	const onSubmitRtr: (e: any) => void = (e: any): void => {
		e.preventDefault();
		userStoresContext.reset();

		let emailAddr = "";
		let userName = "";
		let userId = "";

		if (formState.formData.email !== "" && formState.formData.name !== "") {
			emailAddr = formState.formData.email;
			userName = formState.formData.name;
		} else {
			emailAddr = accountStore.Email;
			userName = accountStore.FirstName + " " + accountStore.LastName;
		}

		userStoresContext.DisplayName = userName /* formState.formData.name */;
		userStoresContext.EmailAddress = emailAddr /* formState.formData.email */;

		let auditSearchId = "";

		if (floodDataContext && floodDataContext.auditSearchId) {
			auditSearchId = floodDataContext.auditSearchId;
		}

		const data: Contact = {
			name: formState.formData.name,
			email: formState.formData.email,
			auditSearchId: auditSearchId,
		};

		startAudtiSearch(data, realtorGuId);

		submitFormRequest("/api/contact/upsert", data);

		setActiveWizard(true);
		setformScreen(false);
		setActiveStep(0);
	};

	function getPageLeft(el: HTMLDivElement): number {
		const rect = el.getBoundingClientRect();
		const docEl = document.documentElement;
		return rect.left + (window.pageXOffset || docEl.scrollLeft || 0);
	}

	const onRestart: (e: any) => void = (e: any): void => {
		mapStoresContext.ResetLocation();
		if (mapStoresContext.pageSource) {
			window.open(mapStoresContext.pageSource, "_self");
		}
	};

	const dudUrl: string = "javascript:;";
	const [completed] = React.useState<{ [k: number]: boolean }>({});

	const steps: string[] = getSteps();
	const activeSteps: string[] = getActiveSteps();

	function getActiveSteps(): string[] {
		const stepA: string[] = [];
		for (let i = 0; i < steps.length; i++) {
			if (!hideStep(i, false, true)) {
				stepA.push(steps[i]);
			}
		}
		return stepA;
	}

	useEffect(() => {
		window.addEventListener("keydown", keyPressed);
		return () => window.removeEventListener("keydown", keyPressed);
	}, [activeStep]);

	function getSteps(): string[] {
		const stepsArray: string[] = [
			"Flood Zone",
			"Elevation Difference",
			"Total FEMA Insurance claims",
			"Total flooding events",
			"Total assistance",
			"Storm Surge",
			"Hurricanes",
			"Your Score",
		];

		return stepsArray;
	}

	const handleQuoteFinished = () => {
		setShowSteps(false);
		setQuoteFinished(true);
	};

	function getStepContent(step: number): any {
		if (quoteFinished) {
			return (
				<>
					<QuoteComplete />
				</>
			);
		}

		switch (step) {
			case 0:
				return (
					<>
						<ReportFloodZone />
					</>
				);
			case 1:
				return (
					<>
						<ReportElevationDifference />
					</>
				);
			case 2:
				return (
					<>
						<ReportTotalFEMAClaims />
					</>
				);
			case 3:
				return (
					<>
						<ReportTotalFloodEvents />
					</>
				);
			case 4:
				return (
					<>
						<ReportTotalAssistance />
					</>
				);
			case 5:
				return (
					<>
						{!hideStep(5, false, true) && (
							<>
								<ReportStormSurge />
							</>
						)}
					</>
				);
			case 6:
				return (
					<>
						{!hideStep(6, false, true) && (
							<>
								<ReportHurricanes />
							</>
						)}
					</>
				);
			case 7:
				return (
					<>
						<ReportScores
							transitionFinished={tranisitonFinished}
							onQuoteSuccess={handleQuoteFinished}
						/>
					</> /* step8 */
				);
			default:
				return "Unknown step";
		}
	}

	function totalSteps(): number {
		return steps.length;
	}

	function completedSteps(): number {
		return Object.keys(completed).length;
	}

	function isLastStep(): boolean {
		return activeStep === totalSteps() - 1;
	}

	function allStepsCompleted(): boolean {
		return completedSteps() === totalSteps();
	}

	function handleShowTerms(): void {
		setShowTerms(true);
		// globalStoresContext.SetIsTermsModalOpen(true);
	}

	function handleHideTerms(): void {
		setShowTerms(false);
		// globalStoresContext.SetIsTermsModalOpen(false);
	}

	function handleCanProceed(): void {
		if (
			(formState.formData.email !== "" && formState.formData.name !== "") ||
			isLoggedIn
		) {
			setCanProceed(true);
		} else {
			setCanProceed(false);
		}
	}

	useEffect(() => {
		handleCanProceed();
	}, [formState.formData.email, formState.formData.name]);

	const doSubmitTerms: (e: any) => void = (e: any): void => {
		e.preventDefault();
		setCanProceed(true);
		globalStoresContext.SetIsTermsModalOpen(false);
		setShowTerms(false);
	};

	const doClickBox: (e: any) => void = (e: any): void => {
		setAcceptedTerms(!acceptedTerms);
	};

	function handleNext(): void {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		if (activeStep === steps.length - 1) {
			return;
		}
		let newActiveStep: number =
			isLastStep() && !allStepsCompleted()
				? // it's the last step, but not all steps have been completed,
				// find the first step that has been completed
				steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;

		if (
			(hideStep(5, false, true) &&
				hideStep(6, false, true) &&
				activeStep === 4) ||
			(hideStep(6, false, true) && activeStep === 5)
		) {
			newActiveStep = 7;
		} else if (
			hideStep(5, false, true) &&
			!hideStep(6, false, true) &&
			activeStep === 4
		) {
			newActiveStep = 6;
		}
		setActiveStep(newActiveStep);
	}

	function handleBack(): void {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		if (activeStep === 0) {
			return;
		}
		if (
			(hideStep(5, false, true) && activeStep === 6) ||
			(hideStep(5, false, true) && hideStep(6, false, true) && activeStep === 7)
		) {
			setActiveStep(4);
		} else if (hideStep(6, false, true) && activeStep === 7) {
			setActiveStep(5);
		} else {
			setActiveStep(activeStep - 1);
		}
	}

	// tslint:disable-next-line: typedef
	const handleStep = (step: number) => () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		if (useTabletStepper) {
			setActiveStep(steps.indexOf(tabletLabelArray()[step]));
		} else if (useMobileStepper) {
			switch (step) {
				case 0:
					handleBack();
					break;
				case 2:
					handleNext();
					break;
			}
		} else {
			setActiveStep(step);
		}
	};

	function hideStep(
		stepNumber: number,
		flag: boolean,
		ignoreMobile: boolean
	): boolean {
		if (useMobileStepper && !ignoreMobile) {
			return stepNumber === 0 && activeStep === 0 && flag
				? true
				: stepNumber > 2;
		} else if (useTabletStepper && !ignoreMobile) {
			return stepNumber > tabletChevronCount() - 1;
		} else {
			return (stepNumber === 5 &&
				floodDataContext.getPropertySurgeHazard === 0) ||
				(stepNumber === 6 && floodDataContext.getriskHurricane.length === 0)
				? true
				: false;
		}
	}

	function buttonLabel(stepNumber: number, label: string): string {
		if (useMobileStepper) {
			return stepNumber === 1 ? steps[activeStep] : "";
		} else {
			return label;
		}
	}

	function lastNonScoreStep(): boolean {
		return (
			(activeStep === 4 &&
				hideStep(5, false, true) &&
				hideStep(6, false, true)) ||
			(activeStep === 5 && hideStep(6, false, true)) ||
			activeStep === 6
		);
	}

	function setContentButtonHeight(): void {
		useMobileStepper = window.innerWidth <= 767;
		if (contentButtons) {
			contentButtons.style.minHeight = "unset";
			contentButtons.style.height = "unset";
			if (!useMobileStepper) {
				return;
			}
			let parent: HTMLElement | null = contentButtons.parentElement;
			if (parent) {
				let parentStyles: CSSStyleDeclaration = window.getComputedStyle(parent);
				if (parentStyles.height) {
					// tslint:disable-next-line: typedef
					let parentHeight = parentStyles.height.replace("px", "");
					// tslint:disable-next-line: typedef
					let minHeight = +parentHeight - 90 - 75;
					contentButtons.style.minHeight = `${minHeight}px`;
				}
			}
		}
	}

	/* #region Tablet Chevron Functions */

	const tabletCentreChevron: number = Math.floor(
		(tabletChevronCount() - 1) / 2
	);
	const tabletDiffCentreChevron: number =
		tabletChevronCount() - tabletCentreChevron;

	function tabletChevronCount(i?: number): number {
		var n: number = Math.floor(
			(window.innerWidth - 88) / (150 - (i ? (i - 1) * 17 : 0))
		);
		if (n === 0) {
			return n;
		}
		if (i && n >= i) {
			return i;
		} else {
			return tabletChevronCount(n);
		}
	}

	function tabletLabelArray(): string[] {
		var start: number = 0;
		var activeStepsNo: number = activeSteps.length;
		var adjActiveStep: number = tabletAdjustedActiveStep();
		var chevCount: number = tabletChevronCount();

		if (activeStepsNo - adjActiveStep < chevCount - (chevCount > 4 ? 2 : 1)) {
			start = activeStepsNo - chevCount;
		} else if (adjActiveStep >= tabletCentreChevron) {
			start = adjActiveStep - tabletCentreChevron;
		}
		return tabletPopulateLabelArray(start);
	}

	function tabletPopulateLabelArray(start: number): string[] {
		var labelA: string[] = [];
		while (labelA.length < tabletChevronCount()) {
			if (!hideStep(start, false, true)) {
				labelA.push(steps[start]);
			}
			start++;
		}
		return labelA;
	}

	function tabletAdjustedActiveStep(): number {
		var adjActiveStep: number = -1;
		for (var i: number = 0; i <= activeStep; i++) {
			if (!hideStep(i, false, true)) {
				adjActiveStep++;
			}
		}
		return adjActiveStep;
	}

	function tabletChevronIsSelected(i: number): boolean {
		var activeStepsNo: number = activeSteps.length;
		var adjActiveStep: number = tabletAdjustedActiveStep();
		var chevCount: number = tabletChevronCount();
		var remainingSteps: number = activeStepsNo - adjActiveStep;

		if (activeStepsNo - adjActiveStep < chevCount - (chevCount > 4 ? 2 : 1)) {
			return i <= chevCount - remainingSteps;
		} else if (adjActiveStep >= tabletCentreChevron) {
			return i <= tabletCentreChevron;
		}
		return i <= adjActiveStep;
	}

	/* #endregion */

	const stepperSteps: (JSX.Element | null)[] = steps.map((label, index) =>
		hideStep(index, false, false) ? null : (
			<ReportStep
				key={label}
				disabled={!activeWizard}
				className={"Step" + index}
			>
				<ChevronButton
					onClick={handleStep(index)}
					completed={completed[index]}
					disabled={!activeWizard}
					className={
						(useTabletStepper
							? tabletChevronIsSelected(index)
								? "chevronSelected"
								: "chevron"
							: (activeStep + 1 <= index ? "chevron" : "chevronSelected") +
							(hideStep(5, false, false) || hideStep(6, false, false)
								? hideStep(5, false, false) && hideStep(6, false, false)
									? " longChevron"
									: " medChevron"
								: "")) +
						(" Step" + index) +
						(hideStep(index, true, false) ||
							(index === 2 &&
								activeStep === steps.length - 1 &&
								useMobileStepper) ||
							(index === 2 && useMobileStepper && lastNonScoreStep())
							? " hide"
							: "") +
						(activeStep === steps.length - 1 && useMobileStepper && index === 1
							? " mobileScore"
							: "") +
						(useTabletStepper &&
							index === tabletChevronCount() - 1 &&
							tabletAdjustedActiveStep() >=
							activeSteps.length - tabletDiffCentreChevron
							? " tabletScore"
							: "")
					}
				>
					{useTabletStepper
						? tabletLabelArray()[index]
						: buttonLabel(index, label)}
				</ChevronButton>
			</ReportStep>
		)
	);

	return useObserver(() => (
		<>
			<Helmet>
				<title>
					My Flood Risk Wizard - Find Your True Flood Risk | My Flood Risk
				</title>
				<meta
					name="description"
					content="Find your true flood risk with My Flood Risk, which illustrates real-life, 
                     industry-proven flood factors, such as FEMA flood zones, elevation, and hurricanes."
				/>
			</Helmet>
			{!mapStoresContext.hasAddress && (
				<Redirect to={{ pathname: "/myrisk" }} />
			)}

			<PageRoot>
				<WizardMain role="main">
					<WizGrid item quotes={activeStep === steps.length - 1}>
						<div className="WizardInner">
							{showSteps &&
								activeWizard &&
								!floodDataContext.getIsAPILoading &&
								!(
									(!floodDataContext.getIsAPILoading &&
										floodDataContext.fldZone === undefined) ||
									femaError
								) && (
									<>
										<ReportStepper
											nonLinear
											activeStep={activeStep}
											orientation="horizontal"
											className=" chevronStepper"
										>
											{stepperSteps}
										</ReportStepper>
										{useMobileStepper && lastNonScoreStep() ? (
											<SemiCircle className=" circle" />
										) : null}
									</>
								)}
							{formScreen && (
								<>
									<ReportResultBox style={{ height: "100%" }}>
										<form
											onSubmit={isLoggedIn ? onSubmitRtr : onSubmit}
											onChange={handleCanProceed}
										>
											<TitleHeader>
												{!isLoggedIn && <>Your details</>}
												{isLoggedIn && <>Buyer's details</>}
											</TitleHeader>
											<h2 style={{ color: "#4190CA" }}>
												{mapStoresContext.address}
											</h2>
											<p>
												We just need a couple more bits of information from
												you...
											</p>
											<div className="inputs">
												<DetailsWrapper>
													<div>
														<TextFieldWizard
															label=""
															className={"details"}
															fullWidth
															autoComplete="Name"
															placeholder={
																!isLoggedIn
																	? "What is your name? (required)"
																	: "Buyer's name? (optional)"
															}
															{...text(
																"name",
																!isLoggedIn
																	? {
																		validationRules: [
																			(v: string) =>
																				(v && v.length > 0) ||
																				"* Your name is required",
																		],
																	}
																	: undefined
															)}
														/>
														{formState.showErrors("name", "error")}
													</div>
													<div>
														<TextFieldWizard
															label=""
															className={"details"}
															fullWidth
															autoComplete="email"
															placeholder={
																!isLoggedIn
																	? "Please add your email address here (required)"
																	: "Buyer's email address (optional)"
															}
															{...email(
																"email",
																!isLoggedIn
																	? {
																		validationRules: [
																			(v: string) =>
																				validator.isEmail(v) ||
																				"* Email address is invalid",
																			(v: string) =>
																				(v && v.length > 0) ||
																				"* Email address is required",
																		],
																	}
																	: undefined
															)}
														/>
														{formState.showErrors("email", "error")}
													</div>
												</DetailsWrapper>
												<div className="terms">
													<FormLabel className="termsText" component="label">
														<FormCheckbox
															color="primary"
															checked={acceptedTerms}
															onChange={doClickBox}
														/>
														I accept AB Risk Corporations
													</FormLabel>
													<Link
														href={dudUrl}
														onClick={handleShowTerms}
														className="termsLink"
													>
														Terms of Use
													</Link>
												</div>

												<div className="submission">
													<SubmitWizard
														disabled={!canProceed || !acceptedTerms}
														type="submit"
													>
														We're all set please continue
													</SubmitWizard>
												</div>
											</div>
										</form>
									</ReportResultBox>
								</>
							)}

							{!formScreen && (
								<>
									{floodDataContext.getIsAPILoading && (
										<>
											<ReportResultBox>
												<Loader
													loadingText="Streaming your flood data..."
													delayBeforeShow={0}
													className={"api-loader"}
												/>
											</ReportResultBox>
										</>
									)}

									{!floodDataContext.getIsAPILoading &&
										floodDataContext.fldZone !== undefined &&
										!femaError && (
											<>
												<div
													className="contentAndButtons"
													ref={(content) => {
														contentButtons = content;
														window.addEventListener(
															"resize",
															setContentButtonHeight
														);
														setContentButtonHeight();
													}}
												>
													{allStepsCompleted() ? (
														<div>{/* <ReportScores/> */}</div>
													) : (
														<>
															<ReportResultBox
																quotes={activeStep === steps.length - 1}
															>
																{!(useTabletStepper || useMobileStepper) &&
																	steps.map((_, step) => {
																		return (
																			<>
																				<WizardFadeTransition
																					in={
																						(activeStep === step &&
																							transition) ||
																						(prevStep === step &&
																							lastTransition)
																					}
																					timeout={
																						activeStep === step ||
																							step === steps.length - 1
																							? { enter: 650, exit: 0 }
																							: { enter: 1, exit: 650 }
																					}
																					onEntered={
																						activeStep === step && step === 7
																							? () =>
																								setTransitionFinished(true)
																							: () => { }
																					}
																					mountOnEnter
																					unmountOnExit
																				>
																					<div
																						style={
																							activeStep !== step
																								? { position: "absolute" }
																								: { width: "100%" }
																						}
																					>
																						{getStepContent(step)}
																					</div>
																				</WizardFadeTransition>
																			</>
																		);
																	})}
																{(useTabletStepper || useMobileStepper) && (
																	<>{getStepContent(activeStep)}</>
																)}
															</ReportResultBox>
															<LastNextBox>
																{!quoteFinished && (
																	<>
																		{activeStep !== 7 && (
																			<>
																				<div
																					className={
																						activeStep === 5
																							? "lastBtnHolderStep5"
																							: "lastBtnHolder"
																					}
																				>
																					{activeStep !== 0 && (
																						<>
																							<Button onClick={handleBack}>
																								{useMobileStepper
																									? "Prev"
																									: "Previous"}
																							</Button>
																						</>
																					)}
																				</div>
																			</>
																		)}
																		<div
																			className={`${activeStep === 5
																					? "nextBtnHolderStep5"
																					: "nextBtnHolder"
																				}${activeStep === steps.length - 1
																					? " restart-btn"
																					: ""
																				}`}
																		>
																			{activeStep !== steps.length - 1 && (
																				<>
																					<Button onClick={handleNext}>
																						Next
																					</Button>
																				</>
																			)}
																			{activeStep === steps.length - 1 && (
																				<>
																					<Button
																						className="restart"
																						onClick={onRestart}
																					>
																						Restart my risk tool
																					</Button>
																				</>
																			)}
																		</div>
																	</>
																)}
															</LastNextBox>
														</>
													)}
												</div>
											</>
										)}

									{!floodDataContext.getIsAPILoading &&
										floodDataContext.fldZone === undefined &&
										!femaError && (
											<>
												<ReportResultBox style={{ height: "100%" }}>
													<ParcelNotFound isError={false} />
												</ReportResultBox>
											</>
										)}

									{!floodDataContext.getIsAPILoading &&
										/* floodDataContext.fldZone ===
                                            undefined && */ femaError && (
											<>
												<ReportResultBox style={{ height: "100%" }}>
													<ParcelNotFound isError={true} />
												</ReportResultBox>
											</>
										)}
								</>
							)}
						</div>
					</WizGrid>
				</WizardMain>
			</PageRoot>
			<Modal open={showTerms} onClose={handleHideTerms}>
				<TermsModalBox>
					<form onSubmit={doSubmitTerms}>
						<div className="termsmodal-wrapper">
							<div className="termsmodal-title">
								<span className="termsmodal-title-text">
									AB Risk Corporation's Terms of Use
								</span>
							</div>
							<div className="termsmodal-text">
								<TermsComponent />
							</div>
						</div>
						<div className="button-row">
							<GenericAcceptButton onClick={doSubmitTerms}>
								Ok
							</GenericAcceptButton>
							<GenericCancelButton onClick={handleHideTerms}>
								Cancel
							</GenericCancelButton>
						</div>
					</form>
				</TermsModalBox>
			</Modal>
		</>
	));
};
