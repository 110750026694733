import MapboxGl /* , { FullscreenControl } */ from "mapbox-gl";
import { useObserver } from "mobx-react-lite";
import React, { useContext /* , useEffect */, useState } from "react";
import * as ReactMapboxGl from "react-mapbox-gl";
import { makeStyles } from "@material-ui/styles";
import MapMarkerSvg from "../../Content/map-pin.svg";
import { Stores, StoresContext } from "../../Stores/Stores";
import { FloodAndClaimDataStore } from "../../Stores/Domain/FloodAndClaimDataStore";

const image: HTMLImageElement = new Image(56, 74);
image.src = MapMarkerSvg;
/* const images: [string, HTMLImageElement] = ["markerIcon", image]; */

const accessToken: string =
  "pk.eyJ1IjoiYW5keWp1bXAiLCJhIjoiY2pudnYzYWI4MDJ5eDNxbzhldWNhc3ZlZiJ9.fD-fnggIKvMR5QQLlb1M3Q";

const ReactMapboxGlMap: any = ReactMapboxGl.default({
  accessToken: accessToken
});

export interface ILocationInfo {
  lngLat: MapboxGl.LngLat;
  sloshLevel: number | undefined;
}

export interface IProps {
  className?: string;
  center?: [number, number];
  zoom?: [number];
  style: MapboxGl.Style;

  onMove?: (center: [number, number]) => any;
  onZoom?: (zoom: [number]) => any;

  onClick?: (locationInfo: ILocationInfo) => any;
  datastore: FloodAndClaimDataStore;
}

const useStyles: any = makeStyles(theme => ({
  reportroot: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
    height: "100%",
    minHeight: "unset !important",
    "@media (min-width: 768px)": {
      borderBottomRightRadius: "20px"
    }
  },
  reportmap: {
    overflow: "none",
    height: "544px",
    "@media (min-width: 1180px)": {
      borderBottomRightRadius: "20px"
    },
    "@media (max-width: 767px)": {
      height: "304px"
    }
  }
}));

export const ReportMap: React.FC<IProps> = props => {
  const { style, zoom } = props;
  /*   let mapRef: any = undefined;*/

  const classes: Record<"reportroot" | "reportmap", string> = useStyles(
    "reportmap"
  );
  const mapStoreContext: any = useContext<Stores>(StoresContext).domain
    .MapStore;

  const _fitBounds:
    | { south: number; west: number; north: number; east: number }
    | undefined = undefined;

  const [hasExpandButton, setHasExpandButton] = useState(false);

  function getFitBounds(): any {
    const fitBounds: any = _fitBounds
      ? ([
          [_fitBounds.south, _fitBounds.west],
          [_fitBounds.north, _fitBounds.east]
        ] as [[number, number], [number, number]])
      : undefined;

    return fitBounds;
  }

  return useObserver(() => {
    return (
      <main
        role="main"
        className={classes.reportroot}
        style={{ marginTop: "0" }}
      >
        <ReactMapboxGlMap
          key="ReportMap"
          className={classes.reportmap}
          style={style as any}
          center={[mapStoreContext.longitude, mapStoreContext.latitude]}
          zoom={zoom}
          fitBounds={getFitBounds()}
          fitBoundsOptions={{ duration: 0 }}
        >
          {[mapStoreContext.longitude, mapStoreContext.latitude] && (
            <ReactMapboxGl.Marker
              coordinates={[
                mapStoreContext.longitude,
                mapStoreContext.latitude
              ]}
              anchor="bottom"
            >
              <img
                src={MapMarkerSvg}
                alt="Selected location"
                style={{ width: 40, height: 62 }}
              />
            </ReactMapboxGl.Marker>
          )}
          <ReactMapboxGl.MapContext.Consumer>
            {(map): any => {
              if (map !== undefined) {
                /*                                 mapRef = map;
                                console.log(mapRef.getCanvas().toDataURL()); */
                if (!hasExpandButton) {
                  map.addControl(new MapboxGl.FullscreenControl());
                  setHasExpandButton(true);
                }
              }
            }}
          </ReactMapboxGl.MapContext.Consumer>
        </ReactMapboxGlMap>
      </main>
    );
  });
};
