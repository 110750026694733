import { ArticleListItem } from "../Shoothill.Components/Articles";
import { Helmet } from "react-helmet";
import awaitingImage from "../Content/awaitingimage.jpg";
import { Button, GridList, Select, MenuItem } from "@material-ui/core";
import { EmailFormModel } from "../Models/FormModels/EmailFormModel";
import { GenericIncludeDeletedWithId } from "../Models";
import Grid from "@material-ui/core/Grid";
import { LargeWhiteFormControl } from "../Components/Controls/Forms/LargeWhiteFormControl";
import * as MUI from "@material-ui/core";
import React, { useEffect, useContext, useState } from "react";
import { Stores, StoresContext } from "../Stores";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react-lite";
import { useValidation, UseDataApi } from "../Shoothill.Core/Utils";
/* import { useMediaQuery } from "../Shoothill.Core/Utils/Media"; */
import validator from "validator";
import { BlogPostStore } from "../Stores/Domain/BlogPostStore";
import ReactGA from "react-ga";

import {
    useBlogStyles,
    BlogFilterBox
} from "../Shoothill.Components/Styles/BlogStyles";
import {
    AppHeader,
    BRFormBox,
    BtnGrid,
    Row1040,
    MasonryCustom,
    Row1076
} from "../Shoothill.Components/Styles/BlogRefStyles";

export const NewsletterSignUpForm: React.FC = () => {
    const classes: any = useBlogStyles("contact");
    const { /* data, */ isLoading /* , isError, doRequest */ } = UseDataApi();
    const [isSent, SetIsSent] = useState(false);

    const [formState, { email }] = useValidation<EmailFormModel>(
        new EmailFormModel()
    );

/*     let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 1023px)") ? true : false; */

    ReactGA.pageview(window.location.pathname + window.location.search);

    // tslint:disable-next-line: typedef
    const onSubmit = (e: any): void => {
        if (formState.isFormValid()) {
            SetIsSent(true);
        } else {
            e.preventDefault();
        }
    };

    return (
        <Row1040>
            <Grid container>
                <Grid item xs={12}>
                    <BRFormBox textAlign="center" width={1}>
                        <MUI.Box
                            mt={"42px"}
                            ml={"48px"}
                            mr={"48px"}
                            textAlign="center"
                            className={classes.headingBlue}
                        >
                            Subscribe to Our Newsletter
                        </MUI.Box>
                        <MUI.Box
                            mt={3}
                            ml={"48px"}
                            mr={"48px"}
                            fontSize={32}
                            textAlign="center"
                            className={classes.subHeadingGrey}
                        >
                            Sign up for our free newsletter to discover <br />
                            the latest news and updates
                        </MUI.Box>

                        <form
                            action="https://MyFloodRisk.us20.list-manage.com/subscribe/post"
                            method="POST"
                            noValidate
                            className={classes.form}
                            target="_blank"
                            onSubmit={onSubmit}
                        >
                            <input
                                type="hidden"
                                name="u"
                                value="b16c7042649625b33c9e59043"
                            />
                            <input type="hidden" name="id" value="0bfa49cd43" />

                            <Grid container>
                                <Grid item xs={12}>
                                    <MUI.Box
                                        mt={2}
                                        ml={"50px"}
                                        mr={"50px"}
                                        textAlign="center"
                                    >
                                        <LargeWhiteFormControl>
                                            {formState.showErrors(
                                                "EMAIL",
                                                classes.error
                                            )}
                                            <MUI.TextField
                                                label=""
                                                fullWidth
                                                name="EMAIL"
                                                id="mce-EMAIL"
                                                autoComplete="email"
                                                placeholder="Please enter your email address"
                                                {...email("EMAIL", {
                                                    validationRules: [
                                                        (v: string) =>
                                                            validator.isEmail(
                                                                v
                                                            ) ||
                                                            "* Email address is invalid",
                                                        (v: string) =>
                                                            (v &&
                                                                v.length > 0) ||
                                                            "* Email address is required"
                                                    ]
                                                })}
                                            />
                                        </LargeWhiteFormControl>
                                    </MUI.Box>
                                </Grid>

                                {isSent && (
                                    <div className={classes.width100}>
                                        <p className={classes.successSentTxt}>
                                            Thank you for signing up. A new tab
                                            will have opened confirming your
                                            subscription.
                                        </p>
                                    </div>
                                )}

                                <BtnGrid>
                                    <MUI.Box mt={3} mb={3} textAlign="center">
                                        <MUI.Button
                                            variant="outlined"
                                            className={classes.contactBtn}
                                            type="submit"
                                        >
                                            {isLoading
                                                ? "Subscribing.."
                                                : "Subscribe now"}
                                        </MUI.Button>
                                    </MUI.Box>
                                </BtnGrid>
                            </Grid>
                        </form>
                    </BRFormBox>
                </Grid>
            </Grid>
        </Row1040>
    );
};

export const Blog: React.FC = () => {
    const classes: any = useBlogStyles("contact");

    const { data, /* isLoading, isError, */ doRequest } = UseDataApi();

    const blogPostStoreContext: BlogPostStore = useContext<Stores>(
        StoresContext
    ).domain.BlogPostStore;

    useEffect(() => {
        if (blogPostStoreContext.blogPosts.length === 0) {
            const includeGenericDeletedWithId: GenericIncludeDeletedWithId = {
                id: undefined,
                isBlog: true,
                includeDeleted: false
            };

            doRequest("/api/BlogPost/GetAll", includeGenericDeletedWithId);
        }
    }, [data]);

    useEffect(() => {
        if (data !== null) {
            if (data.wasSuccessful) {
                blogPostStoreContext.SetBlogPosts(data.payload);
            }
        }
    }, [data]);

    useEffect(() => {
        blogPostStoreContext.ClearFilters();
    }, []);

    function handleFilterChange(event: any): void {
        blogPostStoreContext.SetActiveFilter(event.target.value);
    }

    function handlePageSizeChange(event: any): void {
        if (event.target.value > 0) {
            blogPostStoreContext.SetActivePageSize(event.target.value);
        } else {
            blogPostStoreContext.SetActivePageSize(10000);
        }
    }

    function handleLoadMore(): void {
        blogPostStoreContext.SetActiveBlogCount();
    }

    function showLoadMore(): boolean {
        return (
            blogPostStoreContext.blogPosts.length >
            blogPostStoreContext.activeBlogCount
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breakpointColumnsMasonry = {
        default: 3,
        1000: 2,
        600: 1
    };

    return useObserver(() => (
        <>
            <Helmet>
                <title>Blog - Original Content on Flood-related Issues | My Flood Risk</title>
                <meta name="description" content="My Flood Risk focuses on advocacy, awareness, and education regarding
                 flood risk throughout the United States. Our blog features original content on flood-related issues such
                 as seasonal flood risks, flood preparation and mitigation tips, and flood insurance information." />
            </Helmet>
            <div className={classes.blog}>
                <AppHeader>
                    <Typography
                        variant="h1"
                        className={classes.blogHeadingWhite}
                    >
                        Blog
                    </Typography>
                    <div className={classes.rootMargin}>
                        <Grid container>
                            <Typography className={classes.headingWhite}>
                                Educate. Empower. Elevate Awareness.
                            </Typography>
                        </Grid>
                    </div>
                </AppHeader>
            </div>
            <div className={classes.blogBottom}>
                <Row1040 container>
                    <Grid item xs={12} md={6}>
                        <BlogFilterBox textAlign={"left"}>
                            <Typography
                                id="header"
                                className={classes.headingBlue34}
                            >
                                Show
                            </Typography>
                            <Select
                                className={classes.textField}
                                name="ShowFilter"
                                value={blogPostStoreContext.activeFilter}
                                onChange={handleFilterChange}
                            >
                                <MenuItem
                                    key={"Newest first"}
                                    value={"Newest first"}
                                >
                                    {"Newest first"}
                                </MenuItem>
                                <MenuItem
                                    key={"Oldest first"}
                                    value={"Oldest first"}
                                >
                                    {"Oldest first"}
                                </MenuItem>
                                <MenuItem
                                    key={"Title A to Z"}
                                    value={"Title A to Z"}
                                >
                                    {"Title A to Z"}
                                </MenuItem>
                                <MenuItem
                                    key={"Title Z to A"}
                                    value={"Title Z to A"}
                                >
                                    {"Title Z to A"}
                                </MenuItem>
                            </Select>
                        </BlogFilterBox>
                    </Grid>
                </Row1040>
                <Row1076 container>
                    <GridList cellHeight={454} className={classes.gridList}>
                        <MasonryCustom
                            breakpointCols={breakpointColumnsMasonry}
                            columnClassName="my-masonry-grid_column"
                        >
                            {blogPostStoreContext.GetBlogPosts.map(blogPost => (
                                <ArticleListItem
                                    id={blogPost.id}
                                    title={blogPost.name}
                                    excerpt={blogPost.description}
                                    publishDate={blogPost.startDate}
                                    link={"blogpost/" + blogPost.id}
                                    linkText={"Read more"}
                                    imagePath={
                                        blogPost.desktopBtnImageUrl
                                            ? blogPost.desktopBtnImageUrl
                                            : awaitingImage
                                    }
                                    key={
                                        blogPost.tabletImageURL +
                                        "-" +
                                        blogPost.id
                                    }
                                    isBlog={true}
                                />
                            ))}
                        </MasonryCustom>
                    </GridList>
                </Row1076>

                <div className={classes.whiteToBlueGrad}>
                    {showLoadMore() && (
                        <Row1040 container>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    className={classes.loadMoreBtn}
                                    onClick={handleLoadMore}
                                >
                                    Load more
                                </Button>
                            </Grid>
                        </Row1040>
                    )}

                    <NewsletterSignUpForm />
                </div>
            </div>
        </>
    ));
};
