import React, {
	/* useContext,
	ChangeEvent, */
	useState,
	useEffect,
	useRef
} from "react";
import {
	InputLabel,
	/* Button, */
	MenuItem,
	TextField,
	Select,
	/* NativeSelect, */
	useMediaQuery,
	Typography,
	Box
} from "@material-ui/core";
/* import { Stores, StoresContext, DomainStores } from "../../Stores"; */

import { RegProUserFormModel } from "Models/FormModels/RegProUserFormModel";
import {
	useValidation,
	usePercentageHeightOfWidth
} from "../../Shoothill.Core/Utils";
import validator from "validator";
import dropDownArrow from "Content/dropDownArrow.svg";

import FitText from "@kennethormandy/react-fittext";

interface IProps {
	onSubmit: (registerModel: RegProUserFormModel) => void;
	isLoading: boolean;
	errorMessage?: string;
	confirmMsg?: string;
	onCancel?: () => void;
}

export const RegisterFormComp: React.FC<IProps> = props => {
	const { onSubmit, isLoading, errorMessage = "", confirmMsg } = props;
	const [dropDownLabel, setDropDownLabel] = useState(
		"Please select... (optional)"
	);
	const [dropDownOpen, setDropDownOpen] = useState(false);
	const el1 = useRef<HTMLDivElement>(null);
	const el2 = useRef<HTMLDivElement>(null);
	const el3 = useRef<HTMLDivElement>(null);
	const el4 = useRef<HTMLDivElement>(null);
	const el5 = useRef<HTMLDivElement>(null);
	const el6 = useRef<HTMLDivElement>(null);
	const el7 = useRef<HTMLDivElement>(null);
	const el8 = useRef<HTMLButtonElement>(null);
	const el9 = useRef<HTMLButtonElement>(null);
	const elImg = useRef<HTMLImageElement>(null);

	usePercentageHeightOfWidth(
		[el1, el2, el3, el4, el5, el6, el7, el8, el9],
		0.124
	);

	const [formState, { text, password, email, select }] = useValidation<
		RegProUserFormModel
	>(new RegProUserFormModel());

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var isIE: boolean =
		msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

	let useMobile = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	let isTabOrMob = false; /* useMediaQuery("screen and (max-width: 1024px")
		? true
		: false; */

	const doSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		if (formState.isFormValid()) {
			onSubmit({ ...formState.formData, companyType: dropDownLabel });
		}
	};

	function handleDropDownChange(
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>
	) {
		setDropDownOpen(false);
		setDropDownLabel(e.target.value as string);
	}

	function handleDropDownClose() {
		setDropDownOpen(false);
	}

	function handleDropDownOpen() {
		setDropDownOpen(true);
	}

	function handeScroll() {
		setDropDownOpen(false);
	}

	useEffect(() => {
		window.addEventListener("scroll", handeScroll);

		return () => window.removeEventListener("scroll", handeScroll);
	}, []);

	function onResize() {
		if (window.innerWidth <= 1140) {
			if (el5.current) {
				el5.current.style.width = `calc(100% - ${el5.current.offsetHeight}px)`;
				if (elImg.current) {
					elImg.current.style.width = `${el5.current.offsetHeight}px`;
					elImg.current.style.left = `calc(100% - ${el5.current.offsetHeight}px)`;
				}
			}
		} else {
			if (el5.current) {
				el5.current.style.width = null;
				if (elImg.current) {
					elImg.current.style.width = null;
					elImg.current.style.left = null;
				}
			}
		}
	}

	useEffect(() => {
		window.addEventListener("resize", onResize);
		onResize();

		return () => window.addEventListener("resize", onResize);
	}, [
		el5.current,
		!!el5.current ? el5.current.offsetHeight : undefined,
		elImg.current
	]);

	const signupHeader = (
		<>
			<h2 className={"lm30"}>Sign me up for a Pro account</h2>
		</>
	);

	const nameLabel = (
		<>
			<InputLabel>What is your name?</InputLabel>
		</>
	);

	const emailLabel = (
		<>
			<InputLabel>The best email to reach you on?</InputLabel>
		</>
	);

	const companyLabel = (
		<>
			<InputLabel>Company name?</InputLabel>
		</>
	);

	const companyTLabel = (
		<>
			<InputLabel htmlFor="companyType">Company type?</InputLabel>
		</>
	);

	const passwordLabel = (
		<>
			<InputLabel>Set your password</InputLabel>
		</>
	);

	return (
		<>
			<form onSubmit={doSubmit} className="register">
				{errorMessage.length > 0 && (
					<div className={"PlaceHolderPart"}>
						<Box pb={1} pt={1}>
							<Typography component="h6" color="error" variant="caption">
								{errorMessage}
							</Typography>
						</Box>
					</div>
				)}

				{useMobile ? (
					<FitText compressor={/* 1.21 */ 1.18} debounce={0}>
						{signupHeader}
					</FitText>
				) : (
					<>{signupHeader}</>
				)}

				<div className={"PlaceHolderPart"}>
					{useMobile ? (
						<>
							<FitText compressor={/* 1.7 */ 1.71} debounce={0}>
								{nameLabel}
							</FitText>
						</>
					) : (
						<>{nameLabel}</>
					)}
					{/* 	{formState.showErrors("firstName", "login_error")} */}
					<TextField
						label=""
						variant={(isIE ? undefined : "outlined") as "outlined"}
						fullWidth
						placeholder="First name (required)"
						ref={el1}
						{...text("firstName", {
							validationRules: [
								(v: string) =>
									(v && v.length > 0) || "* Your first name is required"
							]
						})}
					/>
				</div>

				<div className={"PlaceHolderPart"}>
					{/* Invisible Char */}
					{useMobile || (
						<>
							<InputLabel>&lrm;</InputLabel>
						</>
					)}
					{/* 	{formState.showErrors("surname", "login_error")} */}
					<TextField
						label=""
						fullWidth
						variant={(isIE ? undefined : "outlined") as "outlined"}
						placeholder="Last name (required)"
						ref={el2}
						{...text("surname", {
							validationRules: [
								(v: string) =>
									(v && v.length > 0) || "* Your last name is required"
							]
						})}
					/>
				</div>

				<div className={"PlaceHolderPart"}>
					{useMobile ? (
						<>
							<FitText compressor={/* 1.7 */ 1.71} debounce={0}>
								{emailLabel}
							</FitText>
						</>
					) : (
						<>{emailLabel}</>
					)}
					{/* 	{formState.showErrors("emailAddress", "login_error")} */}
					<TextField
						label=""
						fullWidth
						variant={(isIE ? undefined : "outlined") as "outlined"}
						placeholder="Email address (required)"
						ref={el3}
						{...email("emailAddress", {
							validationRules: [
								(v: string) =>
									validator.isEmail(v) || "* Email address is invalid",
								(v: string) =>
									(v && v.length > 0) || "* Email address is required"
							]
						})}
					/>
				</div>

				{useMobile || (
					<>
						<div className={"PlaceHolderPart"}>&nbsp;</div>
					</>
				)}

				<div className={"PlaceHolderPart"}>
					{useMobile ? (
						<>
							<FitText compressor={/* 1.7 */ 1.71} debounce={0}>
								{companyLabel}
							</FitText>
						</>
					) : (
						<>{companyLabel}</>
					)}
					<TextField
						label=""
						fullWidth
						variant={(isIE ? undefined : "outlined") as "outlined"}
						placeholder="Company name (optional)"
						ref={el4}
						{...text("companyName", {
							/* 
							validationRules: [
								(v: string) =>
									(v && v.length > 0) || "* Your first name is required"
							]
						 */
						})}
					/>
				</div>

				<div className={"PlaceHolderPart"}>
					{useMobile ? (
						<>
							<FitText compressor={/* 1.7 */ 1.71} debounce={0}>
								{companyTLabel}
							</FitText>
						</>
					) : (
						<>{companyTLabel}</>
					)}
					<div className="dropDownContainer">
						<Select
							{...select("companyType", {
								//validationRules: [(v: number) => (v && v > 0) || "You must select your age"],
							})}
							className="dropDown"
							name="ShowFilter"
							value={dropDownLabel}
							onChange={handleDropDownChange}
							displayEmpty
							renderValue={(value: any) => {
								return value || "Please select... (optional)";
							}}
							open={dropDownOpen}
							onOpen={handleDropDownOpen}
							onClose={handleDropDownClose}
							IconComponent="script"
							ref={el5}
							native={isTabOrMob}
						>
							{isTabOrMob && (
								<>
									<option key="Other" disabled>
										Please select... (optional)
									</option>
									<option key="Other">N/A or Other</option>
									<option key="Real Estate Agent">Real Estate Agent</option>
									<option key="Lender">Lender</option>
									<option key="Builder">Builder</option>
									<option key="Title Agent or related field">
										Title Agent or related field
									</option>
								</>
							)}
							<MenuItem value="N/A or Other" key="Other">
								N/A or Other
							</MenuItem>
							<MenuItem value="Real Estate Agent" key="Real Estate Agent">
								Real Estate Agent
							</MenuItem>
							<MenuItem value="Lender" key="Lender">
								Lender
							</MenuItem>
							<MenuItem value="Builder" key="Builder">
								Builder
							</MenuItem>
							<MenuItem
								value="Title Agent or related field"
								key="Title Agent or related field"
							>
								Title Agent or related field
							</MenuItem>
						</Select>
						{/* {formState.showErrors("companyType", "ramdomClassName")} */}
						<img
							src={dropDownArrow}
							className="dropDownArrow"
							onClick={() => {
								if (isTabOrMob) {
									if (el5.current) {
										el5.current.click();
									}
								} else {
									setDropDownOpen(!dropDownOpen);
								}
							}}
							ref={elImg}
						/>
					</div>
				</div>

				<div className={"PlaceHolderPart"}>
					{useMobile ? (
						<>
							<FitText compressor={/* 1.7 */ 1.71} debounce={0}>
								{passwordLabel}
							</FitText>
						</>
					) : (
						<>{passwordLabel}</>
					)}
					{/* 	{formState.showErrors("password", "login_error")} */}
					<TextField
						label=""
						fullWidth
						variant={(isIE ? undefined : "outlined") as "outlined"}
						placeholder="Password (required)"
						ref={el6}
						{...password("password", {
							validationRules: [
								(v: string) => (v && v.length > 0) || "Password required"
							]
						})}
					/>
				</div>

				<div className={"PlaceHolderPart"}>
					{/* Invisible Char */}
					{useMobile || (
						<>
							<InputLabel>&lrm;</InputLabel>
						</>
					)}
					{/* 	{formState.showErrors("passwordConfirm", "login_error")} */}
					<TextField
						label=""
						fullWidth
						variant={(isIE ? undefined : "outlined") as "outlined"}
						placeholder="Confirm password (required)"
						ref={el7}
						{...password("passwordConfirm", {
							validationRules: [
								(v: string) =>
									(v && v.length > 0) || "Confirm password required",
								(v: string, vs: any[]) =>
									(v && v === vs["password"]) || "Password does not match"
							]
						})}
					/>
				</div>

				<div className={"PlaceHolderPart"}>
					<button type="submit" className={"submitBtn"} ref={el8}>
						{isLoading ? "Please Wait..." : confirmMsg}
					</button>
				</div>
				<div className="PlaceHolderPart cancelPlaceHolder">
					<button onClick={props.onCancel} ref={el9} className="cancelBtn">
						Cancel
					</button>
				</div>
			</form>
		</>
	);
};
