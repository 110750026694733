import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Typography, useMediaQuery } from "@material-ui/core";
/* import { useTimeout } from "../../Shoothill.Core/Utils"; */
import { ColorLinearProgress } from "../../Components/Loader/ColourLinearProgress";
import bucketsGraphics from "../../Content/bucketsGraphic.svg";

interface IProps {
  delayBeforeShow?: number;
  loadingText?: string;
  className?: string;
}

const useStyles: any = makeStyles(theme => ({
  h5: {
    textTransform: "none",
    fontSize: "38px",
    padding: "20px",
    fontWeight: 300,
    color: "white",
    "@media (max-width: 767px)": {
      textAlign: "center"
    }
  },
  darkBack: {
    backgroundColor: "rgba(0, 0, 0, .7)"
  },
  m5: {
    margin: "5px"
  }
}));

export const Loader: any = (props: IProps) => {
  const [show, setShow] = useState(false);
  // const { start, clear } = useTimeout(() => {
  //     setShow(true);
  // }, props.delayBeforeShow);
  const classes: any = useStyles();

  let isMobile: boolean;
  isMobile = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

  useEffect(() => {
    setShow(true);
  }, []);

  // useEffect(() => {
  //     start();
  //     return () => {
  //         clear();
  //     };
  // }, []);

  if (show === true) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={props.className}
      >
        <Grid item>
          <Typography variant="h5" className={classes.h5}>
            {props.loadingText}
          </Typography>
          <ColorLinearProgress className={classes.m5} />
        </Grid>
        {!isMobile && (
          <img
            src={bucketsGraphics}
            style={{ width: "814px", paddingTop: "30px" }}
            alt={"Loading"}
          />
        )}
      </Grid>
    );
  } else {
    return <></>;
  }
};

Loader.defaultProps = {
  delayBeforeShow: 0,
  loadingText: "Loading...",
  className: ""
};
