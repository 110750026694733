import React from "react";
import { Route, Redirect, RouteProps } from "react-router";

interface Props extends RouteProps {
    component: any;
    isAllowed: () => boolean;
}

// https://tylermcginnis.com/react-router-protected-routes-authentication/

// const PrivateRoute = (props: Props) => {
export const PrivateRoute: React.FC<Props> = props => {
    // EN: Destructure component and RENAME it to Component!
    const { isAllowed, component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={props => {
                // return isAllowed() && <Component {...props} />;
                return isAllowed() === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                );
            }}
        />
    );
};

// Usage
//{
/* <DecisionRoute
	path="/signin"
	exact={true}
	trueComponent={redirectStart}
	falseComponent={SignInPage}
	decisionFunc={isAuth}
/>; */
// const redirectStart = props => <Redirect to="/orders" />
//}
interface DProps extends RouteProps {
    trueComponent: any;
    falseComponent: any;
    decisionFunc: any;
}
export const DecisionRoute: React.FC<DProps> = ({
    trueComponent,
    falseComponent,
    decisionFunc,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={decisionFunc() ? trueComponent : falseComponent}
        />
    );
};
