import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
/* import { PromptBox } from "../Styles"; */
import { Stores, StoresContext, DomainStores } from "../../Stores";
import { StringToArray } from "../../Shoothill.Core/Utils/Misc";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { UseRouter, UseDataApi } from "../../Shoothill.Core/Utils";
import * as AppUrls from "../../Globals/AppUrls";
import { UpdateAuditSearchRequest } from "../../Models";
// todo cmr import ReactGA from "react-ga";
import iconFloodMitigation from "../../Content/iconFloodMitigation.svg";
import iconFloodInsurance from "../../Content/iconFloodInsurance.svg";
import iconFloodRestoration from "../../Content/iconFloodRestoration.svg";

export const AboutFloodRow: any = styled.div`
	@media (min-width: 1025px) {
		display: inline-flex;
		padding: 0;
		margin: 0;
	}
`;

export const AboutFloodRowBox: any = styled(Box)`
	font-family: "Lato", sans-serif;
	font-weight: normal;
	color: #222120;
	img {
		height: 84px;
		width: auto;
	}

	h3 {
		color: #4190ca;
		font-size: 18px;
		font-weight: bold;
		margin: 0px 0px 12px;
	}

	p {
		font-size: 14px;
		width: 320px;
		margin: 0 auto;
	}

	@media (min-width: 1025px) {
		position: relative;
		margin: 10px;
		padding: 0;
		display: inline-block;
		width: 276px;
		height: 478px;
		padding: 21px 15px;
		background-color: rgba(230, 246, 255, 0.65);
		border-radius: 30px;

		img {
			height: 91px;
			width: auto;
		}

		h3 {
			font-weight: bold;
			font-size: 28px;
			padding: 0;
		}

		p {
			font-size: 14px;
			padding: 0;
			margin: 0;
			width: 245px;
		}
	}

	.rowabout-btnrow {
		display: flex;
		flex: 1;
		align-self: flex-end;
		@media (max-width: 1024px) {
			margin: 17px auto 50px;
			height: 34px;
			width: 320px;
		}

		@media (min-width: 1025px) {
			position: absolute;
			bottom: 21px;
			width: 245px;
		}
	}

	.rowabout-btn {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		flex: 1;
		flex-basis: 100%;
		background-color: #4190ca;
		border-radius: 10px;
		font-size: 14px;
		height: 34px;
		width: 320px;
		vertical-align: middle;
		cursor: default;

		@media (max-width: 1024px) {
			height: 34px;
			font-size: 12px;
			padding: 0 44px;
		}

		@media (min-width: 1025px) {
			width: 245px;
			height: 72px;
			padding: 8px 26px;
		}
	}

	.rowabout-btn:hover {
		cursor: pointer;
		text-decoration: none;
	}

	.readmore-btn {
		background-color: #ffffff;
		border: 1px solid #e0daf0;
		color: #4190ca;
	}
`;

export const RowAboutFloodCard: React.FC = () => {
	const { history } = UseRouter();
	const { doRequest } = UseDataApi();
	const userContext: DomainStores["UserStore"] = useContext<Stores>(
		StoresContext
	).domain.UserStore;
	const mapStoresContext: DomainStores["MapStore"] = useContext<Stores>(
		StoresContext
	).domain.MapStore;
	const floodDataContext: DomainStores["FloodAndClaimDataStore"] = useContext<
		Stores
	>(StoresContext).domain.FloodAndClaimDataStore;

	function onFloodMitigation(): void {
		history.push("/BlogPost/2c70d17e-c553-46e8-8268-f7d99ded9168");
	}

	function onFloodRestoration(): void {
		history.push("/BlogPost/92760d73-d215-4398-9a4d-a7de0aa52252");
	}

	function tfiformatLink(): string {
		let retVal: string = "";

		if (mapStoresContext.address.length > 0) {
			let arrAddress: string[] = StringToArray(mapStoresContext.address, ",");
			if (arrAddress != null) {
				let zipCode: string = arrAddress[arrAddress.length - 2].replace(
					floodDataContext.stateShortCode + " ",
					""
				);
				retVal = "site=mfr";
				retVal += "&email=";
				retVal +=
					userContext.EmailAddress === undefined
						? ""
						: userContext.EmailAddress;
				retVal += "&address1=" + encodeURIComponent(arrAddress[0]);
				retVal += "&address2=" + encodeURIComponent(arrAddress[1]);
				retVal +=
					"&city=" + encodeURIComponent(arrAddress[arrAddress.length - 3]);
				retVal +=
					"&state=" + encodeURIComponent(floodDataContext.stateShortCode);
				retVal += "&zip=" + encodeURIComponent(zipCode);
				retVal += "&zone=" + floodDataContext.fldZone.substring(0, 1);
			}
		}

		return retVal;
	}

	function getQuoteLink(): string {
		let quoteUri: string =
			"https://nationalfloodinsurance.org/quote?" + tfiformatLink();
		return quoteUri;
	}

	function getQuoteClick(a: any): void {
		const tfiLink: string = tfiformatLink();
		if (
			floodDataContext.auditSearchId !== undefined &&
			floodDataContext.auditSearchId.length > 0
		) {
			// post to API
			const data: UpdateAuditSearchRequest = {
				id: floodDataContext.auditSearchId,
				quoteClickThrough: tfiLink,
				clickSource: "More About Flooding"
			};

			doRequest(AppUrls.Server.Api.AuditSearch.UpdateUserQuote, data);
		}
	}

	return (
		<>
			<AboutFloodRow>
				<AboutFloodRowBox>
					<img src={iconFloodMitigation} alt={"Flood Mitigation"} />
					<h3>Flood Mitigation</h3>
					<p>
						Flood mitigation refers to steps you can take to prevent flood
						damage to your property before it happens. Simpler flood mitigation
						efforts include grading your lawn away from your home, applying a
						flood proofing sealant, and installing watertight doors and windows.
						More extreme techniques include flood venting, floodwalls, and
						raising the home itself.
					</p>
					<div className="rowabout-btnrow">
						<div
							className="rowabout-btn readmore-btn"
							onClick={onFloodMitigation}
						>
							Read More
						</div>
					</div>
				</AboutFloodRowBox>
				<AboutFloodRowBox>
					<img src={iconFloodInsurance} alt={"Flood Insurance"} />
					<h3>Flood Insurance</h3>
					<p>
						Flood insurance is the best way to protect yourself against the
						financial repercussions of flood damage. Though you may not be
						lender-required to carry flood insurance, we encourage you to
						conduct your own research about the flood risk of your property,
						including checking your score on our My Flood Risk tool.
					</p>
					<Link
						to="/myrisk"
						component={RouterLink}
						title={"Get a flood insurance quote now"}
						className="external-link"
					>
						<div className="rowabout-btnrow">
							<div className="rowabout-btn getquote-btn">
								Click here to find out your true risk and get a FREE Flood
								Insurance quote NOW!
							</div>
						</div>
					</Link>
				</AboutFloodRowBox>
				<AboutFloodRowBox className="last-box">
					<img src={iconFloodRestoration} alt={"Flood Restoration"} />
					<h3>Flood Restoration</h3>
					<p>
						Flood restoration is the water removal and repair process that takes
						place after a flood. Restoration services work to extract the water
						from your home, and then dry the home, using industrial equipment.
						Depending on the extent of the damage, flooring, baseboards,
						drywall, cabinetry, and other items may need to be replaced.
					</p>
					<div className="rowabout-btnrow">
						<div
							className="rowabout-btn readmore-btn"
							onClick={onFloodRestoration}
						>
							Read More
						</div>
					</div>
				</AboutFloodRowBox>
			</AboutFloodRow>
		</>
	);
};
