/* eslint-disable @typescript-eslint/no-explicit-any */
import { InitialState } from "../../../Models/InitialState";
import { useState, useEffect, useReducer } from "react";
import axios, { AxiosResponse } from "axios";
import { getCookie } from "../Utils";
// import * as Models from "../../Models";

function dataFetchReducer(state: any, action: IActions): any {
	switch (action.type) {
		case "FETCH_INIT":
			return { ...state, isLoading: true, isError: false };
		case "FETCH_SUCCESS":
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload
			};
		case "FETCH_FAILURE":
			return {
				...state,
				isLoading: false,
				isError: true,
				data: action.payload
			};
		default:
			throw new Error();
	}
}

interface IActions {
	type: string;
	payload?: InitialState;
}

interface IDataApi<T> {
	isLoading: boolean;
	isError: boolean;
	data: T;
	doRequest(url: string, postData?: any, event?: any): void;
	status: number;
}

enum RequestType {
	GET,
	POST
}

// export const useDataApi: (initialUrl: string, initialData: any) => <string> = (initialUrl: string) {
// export const useDataApi: (initialUrl: string, initialData: any) => {} = (initialUrl: string, initialData: string) => {
/**
 * Hook to fetch data from the url specified.
 * @param {string} initialUrl Url to send data to. Required.
 * @param {RequestType} requestType Defaults to GET. Ignored if postData is not null
 * @param {any} initialPostData Data to be posted to the Url specified. If blank the call will be a get request. Defaults to null
 */

// tslint:disable-next-line: typedef
export const UseDataApi = <T>(
	initialUrl: string = "",
	requestType: RequestType = RequestType.GET,
	initialPostData: any = null
): IDataApi<T> => {
	const initialData: any = null;
	const [url, setUrl] = useState(initialUrl);
	const [postData, setPostData] = useState(initialPostData);
	const [status, setStatus] = useState(501); // Not Implemented

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		isError: false,
		data: initialData
	});

	useEffect(() => {
		let didCancel: boolean = false;

		if (url === "") {
			return;
		}

		// tslint:disable-next-line: typedef
		const fetchData = async () => {
			dispatch({ type: "FETCH_INIT" });
			let headers: any = {};
			var jwt: string | undefined | null = getCookie(".auth");
			if (jwt !== null || jwt !== "") {
				headers = { Authorization: `Bearer ${jwt}` };
			}

			try {
				let result: AxiosResponse<any> = {} as any;
				if (postData || requestType === RequestType.POST) {
					const encType =
						postData instanceof FormData ? "multipart/form-data" : undefined;
					result = await axios.post(url, postData, {
						headers: headers,
						"Content-Type": encType
					} as any);
				} else {
					result = await axios(url, { headers: headers });
				}

				setStatus(result.status);
				if (!didCancel) {
					dispatch({ type: "FETCH_SUCCESS", payload: result.data });
					setUrl(""); // reset so that subsequent calls will show a state change
				}
			} catch (error) {
				setStatus(error.response.status);
				if (!didCancel) {
					dispatch({ type: "FETCH_FAILURE", payload: error.response.data });
					setUrl("");
				}
			}
		};

		fetchData();

		return () => {
			didCancel = true;
		};
	}, [url, postData, requestType]);

	// tslint:disable-next-line: typedef
	const doRequest = (
		url: string,
		initialPostData: any = null,
		event: any = null
	): void => {
		setStatus(501);
		setUrl(url);
		setPostData(initialPostData);

		if (event) {
			event.preventDefault();
		}
	};

	return { ...state, doRequest, status };
};
