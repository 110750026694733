import { AddressSelectorGoogle } from "../Shoothill.Components/Forms";
import { Helmet } from "react-helmet";
import { Box, Grid, Link, Button } from "@material-ui/core";
import { GoogleMap } from "../Shoothill.Components/Map";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { Link as RouterLink } from "react-router-dom";
import { useMediaQuery } from "../Shoothill.Core/Utils/Media";
import { isNullorWhiteSpace } from "../Shoothill.Core/Utils/Validation/isNullorWhiteSpace";
/* import styled from "styled-components"; */
import { StoresContext, Stores } from "../Stores";
import { MapStore } from "../Stores/Domain/MapStore";
import ReactGA from "react-ga";
import myfloodlogo from "../Content/myfloodlogo.svg";
import myfloodprologowhite from "../Content/myfloodprologowhite.svg";
import FitText from "@kennethormandy/react-fittext";

import {
	AppHeaderStart,
	MyRiskRoot,
	MyLocationBox,
	PlacementBox,
	TitleHeader,
	Pad60LeftBox,
	WelcomeBox,
	WhiteGraditentBox,
	TallGrid,
	NotMyLocationBox,
	MyRiskMain
} from "../Shoothill.Components/Styles/MyRiskStyles";

import myFloodRiskTool from "Content/icon-MyFloodRiskTool.svg";

import {
	/* PageRoot, */
	WizGrid
} from "../Shoothill.Components/Styles/MyRiskWizardStyles";
import { ProRadiusBox } from "Shoothill.Components/Styles/ProAccStyling";
import styled from "styled-components";
import { LoginEmbed } from "../Shoothill.Components/Account/LoginEmbed";
import { UseRouter } from "../Shoothill.Core/Utils/Hooks/UseReactRouter";
import { UseDataApi } from "../Shoothill.Core/Utils/Axios/UseDataApi";
import { LoginModel } from "Shoothill.Components/Account";
import { DomainStores } from "../Stores/DomainStores";
import { AccountStore } from "../Stores/Domain/AccountStores";
import homepageLogo from "../Content/myfloodprologo.svg";
import { RegisterFormComp } from "Shoothill.Components/Pro";
import { RegProUserFormModel } from "Models";
import ToggleFitText from "../Shoothill.Components/General/ToggleFitText";
import whiteStar from "Content/whiteStar.svg";
import { usePercentageHeightOfWidth } from "../Shoothill.Core/Utils/Hooks/UsePercentageHeightOfWidth";

const RiskProRadiusBox = styled(ProRadiusBox)`
	padding: 60px 30px;
	height: 100%;

	@media (min-width: 1140px) {
		.thirdBox3Background {
			position: absolute !important;
			top: -60px;
			bottom: -60px;
			left: 0;
			right: -31px;
			background-color: rgba(65, 144, 202, 1);
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
		}
	}

	.thirdBox:nth-child(3) > *:not(.thirdBox3Btn) {
		position: relative;
	}

	.thirdBox {
		width: 35.2%;
		padding: 0 30px;
		display: inline-block;
		vertical-align: top;
		position: relative;

		@media (min-width: 1141px) {
			height: 100%;
		}

		h2 {
			margin: 24px 0;
			line-height: 100%;

			@media (max-width: 1140px) {
				margin: 20px 0;
			}
		}

		p {
			color: #222120;
		}

		p:first-of-type:not(:last-of-type) {
			position: absolute;
			top: 162px;
			max-width: 320px;
			font-size: 11px;
		}

		img {
			height: 64px;
		}

		ul {
			padding: 0;
		}

		li {
			font-weight: normal;
			list-style-type: none;
			padding: 5px 0;

			@media (max-width: 1140px) {
				padding: 2px 0;
			}
		}
	}

	.invert {
		h2,
		h3,
		p,
		li {
			color: white;
		}
	}

	.thirdBox:nth-child(1),
	.thirdBox:nth-child(3) {
		width: 32.4%;
	}

	.riskLoginEmbed {
		position: absolute;
		bottom: -60px;
		right: 30px;
		left: 30px;
	}

	@media (max-width: 1140px) {
		padding: 0;

		.thirdBox {
			padding: 0;
			width: 100% !important;
			text-align: center;

			img {
				height: 32px;
				margin: auto;
				display: block;
			}

			ul {
				text-align: left;
				padding-left: 17px;

				li {
					list-style-image: url(${whiteStar});
				}
			}

			:nth-child(2) {
				position: absolute;
			}

			:nth-child(1) {
				p {
					margin-top: 18px;
				}
			}

			:nth-child(3) {
				margin-top: 18px;
			}
		}
	}
`;

const Step1Buttons = styled.div`
	position: absolute;
	left: 30px;
	right: 30px;
	bottom: 0;

	a,
	button {
		height: 54px;
		display: block;
		padding: 16px;
		font-size: 16px;
		color: white;
		margin: auto;
		width: 100%;
		background-color: transparent;
		font-weight: bold;

		span {
			height: 100%;
			display: flex;
		}
	}

	@media (max-width: 1140px) {
		position: static;
	}
`;

const ContinueBox = styled(MyLocationBox)`
	margin: 0;

	@media (max-width: 1140px) {
		margin-top: 18px;
		margin-bottom: 18px;

		height: auto;

		a {
			height: auto;
			padding: 10px;
			line-height: normal;
		}
	}
`;

const SignUpBox = styled(ContinueBox)`
	a,
	button {
		background-color: #ffffff !important;
		text-transform: uppercase;
		color: #4190ca !important;
	}

	@media (max-width: 1140px) {
		button {
			text-transform: none;
			height: auto !important;
			padding: 10px !important;
			margin: 0;
			max-width: 100% !important;

			span {
				display: block;
				line-height: normal;
			}
		}
	}
`;

const LoginBox = styled(SignUpBox)`
	margin-bottom: 0 !important;
`;

const industryProfessional = (
	<>
		<h2>I am an:</h2>
		<h3>Industry Professional</h3>
		<p>(Real Estate Agent, Lender, Builder, Title Agent, or related field)</p>
	</>
);

export const MyRisk: React.FC = () => {
	const domainStores: DomainStores = useContext<Stores>(StoresContext).domain;

	const mapStoresContext: MapStore = domainStores.MapStore;
	const accountStoresContext: AccountStore = domainStores.AccountStore;

	const [step, setStep] = useState<number>(0);
	const [errorMessage, setErrorMessage] = useState("");

	const [mobileLogin, setMobileLogin] = useState<boolean>(false);

	const { history } = UseRouter();
	const { data, isLoading, doRequest } = UseDataApi();
	const {
		data: userSet,
		isLoading: userLoading,
		doRequest: userRequest
	} = UseDataApi();

	const el1 = useRef<HTMLImageElement>(null);
	const el2 = useRef<HTMLImageElement>(null);

	ReactGA.pageview(window.location.pathname + window.location.search);

	let isMobile: boolean;
	let mobMap: boolean;
	let smallerThanTab: boolean;
	let useMobile: boolean;
	isMobile = useMediaQuery("screen and (max-width: 1024px)") ? true : false;
	mobMap = useMediaQuery("screen and (max-width: 960px)") ? true : false;
	smallerThanTab = useMediaQuery("screen and (max-width: 767px)")
		? true
		: false;
	useMobile = useMediaQuery("screen and (max-width: 1140px)") ? true : false;

	usePercentageHeightOfWidth([el1, el2], 0.116, useMobile);

	const handleLogin: (loginModel: LoginModel) => void = (
		loginModel: LoginModel
	): void => {
		doRequest("/api/account/login", loginModel);
	};

	const handleRegister: (registerModel: RegProUserFormModel) => void = (
		registerModel: RegProUserFormModel
	): void => {
		userRequest("/api/account/register", registerModel);
	};

	useEffect(() => {
		const ev = new Event("resize");
		window.dispatchEvent(ev);
	});

	useEffect(() => {
		setErrorMessage("");
	}, [step]);

	useEffect(() => {
		const checkLoginReturn: () => any = (): any => {
			if (data !== null) {
				if (data.wasSuccessful) {
					accountStoresContext.getLoginState(data.payload);
					history.push(`/myriskwizard`);
				} else {
					if (data.errors.length > 0) {
						setErrorMessage("* " + data.errors[0].message);
					} else {
						setErrorMessage("* Unknown error has occurred.");
					}
				}
			}
		};
		checkLoginReturn();
	}, [data]);

	useEffect(() => {
		if (userSet !== null) {
			if (userSet.wasSuccessful) {
				setStep(3);
			} else {
				if (userSet.errors.length > 0) {
					setErrorMessage("* " + userSet.errors[0].message);
				} else {
					setErrorMessage("* Unknown error has occurred.");
				}
			}
		}
	}, [userSet]);

	let pageRoute: string = "";
	pageRoute = !isNullorWhiteSpace(mapStoresContext.pageSource)
		? "/myrisk"
		: mapStoresContext.pageSource;

	function onClickBack(): void {
		mapStoresContext.ResetLocation();
	}

	const getContent = () => {
		switch (step) {
			case 0:
				window.scrollTo(0, 0);
				return (
					<>
						{!mapStoresContext.hasAddress && (
							<>
								<AppHeaderStart>
									<Grid container className=" gridContainer">
										<Grid item xs={12}>
											<WelcomeBox>
												<div className={"image"}>
													<img
														src={myFloodRiskTool}
														alt={"Potential flood loss"}
													/>
												</div>
												<h1>My Flood Risk Tool</h1>
												<p>
													The purpose of this tool is to provide an illustration
													of real-life, industry-proven {!isMobile && <br />}
													flood factors, and to give a true understanding of
													potential flood risk.
												</p>
											</WelcomeBox>
											<div className="AddressSelector">
												<AddressSelectorGoogle />
											</div>
										</Grid>
									</Grid>
								</AppHeaderStart>
							</>
						)}

						{mapStoresContext.hasAddress && (
							<>
								<WizGrid item style={smallerThanTab ? { height: "100%" } : {}}>
									<PlacementBox>
										<TallGrid container spacing={3}>
											<Grid item xs={12} md={6}>
												<div className="flexContainer">
													<Pad60LeftBox
														className="paddingBox"
														pt={6}
														textAlign="center"
													>
														<TitleHeader>Your location</TitleHeader>
														<h2 style={{ color: "#4190CA" }}>
															{mapStoresContext.address}
														</h2>
														<div className="information">
															{isMobile ? null : (
																<h1>Is this your location?</h1>
															)}
															<p>
																{isMobile ? (
																	<strong>Is this your location?</strong>
																) : null}{" "}
																If not, please click and drag the location pin
																to your building's correct location.
															</p>
															<p className={"red"}>
																If your property is located in a complex, please
																confirm that the pin is on the correct building
																before continuing.
															</p>
														</div>
													</Pad60LeftBox>
													{mobMap ? <GoogleMap /> : null}
													<div className="addressButtons">
														<MyLocationBox pt={3}>
															<Button
																/* component={RouterLink} */
																/* to="/myriskwizard" */ onClick={() => {
																	if (accountStoresContext.IsLoggedIn) {
																		history.push(`/myriskwizard`);
																	} else {
																		setStep(1);
																	}
																}}
																disableFocusRipple
																disableRipple
																disableTouchRipple
															>
																Yes, this is my location
															</Button>
														</MyLocationBox>
														<NotMyLocationBox pt={3}>
															<Link
																component={RouterLink}
																to={pageRoute} /* "/myrisk" */
																onClick={onClickBack}
															>
																No, take me back
															</Link>
														</NotMyLocationBox>
													</div>
												</div>
											</Grid>
											{mobMap ? null : (
												<Grid
													item
													xs={12}
													md={6}
													style={{
														paddingTop: "0px",
														paddingBottom: "0px",
														paddingRight: "0px"
													}}
												>
													<Box
														pt={6}
														textAlign="center"
														style={{
															padding: "0px"
														}}
													>
														<div
															className="mapPlaceholder"
															style={{
																margin: "0px"
															}}
														>
															<GoogleMap />
														</div>
													</Box>
												</Grid>
											)}
										</TallGrid>
									</PlacementBox>
								</WizGrid>
							</>
						)}
					</>
				);

			case 1:
				return (
					<>
						<ProRadiusBox
							color="#464545"
							background={
								useMobile
									? "linear-gradient(180deg,rgba(230,246,255,0.7) 30.35%,#247FC2 30.35%)"
									: "linear-gradient(90deg,rgba(230,246,255,0.7) 33.25%,rgba(118,197,255,0.85) 33.25%)"
							}
							radius="20px"
							mg="auto"
							/* minht={getPageHeight()} */
							mobdesignheight={542}
							minheight={634}
							height={useMobile ? undefined : 634}
							style={{ minHeight: "auto", maxWidth: "100%" }}
							nanwidth
						>
							<RiskProRadiusBox className="innerradbox">
								<div className="thirdBox">
									<img src={myfloodlogo} alt="My Flood Risk" ref={el1} />
									{useMobile || (
										<>
											<h2>I am a:</h2>
											<h3>Property owner / tenant</h3>
										</>
									)}
									<ToggleFitText
										compressor={2.68}
										toggle={useMobile}
										debounce={0}
									>
										<p>
											I do not currently require a My Flood Risk Pro account and
											wish to continue using the My Flood Risk tool.
										</p>
									</ToggleFitText>
									<Step1Buttons>
										<ContinueBox>
											<Link to="/myriskwizard" component={RouterLink}>
												<ToggleFitText
													toggle={useMobile}
													compressor={/* 2 */ 1.95}
													debounce={0}
												>
													{`Continue${
														useMobile ? " as Property owner / tenant" : ""
													}`}
												</ToggleFitText>
											</Link>
										</ContinueBox>
									</Step1Buttons>
								</div>
								<div className="thirdBox invert">
									{useMobile || (
										<>
											<img src={myfloodprologowhite} alt="My Flood Risk" />
											{industryProfessional}
											<p>
												I have a My Flood Risk account and wish to continue by
												logging in below:
											</p>
											<div className="riskLoginEmbed">
												<LoginEmbed
													onSubmit={handleLogin}
													errorMessage={errorMessage}
													isLoading={isLoading}
													loginBtnText="Login"
													hideForgot={true}
												/>
											</div>
										</>
									)}
								</div>
								<div className="thirdBox invert">
									<div className="thirdBox3Background" />
									<img
										src={myfloodprologowhite}
										alt="My Flood Risk"
										ref={el2}
									/>
									{useMobile ? (
										<>
											<FitText compressor={1.95} debounce={0}>
												<p>Sign me up for a My Flood Risk Pro account</p>
											</FitText>
											<FitText compressor={2.68} debounce={0}>
												<p>to get the following benefits:</p>
											</FitText>
										</>
									) : (
										<>
											{industryProfessional}
											<p>
												I would like to sign up for a FREE My Flood Risk Pro
												Account to get the following benefits:
											</p>
										</>
									)}
									<ToggleFitText
										toggle={useMobile}
										compressor={2.9}
										debounce={0}
									>
										<ul>
											<li>Your branding on MyFloodRisk reports</li>
											<li>Liability waiver</li>
											<li>Real-time chat</li>
											<li>Access to flood insurance experts</li>
											<li>
												Preferred pricing on FEMA Flood Zone Determination
												Reports
											</li>
										</ul>
									</ToggleFitText>

									<Step1Buttons className="thirdBox3Btn">
										<SignUpBox>
											<Button
												onClick={() => setStep(2)}
												disableFocusRipple
												disableRipple
												disableTouchRipple
											>
												<ToggleFitText
													toggle={useMobile}
													compressor={1.95}
													debounce={0}
												>
													{useMobile
														? "Sign up & continue as Industry professional"
														: "Sign me up, It's Free!"}
												</ToggleFitText>
											</Button>
										</SignUpBox>
									</Step1Buttons>
									{useMobile && (
										<>
											<FitText compressor={1.82} debounce={0}>
												<h2>I have a MyFloodrisk Pro account</h2>
											</FitText>
											<Step1Buttons className="thirdBox3Btn thirdBox3Btn2">
												<LoginBox>
													<Button
														onClick={() => {
															setStep(3);
															setMobileLogin(true);
														}}
														disableFocusRipple
														disableRipple
														disableTouchRipple
													>
														<FitText compressor={1.95} debounce={0}>
															Login and continue as Industry professional
														</FitText>
													</Button>
												</LoginBox>
											</Step1Buttons>
										</>
									)}
								</div>
							</RiskProRadiusBox>
						</ProRadiusBox>
					</>
				);

			case 2:
				return (
					<>
						<ProRadiusBox
							color="#464545"
							background="rgba(230,246,255,0.7)"
							radius="20px"
							mg="auto"
							pd={"30px 0px 0px"}
							/* minht={getPageHeight()} */
							/* mobdesignheight={getMobDesignHeight()} */
							minheight={507}
						>
							<img
								src={homepageLogo}
								className="signupLogo"
								alt="MyFlood Risk Pro"
							/>
							<RegisterFormComp
								onSubmit={handleRegister}
								errorMessage={errorMessage}
								isLoading={userLoading}
								confirmMsg={"Confirm and sign up"}
								onCancel={() => setStep(1)}
							/>
						</ProRadiusBox>
					</>
				);

			case 3:
				return (
					<>
						<ProRadiusBox
							color="#464545"
							background="rgba(230,246,255,0.7)"
							radius="20px"
							mg="auto"
							pd={"30px 0px 0px"}
							/* minht={getPageHeight()} */
							/* mobdesignheight={590} */
							minheight={507}
							width={560}
						>
							<img
								src={homepageLogo}
								className="signupLogo"
								alt="MyFlood Risk Pro"
							/>
							<div className="loginSignup">
								<div className={"PlaceHolderPart"}>
									<ToggleFitText
										toggle={useMobile}
										compressor={1.4}
										debounce={0}
									>
										<h2 className={"regSuccess"}>
											{mobileLogin ? "P" : "Success, p"}lease login to
											<br />
											<span>continue your report</span>
										</h2>
									</ToggleFitText>
									<LoginEmbed
										onSubmit={handleLogin}
										errorMessage={errorMessage}
										isLoading={isLoading}
										hideForgot={true}
										loginBtnText="Login"
									/>
								</div>
							</div>
						</ProRadiusBox>
					</>
				);
		}
	};

	return useObserver(() => (
		<>
			<Helmet>
				<title>
					Flood Risk Tool - Find Your True Flood Risk | My Flood Risk
				</title>
				<meta
					name="description"
					content="Find your true flood risk with My Flood Risk, which illustrates real-life, 
         industry-proven flood factors, such as FEMA flood zones, elevation, and hurricanes."
				/>
			</Helmet>
			<MyRiskRoot signup={step >= 2 && !mobileLogin}>
				<WhiteGraditentBox
					gradient={
						step >= 1
							? "none"
							: `linear-gradient(
						0deg,
						rgba(255, 255, 255, 1) 2%,
						rgba(255, 255, 255, 0.05) 44%)`
					}
				>
					<MyRiskMain
						role="main"
						style={
							mapStoresContext.hasAddress && !smallerThanTab
								? { padding: "20px 20px" }
								: {}
						}
					>
						<Box
							margin={step > 0 && useMobile ? "20px" : undefined}
							style={
								useMobile
									? {
											minHeight: "calc(100vh - 70px)"
									  }
									: {}
							}
						>
							{getContent()}
						</Box>
					</MyRiskMain>
				</WhiteGraditentBox>
			</MyRiskRoot>
		</>
	));
};
