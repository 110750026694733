import React, { useContext, useEffect } from "react";
import moment from "moment";

import { StoresContext, Stores, DomainStores } from "../../Stores";
import { AuditProUserModel } from "../../Models/AuditProUser";
import { reportAdminModel } from "../../Models/FormModels";

import { Box, Grid, Button, Modal, Typography, Table, TableBody, TableCell, TableSortLabel, TableHead, TableRow, Paper, Link} from "@material-ui/core";
import { useReportStyles, ReportModalBox } from "../../Shoothill.Components/Styles/ReportStyles";
import { useAdminStyles} from "../../Shoothill.Components/Styles/AdminStyles";
import { Row1040 } from "../../Shoothill.Components/Styles/BlogRefStyles";

import { useRefLibStyles } from "../../Shoothill.Components/Styles/RefLibStyles";
import editIcon from "../../Content/visibility-24px.svg";
import { GenericCancelButton } from "../../Shoothill.Components/Styles/ModalStyles";
import { ReportsRoot } from "../../Shoothill.Components/Styles/ReportAdminStyling";

import { UseDataApi } from 'Shoothill.Core/Utils';
import { isNullorEmpty } from "Shoothill.Core/Utils/Validation";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import DateFnsUtils from '@date-io/date-fns';
import { observable } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { AuditProOrderModel } from 'Models';

export interface IProps {
    onClickValue: (user: reportAdminModel) => void;
    sDate: string;
    eDate: string;
}

export interface headCell {
    id: keyof AuditProUserModel;
    label: string;
    allowSorting: boolean;
}

export const UserListComp: React.FC<IProps> = props => {
    const classes: any = useReportStyles();
    const adminClasses: any = useAdminStyles();
    const refClasses: any = useRefLibStyles("contact");

    const { onClickValue } = props;
/*     const [startDate, setStartDate] = React.useState<Date | null>(
        new Date(moment.utc().add(-7, "day").format("YYYY-MM-DD"))
    );
    const [endDate, setEndDate] = React.useState<Date | null>(new Date()); */
    const [startDate, setStartDate] = React.useState<Date | null>(
        !isNullorEmpty(props.sDate)
        ? new Date(props.sDate)
        : new Date(moment.utc().add(-7, "day").format("YYYY-MM-DD"))
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        !isNullorEmpty(props.eDate)
        ? new Date(props.eDate)
        : new Date()
    );

    const AuditProStoresContext: DomainStores["AuditProStores"] = useContext<Stores>(StoresContext).domain.AuditProStores;
    const { data: proUserSet, doRequest: proUserSetRequest } = UseDataApi();

    useEffect(() => {
        if (AuditProStoresContext.isLoading === false) {
            AuditProStoresContext.ResetUser();
            AuditProStoresContext.setAPIIsLoading(true);

            const apiurl: string = "/api/auditprouser/getprousers";
            proUserSetRequest(
              AuditProStoresContext.getAPICall(apiurl,
                {
                    start: formatDate(startDate!),
                    end: formatDate(endDate!, true)
                })
            );
        }
    }, [proUserSet, startDate, endDate]);

    useEffect(() => {
        if (!isNullorEmpty(proUserSet)) {
            AuditProStoresContext.SetProUsers(proUserSet.payload);
        }
    }, [proUserSet]);

    function downloadCSV(): void {
        window.open(
            AuditProStoresContext.getAPICall("/api/auditprouser/getprouserscsv", {
                start: formatDate(startDate!),
                end: formatDate(endDate!, true)
            })
        );
    }

    function formatDateTime(date: string): string {
        const mom: moment.Moment = moment.utc(date);
        return mom.local().format("MM/DD/YYYY HH:mm");
    }

    function handleStartDateChange(date: Date | null): void {
        setStartDate(date);
    }

    function handleEndDateChange(date: Date | null): void {
        setEndDate(date);
    }

    function formatDate(date: Date, isEndDate: boolean = false): string {
        let count: number = 0;
        if (isEndDate) {
            count = 1;
        }
        const utc: moment.Moment = moment(date).add(count, "days").startOf("day").utc();
        return utc.toISOString();
    }

    function handleLoadMore(): void {
        AuditProStoresContext.getMoreUserRecords();
    }

    function showLoadMore(): boolean {
        return (
            AuditProStoresContext.auditProUsers.length >
            AuditProStoresContext.tableUserCount
        );
    }

    const seeUserOrdersClick: (userStuff: AuditProUserModel) => void = (userStuff: AuditProUserModel): void => {
      let aUser: reportAdminModel = {
        guid: userStuff.id,
        name: userStuff.firstName + " " + userStuff.lastName,
        companyLogo: userStuff.companyLogo !== undefined ? userStuff.companyLogo : "",
        companyName: userStuff.companyName !== undefined ? userStuff.companyName : "",
        startDate: startDate!.toISOString(),
        endDate: endDate!.toISOString()
      }

      onClickValue(aUser);
    };

    const handleCounterChange: (event: any, newValue: number) => void = (event: any, newValue: number): void => {
        AuditProStoresContext.setMoreUserCounter(newValue);
    };

    const createSortHandler = (property: keyof AuditProUserModel) => (event: React.MouseEvent<unknown>) => {
        AuditProStoresContext.sortUserHandler(property);
    };

    const headCells: headCell[] = [
        { id: 'createdOn', label: 'Date Joined', allowSorting: true },
        { id: 'firstName', label: 'First Name', allowSorting: false },
        { id: 'lastName', label: 'Last Name', allowSorting: true },
        { id: 'companyName', label: 'Company', allowSorting: true },
        { id: 'email', label: 'Email', allowSorting: false },
        { id: 'companyLogo', label: 'Logo', allowSorting: false },
        { id: 'mfrQty', label: 'MFR', allowSorting: false },
        { id: 'fzdQty', label: 'FZD', allowSorting: false }
    ];

    return useObserver(() => (
    <>
      <div className={classes.blogBottom}>
        <ReportsRoot>
          <Typography id="header" className={adminClasses.headingBlue34}>
            Pro Accounts
          </Typography>
          <div className="content">
            <div className={classes.dateRow}>
              <Grid container className={classes.dateRowContainer}>
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      className={adminClasses.datePickerGridContainer}
                    >
                      <Box textAlign="left" className={adminClasses.datePickerBox}>
                        <Typography
                          id="header"
                          className={`${adminClasses.headingBlue21} ${classes.subTitle}`}
                        >
                          Start date
                        </Typography>
                        <KeyboardDatePicker
                          className={adminClasses.datePicker}
                          margin="normal"
                          id="start-date-picker-dialog"
                          format="MM/dd/yyyy"
                          minDate={new Date("2019-08-01")}
                          maxDate={endDate}
                          value={startDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </Box>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      className={adminClasses.datePickerGridContainer}
                    >
                      <Box textAlign="left" className={adminClasses.datePickerBox}>
                        <Typography
                          id="header"
                          className={`${adminClasses.headingBlue21} ${classes.subTitle}`}
                        >
                          End date
                        </Typography>
                        <KeyboardDatePicker
                          className={adminClasses.datePicker}
                          margin="normal"
                          id="end-date-picker-dialog"
                          format="MM/dd/yyyy"
                          minDate={startDate}
                          maxDate={new Date()}
                          value={endDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </Box>
                    </Grid>                    
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid 
                  item
                  xs={6}
                  sm={2}
                  justify="center"
                >
                  <Box textAlign="left" className={adminClasses.datePickerBox}>
                    <Typography
                        id="header"
                        className={`${adminClasses.headingBlue21} ${classes.subTitle}`}
                      >
                      Pro Users
                    </Typography>
                    <div className="totalQuotesClicked">
                        <p>{ AuditProStoresContext.countUserRecords }</p>
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={6} sm={2}>

                </Grid>
              </Grid>
            </div>
            <div className="buttons">
                  <Button
                    className={adminClasses.adminBtn}
                    variant="outlined"
                    onClick={downloadCSV}
                  >
                    {"Download CSV"}
                  </Button>
            </div>
            <div className="adminReportResults">
              { AuditProStoresContext.isLoading === true &&
                <div>Loading!!!!!!!!!!!!!!!</div>
              }

              { (AuditProStoresContext.isLoading === false && AuditProStoresContext.filteredUserRecords.length > 0) &&
                <>
                  <div className={classes.toggleContainer}>
                    <ToggleButtonGroup
                      value={AuditProStoresContext.moreUserCounter.toString()}
                      exclusive
                      onChange={handleCounterChange}
                      aria-label="text alignment"
                        >
                          <ToggleButton value="10" aria-label="left aligned">
                            10
                          </ToggleButton>
                          <ToggleButton value="50" aria-label="centered">
                            50
                          </ToggleButton>
                          <ToggleButton value="100" aria-label="right aligned">
                            100
                          </ToggleButton>
                        </ToggleButtonGroup>
                    <div className={classes.toggleContainerSummary}>
                        Showing 1-{AuditProStoresContext.filteredUserRecords.length}
                        &nbsp;of {AuditProStoresContext.countUserRecords} Records.
                    </div>
                  </div>
                  <div>
                  <div className={classes.horizontalScroll}>
                    <Table className={classes.table}>
                      <TableHead>
                          <TableRow className={classes.tableHeader}>
                              {headCells.map(headCell => (
                                <TableCell
                                  key={headCell.id}
                                  className={classes.tableHeaderCell}
                                  sortDirection={AuditProStoresContext.orderBy === headCell.id ? AuditProStoresContext.order : false}
                                >
                                  <TableSortLabel
                                    active={AuditProStoresContext.orderBy === headCell.id}
                                    direction={AuditProStoresContext.order}
                                    onClick={createSortHandler(headCell.id)}
                                  >
                                    {headCell.label}
                                    {AuditProStoresContext.orderBy === headCell.id ? (
                                      <span className={classes.visuallyHidden}>
                                        {AuditProStoresContext.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                      </span>
                                    ) : null}
                                  </TableSortLabel>
                                </TableCell>
                              ))}
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {(AuditProStoresContext.filteredUserRecords != null && AuditProStoresContext.filteredUserRecords.length > 0) &&
                            <>
                              {AuditProStoresContext.filteredUserRecords.map((n: AuditProUserModel, index: number) => {
                                return (
                                  <TableRow key={n.id}
                                    className={classes.tableRowClickable}>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                        {formatDateTime(n.createdOn).substring(0, 10)}
                                    </TableCell>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                      { !isNullorEmpty(n.firstName) && 
                                      <>
                                        {n.firstName.substring(0,10)}
                                      </>
                                      }
                                    </TableCell>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                      { !isNullorEmpty(n.lastName) && 
                                      <>
                                        {n.lastName.substring(0,10)}
                                      </>
                                      }
                                    </TableCell>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                        {n.companyName}
                                    </TableCell>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                        {n.email}
                                    </TableCell>
                                    <TableCell className={classes.tableRowCell}>
                                      { !isNullorEmpty(n.companyLogo) &&
                                      <>
                                      <Link
                                        href={n.companyLogo}
                                        target={"_blank"}
                                      >
                                        <img className={classes.editIcon} src={editIcon} alt={"Logo"}/>
                                      </Link>
                                      </>
                                      }
                                    </TableCell>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                      {n.mfrQty}
                                    </TableCell>
                                    <TableCell 
                                      className={classes.tableRowCell}
                                      onClick={() => seeUserOrdersClick(n)}
                                    >
                                      {n.fzdQty}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          }
                      </TableBody>
                    </Table>
                  </div>
                  <div className={classes.whiteToBlueGrad}>
                    {showLoadMore() && (
                        <Row1040 container>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    className={refClasses.loadMoreBtn}
                                    onClick={handleLoadMore}
                                >
                                    Load more
                                </Button>
                            </Grid>
                        </Row1040>
                    )}
                  </div>
                  </div>
                </>
              }

            </div>
          </div>
        </ReportsRoot>
      </div>
    </>
    ));
}