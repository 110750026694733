// apps
import * as Mui from "@material-ui/core";

export const ColorLinearProgress: any = Mui.withStyles({
    colorPrimary: {
        backgroundColor: "#ff234e"
    },
    barColorPrimary: {
        backgroundColor: "#f0bdc7"
    }
})(Mui.LinearProgress);
