import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { AboutDiv, ProUSPul } from "./AboutCompStyling";
import ToggleFitText from "../General/ToggleFitText";

import FitText from "@kennethormandy/react-fittext";

export const AboutComp: React.FC = () => {
	let useMobile: boolean = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	return (
		<>
			<AboutDiv>
				<ToggleFitText toggle={useMobile} compressor={1.635} debounce={0}>
					<h2 className="mobBold">
						Are you a Real Estate Agent, Lender, Builder or Title Agent?
					</h2>
				</ToggleFitText>
				<ToggleFitText toggle={useMobile} compressor={1.635} debounce={0}>
					<h3>
						{useMobile ? "G" : "Sign up now and g"}et a <span>FREE</span>{" "}
						Myfloodrisk Pro account for all the following benefits...
					</h3>
				</ToggleFitText>
				<ToggleFitText compressor={1.92} debounce={0} toggle={useMobile}>
					<ProUSPul>
						<li>Your branding on My Flood Risk reports</li>
						<li>Liability waiver</li>
						<li>Resource page listing</li>
						<li>Real-time chat</li>
						<li>Access to flood insurance experts</li>
						<li>Preferred pricing on FEMA Flood Zone Determination Reports</li>
					</ProUSPul>
				</ToggleFitText>
			</AboutDiv>
		</>
	);
};
