export const isNullorWhiteSpace = (content: any) => {
    let response = false;

    if (content === null || content === undefined) {
        response = true;
    } else {
        if (content.length > 0){
            response = true;
        }
    }

    return response;
};