import GoogleAnalytics, { EventArgs, FieldsObject, TrackerNames } from "react-ga";

//export { EventArgs, FieldsObject, TrackerNames };

GoogleAnalytics.initialize([
	{
		trackingId: "your-ua-here", //UA-1852243-67
		gaOptions: {
			name: "ReallyReady"
		}
	}
]);

GoogleAnalytics.set({ anonymizeIp: true });

///
/// sendEvent
///
///https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export const sendEvent = (args: EventArgs) => {
	//let eventArgs: EventArgs = {
	//    category: category,
	//    action: action,
	//    label: label,
	//    nonInteraction: nonInteraction,
	//    transport: transport,
	//    value: value
	//};
	//if (args != undefined && args.transport == undefined) {
	// `args.transport` must be either one of these values: `beacon`, `xhr` or `image`
	//    args.transport = window.location.pathname;
	//}

	GoogleAnalytics.event(args);
};

///
/// set
///
export const set = (fieldsObject: FieldsObject, trackerNames?: TrackerNames) => {
	GoogleAnalytics.set({
		fieldsObject,
		trackerNames
	});
};

///
/// pageview
///
export const pageview = (path: string, trackerNames?: TrackerNames, title?: string): void => {
	GoogleAnalytics.pageview(path);
};
