
import { Stores } from "../Stores";
import { observable, computed, action } from "mobx";

export class BaseStore {
    // public constructor() {}
    public stores: Stores | null | undefined;
    @observable protected isLoadingCount: number = 0;
    @computed public get isLoading(): boolean {
        return this.isLoadingCount > 0;
    }
    public constructor(stores?: Stores) {
        if (stores) {
            this.stores = stores;
        }
    }

    @action protected setIsLoading: () => number = () => this.isLoadingCount++;
    @action protected unsetIsLoading: () => number = () => this.isLoadingCount--;
}
