import styled from "styled-components";
import { Box } from "@material-ui/core";

import HurricaneCard from "../../Content/hurricane-card.svg";

export const HurricaneBox: any = styled(Box)`
	padding-top: 21px;
	font-weight: normal;
	h1 {
		font-size: 32px;
		@media (max-width: 767px) {
			font-size: 24px;
			line-height: 18px;
			margin: 20px 0;
		}
	}

	.content {
		margin-top: -21px;
	}

	.fuschia {
		color: #ee00ee;
	}

	.hscore {
		@media (min-width: 768px) {
			margin-top: 0px;
		}
		width: 100%;
		margin: 30px 0 0;
		text-align: center;
		.group {
			display: inline-flex;
			@media (max-width: 1179px) {
				flex-direction: column;
			}
			margin: 0 auto;
			.quantity {
				text-align: left;
				font: Bold 48px "Lato", sans-serif;
				color: #ee00ee;
				opacity: 1;
				line-height: 0.7;
				margin-bottom: 0px;
				margin-right: 20px;
				@media (max-width: 1179px) {
					text-align: center;
					margin: auto;
				}

				@media (max-width: 767px) {
					font-size: 64px;
				}
			}

			.text {
				p {
					text-align: left;
					font: 18px "Lato", sans-serif;
					letter-spacing: 0;
					color: #292b28;
					opacity: 1;

					@media (max-width: 767px) {
						text-align: center;
					}
				}
				p:first-child {
					padding: 5px 0px;
					margin: 0;
				}
			}
		}
	}
`;

export const HurricaneChart: any = styled(Box)`
	padding-top: 20px;
	width: 1046px;
	height: 232px;
	margin-bottom: 30px;
	.milesFrom,
	.yearLabel {
		font-size: 12px;
		padding: 10px 0px;
		text-transform: uppercase;
	}

	.gridBase {
		display: inline-flex;

		.xAxisLabel {
			width: 30px;
			height: 100%;
			text-align: right;
			border-right: solid 1px #000;
			padding-right: 10px;
		}

		.yAxisLabel {
			width: 1046px;
			height: 100%;
			display: inline-flex;

			.grouped {
				position: relative;
				width: 100px;
				height: 222px;
				text-align: center;
				.yearLabel {
					position: absolute;
					bottom: 0px;
					border-top: 1px solid #000;
					width: 100%;
					text-transform: lowercase;
				}
			}
		}
	}
`;

export const HurricaneChartMob: any = styled(Box)`
	width: 100%;
	.milesFrom,
	.yearLabel {
		font-size: 12px;
		padding: 10px 0px;
		text-transform: uppercase;
	}

	.topLabels {
		padding-left: 37px;
		display: flex;
		justify-content: space-between;
	}

	.gridBase {
		width: 100%;

		.yAxisLabel {
			width: 30px;
			height: 100%;
			text-align: right;
			border-right: solid 1px #000;
			padding-right: 10px;
		}

		.xAxisLabel {
			width: 100%;
			height: 100%;

			.grouped {
				position: relative;
				width: 100%;
				height: 28px;
				text-align: center;
				.yearLabel {
					position: absolute;
					text-transform: lowercase;
					top: 50%;
					transform: translateY(-50%);
					border-right: black 1px solid;
					width: 40px;
				}
			}
		}
	}
`;

export interface IHurricaneCardsProps {
	distance: number;
}

export const HurricaneCards: any = styled(Box)<IHurricaneCardsProps>`
	z-index: 20;
	position: absolute;
	/* bottom: calc(${props => props.distance + 8}%); */
  top: -10px;
	left: 45.5%;
	height: 30px;

	.hurricaneLabel {
		width: 175px;
		height: 35px;
		font-size: 8px;
		text-transform: uppercase;
		text-align: left;
		background-image: url(${HurricaneCard});
		background-repeat: no-repeat;
		span {
			color: #ee00ee;
			display: block;
			font-weight: 700;
			font-size: 15px;
			padding: 3px 48px;
		}
	}
`;

export interface IHurricaneNodeProps {
	distance: number;
}

export const HurricaneNode: any = styled(Box)<IHurricaneNodeProps>`
	display: block;
	position: absolute;
	bottom: 37px;
	background-color: rgba(65, 144, 202, 0.25);
	width: 40px;
	height: ${props => props.distance}%;
	left: 29.25%;
	max-height: 200px;
`;

export const HurricaneNodeMob: any = styled(Box)<IHurricaneNodeProps>`
	display: block;
	position: absolute;
	/* bottom: 37px; */
	background-color: rgba(65, 144, 202, 0.25);
	width: ${props => props.distance}%;
	height: 24px;
	left: 41px;
	top: 50%;
	transform: translateY(-50%);
`;
