import React, { useState } from "react";
import { 
    Typography,
    Button
  } from "@material-ui/core";
import { UserListComp } from "./UserListComp";
import { ReportsComp } from "./ReportsComp";
import { reportAdminModel } from "../../Models/FormModels";

import {
    useAdminStyles
  } from "../../Shoothill.Components/Styles/AdminStyles";
import { ReportsRoot } from "../../Shoothill.Components/Styles/ReportAdminStyling";
import moment from "moment";

export const ProAdminComp: React.FC = () => {
    const classes = useAdminStyles();
    const [realtorId, setRealtorId] = useState("");
    const [thisRealtor, setThisRealtor] = useState("Pro User");

    const [sDate, setSDate] = useState<string>(
        new Date(moment.utc().add(-7, "day").format("YYYY-MM-DD")).toISOString()
    );
    const [eDate, setEDate] = useState<string>(
        new Date().toISOString()
    );

    const userDetail: (aUser: reportAdminModel) => void = (
        thisUser: reportAdminModel
    ): void => {
        setRealtorId(thisUser.guid);
        setSDate(thisUser.startDate);
        setEDate(thisUser.endDate);
        let tempUser: string = thisUser.name + (thisUser.companyName !== undefined ? ", " + thisUser.companyName : "")
        setThisRealtor(tempUser);
    };

    function resetRealtor() {
        setRealtorId("");
    }

    return (
        <>
            { realtorId === undefined || realtorId.length === 0 &&
            <>
                <UserListComp
                    onClickValue={userDetail}
                    sDate={sDate}
                    eDate={eDate}
                />
            </>
            }

            { realtorId.length > 0 &&
            <>
                <div className={classes.blogBottom}>
                    <ReportsRoot>
                        <Typography id="header" className={classes.headingBlue34}>
                            {thisRealtor}
                            <Button 
                            onClick={resetRealtor}
                            >
                                back
                            </Button> 
                        </Typography>
                        <div className="content">
                            <ReportsComp 
                                userGuId={realtorId}
                                sDate={sDate}
                                eDate={eDate}
                            />
                        </div>
                    </ReportsRoot>
                </div>
            </>
            }
        </>
    )
}