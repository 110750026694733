import React from "react";
import { Theme } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
/* import _clone from "lodash/clone"; */
import Dropzone from "react-dropzone";

const useStyles: any = makeStyles((theme: Theme) => ({
  dropzone: {
    borderWidth: "0px",
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: "5px",
    cursor: "pointer"
  }
}));

export interface IUploadableImageProps {
  imageUrl?: string;
  isDefaultImage?: boolean;
  className?: string;
  onImageSelected?: (imageFile: File) => any;
}

const allowedImageTypes = ["image/jpeg", "image/png"];

const maxSize = 100000000; // 100mb

export const UploadableImage: React.FC<IUploadableImageProps> = props => {
  const { imageUrl, /* isDefaultImage, */ className } = props;
  const theme: any = useTheme();
  const classes: any = useStyles(theme);
  /* const isAdd = isDefaultImage === false; */

  function handleDrop(acceptedFiles: any) {
    const { onImageSelected } = props;
    const selectedImage = acceptedFiles[0];

    if (onImageSelected) {
      onImageSelected(selectedImage);
    }
  }

  return (
    <>
      <Dropzone
        accept={(allowedImageTypes as any) as string}
        maxSize={maxSize}
        multiple={false}
        onDrop={handleDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div className="form-group">
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {imageUrl && (
                  <div className="width-100">
                    <img
                      className={className}
                      src={imageUrl}
                      alt={"Upload Preview"}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
    </>
  );
};
