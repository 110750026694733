import styled from "styled-components";
import { FormControl } from "@material-ui/core";

export const LargeWhiteFormControl = styled(FormControl)`
	width: 100%;
	input,
	select,
	textarea {
		font-size: 14px !important;
		font-weight: "bold";
		background-color: #ffffff;
        height: 24px;
        margin-top: 16px;
	}
	textarea {
		min-height: 134px;
	}
`;