import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Link } from "@material-ui/core";
import { GenericIncludeDeleted } from "../Models";
import homepageLogo from "../Content/homepageLogo.svg";
import bucketsGraphic from "../Content/bucketsGraphic.svg";
import bucketsGraphicMob from "../Content/bucketsGraphicMobile.svg";
import PEWBannerAD from "../Content/PEWBannerAD.png";
import PEWBannerADMobile from "../Content/PEWBannerAD-mobile.png";
import { SevenFactsInfographic } from "Shoothill.Components/RowTools";
import "./Home.css";
import { UseDataApi } from "../Shoothill.Core/Utils";
import { Stores, StoresContext } from "../Stores";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "../Shoothill.Core/Utils/Media";

import { Grid, Paper, Typography } from "@material-ui/core";
import { AddressSelectorGoogle } from "../Shoothill.Components/Forms";
import { RowToolsCard } from "../Shoothill.Components/RowTools/RowToolsCard";
import { RowDropsCard } from "../Shoothill.Components/RowTools/RowDropsCard";
import { IncLogos } from "Shoothill.Components/RowTools/IncLogos";

import { homeimage1 } from "../Shoothill.Components/Styles";

import {
	AppHeader,
	BridgeOver,
	useStyles,
	Row826,
	Row1040,
	HowTypo,
	HomeMiddle,
	SomeBox,
	HomeBox,
	HomeHeader,
	HomeWrap,
	VideoPlayer,
	HomeMergeToWhite
} from "../Shoothill.Components/Styles/HomeStyling";

import { RegionCostStore } from "../Stores/Domain/RegionCostStore";

export const Home: React.FC = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);

	/*   let showMobileImages: boolean; */
	let isMobile: boolean;
	isMobile = useMediaQuery("screen and (max-width: 1023px)") ? true : false;
	/*   showMobileImages = useMediaQuery("screen and (max-width: 767px)")
    ? true
    : false; */

	const classes: any = useStyles("home");
	const { data, doRequest } = UseDataApi();
	const regionCostStoreContext: RegionCostStore = useContext<Stores>(
		StoresContext
	).domain.RegionCostStore;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (regionCostStoreContext.regionCosts.length === 0) {
			const includeGenericDeleted: GenericIncludeDeleted = {
				includeDeleted: true
			};

			doRequest("/api/RegionCost/GetAll", includeGenericDeleted);
		}
	}, [data]);

	useEffect(() => {
		if (data !== null) {
			if (data.wasSuccessful) {
				regionCostStoreContext.SetRegionCosts(data.payload);
			}
		}
	}, [data]);

	return useObserver(() => (
		<>
			<Helmet>
				<title>My Flood Risk - Discover Your Property’s True Flood Risk</title>
				<meta
					name="description"
					content="Discover your property’s true flood risk with My Flood Risk, a free and interactive platform that uses comprehensive,
					 up-to-date data sets, including FEMA flood zones, storm surge, and hurricanes."
				/>
			</Helmet>
			<SomeBox />
			<HomeWrap>
				<HomeBox>
					{!isMobile && (
						<>
							<VideoPlayer loop autoPlay muted poster={homeimage1}>
								<source
									src={
										"https://nfiportal.blob.core.windows.net/images/darkerRaindrops.mp4"
									}
									type="video/mp4"
								/>
							</VideoPlayer>
						</>
					)}

					<HomeHeader>
						<AppHeader>
							<Typography variant="h1">
								{/* Determine your true flood <br />
								risk, here and now */}
								Determine your risk, <br />
								here and now
							</Typography>
							<Grid
								container
								className={classes.marginTop}
								alignContent="center"
								justify="center"
							>
								<Grid item xs={10} sm={6}>
									<AddressSelectorGoogle gotoMyRisk={true} />
								</Grid>
							</Grid>
							<Link
								href="https://myfloodrisk.org/BlogPost/03122018-19bb-4a6d-a782-d76a0bf54174"
								target="_blank"
								title={
									"Get an instant quote now from our partner site myfloodinsurance.com"
								}
								className="PEWBanner"
							>
								<img
									src={PEWBannerAD}
									alt={
										"Get an instant quote now from our partner site myfloodinsurance.com"
									}
								/>
							</Link>
							<Link
								href="https://myfloodrisk.org/BlogPost/03122018-19bb-4a6d-a782-d76a0bf54174"
								target="_blank"
								title={
									"Get an instant quote now from our partner site myfloodinsurance.com"
								}
								className="PEWBannerMobile"
							>
								<img
									src={PEWBannerADMobile}
									alt={
										"Get an instant quote now from our partner site myfloodinsurance.com"
									}
								/>
							</Link>
						</AppHeader>
					</HomeHeader>
				</HomeBox>

				<HomeMiddle>
					<Grid container className={classes.rootMargin}>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<HowTypo
									variant="h2"
									/* className={classes.headingWhite} */
								>
									How{" "}
									<img src={homepageLogo} alt={"How MyFloodRisk Can Help"} />{" "}
									Can Help
								</HowTypo>
							</Paper>
						</Grid>
					</Grid>

					<div className="cards">
						<Row1040
							container
							item
							xs={12}
							direction="row"
							justify="center"
							alignItems="center"
						>
							<RowDropsCard />
						</Row1040>
					</div>

					<HomeMergeToWhite>
						<Row1040>
							<Grid
								container
								className={classes.rootMargin + " GrdCntr1"}
								alignItems="center"
							>
								<Grid item xs={12} className="GrdItm1">
									<img
										className="buckets"
										src={isMobile ? bucketsGraphicMob : bucketsGraphic}
										alt={"Don't wait until it's too late"}
									/>
								</Grid>
								<Grid item xs={12}>
									<Paper className={classes.paper}>
										<Typography variant="h2" className={classes.titleBlue}>
											Don't wait until it's too late
										</Typography>
									</Paper>
								</Grid>
								<Grid
									container
									className={classes.marginTop}
									alignContent="center"
									justify="center"
								>
									<Grid item xs={10} sm={6}>
										<AddressSelectorGoogle gotoMyRisk={true} />
									</Grid>
								</Grid>
							</Grid>
						</Row1040>
					</HomeMergeToWhite>
				</HomeMiddle>
			</HomeWrap>

			<div className={classes.blueGradient}>
				<Row1040>
					<SevenFactsInfographic />
				</Row1040>

				<Row826>
					<div>
						<Grid container>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<BridgeOver className={classes.titleBlue}>
										Your bridge over troubled waters
									</BridgeOver>
								</Paper>
							</Grid>
						</Grid>
					</div>
					<div className={classes.bottomMargin}>
						<Grid container>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Typography className={classes.titleBlack}>
										Floods are among Earth's most common - and most destructive
										- natural hazards.
										<br /> Don't let the misconceptions get to you. We are here.
									</Typography>
								</Paper>
							</Grid>
						</Grid>
					</div>
					<div className={classes.homeBottom + " cards"}>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							className={classes.rowTools}
							alignContent="center"
						>
							<RowToolsCard colorSet={false} />
						</Grid>
					</div>
					<IncLogos miscCSS={"margin: 0px auto 150px;"} />
				</Row826>
			</div>
		</>
	));
};
