import styled from "styled-components";
import { Box } from "@material-ui/core";
import dropBackground from "../../Content/dropLowOpacity.svg";

export const YourOptions: any = styled(Box)`
    margin: 0px auto 30px;
    display: block;
    font-family: "Lato", sans-serif;
    width: 90%;
    @media (min-width: 1024px /* 768px */) {
        position: relative;
        height: 363px;
        width: 906px;
    }
    
    .wrapper, .wrapper .inner, ul {
        @media (min-width: 1024px /* 768px */) {
            height: 380px;
        }
    }

    .wrapper {
        @media (min-width: 1024px /* 768px */) {
            position: absolute;
            z-index: 10;
        }
        .inner {
            width: 100%;
            @media (min-width: 1024px /* 768px */) {
                position: relative;
                width: 380px;
                background-image: url(${dropBackground});
                background-repeat: no-repeat;
                background-size: fill;
            }
            p {
                width: 100%;
                @media (min-width: 1024px /* 768px */) {
                    position: absolute;
                    bottom: 65px;
                    left: 40px;
                    width: 50%;
                }
                font-size: 12px;
                font-weight: normal;
            }
            h2 {
                @media (min-width: 1024px /* 768px */) {
                    position: absolute;
                    top: 190px;
                    left: 0px;
                    width: 270px;
                }                
                font-size: 21px;
                color: #222120;
                letter-spacing: -0.53px;
                margin: 0;
                padding: 0;
                span {
                    @media (min-width: 1024px /* 768px */) {
                        display: block;
                        width: 155px;
                        margin: 0 auto;
                    }
                }
            }
            img {
                @media (min-width: 1024px /* 768px */) {
                    position: absolute;
                    top: 80px;
                    left: 89px;
                }
                width: 93px;
                height: auto;
            }
        }
    }

    ul {
        width: 100%;
        @media (max-width: 1023px /* 767px */) {
            padding: 0;
        }
        @media (min-width: 1024px /* 768px */){
            position: absolute;
            top: 20px;
            right: 0px;
            width: 776px;
        }

        li {
            list-style: none;
            text-align: center;
            font-weight: normal;
            color: #222120;
            @media (max-width: 1023px /* 767px */) {
                width: 100%;
                padding: 10px 0px;
            }
            @media (min-width: 1024px /* 768px */) {
                padding: 5px 0px 5px 130px;
            }
        }
        li:nth-child(odd) {
            background-color: rgba(65,144,202,0.2);
        }
        li:nth-child(even) {
            background-color: rgba(65,144,202,0.05);
        }
        li.heading {
            font-weight: bold;
            background-color: rgba(255,255,255, 0);
            span {
                font-size: 21px;
            }
            span.option {
                font-size: 28px;
                color: #4190ca;
            }
            @media (max-width: 1023px /* 767px */){
                display: none;
            }
        }
        li span {
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.35px;
            width: 15%;
            @media (max-width: 1023px /* 767px */){
                h3, img {
                    display: inline;
                }
                h3 {
                    margin: 0px 10px;
                    font-weight: normal;
                    font-size: 14px;
                }
                img {
                    width: 12px;
                    height: auto;
                }
                width: 48%;
                max-width: 170px;
                text-align: left;
            }
        }
        li span:first-child {
            width: 100%;
            text-align: center;
            font-weight: bold;
            max-width: 100%;
            @media (min-width: 1024px /* 768px */) {
                width: 70%;
                text-align: left;
                font-weight: normal;
                max-width: unset;
            }
        }
        li.white {
            background-color: rgba(255,255,255, 0);
        }
        .comment {
            font-size: 12px;
        }
    }
`;