import { Stores } from "./../Stores";
import { BaseStore } from "./BaseStore";
import { observable, action } from "mobx";

export class GlobalStore extends BaseStore {
    @observable public isTermsChecked: boolean = false;
    @observable public isTermsModalOpen: boolean = false;
    @observable public isPrivacyModalOpen: boolean = false;

    public constructor() {
        super();
    }

    // tslint:disable-next-line: no-empty
    public init(stores: Stores): void {}

    @action
    public SetIsTermsChecked(termsChecked: boolean): void {
        this.isTermsChecked = termsChecked;
    }

    @action
    public SetIsTermsModalOpen(termsModalOpen: boolean): void {
        this.isTermsModalOpen = termsModalOpen;
    }

    @action GetIsTermsChecked(): boolean {
        return this.isTermsChecked;
    }

    @action GetIsTermsModalOpen(): boolean {
        return this.isTermsModalOpen;
    }

    @action
    public SetIsPrivacyModalOpen(privacyModalOpen: boolean): void {
        this.isPrivacyModalOpen = privacyModalOpen;
    }

    @action GetIsPrivacyModalOpen(): boolean {
        return this.isPrivacyModalOpen;
    }
}
