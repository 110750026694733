/* eslint-disable @typescript-eslint/no-empty-interface */
// Libs
import axios from "axios";
import * as History from "history";

// App
// import * as Globals from "../../Globals";
import * as Models from "../../../Models";
import { getCookie } from "../Utils";

// TODO Move these helper types and functions in to thier own files and export for reuse.
interface KeyValuePair<TValue = any> {
	key: string;
	value: TValue;
}

interface KeyValueStringPair extends KeyValuePair<string> {}

interface StringIndexed {
	[key: string]: string;
}

const CleanQueryString: (queryString: string) => string = queryString =>
	queryString[0] === "?" ? queryString.substr(1) : queryString;

const QueryStringToKeyValuePairs: (
	queryString: string
) => KeyValueStringPair[] = queryString =>
	queryString
		.split("&")
		.map(components => components.split("="))
		.map(pair => ({
			key: decodeURIComponent(pair[0]),
			value: decodeURIComponent(pair[1] || "")
		}));

const KeyValuePairsToObject: (
	keyValuePairs: KeyValueStringPair[]
) => StringIndexed = keyValuePairs => {
	const query: StringIndexed = {};

	for (const keyValuePair of keyValuePairs) {
		query[keyValuePair.key] = keyValuePair.value;
	}

	return query;
};

const ParseQueryStringToObject: (
	queryString: string
) => StringIndexed = queryString => {
	queryString = CleanQueryString(queryString);
	const pairs = QueryStringToKeyValuePairs(queryString);
	const queryObject = KeyValuePairsToObject(pairs);

	return queryObject;
};

export const GetInitialState: (
	history: History.History,
	url: string
) => Promise<Models.InitialState> = (history: History.History, url: string) => {
	const location = history.location;
	const queryString = location.search;
	const queryObject = ParseQueryStringToObject(queryString);

	if (queryString != null && queryString !== "") {
		// Add the queryString to the GetInitialStateJson URL
		url += queryString;
		// Remove the queryString from the current browser URL.
		if (queryString.indexOf("?p=") >= 0) {
			const rurl = queryString.split("=")[1];
			history.replace(rurl);
		} else {
			history.replace(location.pathname);
		}
	}

	var jwt = getCookie(".auth");
	const headers = jwt ? { Authorization: "Bearer " + jwt } : {};

	return Promise.resolve(
		axios(url, {
			headers: headers
		})
	).then(({ data }) => {
		// Merge the initialState from the server and the queryString key/values
		const initialState = {
			...data,
			...queryObject
		};

		return initialState;
	});
};
