import "./Home.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";

import { TermsComponent } from "Shoothill.Components/Components/Terms";

import {
	useStyles,
	Methodology,
	PageContainer,
	WhiteGradient,
	FooterLinksSpacing
} from "../Shoothill.Components/Styles/AboutStyling";

export const Terms: React.FC = () => {
	const classes: any = useStyles("about");
	ReactGA.pageview(window.location.pathname + window.location.search);

	var whiteGrad: HTMLElement | null;
	var secondBackground: HTMLElement | null;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function positionSecondBackground() {
		if (whiteGrad && secondBackground) {
			var style = window.getComputedStyle(whiteGrad);
			if (style) {
				secondBackground.style.top = style.height;
			}
		}
	}

	return useObserver(() => (
		<>
			<Helmet>
				<title>Terms Of Use | My Flood Risk</title>
			</Helmet>
			<PageContainer>
				<FooterLinksSpacing>
					<div className={classes.blueGradient}>
						<div className={classes.subHeadingSpacing}>
							<Methodology>
								<h1>AB Risk Corporation's Terms of Use</h1>
								<TermsComponent />
							</Methodology>
						</div>
					</div>
					<WhiteGradient
						ref={(grad: HTMLElement | null) => {
							whiteGrad = grad;
							positionSecondBackground();
						}}
					></WhiteGradient>
				</FooterLinksSpacing>
			</PageContainer>
		</>
	));
};
