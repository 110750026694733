/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import {
    Paper,
    Theme,
    Avatar,
    Typography,
    FormControl,
    InputLabel,
    Input,
    FormControlLabel,
    Checkbox,
    Button,
    TextField,
    Box
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { LoginModel } from "./LoginModel";
import { useValidation } from "../../Shoothill.Core/Utils";
import validator from "validator";

const useStyles: any = makeStyles((theme: Theme) => ({
    main: {
        width: "auto",
        display: "block", // fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3)
    },
    error: {
        color: "red!important"
    }
}));

interface IProps {
    onSubmit: (loginModel: LoginModel) => void;
    isLoading: boolean;
    errorMessage?: string;
}
export const Login: React.FC<IProps> = props => {
    const {
        onSubmit,
        isLoading,
        errorMessage = ""
    } = props;

    const theme: any = useTheme();
    const classes: any = useStyles(theme);
    const [
        formState,
        {
            password,
            email
        }
    ] = useValidation<LoginModel>({
        emailAddress: "",
        password: ""
    } as any);

    const doSubmit: (e: any) => void = (e: any): void => {
        e.preventDefault();
        if (formState.isFormValid()) {
            onSubmit(formState.formData);
        }
    };

    return (
        <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>

            <form className={classes.form} onSubmit={doSubmit}>
                {errorMessage.length > 0 && (
                    <Box pb={1} pt={1}>
                        <Typography
                            component="h6"
                            color="error"
                            variant="caption"
                        >
                            {errorMessage}
                        </Typography>
                    </Box>
                )}
                <TextField
                    label="Email Address"
                    fullWidth
                    autoComplete="email"
                    {...email("emailAddress", {
                        validationRules: [
                            (v: string) =>
                                validator.isEmail(v) ||
                                "Email address is invalid",
                            (v: string) =>
                                (v && v.length > 0) ||
                                "Email address is required"
                        ]
                    })}
                />
                {formState.showErrors("emailAddress", classes.error, 2)}

                {/* EN: Example of a contrik without Textfield */}
                <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        placeholder="Password"
                        autoComplete="current-password"
                        {...password("password", {
                            validationRules: [
                                (v: string) =>
                                    (v && v.length > 0) ||
                                    "Password is required"
                            ]
                        })}
                    />
                    {formState.showErrors("password", classes.error)}
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            value="true"
                            id="rememberMe"
                            name="rememberMe"
                            color="primary"
                        />
                    }
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    {isLoading ? "Please Wait..." : "Sign In"}
                </Button>
            </form>
        </Paper>
    );
};
