export const Client = {
	BlogPost: "/BlogPost/:Id?",
	BlogPostAdmin: "/BlogPostAdmin/:Id?",
	ArticleItemAdmin: "/ArticleItemAdmin/:Id?",
	SetPassword: "/ProUser/SetPasswordComplete/:email/:token1/:token2",
};

export const Server = {
	Api: {
		Account: {
			Login: "./api/account/login",
			Logout: "./api/account/logout",
			ForgotPassword: "./api/account/forgotpasswordemail",
			ForgotPasswordReset: "./api/account/ForgotPasswordReset",
			ResetPassword: "./api/account/resetpassword",
			SetPassword: "./api/account/setpassword",
		},
		AuditSearch: {
			UpdateUserQuote: "./api/auditsearch/updateauditsearchforquote",
			InsertAuditSearch: "./api/NFIData/StartAuditSearch",
		},
		Blog: {
			Upsert: "./api/blogPost/upsert",
		},
		Image: {
			cropAndResize: "./api/image/cropAndResize",
		},
		InitialState: {
			GetInitialStateJson: "/api/initialstate/getinitialstatejson",
		},
		Inquiry: {
			PostContactFormAsync: "./api/Inquiry/PostContactFormAsync",
			PostReferenceLibraryFormAsync:
				"./api/Inquiry/PostReferenceLibraryFormAsync",
		},
		Store: {
			GetAll: "./api/store/getall",
			GetStoreItem: "./api/store/getStore",
			Upsert: "./api/store/upsert",
		},
		ProAccountReportRequest: {
			GetMCReport: "./api/ProAccountReportRequest/GetMCReport",
			GetProAccountReports:
				"./api/ProAccountReportRequest/GetProAccountReports",
			MakePayment: "./api/ProAccountReportRequest/MakePayment",
		},
	},
	Hubs: {
		Data: "./hubs/data",
	},
};
