import { createMuiTheme, Theme } from "@material-ui/core/styles";

export const defaultTheme: Theme = createMuiTheme({
    typography: {
        fontFamily: `"Lato", sans-serif`,
        fontWeightRegular: "bold",
        h1: {
            fontWeight: "bold",
            fontSize: "72px"
        },
        h2: {
            fontWeight: "bold"
        },
        h3: {
            fontWeight: "bold"
        },
        h4: {
            fontWeight: "bold"
        },
        h5: {
            fontSize: "18px",
            fontWeight: "bold"
        },
        h6: {
            fontSize: "14px",
            fontWeight: "bold"
        },
        subtitle1: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: "-0.4px"
        },
        subtitle2: {
            fontSize: "14px",
            fontWeight: 400
        }
    },
    palette: {
        primary: {
            main: "#00002A",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#F2893E",
            contrastText: "#FFFFFF"
        }
    },
    overrides: {
        MuiLink: {
            root: {
                textDecoration: "none",
                color: "rgba(255, 255, 255, 1)"
            }
        },
        MuiTabs: {
            indicator: {
                display: "none"
            }
        },
        MuiTab: {
            root: {
                backgroundColor: "#F1F1F1",
                border: "1px solid rgba(0, 0, 0, 0.08)",
                marginLeft: "1px",
                textTransform: "capitalize",
                fontWeight: "bold",
                borderRadius: "2px 2px 0 0",
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, .1)",
                    color: "black"
                },
                "@media (min-width: 960px)": {
                    width: "57px",
                    minWidth: "57px"
                }
            }
        },
        MuiButton: {
            root: {
                margin: "15px 0 15px 0",
                borderRadius: "0",
                textTransform: "capitalize",
                fontSize: "14px"
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "36px"
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: "10px 6px 10px 6px",
                height: "30px"
            },
            notchedOutline: {
                borderRadius: "0"
            }
        },
        MuiInputLabel: {
            outlined: {
                fontWeight: 500,
                color: "#191919"
            }
        },
        MuiInput: {
            input: {
                fontWeight: "bold"
            }
        }
    }
});