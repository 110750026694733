import * as MobX from "mobx";
import * as React from "react";
import * as ReactRouterDom from "react-router-dom";

export class AppErrorBoundary extends React.Component {
    @MobX.observable private error: any | undefined = undefined;

    public componentDidCatch(error: any) {
        this.setError(error);
    }

    public render() {
        // AJ: ErrorBoundaries don't catch event/promise errors... so no point handling them here...
        // AJ: https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
        if (this.error) {
            return <ReactRouterDom.Redirect to={"/unknownerror"} />;
        }

        return this.props.children;
    }

    @MobX.action private setError = (error: any) => (this.error = error);
}
