import React, { useContext/* , useState, useEffect */ } from "react";
import { Stores, StoresContext } from "../../Stores";
import styled from "styled-components";
import { Box } from "@material-ui/core";

import { percentage } from "../../Shoothill.Core/Utils/Useful";
import useCountUp, {
  useGroupedCountUp
} from "Shoothill.Core/Utils/Hooks/UseCountUp";

export const TotalFloodingBox: any = styled(Box)`
  padding-top: 21px;
  text-align: left;
  letter-spacing: 0;
  color: #292b28;

  h1 {
    font-size: 32px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 18px;
      margin: 20px 0;
    }
  }

  p {
    font-weight: normal;
  }

  .content {
    margin-top: -21px;
  }

  .graphs {
    padding-top: 50px;
    @media (max-width: 1179px) {
      padding-top: 26px;
      padding-bottom: 26px;
    }
    @media (max-width: 767px) {
      padding-top: 6px;
    }
  }

  .group {
    display: inline-flex;
    width: 100%;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .legend {
    width: 170px;
    padding: 30px 0px;
    vertical-align: middle;
    border-right: solid 1px #707070;
    text-align: left;
    font-weight: Bold;
    font-size: 18px;

    @media (max-width: 767px) {
      border-right: none;
      padding: 0;
    }
  }

  #firstGraph {
    padding-bottom: 38px;
  }
`;

export interface IGraphProps {
  stateVal: number;
  countyVal: number;
}

export const GraphBox: any = styled(Box)<IGraphProps>`
  max-width: 896px;
  width: 100%;
  padding: 12px 2px;
  .outofText {
    position: relative;
    height: 0;
    top: 10px;
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    @media (max-width: 767px) {
      width: 95vw;
      right: 12px;
    }
  }
  .barBase {
    background: rgba(120, 120, 120, 0.2) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 66px;
    display: inline-flex;

    .summary {
      font-size: 10px;
      color: #222120;
      text-align: center;
      padding: 6px 20px;
      line-height: 1;
      text-transform: uppercase;
      span {
        font-size: 38px;
        color: #ee00ee;
        font-weight: bold;
      }
    }

    .stateVal,
    .countyVal {
      display: block;
      height: 100%;
      background-color: #4190ca;
    }
    .stateVal {
      width: ${props => props.stateVal}%;
    }
    .countyVal {
      width: ${props => props.countyVal}%;
    }
  }
`;

export const ReportTotalFloodEvents: React.FC = () => {
  const floodDataContext: any = useContext<Stores>(StoresContext).domain
    .FloodAndClaimDataStore;

  let statePerc: number = percentage(floodDataContext.getFloodQtyState, 9116);
  let countyPerc: number = percentage(floodDataContext.getFloodQtyCounty, 311);

  const stateFloodQty = useCountUp({
    end: floodDataContext.getFloodQtyState,
    duration: countyPerc > statePerc ? (2.5 * statePerc) / countyPerc : 2.5,
    easeStart: false
  });

  const countyFloodQty = useCountUp({
    end: floodDataContext.getFloodQtyCounty,
    duration: Math.max(
      1,
      countyPerc <= statePerc ? (2.5 * countyPerc) / statePerc : 2.5
    ),
    easeStart: false
  });

  const [stateFloodBar, countyFloodBar] = useGroupedCountUp({
    end: [statePerc, countyPerc],
    duration: 2.5,
    capDecimals: false
  });

  return (
    <>
      <TotalFloodingBox>
        <div className="content">
          <h1>Total Flooding Events</h1>
          <p>
            The number of major flooding events in your county and your state
            since 1950, as recorded by the National Weather Service (NWS). Only
            flood occurrences having sufficient intensity to cause loss of life,
            injuries, significant property damage, and/or disruption to commerce
            are included.
          </p>

          <div className="graphs">
            <div className="group">
              <div className="legend">{floodDataContext.county}</div>
              <GraphBox countyVal={countyFloodBar} id="firstGraph">
                <div className="barBase">
                  <div className="countyVal"></div>
                  <div className="summary">
                    <span>{countyFloodQty.counter}</span>
                    <br />
                    Floods
                  </div>
                </div>
                <div className="outofText">
                  San Bernardino, CA has highest recorded county level flood
                  events with 311
                </div>
              </GraphBox>
            </div>
            <div className="group">
              <div className="legend">{floodDataContext.state_abbr}</div>
              <GraphBox stateVal={stateFloodBar}>
                <div className="barBase">
                  <div className="stateVal"></div>
                  <div className="summary">
                    <span>{stateFloodQty.counter} </span>
                    <br />
                    Floods
                  </div>
                </div>
                <div className="outofText">
                  Texas has highest recorded state level flood events with 9116
                </div>
              </GraphBox>
            </div>
          </div>
        </div>
      </TotalFloodingBox>
    </>
  );
};
