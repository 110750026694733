import React, { useState, useContext, useEffect } from "react";
import { 
    Box,
    Tab,
    useMediaQuery, 
    Typography,
    Button,
    Grid
} from "@material-ui/core";
import { UserListComp } from "./UserListComp";
import { OrderListComp } from "./OrderListComp";
import { MFRListComp } from "./MFRListComp";

import {
    useAdminStyles,
    TabsCustom
} from "../../Shoothill.Components/Styles/AdminStyles";
import { StoresContext, Stores, DomainStores } from "../../Stores";
import { AuditProOrderModel } from "Models";
import { AuditSearch } from "Models";

import { UseDataApi } from "Shoothill.Core/Utils";
import { isNullorEmpty } from "Shoothill.Core/Utils/Validation";
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface IProps {
    userGuId: string;
    sDate: string;
    eDate: string;
}

export const ReportsComp: React.FC<IProps> = props => {
    const classes = useAdminStyles();
    const adminClasses: any = useAdminStyles();
    const [value, setValue] = React.useState(0);
    const [myrealtorId, setMyRealtorId] = useState<string>(props.userGuId);
    const [startDate, setStartDate] = React.useState<Date | null>(
        !isNullorEmpty(props.sDate)
        ? new Date(props.sDate)
        : new Date(moment.utc().add(-7, "day").format("YYYY-MM-DD"))
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        !isNullorEmpty(props.eDate)
        ? new Date(props.eDate)
        : new Date()
    );

    const AuditProStoresContext: DomainStores["AuditProStores"] = useContext<Stores>(StoresContext).domain.AuditProStores;
    const { data: resultsSet, doRequest: proReportsSetRequest } = UseDataApi();

    useEffect(() => {
        if (AuditProStoresContext.isLoading === false) {
            AuditProStoresContext.ResetOrder();
            AuditProStoresContext.ResetMFR();
            AuditProStoresContext.setAPIIsLoading(true);

            const apiurl: string = "/api/auditprouser/getprouserbothreports";
            proReportsSetRequest(
                AuditProStoresContext.getAPICall(apiurl,
                {
                    guid: myrealtorId,
                    start: formatDate(startDate!),
                    end: formatDate(endDate!, true)
                })
            );
        }

    }, [resultsSet, startDate, endDate]);

    useEffect(() => {
        if (!isNullorEmpty(resultsSet)) {
            AuditProStoresContext.SetProOrders(resultsSet.payload.proAccountReport);
            AuditProStoresContext.SetProMFRorder(resultsSet.payload.auditSearchReport);
        }
    }, [resultsSet]);

    function formatDate(date: Date, isEndDate: boolean = false): string {
        let count: number = 0;
        if (isEndDate) {
            count = 1;
        }
        const utc: moment.Moment = moment(date).add(count, "days").startOf("day").utc();
        return utc.toISOString();
    }

    function handleStartDateChange(date: Date | null): void {
        setStartDate(date);
    }

    function handleEndDateChange(date: Date | null): void {
        setEndDate(date);
    }

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    let useMobile: boolean;
    useMobile = useMediaQuery("screen and (max-width: 767px)")
        ? true
        : false;

    interface TabProPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
    }

    function TabProPanel(props: TabProPanelProps) {
        const { children, value, index, ...other } = props;

        return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
        );
    }

    function a11yProProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    }
/* 
    const userClickValue: (guid: string) => void = (
        thisId: string
    ): void => {
        setMyRealtorId(thisId);
    }; */

    return (
        <>
        <div className={classes.dateRow + " " + classes.tabsProMargin}>
            <Grid container className={classes.dateRowContainer}>
                <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        className={adminClasses.datePickerGridContainer}
                    >
                        <Box textAlign="left" className={adminClasses.datePickerBox}>
                        <Typography
                            id="header"
                            className={`${adminClasses.headingBlue21} ${classes.subTitle}`}
                        >
                            Start date
                        </Typography>
                        <KeyboardDatePicker
                            className={adminClasses.datePicker}
                            margin="normal"
                            id="start-date-picker-dialog"
                            format="MM/dd/yyyy"
                            minDate={new Date("2019-08-01")}
                            maxDate={endDate}
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            "aria-label": "change date"
                            }}
                        />
                        </Box>
                    </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        className={adminClasses.datePickerGridContainer}
                    >
                        <Box textAlign="left" className={adminClasses.datePickerBox}>
                        <Typography
                            id="header"
                            className={`${adminClasses.headingBlue21} ${classes.subTitle}`}
                        >
                            End date
                        </Typography>
                        <KeyboardDatePicker
                            className={adminClasses.datePicker}
                            margin="normal"
                            id="end-date-picker-dialog"
                            format="MM/dd/yyyy"
                            minDate={startDate}
                            maxDate={new Date()}
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            "aria-label": "change date"
                            }}
                        />
                        </Box>
                    </Grid>                    
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4}>

                </Grid>
            </Grid>
        </div>

        <div className={"classes.tabsContainer"}>
            <TabsCustom
                value={value}
                onChange={handleChange}
                aria-label="My Flood Risk Admin"
                className={classes.tabsWidth + " " + classes.tabsProMargin}
                variant={
                    useMobile 
                    ? "scrollable"
                    : "standard"
                }
                scrollButtons="auto"
            >
                <Tab label="MFR Reports" {...a11yProProps(0)} />
                <Tab label="FZD Orders" {...a11yProProps(1)} />
            </TabsCustom>
        </div>

        <TabProPanel 
            value={value} 
            index={0}
        >
            { AuditProStoresContext.getMFR.length > 0 && 
            <>
            <MFRListComp
                userGuId={myrealtorId}
                sDate={startDate!.toISOString()}
                eDate={endDate!.toISOString()}
            />
            </>
            }

            { AuditProStoresContext.getMFR.length === 0 && 
            <>
            <div className={classes.tabsProMargin}>
                No My Flood Risk Reports requested for this realtor.
            </div>
            </>
            }

        </TabProPanel>
        <TabProPanel 
            value={value} 
            index={1}
        >
            { AuditProStoresContext.getFZD.length > 0 &&
            <>
            <OrderListComp
                userGuId={myrealtorId}
                sDate={startDate!.toISOString()}
                eDate={endDate!.toISOString()}
            />
            </>
            }

            { AuditProStoresContext.getFZD.length === 0 &&
            <>
            <div className={classes.tabsProMargin}>
                No Flood Zone Determinations Reports ordered by this Pro Account.
            </div>
            </>
            }

        </TabProPanel>
        </>
    );
}