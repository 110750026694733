import { AuditSearchStore } from "./Domain/AuditSearchStore";
import { AuditWidgetStore } from "./Domain/AuditWidgetStore";
import { AccountStore } from "./Domain/AccountStores";
import { BlogPostStore } from "./Domain/BlogPostStore";
import { CategoryStore } from "./Domain/CategoryStore";
import { DatasetWeightsStore } from "./Domain/DatasetWeightsStore";
import { FloodAndClaimDataStore } from "./Domain/FloodAndClaimDataStore";
import { FloodClaimsDataStore } from "./Domain/FloodClaimsDataStore";
import { FloodDataStore } from "./Domain/FloodDataStore";
import { GlobalStore } from "./Domain/GlobalStore";
import { HurricaneDataStore } from "./Domain/HurricaneDataStore";
import { HurricaneSummaryStore } from "./Domain/HurricaneSummaryStore";
import { MapStore } from "./Domain/MapStore";
import { RegionCostStore } from "./Domain/RegionCostStore";
import { StatesDisasterAssistanceStore } from "./Domain/StatesDisasterAssistanceStore";
import { AuditProStores } from "./Domain/AuditProStores";
import { UserStore } from "./Domain/UserStore";
import { Stores } from "../Stores";
import * as Models from "../Models";
import { QuoteStore } from "./Domain/QuoteStore";

export class DomainStores {
	public AccountStore: AccountStore = new AccountStore();
	public AuditSearchStore: AuditSearchStore = new AuditSearchStore();
	public AuditWidgetStore: AuditWidgetStore = new AuditWidgetStore();
	public AuditProStores: AuditProStores = new AuditProStores();
	public BlogPostStore: BlogPostStore = new BlogPostStore();
	public CategoryStore: CategoryStore = new CategoryStore();
	public DatasetWeightsStore: DatasetWeightsStore = new DatasetWeightsStore();
	public FloodAndClaimDataStore: FloodAndClaimDataStore = new FloodAndClaimDataStore();
	//public FloodClaimsDataStore: FloodClaimsDataStore = new FloodClaimsDataStore();
	//public FloodDataStore: FloodDataStore = new FloodDataStore();
	public GlobalStore: GlobalStore = new GlobalStore();
	public HurricaneDataStore: HurricaneDataStore = new HurricaneDataStore();
	public HurricaneSummaryStore: HurricaneSummaryStore = new HurricaneSummaryStore();
	public MapStore: MapStore = new MapStore();
	public RegionCostStore: RegionCostStore = new RegionCostStore();
	//public StatesDisasterAssistanceStore: StatesDisasterAssistanceStore = new StatesDisasterAssistanceStore();
	public UserStore: UserStore = new UserStore();
	public QuoteStore: QuoteStore = new QuoteStore();

	public init(stores: Stores, initialState: Models.InitialState): void {
		this.AccountStore.init(stores, initialState);
		this.BlogPostStore.init(stores);
		this.CategoryStore.init(stores);
		this.MapStore.init(stores);
		this.RegionCostStore.init(stores);
	}

	public populate(stores: any) {
		//this.AccountStore.populate(stores.AccountStore);
		//this.DatasetWeightsStore.populate(stores.DatasetWeightsStore);
		this.FloodAndClaimDataStore.populate(stores.FloodAndClaimDataStore);
		//this.FloodClaimsDataStore.populate(stores.FloodClaimsDataStore);
		//this.FloodDataStore.populate(stores.FloodDataStore);
		//this.GlobalStore.populate(stores.GlobalStore);
		this.HurricaneDataStore.populate(stores.HurricaneDataStore);
		this.HurricaneSummaryStore.populate(stores.HurricaneSummaryStore);
		this.MapStore.populate(stores.MapStore);
		//this.RegionCostStore.populate(stores.RegionCostStore);
		//this.StatesDisasterAssistanceStore.populate(
		//	stores.StatesDisasterAssistanceStore
		//);
		this.UserStore.populate(stores.UserStore);
	}
}
