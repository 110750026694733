import "./Home.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";

import {
    useStyles,
    Methodology,
    PageContainer,
    WhiteGradient,
    FooterLinksSpacing
} from "../Shoothill.Components/Styles/AboutStyling";

export const Privacy: React.FC = () => {
    const classes: any = useStyles("about");
    ReactGA.pageview(window.location.pathname + window.location.search);

    var whiteGrad: HTMLElement | null;
    var secondBackground: HTMLElement | null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function positionSecondBackground() {
        if (whiteGrad && secondBackground) {
            var style = window.getComputedStyle(whiteGrad);
            if (style) {
                secondBackground.style.top = style.height;
            }
        }
    }

    return useObserver(() => (
        <>
      <Helmet>
        <title>Privacy Policy | My Flood Risk</title>
      </Helmet>
            <PageContainer>
                <FooterLinksSpacing>
                    <div className={classes.blueGradient}>
                        <div className={classes.subHeadingSpacing}>
                            <Methodology>
                                <h1>AB Risk Corporation's Privacy Policy</h1>
                                <p style={{ alignContent: "center"}}>MyFloodRisk.org Privacy Policy</p>
                                <p>AB Risk Corporation (&ldquo;ABR&rdquo; or &ldquo;We&rdquo;) respects your privacy and is committed to protecting it through our compliance with this Privacy Policy.&nbsp;</p>
                                <p>Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it.&nbsp; By accessing <a href="https://www.myfloodrisk.org/">https://www.myfloodrisk.org/</a> (the &ldquo;Site&rdquo;), you agree to this Privacy Policy.&nbsp; If you do not agree with our policies and practices, your choice is not to use the Site.&nbsp; This Privacy Policy may change from time to time.&nbsp; Your continued use of this Site after we make changes is deemed to be an acceptance of those changes, so please check this Privacy Policy periodically for updates.&nbsp; If we make a material change about how we treat our members&rsquo; Personal Information we will notify you at the email address specified in your account.</p>
                                <p>This Privacy Policy describes the types of information we may collect from you or that you may provide when you access the Site and our practices for collecting, using, maintaining, protecting, and disclosing that information.&nbsp;</p>
                                <p>This Privacy Policy applies to the information we collect on the Site, in email, text, or other electronic messages between you and the Site.&nbsp; This Privacy Policy does not apply to any services which do not display or link this Privacy Policy or that have their own privacy policy statements.&nbsp;&nbsp; Additionally, this Privacy Policy does not apply to any external websites which may be linked to the Site.&nbsp;</p>
                                <p style={{ alignContent: "center" }}>Information We Collect About You and How We Collect It</p>
                                <p>As you use the Site, we may collect two types of information from you: (1) &ldquo;personally identifiable information&rdquo; (as described below); and (2) &ldquo;non-personally identifiable information&rdquo; (as described below).</p>
                                <p>&ldquo;Personal Information&rdquo; or &ldquo;Personally identifiable information&rdquo; is information that identifies you personally, such as your name, address, telephone number, email address, company name, or other identifying information.&nbsp;</p>
                                <p>Here are some examples of the ways in which we may collect and store your personally identifiable information through the Site:</p>
                                <ul>
                                    <li>We may collect your first and last name, email address, or other information if you fill out certain forms or online requests on the Site;</li>
                                    <li>We may collect your email address if you contact us with a question; or</li>
                                    <li>We may collect your first and last name, email address, and account number if you make a payment on the website.&nbsp;</li>
                                </ul>
                                <p>&ldquo;Non-personally identifiable information&rdquo; can be technical information or it can be demographic information, such as your age, name, gender, or interests.&nbsp; Non-personally identifiable information may also mean aggregated, non-identifiable, or anonymized information.&nbsp; Non-personally identifiable information does not identify you personally.&nbsp; If you do provide us with non-personally identifiable information, we may use it for the purposes described in this Privacy Policy or any other legal purpose.</p>
                                <p>Here are some examples of ways through which we may collect and store your non-personally identifiable information through the Site, and how we use such information:</p>
                                <ul>
                                    <li>Log Information: When you use the Site or view content provided through the Site, we automatically collect and store certain information in our server logs.&nbsp; This type of information includes details of how you use the Site, IP address information described below, web pages which have been viewed by you, date and time, domain type, device event information such as crashes, system activity, hardware, settings, browser type, browser language, the date and time of your requests, and referral url.&nbsp;</li>
                                    <li>Internet Protocol (IP) Address: Your &ldquo;IP address&rdquo; is a number that lets computers attached to the internet know where to send you data, such as screens and pages of our services that you view.&nbsp; We use this information to deliver our screens and pages to you upon request, to tailor our services to the interests of you and our other visitors, and to measure traffic to and within our services.&nbsp;</li>
                                    <li>Demographic Information: &ldquo;Demographic Information&rdquo; may be gender, age, zip code, and interests.&nbsp; We may collect such information about you through our services and use it to provide you with personalized services and to analyze trends to ensure that our services and the information on them is targeted to meet your needs.&nbsp; Please note that we also consider aggregated information, which is not personally identifiable, to be non-personally identifiable information.&nbsp;</li>
                                    <li>Location Information: When you use the Site, we may collect and process information about your actual location.&nbsp; We use various technologies to determine location, including IP address, global positioning systems, and other sensors that may provide ABR with information about nearby devices and WiFi access points.&nbsp;</li>
                                    <li>Usage Information: When you use the Site, we may collect and process information about how you use the Site, including how you move through the Site.&nbsp;</li>
                                    <li>Feedback Information: From time to time, we may request that you provide us with feedback regarding the Site, as well as the products and services we offer.&nbsp; We may do this in the form of a survey or other feedback mechanism.&nbsp; We may collect and analyze this information.&nbsp;</li>
                                </ul>
                                <p>The technologies we use for this automatic data collection may include:</p>
                                <ul>
                                    <li>Cookies (or browser cookies): A cookie is a small file placed on the hard drive of your computer.&nbsp; You may refuse to accept browser cookies by activating the appropriate setting on your browser.&nbsp; However, if you select this setting, you may be unable to access certain parts of the Site.&nbsp; Unless you have adjusted your browser setting so that it will refuse our cookies, our system will use cookies when you direct your browser to the Site.&nbsp;</li>
                                    <li>Web Beacons: Pages of the Site and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit our company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
                                </ul>
                                <p>If you encounter a screen or page that requests information you do not want to share with us, do not enter the information and do not proceed with that screen or page.&nbsp;</p>
                                <p style={{ alignContent: "center" }}>How We Use Your Information</p>
                                <p>Personally Identifiable Information</p>
                                <p>If you provide us with personally identifiable information, we will only use it for the purposes described in this Privacy Policy.&nbsp; Primarily, we use it to fulfill our business interests as set forth below, or for other purposes based on your consent, which in either case may include:</p>
                                <ul>
                                    <li>Offer you the products and services you request;</li>
                                    <li>Help us create or offer content which is relevant to you;</li>
                                    <li>Allow you to access certain areas of the Site;</li>
                                    <li>Register you as a member and develop your profile to enable you to take advantage of the personalized features of the Site;</li>
                                    <li>Alert you to special offers, updated information, and other new services offered by us or by third parties;</li>
                                    <li>Forward promotional materials and to fulfill the terms of any promotion;</li>
                                    <li>To improve your user experience and the experience of other users of the Site, including through the improvement and implementation of new security measures and protections;</li>
                                    <li>To understand how you use the Site; or</li>
                                    <li>Contact you in response to an email or other communication.</li>
                                </ul>
                                <p>Non-Personally Identifiable Information</p>
                                <p>We may use non-personally identifiable information for the purposes described in this Privacy Policy, or any other legal purpose, including, when and where applicable, combining non-personally identifiable information with personally identifiable information.&nbsp; Without limiting the above, we may collect information to understand how you use the Site and how we can improve the Site&rsquo;s functionality and privacy and security measures.</p>
                                <p>Reports</p>
                                <p>We periodically prepare analyses and reports reflecting our visitor and member use of the Site and other services.&nbsp; In preparing these reports, we may combine and analyze the Personal Information you provide to us with information from other sources.&nbsp; However, these reports will only include aggregate information about visitors and members.&nbsp; The information in these reports will not identify you individually and any business partner with whom such reports may be shared will not be able to contact you based on the information contained in the reports.&nbsp;</p>
                                <p style={{ alignContent: "center" }}>Disclosures of Your Information</p>
                                <p>We may disclose information we obtain about you in compliance with this Privacy Policy.&nbsp;</p>
                                <p>Personally Identifiable Information</p>
                                <p>We may share or disclose your personally identifiable information in the following instances:</p>
                                <ul>
                                    <li>To fulfill a service to you (e.g.&nbsp; advertising and marketing to you through the Site);</li>
                                    <li>To send you information that you have subscribed to receive;</li>
                                    <li>To offer you products and service from our affiliates, strategic partners, or agents, or to assist such parties for research, administrative, and/or business purposes;</li>
                                    <li>To unaffiliated third-party service providers, agents, or independent contractors who help us maintain our products and services;</li>
                                    <li>To comply with law or, if in good faith we believe that such action is necessary to conform to the requirements of law, or comply with legal process served on us, and to protect and defend our rights or property, or act in urgent circumstances to protect the personal safety of you and our other visitors;</li>
                                    <li>To third parties as part of a corporate reorganization process including, but not limited to, a merger, acquisition, or sale of all or substantially all of our assets;</li>
                                    <li>To track and analyze non-identifying, aggregate usage and volume information from our visitors or members and provide such information to third parties;</li>
                                    <li>To protect against fraud or potential fraud.&nbsp;</li>
                                </ul>
                                <p>Non-Personally Identifiable Information</p>
                                <p>We may share or disclose your non-personally identifiable information for the purposes disclosed in this Privacy Policy or for any other legal purpose, including when and where applicable, sharing and disclosing non-personally identifiable information combined with personally identifiable information.&nbsp;</p>
                                <p>Legal Disclosure</p>
                                <p>We may disclose and share information about you and your use of the Site if we believe such disclosure is necessary to:</p>
                                <ul>
                                    <li>Comply with the law and/or legal process where a formal request has been made;</li>
                                    <li>Protect or defend our rights and/or property and property of others;</li>
                                    <li>Enforce our Terms of Use and/or this Privacy Policy</li>
                                    <li>Respond to claims that the content(s) of a communication violates the rights of another.</li>
                                </ul>
                                <p style={{ alignContent: "center" }}>Choices About How We Use and Disclose Your Information</p>
                                <p>We strive to provide you with choices regarding the Personal Information you provide to us.&nbsp; We have created mechanisms to provide you with the following control over your information:</p>
                                <ul>
                                    <li>Tracking Technologies and Advertising.&nbsp; You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent.&nbsp; If you disable or refuse cookies, please note that some parts of the Site may then be inaccessible or not function properly.</li>
                                    <li>Promotional Offers from us or our nonaffiliated partners.&nbsp; If you do not wish to have your contact information used to promote our products or services, you can opt-out by sending us an email stating your request to infot@myfloodrisk.org.&nbsp; If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions.&nbsp;</li>
                                </ul>
                                <p style={{ alignContent: "center" }}>Accessing, Correcting, and Deleting Your Information</p>
                                <p>You may choose not to receive marketing email communications from us by clicking on the &ldquo;unsubscribe&rdquo; link in our marketing emails.&nbsp; You also may ask us not to send you other marketing communications by contacting us as specified in the &ldquo;Contact Us&rdquo; section below, and we will honor your request.</p>
                                <p>We aim to maintain our services in a manner that protects information from accidental or malicious destruction.&nbsp; Because of this, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems.</p>
                                <p>Subject to applicable law, you may obtain a copy of Personal Information we maintain about you or request that we delete Personal Information we maintain about you by contacting us as indicated below.&nbsp; To help protect your privacy and maintain security, we will take steps to verify your identity before granting you access to the information.&nbsp; In addition, if you believe that Personal Information we maintain about you is inaccurate, subject to applicable law, you may have the right to request that we correct or amend the information by contacting us as indicated below.&nbsp;</p>
                                <p style={{ alignContent: "center" }}>Objection to Processing</p>
                                <p>Under applicable law, you may have the right at any time to object to our use of your Personal Information for any direct marketing purposes, including profiling to the extent it is used for direct marketing.&nbsp; If we are processing your Personal Information based on our business interests, you may contact us and object to such processing by asserting that our interests do not override your interests, rights, and freedoms.&nbsp; You may exercise the rights set out in this paragraph by contacting us as set forth in the &ldquo;Contact Us&rdquo; section below.</p>
                                <p style={{ alignContent: "center" }}>Suspension of Processing</p>
                                <p>Subject to applicable law, you may have the right to request that we restrict the processing of your Personal Information, if:</p>
                                <ul>
                                    <li>you believe that the Personal Information we maintain about you is inaccurate, and you have asked us to verify the accuracy of such information as provided above;</li>
                                    <li>you believe that your Personal Information has been unlawfully processed and you want us to restrict processing rather than erase your information;</li>
                                    <li>we no longer need your Personal Information, but you need us to retain it in order to establish, exercise or defend a legal claim; or</li>
                                    <li>you have objected to our processing of your Personal Information, as permitted under applicable law,, and we are considering the grounds of your objection.</li>
                                </ul>
                                <p style={{ alignContent: "center" }}>Data Security</p>
                                <p>We have implemented measures to secure your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure.&nbsp; Before we receive and store information, we make reasonable efforts to ensure the security of our systems.&nbsp; We use firewalls to protect your information from unauthorized access, disclosure, alteration, or destruction.&nbsp; However, this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of such firewalls or secure server software.</p>
                                <p>The safety and security of your information also depends on you.&nbsp; Where you have chosen a password to access the Site, you are responsible for keeping this password confidential.&nbsp; We ask that you not share this password with anyone.&nbsp;</p>
                                <p>Unfortunately, the transmission of information via the internet is not completely secure.&nbsp; Although we do our best to protect your Personal Information, we cannot guarantee the security of your Personal Information transmitted via our Site.&nbsp; Any transmission of Personal Information is done at your own risk.&nbsp; We are not responsible for circumvention of any privacy settings or security measures contained on the Site.&nbsp;</p>
                                <p style={{ alignContent: "center" }}>Retention and Deletion</p>
                                <p>Personally identifiable information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.&nbsp; We will retain your personally identifiable information for so long as you are a registered user of the Site and/or are otherwise using the Site and for a minimum period of thirty (30) days following the date that you cease to be a registered user of the Site or otherwise cease using the Site (whichever is longer), and for a maximum period of sixty (60) months following the date that you cease to be a registered user of the Site or otherwise cease using the Site (whichever is longer).&nbsp; In some cases it is not possible for us to specify in advance the periods for which your personally identifiable information will be retained. In such cases, we will determine the period of retention based on the following criteria: the period of retention of will be determined based on the nature of the inquiry information included within the contact form submission, or lack thereof.&nbsp; Notwithstanding the other provisions of this section, we may retain your personally identifiable information where such retention is necessary for compliance with a legal obligation to which we are subject, or to protect your vital interests or the vital interests of another natural person.</p>
                                <p style={{ alignContent: "center" }}>Children Under the Age of 16</p>
                                <p>Our Site is not intended for children under 16 years of age.&nbsp; No one under age 16 may provide any information to or on the Site.&nbsp; We do not knowingly collect Personal Information from children under 16.&nbsp; If you are under 16, do not use or provide any information on our Site or on or through any of its features/register on the Site, use any of the interactive or public comment features of this Site or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use.&nbsp; If we learn we have collected or received Personal Information from a child under 16 years of age without verification of parental consent, we will delete the information.&nbsp; If you believe we might have any information from or about a child under age 16, please contact us at info@myfloodrisk.org.</p>
                                <p style={{ alignContent: "center" }}>Third Party Links</p>
                                <p>The Site may contain links to webpages operated by parties other than ABR.&nbsp; We do not control such websites and are not responsible for their contents or the privacy policies or other practices of such websites.&nbsp; Our inclusion of links to such websites does not imply any endorsement of the material on such websites or any association with their operators.&nbsp; Further, it is up to the User to take precautions to ensure that whatever links the User selects or software the User downloads (whether by using the Site to access websites or otherwise) is free of such items as viruses, worms, trojan horses, defects and other items of a destructive nature.&nbsp;&nbsp; Other websites and services may have their own privacy policies, which the User will be subject to upon linking to the other third party&rsquo;s website.&nbsp; ABR strongly recommends that each User review the terms of use and privacy policies of other third parties and their websites.</p>
                                <p style={{ alignContent: "center" }}>Do Not Track (DNT) Signals</p>
                                <p>Our Site does not respond to Do Not Track (DNT) signals.&nbsp; Some third party websites do keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you.&nbsp; If you are visiting such sites, your browser may include controls to block and delete cookies, web beacons and similar technologies, to allow you to opt out of data collection through those technologies.</p>
                                <p style={{ alignContent: "center" }}>Compliance and Cooperation with Regulatory Authorities</p>
                                <p>We regularly review our compliance with our Privacy Policy.&nbsp; When we receive formal written complaints, we will contact the person who made the complaint to follow up.&nbsp; We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data if we cannot resolve the matter with our customers directly.&nbsp; Subject to applicable law, you also have a right to communicate directly with appropriate regulatory authorities if you believe we are in violation of your rights under applicable law.</p>
                                <p style={{ alignContent: "center" }}>Transfers</p>
                                <p>Your Personal Information may be transferred to countries outside of the country in which we collect it, including to the United States.&nbsp; If we transfer your information to a country outside of the country in which we collect it, we will comply with applicable law, including any requirements related to ensuring that data is transferred with appropriate safeguards.</p>
                                <p style={{ alignContent: "center" }}>Changes to this Policy</p>
                                <p>From time to time, we may make changes to this Privacy Policy in order to accommodate new technologies, platforms, industry practices, regulatory requirements, new developments or for other purposes.&nbsp; We encourage you to review this Privacy Policy periodically to ensure that you understand how we collect, use, and share information through the Site.&nbsp; If we do make changes to this Privacy Policy, we will notify you as required under applicable law, and we will also update the &ldquo;Effective Date&rdquo; posted at the top of this Privacy Policy.&nbsp;</p>
                                <p>Any changes to this Privacy Policy will become effective when the revised Privacy Policy is available on the Site.&nbsp; By continuing to use the Site following such changes, you are agreeing to accept the terms of the revised Privacy Policy.&nbsp;</p>
                                <p style={{ alignContent: "center" }}>Contact Information</p>
                                <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at: info@myfloodrisk.org.</p>
                                <p style={{ alignContent: "center" }}>____________________________________________________________________________________</p>
                                <p style={{ alignContent: "center" }}>&nbsp;</p>
                                <p style={{ alignContent: "center" }}>Privacy Notice for California Residents</p>
                                <p>Effective Date: September 17, 2019</p>
                                <p>This Privacy Notice for California Residents supplements the information contained in the MyFloodRisk.org Privacy Policy above and applies solely to all visitors, users, and others who reside in the State of California (&rdquo;consumers&rdquo; or &ldquo;you&rdquo;). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;) and any terms defined in the CCPA have the same meaning when used in this notice.</p>
                                <p>Information We Collect</p>
                                <p>Our Website collects information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (&rdquo;personal information&rdquo;). In particular, our website intends to collect and/or has collected the following categories of personal information from its consumers within the last twelve (12) months:</p>
                                <p>Category A: Identifiers</p>
                                <p>Collected: Real name, IP address, and email address.</p>
                                <p>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).</p>
                                <p>Collected: Real name and address.</p>
                                <p>Category C: Protected classification characteristics under California or federal law.</p>
                                <p>Collected: None.</p>
                                <p>Personal information does not include:</p>
                                <ul>
                                    <li>Publicly available information from government records.</li>
                                    <li>De-identified or aggregated consumer information.</li>
                                    <li>Information excluded from the CCPA&rsquo;s scope, like:</li>
                                    <li style={{ listStyle: "none" }}>
                                        <ul style={{ listStyleType: "circle" }}>
                                            <li>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul>
                                    <li style={{ listStyle: "none" }}>
                                        <ul style={{ listStyleType: "circle" }}>
                                            <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver&rsquo;s Privacy Protection Act of 1994.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>We obtains the categories of personal information listed above from the following categories of sources:</p>
                                <ul>
                                    <li>Directly from you. For example, from forms you complete on our website.</li>
                                    <li>Indirectly from you. For example, from observing your actions on our website or interactions with our advertisers.</li>
                                </ul>
                                <p>Use of Personal Information</p>
                                <p>We may use, or disclose the personal information we collect for one or more of the following business purposes:</p>
                                <ul>
                                    <li>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product orders or process returns.</li>
                                    <li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
                                    <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                                    <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
                                    <li>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</li>
                                </ul>
                                <p>Sharing Personal Information</p>
                                <p>We may disclose your personal information to a third party for a business purpose [or sell your personal information, subject to your right to opt-out of those sales (see Personal Information Sales Opt-Out and Opt-In Rights)]. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. The CCPA prohibits third parties who purchase the personal information we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of further sales.</p>
                                <p>Disclosures of Personal Information for a Business Purpose</p>
                                <p>In the preceding twelve (12) months, Company has not disclosed personal information for a business purpose.</p>
                                <p>Sales of Personal Information</p>
                                <p>In the preceding twelve (12) months, Company had not sold personal information.</p>
                                <p>Your Rights and Choices</p>
                                <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
                                <p>Access to Specific Information and Data Portability Rights</p>
                                <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
                                <ul>
                                    <li>The categories of personal information we collected about you.</li>
                                    <li>The categories of sources for the personal information we collected about you.</li>
                                    <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                                    <li>The categories of third parties with whom we share that personal information.</li>
                                    <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
                                    <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
                                    <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
                                    <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                                </ul>
                                <p>Deletion Request Rights</p>
                                <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
                                <p>We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:</p>
                                <ul>
                                    <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
                                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                                    <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                                    <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
                                    <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546 et. seq.).</li>
                                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information&rsquo;s deletion may likely render impossible or seriously impair the research&rsquo;s achievement, if you previously provided informed consent.</li>
                                    <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                                    <li>Comply with a legal obligation.</li>
                                    <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
                                </ul>
                                <p>Exercising Access, Data Portability, and Deletion Rights</p>
                                <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by sending us a message on our website. Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
                                <p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
                                <ul>
                                    <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
                                    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                                </ul>
                                <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. We will only use personal information provided in a verifiable consumer request to verify the requestor&rsquo;s identity or authority to make the request.</p>
                                <p>Response Timing and Format</p>
                                <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request&rsquo;s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance, specifically by electronic mail communication.</p>
                                <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
                                <p>Personal Information Sales Opt-Out and Opt-In Rights</p>
                                <p>If you are 16 years of age or older, you have the right to direct us to not sell your personal information at any time (the &ldquo;right to opt-out&rdquo;). We do not sell the personal information of consumers we actually know are less than 16 years of age, unless we receive affirmative authorization (the &ldquo;right to opt-in&rdquo;) from either the consumer who is between 13 and 16 years of age, or the parent or guardian of a consumer less than 13 years of age. Consumers who opt-in to personal information sales may opt-out of future sales at any time. To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by visiting the following our webpage and sending us a message.</p>
                                <p>Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back in to personal information sales at any time by visiting our website and sending us a message. We will only use personal information provided in an opt-out request to review and comply with the request.</p>
                                <p>Non-Discrimination</p>
                                <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                                <ul>
                                    <li>Deny you goods or services.</li>
                                    <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
                                    <li>Provide you a different level or quality of goods or services.</li>
                                    <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                                </ul>
                                <p>However, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information&rsquo;s value and contain written terms that describe the program&rsquo;s material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.</p>
                                <p>California&rsquo;s &ldquo;Shine the Light&rdquo; law (Civil Code Section &sect; 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send us an electronic message through our website or write us at our address listed on our webpage.</p>
                                <p>Changes to Our Privacy Notice</p>
                                <p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will post the updated notice on the Website and update the notice&rsquo;s effective date. Your continued use of our Website following the posting of changes constitutes your acceptance of such changes.</p>
                                <p>Contact Information</p>
                                <p>If you have any questions or comments about this notice, the ways in which we collect and uses your information described below and in the Privacy Policy, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us as follows</p>
                                <ul>
                                    <li>Via our website: please send us an electronic message through our website</li>
                                    <li>Write us at our address listed on our webpage.</li>
                                </ul>
                            </Methodology>
                        </div>
                    </div>
                    <WhiteGradient
                        ref={(grad: HTMLElement | null) => {
                            whiteGrad = grad;
                            positionSecondBackground();
                        }}
                    ></WhiteGradient>
                </FooterLinksSpacing>
            </PageContainer>
        </>
    ));
};
