import styled from "styled-components";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box } from "@material-ui/core";
import {
  PromptBox,
  BackGroundBase,
  Container1040,
  GridLarge,
  GridSmall
} from "./Styles";

export const useStyles: any = makeStyles(theme => ({
  blueGradient: {
    backgroundImage:
      "linear-gradient(0deg, rgba(65,144,202,1) 2%, rgba(255,255,255,0.05) 44%)",
    padding: "0 15% 197px"
  },
  rowMissionCardContainer: {
    flexGrow: 1,
    paddingTop: "69px",
    marginBottom: "30px",
    display: "flex",
    "@media (max-width: 414px)": {
      flexDirection: "column",
      paddingTop: "12px"
    }
  },
  impactMargin: {
    flexGrow: 1,
    padding: "30px 15% 0",
    marginBottom: "177px",
    "@media (max-width: 414px)": {
      marginBottom: "61px",
      paddingLeft: "16px",
      paddingRight: "16px"
    }
  },
  aboutBottom: {
    flexGrow: 1,
    paddingTop: "30px",
    textAlign: "center"
  },

  aboutMainHeading: {
    color: "#4190CA",
    fontSize: "72px",
    fontWeight: "bold",
    lineHeight: "80px",
    paddingTop: "0px",
    "@media (max-width: 414px)": {
      fontSize: "32px"
    }
  },
  aboutMainSubHeading: {
    color: "#FFFFFF",
    "@media (max-width: 414px)": {
      fontSize: "12px",
      paddingTop: "6px"
    }
  },
  aboutSecondaryHeading: {
    color: "#4190CA",
    fontSize: "52px",
    fontWeight: "bold",
    marginBottom: "20px",
    "@media (max-width: 414px)": {
      fontSize: "32px",
      marginBottom: "11px"
    }
  },
  aboutSecondarySubHeading: {
    color: "#4190CA",
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "18px",
    "@media (max-width: 414px)": {
      fontSize: "16px",
      marginBottom: "16px"
    }
  },
  aboutMethodologySecondaryHeading: {
    marginBottom: "52px",
    "@media (max-width: 414px)": {
      marginBottom: "21px"
    }
  },
  aboutMethodologyParagraph: {
    fontSize: "21px",
    fontWeight: "normal",
    lineHeight: "1.2",
    marginBottom: "24px",
    "@media (max-width: 414px)": {
      fontSize: "12px",
      marginBottom: "16px"
    }
  },
  aboutMethodologyParagraphBold: {
    fontWeight: "bold"
  },

  aboutHeadingWhite: {
    color: "#ffffff",
    fontSize: "72px",
    fontWeight: "bold"
  },
  headingBlue: {
    color: "#4190CA",
    fontSize: "52px",
    fontWeight: "bold",
    "@media (max-width: 414px)": {
      fontSize: "32px"
    }
  },
  headingBlue34: {
    color: "#4190CA",
    fontSize: "34px",
    fontWeight: "bold"
  },
  subTitleWhite: {
    color: "#FFFFFF",
    fontSize: "18px"
  },
  colorWhite: {
    color: "#FFFFFF"
  },
  paper: {
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "transparent",
    "@media (max-width: 414px)": {
      marginLeft: "16px",
      marginRight: "16px"
    }
  },
  paperLeft: {
    textAlign: "left",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperRight: {
    textAlign: "right",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  text21: {
    fontSize: "21px",
    color: "#222120",
    fontWeight: 400,
    lineHeight: "1.2",
    "@media (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  rowTools: {
    paddingBottom: "151px",
    subTitleWhite: {
      fontWeight: 100
    }
  },
  subHeadingSpacing: {
    marginTop: "15px",
    "@media (max-width: 414px)": {
      marginBottom: "25px"
    }
  },
  waterIsPowerfulContainer: {
    flexDirection: "column",
    "@media (max-width: 414px)": {
      paddingLeft: "16px",
      paddingRight: "16px"
    }
  },
  waterIsPowerfulParagraph: {
    marginBottom: "55px",
    "@media (max-width: 414px)": {
      marginBottom: "25px"
    }
  }
}));

export const ImageBox: any = styled(PromptBox)``;

export const WhiteGradient: any = styled.div`
  position: absolute;
  top: 0;
  display: inline-block;
  z-index: -1;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: inline-block;
    background: linear-gradient(
      rgba(237, 237, 236, 0) 54%,
      rgba(237, 237, 236, 1) 99%
    );
  }

  @media (max-width: 1540px) {
    left: calc(100vw - 1540px);
  }

  @media (max-width: 767px) and (orientation: portrait) {
    top: -330px;
    left: calc(100vw - 200vh);
  }
`;

export const FooterLinksSpacing: any = styled.div`
  @media (max-width: 767px) {
    padding-top: 70px;
  }
`;

export const TopStormBackground: any = styled.img`
  z-index: -2;
  min-width: 100vw;
  min-height: 103vh;

  @media (max-width: 767px) and (orientation: portrait) {
    min-height: unset;
    height: 157vh;
  }
`;

export const MiddleStormBackground: any = styled.img`
  width: 100vw;
  position: absolute;
  z-index: -1;

  @media (max-width: 767px) {
    width: 278vw;
    left: -130%;
  }
`;

export const WhiteBackground: any = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
  background: white;
  top: 0;
`;

export const PageContainer: any = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const SecondBackground: any = styled(BackGroundBase)`
  min-height: 0;
`;

export const ThirdBackground: any = styled(BackGroundBase)`
  min-height: 0;
`;

export const GridContainer: any = styled(Grid)`
  margin: 14px 0 41px 0;
  @media (max-width: 414px) {
    margin: 29px 0;
  }
`;

export const AppHeading: any = styled(Box)`
  height: calc(100vh - 70px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 6%;
  @media (max-width: 767px) and (orientation: portrait) {
    height: 100vh;
  }
  @media (orientation: landscape) {
    min-height: 850px;
  }
`;

export const Row826: any = styled(GridSmall)`
  margin: 0 auto;
  padding: 0;
`;

export const Row1040: any = styled(GridLarge)`
  margin: 0 auto;
  padding: 0;
`;

export const Methodology: any = styled(Container1040)`
  max-width: 896px;
  justify-content: left !important;
  h3,
  h4,
  p {
    text-align: left !important;
  }

  a:link,
  a:visited,
  a:hover {
    text-align: underline;
    color: #4190ca;
  }

  .invert {
    h3,
    h4,
    a:link,
    a:visited {
      color: #ffffff;
    }
  }
`;
