import React, { useContext, useEffect/* , useState */ } from "react";
import {
  feetinches,
  getFeetInchesStringFromInteger
} from "../../Shoothill.Core/Utils/Useful";
import { isNullorWhiteSpace } from "../../Shoothill.Core/Utils/Validation";
import { Stores, StoresContext } from "../../Stores";
import {
  VariableTop,
  VariableTopImag,
  ElevationPreamble,
  ElevationDiffBox,
  SpacedFlex,
  EDDisclaimerText
} from "./reportElevationDiffStyling";

import EleDiffdia1 from "../../Content/EleDiff-dia1.svg";
import EleDiffdia2 from "../../Content/EleDiff-dia2.svg";
import EleDiffdia3 from "../../Content/EleDiff-dia3.svg";
import EleDiffFullDia from "../../Content/EleDiff-fullDia.svg";
import EleDiffdiaMob from "../../Content/EleDiff-dia-Mob.svg";
import { useMediaQuery } from "@material-ui/core";
import useCountUp from "Shoothill.Core/Utils/Hooks/UseCountUp";

export const ReportElevationDifference: React.FC = () => {
  const floodDataContext: any = useContext<Stores>(StoresContext).domain
    .FloodAndClaimDataStore;

  let floodzoneStr: string = floodDataContext.fldZone.substring(0, 1);
  floodzoneStr = floodzoneStr.toLowerCase();

  let startedAnimations: boolean = false;

  const eleDiff = useCountUp({
    end: floodDataContext.getElevationDifferenceInches,
    duration: 2.5,
    autoStart: false
  });

  const pE = useCountUp({
    end: floodDataContext.getPropertyElevation,
    duration: 2.5,
    capDecimals: false,
    callback: eleDiff.start,
    callbackOverlap: 0.7,
    autoStart: false
  });

  const baseFE = useCountUp({
    end: floodDataContext.getBaseElevation,
    duration: 2.5,
    capDecimals: false,
    autoStart: false
  });

  const img2Pos = useCountUp({
    end: 0,
    duration: 2.5,
    start: 8,
    capDecimals: false,
    autoStart: false
  });

  const img3Pos = useCountUp({
    end: 0,
    duration: 2.5,
    start: 16,
    capDecimals: false,
    autoStart: false
  });

  const baseFEPos = useCountUp({
    end: 30,
    duration: 2.5,
    start: 54,
    capDecimals: false,
    autoStart: false
  });

  let useMobileImage: boolean;
  useMobileImage = useMediaQuery("screen and (max-width: 767px)")
    ? true
    : false;

  let fullDesktopWizard: boolean;
  fullDesktopWizard = useMediaQuery("screen and (min-width: 1180px)")
    ? true
    : false;

  function onScroll() {
    if ((window.scrollY >= 64 || !useMobileImage) && !startedAnimations) {
      startedAnimations = true;
      baseFE.start();
      pE.start();
      baseFEPos.start();
      img2Pos.start();
      img3Pos.start();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  /*     let floodzoneStr: string = floodDataContext.fldZone.substring(0, 1);
    floodzoneStr = floodzoneStr.toLowerCase();
 */
  return (
    <SpacedFlex>
      {floodzoneStr === "" && <>To do</>}

      {floodzoneStr !== "" && (
        <>
          {!isNullorWhiteSpace(floodDataContext.getBaseElevation) && (
            <>
              <ElevationDiffBox>
                <div className="ElevationDiffText">
                  <h1>Elevation difference</h1>
                  <p>
                    Your elevation difference is calculated by subtracting the
                    expected height of floodwaters (also called the base flood
                    elevation, or BFE) from the elevation of your property. In
                    general, the lower your property sits to the expected height
                    of floodwaters, the greater your risk of flooding.
                  </p>

                  <p>
                    Properties sitting below this level (“negatively elevated”)
                    are considered to be especially risky.
                  </p>

                  <p>
                    Base flood elevations are established by FEMA as part of the
                    flood zone mapping process. Over 50 percent of FEMA’s maps
                    are out of date, and so are many BFEs. A true reflection of
                    the expected height of floodwaters may result in a lower
                    elevation difference than shown here.
                  </p>
                </div>

                <div className="ElevationDiagram">
                  <div className="flexContainer">
                    <img
                      src={
                        useMobileImage
                          ? EleDiffdiaMob
                          : fullDesktopWizard
                          ? EleDiffdia1
                          : EleDiffFullDia
                      }
                      alt={"Elevation Difference"}
                    />
                    {fullDesktopWizard && (
                      <VariableTopImag
                        src={EleDiffdia2}
                        topPercent={img2Pos.counter}
                      />
                    )}
                    {fullDesktopWizard && (
                      <VariableTopImag
                        src={EleDiffdia3}
                        topPercent={img3Pos.counter}
                      />
                    )}
                    <div className="DiagramVales">
                      <div className="propertyValue">
                        <div className="valueHeader">
                          Property {useMobileImage && <br />}elevation
                        </div>
                        {
                          <div className="elevationValues">
                            {feetinches(pE.counter)}
                          </div>
                        }
                      </div>
                      <VariableTop
                        className="baseValue"
                        topPercent={baseFEPos.counter}
                      >
                        <div className="valueHeader">Base flood elevation</div>
                        {
                          <div className="elevationValues">
                            {feetinches(baseFE.counter)}
                          </div>
                        }
                      </VariableTop>
                    </div>
                  </div>

                  <div className="outcomeValue">
                    <div className="valueHeader">Elevation difference</div>
                    <div className="elevationOutcome">
                      {getFeetInchesStringFromInteger(eleDiff.counter)}
                    </div>
                    {/*<div className="elevationOutcome">{getFeetInchesStringFromInteger(floodDataContext.getElevationDifferenceInches)}</div>*/}
                  </div>
                </div>
              </ElevationDiffBox>
            </>
          )}

          {isNullorWhiteSpace(floodDataContext.getBaseElevation) && (
            <>
              <ElevationPreamble>
                <h1>
                  Elevation difference is <span>UNKNOWN!</span>
                </h1>
                <p>
                  Your property’s elevation difference is calculated by
                  subtracting the expected height of floodwaters (also called
                  the base flood elevation, or BFE) from the elevation of your
                  building. In general, the lower your property sits to the
                  expected height of floodwaters, the greater your risk of
                  flooding. Properties sitting below this level (“negatively
                  elevated”) are considered to be especially risky.
                </p>

                {(floodzoneStr === "b" ||
                  floodzoneStr === "c" ||
                  floodzoneStr === "x") && (
                  <>
                    <div>
                      <p>
                        Your property is in an area for which a base flood
                        elevation has not been established by FEMA, and cannot
                        be reasonably extrapolated from the available data, and
                        therefore, we are unable to calculate an accurate
                        elevation difference.
                      </p>
                    </div>
                  </>
                )}

                {(floodzoneStr === "a" ||
                  floodzoneStr === "v" ||
                  floodzoneStr === "d") && (
                  <>
                    <div>
                      <p>
                        Your property is in a high risk flood hazard are for
                        which a base flood elevation has not been established by
                        FEMA, and therefore, we are unable to calculate an
                        accurate elevation difference. Properties in A, V, and D
                        flood zones are at high risk of flooding.
                      </p>
                    </div>
                  </>
                )}
              </ElevationPreamble>
            </>
          )}
        </>
      )}
      <EDDisclaimerText>
        *Disclaimer: FEMA has not established a definitive BFE in all areas. In
        some cases, the property BFE has been extrapolated from the closest
        available data.
      </EDDisclaimerText>
    </SpacedFlex>
  );
};
