import * as MobX from "mobx";

import ReportMapStyle from "./ReportMapStyle";

export interface IReportMapLayer {
    name: string;
    description?: string;

    // settings
    isVisible: boolean;
    opacity: number;
    styleId: string | number;

    // config
    styles: IStyle[];

    setIsVisible: (isVisible: boolean) => any;
    setOpacity: (opacity: number) => any;
    setStyleId: (styleId: string | number) => any;
}

export interface IStyle {
    id: string | number;
    name: string;
    isDynamic: boolean;

    apply: (mapStyle: typeof ReportMapStyle, _isVisible_: boolean, _opacity_: number) => any;
    getLegend: (mapStyle: typeof ReportMapStyle) => ILegendEntry[];
}

export interface ILegendEntry {
    name: string;
    colour: string;
}

const generateRandomHexColour: () => string = () =>
        // tslint:disable-next-line: no-bitwise
        "#000000".replace(/0/g, function (): string { return (~~(Math.random() * 16)).toString(16); });

const createSloshApply: (getColour1: () => string, getColour2: () => string,
        getColour3: () => string, getColour4: () => string) => any
        = (getColour1: () => string, getColour2: () => string, getColour3: () => string, getColour4: () => string) => {
    return (mapStyle: typeof ReportMapStyle, _isVisible_: boolean, _opacity_: number) => {
        // aJ: Locate the layers of interest
        const mapLayerG1: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-1")!;
        const mapLayerG2: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-2")!;
        const mapLayerG3: any= mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-3")!;
        const mapLayerG4: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-4")!;

        // aJ: Update the fill and border colours.
        mapLayerG1.paint["fill-color"] = mapLayerG1.paint["fill-outline-color"] = getColour1();
        mapLayerG2.paint["fill-color"] = mapLayerG2.paint["fill-outline-color"] = getColour2();
        mapLayerG3.paint["fill-color"] = mapLayerG3.paint["fill-outline-color"] = getColour3();
        mapLayerG4.paint["fill-color"] = mapLayerG4.paint["fill-outline-color"] = getColour4();

        // aJ: Set the opacity. If the layer isn't visible then the opacity is 0.
        // tODO AJ: Investigate if opacity = 0 is the best way to hide a layer.
        const opacity: number = (_isVisible_ === true) ? _opacity_ : 0;

        mapLayerG1.paint["fill-opacity"] = opacity;
        mapLayerG2.paint["fill-opacity"] = opacity;
        mapLayerG3.paint["fill-opacity"] = opacity;
        mapLayerG4.paint["fill-opacity"] = opacity;

        return mapStyle;
    };
};

const sloshGetLegend: (mapStyle: typeof ReportMapStyle) => any = (mapStyle: typeof ReportMapStyle) => {
    // locate the layers of interest
    const mapLayerG1: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-1")!;
    const mapLayerG2: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-2")!;
    const mapLayerG3: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-3")!;
    const mapLayerG4: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "SLOSH-MOM-4")!;

    // get colours
    const colour1: any = mapLayerG1.paint["fill-color"];
    const colour2: any = mapLayerG2.paint["fill-color"];
    const colour3: any = mapLayerG3.paint["fill-color"];
    const colour4: any = mapLayerG4.paint["fill-color"];

    const legendEntrys: ILegendEntry[] = [{
        name: "< 3 feet",
        colour: colour1 as string
    }, {
        name: "3 - 6 feet",
        colour: colour2 as string
    }, {
        name: "6 - 9 feet",
        colour: colour3 as string
    }, {
        name: "> 9 feet",
        colour: colour4 as string
    }];

    return legendEntrys;
};

export class SloshMapLayer implements IReportMapLayer {
    @MobX.observable public name: string = "Storm Surge Hazard";
    @MobX.observable public description: string = "National Storm Surge Hazard Layer\n\nMaximum of Maximums\n\nCategory 5 Hurricane";
    @MobX.observable public isVisible: boolean = true;
    @MobX.observable public opacity: number = 0.35;
    @MobX.observable public styleId: string | number = 1;

    public styles = MobX.observable([{
        id: 1,
        name: "Original",
        isDynamic: false,
        apply: createSloshApply(() => "#0271fe", () => "#fefe02", () => "#feaa02", () => "#fe0202"),
        getLegend: sloshGetLegend
    }, {
        id: 2,
        name: "Reversed",
        isDynamic: false,
        apply: createSloshApply(() => "#fe0202", () => "#feaa02", () => "#fefe02", () => "#0271fe"),
        getLegend: sloshGetLegend
    }, {
        id: 3,
        name: "Vapourwave",
        isDynamic: false,
        apply: createSloshApply(() => "#fffb96", () => "#05ffa1", () => "#01cdfe", () => "#b967ff"),
        getLegend: sloshGetLegend
    }, {
        id: 4,
        name: "Random",
        isDynamic: true,
        apply: createSloshApply(generateRandomHexColour, generateRandomHexColour, generateRandomHexColour, generateRandomHexColour),
        getLegend: sloshGetLegend
    }]);

    @MobX.observable public setIsVisible = (isVisible: boolean) => this.isVisible = isVisible;
    @MobX.observable public setOpacity = (opacity: number) => this.opacity = opacity;
    @MobX.observable public setStyleId = (styleId: string | number) => this.styleId = styleId;
}

export class RiskMapLayer implements IReportMapLayer {
    @MobX.observable public name: string  = "Loss Claim Value (US$)";
    @MobX.observable public description: string  = "Loss Claim Value\n\n1st Jan 1978 - 30th Sept 2018";
    @MobX.observable public isVisible: boolean = false;
    @MobX.observable public opacity: number = 0.35;
    @MobX.observable public styleId: string | number = 1;

    public styles = MobX.observable([{
        id: 1,
        name: "Original",
        isDynamic: false,
        apply: (mapStyle: typeof ReportMapStyle, _isVisible_: boolean, _opacity_: number) => {
            // aJ: Locate the layers of interest
            const lossMapLayer: any = mapStyle.layers!.find((l: { id: string; }) => l.id === "Loss")!;

            // aJ: Set the opacity. If the layer isn't visible then the opacity is 0.
            // tODO AJ: Investigate if opacity = 0 is the best way to hide a layer.
            const opacity: number = (_isVisible_ === true) ? _opacity_ : 0;

            lossMapLayer.paint["fill-opacity"] = opacity;

            return mapStyle;
        },
        getLegend: () => {
            const legendEntrys: ILegendEntry[] = [{
                name: "$0",
                colour: "#4dff3d"
            }, {
                name: "$1 - $10,000,000",
                colour: "#008efa"
            }, {
                name: "$10,000,000 - $100,000,000",
                colour: "#ffef0a"
            }, {
                name: "$100,000,000 - $500,000,000",
                colour: "#fa9600"
            }, {
                name: "$500,000,000 - $1,000,000,000",
                colour: "#fa0000"
            }];

            return legendEntrys;
        }
    }]);

    @MobX.observable public setIsVisible: any = (isVisible: boolean) => this.isVisible = isVisible;
    @MobX.observable public setOpacity: any = (opacity: number) => this.opacity = opacity;
    @MobX.observable public setStyleId: any = (styleId: string | number) => this.styleId = styleId;
}

export const sloshMapLayer: SloshMapLayer = new SloshMapLayer();
export const riskMapLayer: RiskMapLayer = new RiskMapLayer();

export const mapLayers: IReportMapLayer[] = [
    riskMapLayer,
    sloshMapLayer
];

export default mapLayers;