import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import awaitingImage from "../Content/Uploader.svg";
import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { Stores, StoresContext } from "../Stores";
import {
    Box,
    Button,
    Grid,
    Modal,
    Typography
} from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import * as ReactRouterDom from "react-router-dom";

import { CroppableImage } from "../Shoothill.Components/Forms/CroppableImage";

import {
    BlogPost,
    ApiResult,
    CropResizeInfo,
    CropResizeResult,
    GenericIncludeDeletedWithId
} from "../Models";
import { BlogPostFormModel } from "../Models/FormModels/BlogPostFormModel";
import { BlogPostStore } from "../Stores/Domain/BlogPostStore";
import { LargeWhiteFormControl } from "../Components/Controls/Forms/LargeWhiteFormControl";
import { UploadableImage } from "../Shoothill.Components/Forms/UploadableImage";
import { useValidation, UseDataApi } from "../Shoothill.Core/Utils";
import { Editor } from "@tinymce/tinymce-react";
import * as AppUrls from "../Globals/AppUrls";
import ReactImageCrop from "react-image-crop";
import * as MUI from "@material-ui/core";
import {
    useAdminStyles,
    BlueGraditentBoxAdmin,
    Row1040Admin,
    GridContainerAdmin,
    TextFieldAdmin,
    ButtonGridAdmin,
    ButtonBoxAdmin,
    TempBox,
    RadioGroup
} from "../Shoothill.Components/Styles/AdminStyles";

export interface IMatchAdminParams {
    Id: string;
}

export const BlogPostAdmin: React.FC<ReactRouterDom.RouteComponentProps<IMatchAdminParams>
> = props => {
    const classes: any = useAdminStyles();

    const blogPostStoreContext: BlogPostStore = useContext<Stores>(StoresContext).domain.BlogPostStore;

    const creatingBlog: boolean = blogPostStoreContext.GetCreatingBlog();

    let blog: BlogPost | undefined = blogPostStoreContext.GetBlogPostForAdmin(
        props.match.params.Id
    );

    if (blog === undefined) {
        blog = blogPostStoreContext.GetArticleForAdmin(props.match.params.Id);
    }

    let blogDetail: string = blog ? blog.blogDetail : "";

    const { data, isLoading, doRequest } = UseDataApi<ApiResult<BlogPost>>();
    const { data: fileUrlResponse, doRequest: uploadFile } = UseDataApi<
        ApiResult<CropResizeResult>
    >();
    const { data: allPostsData, doRequest: getAllPosts } = UseDataApi();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = React.useState<string>(blog ? blog.name : "");
    const [link, setLink] = React.useState<string>(blog ? blog.linkUrl : "");
    const [categoryId, setCategoryId] = React.useState<string>(
        blog ? blog.categoryId : "69283BF9-6A87-44AB-BF4A-455DF92951D4"
    );
    const [startDate, setStartDate] = React.useState<Date | null>(
        blog ? new Date(blog.startDate) : new Date()
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        blog ? new Date(blog.endDate) : new Date()
    );
    const [hasDesktopPhoto, setHasDesktopPhoto] = React.useState<boolean>(
        blog ? blog.originalImageURL.length > 0 : false
    );
    const [originalImageURL, setOriginalImageURL] = React.useState<string>(
        blog ? blog.originalImageURL : awaitingImage
    );
    const [isDeleted, setIsDeleted] = React.useState<boolean>(
        blog ? blog.isDeleted : false
    );
    const [selectedRadioValue, setSelectedRadioValue] = useState("0");

    const blogPostForServer: () => any = () => {
        let blogPostFormModel: any = {
            id: blog ? blog.id : "",
            name: title,
            isDeleted: isDeleted,
            startDate: startDate ? startDate.toUTCString() : "",
            endDate: endDate ? endDate.toUTCString() : "",
            description: title,
            originalImageURL: originalImageURL,
            desktopImageUrl: originalImageURL,
            desktopBtnImageUrl: originalImageURL,
            tabletImageURL: originalImageURL,
            tabletBtnImageURL: originalImageURL,
            mobileImageURL: originalImageURL,
            mobileBtnImageURL: originalImageURL,
            categoryId: categoryId,
            linkUrl: link,
            isBlog: blog ? blog.isBlog : true,
            blogDetail: blogDetail
        };

        return blogPostFormModel;
    };

    const [formState] = useValidation<BlogPostFormModel>(
        blogPostForServer()
    );
    const onSubmit: (e: any) => void = (e: any): void => {
        e.preventDefault();
        if (formState.isFormValid()) {
            doRequest(AppUrls.Server.Api.Blog.Upsert, blogPostForServer());
        }
    };

    const [crop, setCrop] = React.useState<ReactImageCrop.Crop>({
        x: 0,
        y: 0,
        aspect: 4 / 3,
        width: 100,
        height: 100
    });

    const [percentCrop, setPercentCrop] = React.useState<
        ReactImageCrop.PercentCrop
    >({
        x: 0,
        y: 0,
        aspect: 4 / 3,
        width: 100,
        height: 100
    });

    function handleTitleChange(e: any): void {
        setTitle(e.target.value);
    }

    function handleStartDateChange(date: Date | null): void {
        setStartDate(date);
    }

    function handleEndDateChange(date: Date | null): void {
        setEndDate(date);
    }

    const handleOpen: () => void = (): void => {
        setOpen(true);
    };

    const handleClose: () => void = (): void => {
        setOpen(false);
    };

    function handleEditorChange(e: any): void {
        blogDetail = e.target.getContent();
    }

    function handleCropChanged(
        cropChange: ReactImageCrop.Crop,
        percentCrop: ReactImageCrop.PercentCrop
    ): void {
        setCrop(cropChange);
        setPercentCrop(percentCrop);
    }

    function desktopItemImage(): string {
        return originalImageURL;
    }

    function handleDesktopImageSelect(selectedImageFile: File): void {
        blogPostStoreContext.resetImageData();
        blogPostStoreContext.SetImageFile(selectedImageFile);
        handleOpen();
    }

    function loadImageDataUrl(imageFile: File): void {
        var reader: FileReader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = function(): void {
            let result: any = reader.result;
            blogPostStoreContext.SetFileBase64(result);
        };
        reader.onerror = function(error: any): void {
            console.log("Error: ", error);
        };
    }

    function handleServerCropClick(): void {
        const fileToSave:
            | File
            | undefined = blogPostStoreContext.GetImageFile();
        if (fileToSave) {
            processFile(fileToSave);
        }
    }

    function processFile(imageFile: File): void {
        try {
            loadImageDataUrl(imageFile);

            setTimeout(() => {
                const fileBase64: string = blogPostStoreContext.GetFileBase64();

                if (fileBase64 !== "" && fileBase64 !== null) {
                    const longestSideLength: number = 600;
                    const imageSubDirectory: string = "blogs/";
                    /* const storeThumbnail: boolean = false; */

                    const request: CropResizeInfo = {
                        fileBase64: fileBase64 as string,
                        fileName: imageFile.name,
                        aspect: percentCrop.aspect!,
                        x: percentCrop.x!,
                        y: percentCrop.y!,
                        width: percentCrop.width!,
                        height: percentCrop.height!,
                        resizeImage: true,
                        longestSideLength,
                        imageSubDirectory
                    };

                    uploadFile(AppUrls.Server.Api.Image.cropAndResize, request);
                }
            }, 1000);
        } catch (err) {
            console.log(err);
        }
    }

    const handleRemovePhotoClick: () => void = (): void => {
        setHasDesktopPhoto(false);
        setOriginalImageURL(awaitingImage);
    };

    function handleRadioChange(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): void {
        setSelectedRadioValue(event.target.value);
        if (event.target.value === "0") {
            setIsDeleted(false);
        } else {
            setIsDeleted(true);
        }
    }

    useEffect(() => {
        if (data !== null) {
            if (data.wasSuccessful) {
                // set the edited blog post.
                const includeGenericDeletedWithId: GenericIncludeDeletedWithId = {
                    id: undefined,
                    isBlog: true,
                    includeDeleted: true
                };
                // get all blog posts.
                getAllPosts(
                    "/api/BlogPost/GetAll",
                    includeGenericDeletedWithId
                );
            }
        }
    }, [data]);

    useEffect(() => {
        if (allPostsData !== null) {
            if (allPostsData.wasSuccessful) {
                //Set all blog posts.
                blogPostStoreContext.SetBlogPostsForAdmin(allPostsData.payload);
                blogPostStoreContext.SetBlogPosts([]); //Done so the blog posts will be re-loaded after an edit.
                //Then navigate back.
                window.history.go(-1);
            }
        }
    }, [allPostsData]);

    useEffect(() => {
        if (isDeleted) {
            setSelectedRadioValue("1");
        } else {
            setSelectedRadioValue("0");
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (fileUrlResponse !== null && fileUrlResponse !== undefined) {
            if (fileUrlResponse.wasSuccessful) {
                setOriginalImageURL(
                    fileUrlResponse.payload.croppedAndResizedResult
                );
                handleClose();
            }
        }
    }, [fileUrlResponse]);

    return useObserver(() => (
        <>
            <BlueGraditentBoxAdmin>
                <div className={classes.blogBar}>
                    <Typography className={classes.blogBarText}>
                        <span
                            className={classes.blogHome}
                            onClick={() => window.history.go(-1)}
                        >
                            Admin / Blog
                        </span>{" "}
                        / {blog ? blog.name : creatingBlog ? "New blog" : ""}
                    </Typography>
                </div>

                <div className={classes.blogPost}>
                    <Row1040Admin>
                        <form className={classes.form} onSubmit={onSubmit}>
                            <Grid container>
                                <GridContainerAdmin
                                    container
                                    className={classes.datePickersGridContainer}
                                >
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            className={
                                                classes.datePickerGridContainer
                                            }
                                        >
                                            <Box textAlign="left">
                                                <Typography
                                                    id="header"
                                                    className={`${classes.headingBlue34} ${classes.subTitle}`}
                                                >
                                                    Start date
                                                </Typography>
                                                <KeyboardDatePicker
                                                    className={
                                                        classes.datePicker
                                                    }
                                                    margin="normal"
                                                    id="start-date-picker-dialog"
                                                    format="MM/dd/yyyy"
                                                    value={startDate}
                                                    onChange={
                                                        handleStartDateChange
                                                    }
                                                    KeyboardButtonProps={{
                                                        "aria-label":
                                                            "change date"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            className={
                                                classes.datePickerGridContainer
                                            }
                                        >
                                            <Box textAlign="left">
                                                <Typography
                                                    id="header"
                                                    className={`${classes.headingBlue34} ${classes.subTitle}`}
                                                >
                                                    End date
                                                </Typography>
                                                <KeyboardDatePicker
                                                    className={
                                                        classes.datePicker
                                                    }
                                                    margin="normal"
                                                    id="end-date-picker-dialog"
                                                    format="MM/dd/yyyy"
                                                    value={endDate}
                                                    onChange={
                                                        handleEndDateChange
                                                    }
                                                    KeyboardButtonProps={{
                                                        "aria-label":
                                                            "change date"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </GridContainerAdmin>
                                <GridContainerAdmin item xs={12} sm={12}>
                                    <Box textAlign="left">
                                        <Typography
                                            id="header"
                                            className={`${classes.headingBlue34} ${classes.subTitle}`}
                                        >
                                            Blog title
                                        </Typography>
                                        <LargeWhiteFormControl>
                                            {formState.showErrors(
                                                "name",
                                                classes.error
                                            )}
                                            <TextFieldAdmin
                                                label=""
                                                fullWidth
                                                autoComplete="name"
                                                placeholder="Title (required)"
                                                onChange={handleTitleChange}
                                                value={title}
                                                // {...text("name", {
                                                //     validationRules: [
                                                //         (v: string) =>
                                                //             (v &&
                                                //                 v.length > 0) ||
                                                //             "* Title is required"
                                                //     ]
                                                // })}
                                            />
                                        </LargeWhiteFormControl>
                                    </Box>
                                </GridContainerAdmin>
                                <GridContainerAdmin item xs={12} sm={12}>
                                    <Box
                                        textAlign="left"
                                        className={classes.textAreaBoxContainer}
                                    >
                                        <Typography
                                            id="header"
                                            className={`${classes.headingBlue34} ${classes.subTitle}`}
                                        >
                                            Blog Content
                                        </Typography>
                                        {formState.showErrors(
                                            "blogDetail",
                                            classes.error
                                        )}
                                        <LargeWhiteFormControl>
                                            <Editor
                                                apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                                                initialValue={
                                                    blog ? blog.blogDetail : ""
                                                }
                                                init={{
                                                    height: 500,
                                                    plugins: "link image code",
                                                    toolbar:
                                                        "undo redo | bold italic | alignleft aligncenter alignright"
                                                }}
                                                onChange={handleEditorChange}
                                            />
                                        </LargeWhiteFormControl>
                                    </Box>
                                </GridContainerAdmin>
                                <GridContainerAdmin item xs={12} sm={12}>
                                    <Box textAlign="left">
                                        <Typography
                                            id="header"
                                            className={`${classes.headingBlue34} ${classes.subTitle}`}
                                        >
                                            Blog picture
                                        </Typography>
                                        {!hasDesktopPhoto && (
                                            <div
                                                className={classes.activeImage}
                                            >
                                                <UploadableImage
                                                    imageUrl={desktopItemImage()}
                                                    isDefaultImage={
                                                        hasDesktopPhoto
                                                    }
                                                    className={
                                                        classes.photoUploaderSelect
                                                    }
                                                    onImageSelected={
                                                        handleDesktopImageSelect
                                                    }
                                                />
                                            </div>
                                        )}
                                        {hasDesktopPhoto && (
                                            <div
                                                className={classes.activeImage}
                                            >
                                                <img
                                                    src={originalImageURL}
                                                    className={classes.imgBlog}
                                                />
                                                <div
                                                    className={
                                                        classes.removeImage
                                                    }
                                                    onClick={
                                                        handleRemovePhotoClick
                                                    }
                                                >
                                                    <span className="dummy-link">
                                                        x
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Box>
                                </GridContainerAdmin>
                                <GridContainerAdmin>
                                    <Box textAlign="left">
                                        <Typography
                                            id="header"
                                            className={`${classes.headingBlue34} ${classes.subTitle}`}
                                        >
                                            Visibility
                                        </Typography>
                                        <div className="live-radios">
                                            <RadioGroup
                                                className={
                                                    classes.liveRadiogroup
                                                }
                                            >
                                                <label>
                                                    <MUI.Radio
                                                        checked={
                                                            selectedRadioValue ===
                                                            "0"
                                                        }
                                                        onChange={
                                                            handleRadioChange
                                                        }
                                                        value="0"
                                                        name="radio-button-live"
                                                        checkedIcon={
                                                            <span
                                                                className={`${classes.icon} ${classes.checkedIcon}`}
                                                            />
                                                        }
                                                        icon={
                                                            <span
                                                                className={`${classes.icon}`}
                                                            />
                                                        }
                                                    ></MUI.Radio>
                                                    Live
                                                </label>
                                                <label>
                                                    <MUI.Radio
                                                        checked={
                                                            selectedRadioValue ===
                                                            "1"
                                                        }
                                                        onChange={
                                                            handleRadioChange
                                                        }
                                                        value="1"
                                                        name="radio-button-live"
                                                        checkedIcon={
                                                            <span
                                                                className={`${classes.icon} ${classes.checkedIcon}`}
                                                            />
                                                        }
                                                        icon={
                                                            <span
                                                                className={`${classes.icon}`}
                                                            />
                                                        }
                                                    ></MUI.Radio>
                                                    Hidden
                                                </label>
                                            </RadioGroup>
                                        </div>
                                    </Box>
                                </GridContainerAdmin>
                                {/*
                                {!isBlog && (
                                    <Grid item xs={12} sm={12}>
                                        <Box
                                            mt={2}
                                            textAlign="center"
                                            className={
                                                classes.textAreaBoxContainer
                                            }
                                        >
                                            {formState.showErrors(
                                                "linkUrl",
                                                classes.error
                                            )}
                                            <TextField
                                                label=""
                                                fullWidth
                                                placeholder="Link"
                                                onChange={handleURLChange}
                                                value={reflinkURL}
                                            />
                                        </Box>
                                    </Grid>
                                )} */}
                                <ButtonGridAdmin item>
                                    <ButtonBoxAdmin
                                        mt={5}
                                        mb={3}
                                        textAlign="center"
                                    >
                                        <Button
                                            variant="outlined"
                                            className={classes.adminBtn}
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading
                                                ? "Saving.."
                                                : creatingBlog
                                                ? "Add new blog"
                                                : "Save"}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={`${classes.adminBtn} ${classes.cancelButtonAdmin}`}
                                            onClick={() =>
                                                window.history.go(-1)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </ButtonBoxAdmin>
                                </ButtonGridAdmin>
                            </Grid>
                        </form>
                    </Row1040Admin>
                </div>
            </BlueGraditentBoxAdmin>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                className={classes.modalScroll}
            >
                <TempBox>
                    <div>Resize and position the area you wish to crop</div>
                    <div className={classes.cropCenter}>
                        <CroppableImage
                            title="Edit image"
                            imageFile={blogPostStoreContext.GetImageFile()}
                            aspect={1}
                            resizeImage
                            storeThumbnail={false}
                            longestSideLength={600}
                            imageSubDirectory={"blogs/"}
                            onCropChanged={handleCropChanged}
                        />
                    </div>
                    <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                            <Box
                                mt={3}
                                mb={3}
                                textAlign="center"
                                display="flex"
                                justifyContent="center"
                            >
                                <Button
                                    variant="outlined"
                                    className={classes.adminBtn}
                                    onClick={handleServerCropClick}
                                >
                                    {"Add image"}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={`${classes.adminBtn}`}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                </TempBox>
            </Modal>
        </>
    ));
};
