import React, { useRef, useContext, useEffect, useState } from "react";
import { TopDiv, BottomDiv } from "./QuoteCompleteStyling";
import { quoteIcon, getQuoteType } from "./reportScores";
import { QuoteStore } from "../../Stores/Domain/QuoteStore";
import { StoresContext, Stores } from "../../Stores/Stores";
import { Grid } from "@material-ui/core";
import { DomainStores } from "Stores";
import { useMediaQuery } from "../../Shoothill.Core/Utils/Media/mediaQuery";

export const QuoteComplete: React.FC = () => {
	const quoteStoresContext: QuoteStore = useContext<Stores>(StoresContext)
		.domain.QuoteStore;
	const floodDataContext: DomainStores["FloodAndClaimDataStore"] = useContext<
		Stores
	>(StoresContext).domain.FloodAndClaimDataStore;

	const [quoteType, setQuoteType] = useState<number>(-1);

	const useMobileLayout = useMediaQuery("screen and (max-width: 767px)")
		? true
		: false;

	useEffect(() => {
		setQuoteType(getQuoteType(floodDataContext.fldZone));
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<TopDiv>
				<div style={{ display: "flex" }}>
					{useMobileLayout || <div className="quote-icon">{quoteIcon}</div>}
					<div style={{ width: "100%" }}>
						{quoteType === 1 ? (
							<>
								<h2>
									Congratulations - your premium will be
									<br />
									<span>{`$${quoteStoresContext.premium} or less`}</span> per
									year!
								</h2>
							</>
						) : (
							<>
								<h2>
									<span>Quote Process Complete!</span>
									{useMobileLayout ? <br /> : " "}Based on your flood zone, it
									appears we need to verify some additional information with you
									to provide the most accurate quote possible
								</h2>
							</>
						)}
					</div>
				</div>
			</TopDiv>
			<BottomDiv>
				<h3>Next Steps? Don’t worry, we will contact you!</h3>

				<p>
					We are currently assigning your quote request to a flood insurance
					agent. Once your request has been assigned, you will be contacted
					directly to help with the final steps to bind your flood insurance
					policy. At that time, we can also answer any additional questions you
					may have or assist you with excess coverage, if needed. Thank you for
					your patience and we look forward to providing you the flood insurance
					coverage you need!
				</p>

				<p>
					<strong>Please Note:</strong> This quote is an estimate based on the
					information you provided and is not your guaranteed premium. All
					quotes received are subject to agent review. Upon completion of
					underwriting, your exact premium will be provided. You must speak to
					an agent to bind your flood insurance coverage. You cannot bind,
					alter, or change flood insurance coverage via email.
				</p>
			</BottomDiv>
		</>
	);
};
