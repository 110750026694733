import { BaseStore } from "./BaseStore";
import { DatasetWeights } from "../../Models";
import { observable, action, computed } from "mobx";
import { Stores } from "../Stores";

export class DatasetWeightsStore extends BaseStore {
    @observable public DatasetWeights: DatasetWeights | undefined;
    @observable public DatasetWeightsArr: DatasetWeights[] = [];

    @observable public datasetName: string = "";
    @observable public sectionName: string = "";
    @observable public multiplier: number = 0;

    public constructor() {
        super();
    }

    // tslint:disable-next-line: no-empty
    public init(stores: Stores): void {}

    @action
    public SetDatasetWeightsResult(DatasetWeights: DatasetWeights): void {
        this.DatasetWeights = DatasetWeights;

        if (DatasetWeights) {
            this.datasetName = DatasetWeights.datasetName;
            this.sectionName = DatasetWeights.sectionName;
            this.multiplier = DatasetWeights.multiplier;
        }
    }

    @action
    public SetDatasetWeightsResults(DatasetWeights: DatasetWeights[]): void {
        this.DatasetWeightsArr = DatasetWeights;
    }

    @action
    public ResetDatasetWeightsArr(): void {
        this.DatasetWeightsArr = [];
    }

    @action
    public ResetDatasetWeights(): void {
        this.datasetName = "";
        this.sectionName = "";
        this.multiplier = 0;
    }

    @computed get GetDatasetWeights(): DatasetWeights[] {
        var retVal: DatasetWeights[] = [];

        this.DatasetWeightsArr.forEach(datasetWeight => {
            retVal.push(datasetWeight);
        });

        return retVal;
    }
}
