import * as MobX from "mobx";
import React, { useEffect, useRef } from "react";
import _clone from "lodash/clone";
import ReactImageCrop from "react-image-crop";
import "./ReactCrop.css";

export interface ICroppableImageProps {
    title?: string;
    imageFile?: File;
    aspect?: number;
    resizeImage: boolean;
    storeThumbnail: boolean;
    longestSideLength?: number;
    imageSubDirectory?: string;
    onCropChanged: (crop: ReactImageCrop.Crop, percentCrop: ReactImageCrop.PercentCrop) => any;
    className?: string;
    style?: React.CSSProperties;
}

export interface ImageState {
    src: File | undefined;
    crop: ReactImageCrop.Crop;
    percentCrop: ReactImageCrop.PercentCrop;
}

export const CroppableImage: React.FC<ICroppableImageProps> = props => {
    let imageFileReactionDisposer: MobX.IReactionDisposer | undefined;
    const refReactImageCrop: React.MutableRefObject<any> = useRef(null);

    const { imageFile, aspect } = props;
    const [imageDataUrl, setImageDataUrl] = React.useState<string>("");
    const [cropInfo, setCropInfo] = React.useState<ImageState>(setDefaultCropInfo());

    function setDefaultCropInfo(): any {
        return {
            src: imageFile,
                crop: {
                x: 0,
                    y: 0,
                        aspect: aspect ? aspect : 4 / 3,
                            width: 100,
                                height: 100
            },
            percentCrop: {
                x: 0,
                    y: 0,
                        aspect: aspect ? aspect : 4 / 3,
                            width: 100,
                            height: 100
            }
        };
    }

    function handleChange(crop: ReactImageCrop.Crop, percentCrop: ReactImageCrop.PercentCrop): void {
        const { onCropChanged } = props;
        setCropInfo({
            src: imageFile,
            crop: crop,
            percentCrop: percentCrop
        });
        if (onCropChanged) {
            onCropChanged(crop, percentCrop);
        }
    }

    function onImageLoaded(image: any): boolean {
        setDefaultCropInfo();
        // alert("Loaded Image: " + image.width + ", " + image.height);
        // alert("Loaded Image Natural: " + image.naturalWidth + ", " + image.naturalHeight);

        // const widthcrop:number = image.naturalWidth / image.width;
        // const wheightcrop:number = image.naturalHeight / image.height;
        // alert("Crop Factor: = " + widthcrop+ ", " + wheightcrop);
        // cmr: you must return false from this.
        return false;
    }


    function loadImageDataUrl(imageFile: File): void {
        const fileReader: FileReader = new FileReader();

        fileReader.onload = () => {
            MobX.runInAction("loadImageDataUrl.fileReader.onload", () => {
                setImageDataUrl(fileReader.result as string);
            });
        };

        fileReader.readAsDataURL(imageFile);
    }

    useEffect(() => {
        if (imageFile) {
            loadImageDataUrl(imageFile);
            setCropInfo({
                src: imageFile,
                crop: {
                    x: cropInfo.crop.x,
                    y: cropInfo.crop.y,
                    aspect: aspect ? aspect : 4 / 3,
                    width: cropInfo.crop.width,
                    height: cropInfo.crop.height
                },
                percentCrop: {
                    x: cropInfo.percentCrop.x,
                    y: cropInfo.percentCrop.y,
                    aspect: aspect ? aspect : 4 / 3,
                    width: cropInfo.percentCrop.width,
                    height: cropInfo.percentCrop.height,
                    unit: cropInfo.percentCrop.unit
                }
            });
        }

        return () => {
            if (imageFileReactionDisposer != null) {
                imageFileReactionDisposer();
            }
        };
    }, []);

/*     useEffect(() => {
        if (refReactImageCrop !== undefined && refReactImageCrop !== null){
            if (refReactImageCrop!.current && refReactImageCrop!.current  !== null && (refReactImageCrop!.current! as any).componentRef ) {
                var height: number = (refReactImageCrop!.current! as any).componentRef .clientHeight!;
                var width: number = (refReactImageCrop!.current! as any).componentRef.clientWidth!;
                alert("Client Window" + width + ", " + height);
            }
        }
    }); */

    return (
        <>
            <ReactImageCrop
                ref={refReactImageCrop}
                src={imageDataUrl}
                crop={cropInfo.crop}
                onChange={handleChange}
                imageStyle={{width: "100%", height: "100%", maxHeight: "100%", maxWidth: "100%"}}
                onImageLoaded={onImageLoaded}
            />
        </>
    );
};
