export const ellipsis = (contentString: string, contentLength: number, ellipsisText: string) => {
    let defaultEllipsis = "...";

    if (ellipsisText != null) {
        defaultEllipsis = ellipsisText;
    }

    if (contentString == null) {
        return defaultEllipsis;
    }

    if (contentLength >= contentString.length) {
        return contentString;
    }

    const result = contentString.substring(0, contentLength);

    const occurencies = contentString.indexOf(" ");

    if (occurencies < 0) {
        return result + defaultEllipsis;
    }

    const lastSpace = result.lastIndexOf(" ");

    const returnStr = contentString.substring(0, lastSpace);

    return returnStr + defaultEllipsis;
};