import styled from "styled-components";
import { Box } from "@material-ui/core";

interface TopPercent {
  topPercent: number;
}

export const VariableTopImag: any = styled.img<TopPercent>`
  position: absolute;
  left: 0;
  top: ${props => props.topPercent}%;
`;

export const VariableTop: any = styled.div<TopPercent>`
  top: ${props => props.topPercent}%;
`;

export const ElevationPreamble: any = styled(Box)`
  h1 {
    font-size: 32px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 18px;
      margin: 20px 0;

      span {
        display: block;
        padding-top: 3px;
      }
    }
    span {
      text-transform: uppercase;
      color: #ee00ee;
    }
  }
`;

export const ElevationDiffBox: any = styled(Box)`
  display: inline-flex;

  @media (max-width: 1179px) {
    display: block;
  }

  .ElevationDiffText {
    width: 652px;
    padding: 0;
    margin: 0;
    p {
      font-weight: normal;
      text-align: left;
      font: Regular 14px/20px "Lato", sans-serif;
      letter-spacing: 0;
      color: #292b28;
    }
    h1 {
      font-size: 32px;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 18px;
        margin: 20px 0;
      }
    }
    @media (max-width: 1179px) {
      width: unset;
    }
  }

  .elevationValues {
    font-weight: bold;
    font-size: 32px;
    font-family: Lato;
    letter-spacing: 0;
    color: #4190ca;
    line-height: 36px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .elevationOutcome {
    font-weight: bold;
    font-size: 70px;
    font-family: Lato;
    letter-spacing: 0;
    color: #ee00ee;
    line-height: 64px;
  }

  .ElevationDiagram {
    .flexContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .DiagramVales {
      align-self: center;
    }
    width: 470px;
    /* height: 189px; */
    margin: 30px 0px 111px 60px;
    text-align: center;

    @media (min-width: 1180px) {
      position: relative;

      img {
        position: absolute;
        left: 0;
      }
    }

    @media (max-width: 1179px) {
      width: 90%;
      margin: 20px auto;

      img {
        width: inherit;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .propertyValue,
    .baseValue {
      position: absolute;
      height: 0;
      z-index: 10;
    }

    .propertyValue {
      top: 95px;
      left: 20px;
      width: 164px;
      @media (max-width: 1179px) {
        position: relative;
        top: -92px;
        left: -91px;
      }
      @media (max-width: 767px) {
        top: -90px;
        left: -81px;
      }
    }

    .baseValue {
      left: 230px;
      width: 204px;
      @media (max-width: 1179px) {
        position: relative;
        top: -132px;
        left: 110px;
      }
      @media (max-width: 767px) {
        top: -114px;
        left: 98px;
      }
    }

    .outcomeValue {
      margin: 15px 0;
    }

    .valueHeader {
      font-family: Lato;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: bold;
      color: #292b28;

      @media (max-width: 767px) {
        font-size: 13px;
      }
    }
  }
`;

export const SpacedFlex: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const EDDisclaimerText: any = styled(Box)`
  text-align: left;
  font-weight: Regular;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
  text-align: left;
  color: rgba(34, 33, 32, 0.6);
`;
