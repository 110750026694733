import React, { useContext /* , useEffect, useState  */ } from "react";
import { Stores, StoresContext } from "../../Stores";
import { HurricaneDataModel, HurricaneSummaryModel } from "../../Models";
import {
	HurricaneBox,
	HurricaneCards,
	HurricaneChart,
	HurricaneNode,
	HurricaneChartMob,
	HurricaneNodeMob
} from "./reportHurricaneStyles";
import moment from "moment";
import { HurricaneDataStore, HurricaneSummaryStore } from "../../Stores/Domain";
import { useMediaQuery, Grid } from "@material-ui/core";
import useCountUp from "Shoothill.Core/Utils/Hooks/UseCountUp";

export const ReportHurricanes: React.FC = () => {
	const hurricaneDataContext: HurricaneDataStore = useContext<Stores>(
		StoresContext
	).domain.HurricaneDataStore;
	const hurricaneSummaryContext: HurricaneSummaryStore = useContext<Stores>(
		StoresContext
	).domain.HurricaneSummaryStore;
	const hurricanes: HurricaneDataModel[] = hurricaneDataContext.GetHurricanes;
	const hurricaneSummary: HurricaneSummaryModel[] =
		hurricaneSummaryContext.GetHurricanesSummary;

	const today: Date = new Date();
	const currYear: number = parseInt(moment(today).format("YYYY"), 10);
	const recentNum: number = 100;

	const recentHurricanes: HurricaneDataModel[] = hurricanes.filter(
		recentHurricane => {
			return recentHurricane.season >= currYear - recentNum;
		}
	);

	const summaryHurricanes: HurricaneSummaryModel[] = hurricaneSummary.filter(
		recentHurricane => {
			return recentHurricane.decade >= currYear - recentNum;
		}
	);

	const hurricanesCount = useCountUp({
		end: recentHurricanes.length,
		duration: 2.5
	});

	/*   const [hurricanesCount, el] = useCountUp({
    to: recentHurricanes.length,
    speed: 1400,
    startPercent: 0.1,
    stopPercent: 0.85
  }); */

	let mobileLayout: boolean;
	mobileLayout = useMediaQuery("screen and (max-width: 1179px)") ? true : false;

	return (
		<>
			<HurricaneBox>
				<div className="content">
					<h1>Hurricanes</h1>
					{hurricanes.length > 0 && (
						<>
							<p>
								Hurricanes can cause severe damage from wind, rain, and storm
								surge. The graph below shows how many hurricane eyes have passed
								within 100 miles of your property over the last ten decades. The
								average diameter of hurricane-force winds is 100 miles from the
								eye in any direction.
							</p>
						</>
					)}
					{recentHurricanes.length > 0 && !mobileLayout && (
						<>
							<HurricaneChart>
								<div className="gridBase">
									<div className="xAxisLabel">
										<div className="milesFrom mile25">10+</div>
										<div className="milesFrom mile20">8</div>
										<div className="milesFrom mile15">6</div>
										<div className="milesFrom mile10">4</div>
										<div className="milesFrom mile5">2</div>
										<div className="milesFrom mile0">0</div>
									</div>
									<div className="yAxisLabel">
										{summaryHurricanes.map(hurricane => (
											<>
												<div className="grouped" key={hurricane.decade}>
													<DesktopHurricanesGraph
														qty={hurricane.hurricaneQty}
													/>
													<div className="yearLabel">{hurricane.decade}s</div>
												</div>
											</>
										))}
									</div>
								</div>
							</HurricaneChart>
						</>
					)}
					<Grid container spacing={3}>
						{recentHurricanes.length > 0 && mobileLayout && (
							<>
								<Grid item xs={6}>
									<HurricaneChartMob>
										<div className="topLabels">
											<span className="milesFrom mile25">0</span>
											<span className="milesFrom mile20">2</span>
											<span className="milesFrom mile15">4</span>
											<span className="milesFrom mile10">6</span>
											<span className="milesFrom mile5">8</span>
											<span className="milesFrom mile0">10</span>
										</div>
										<div className="gridBase">
											<div className="xAxisLabel">
												{summaryHurricanes.map(hurricane => (
													<>
														<div className="grouped" key={hurricane.decade}>
															<HurricaneNodeMob
																distance={
																	Math.round(hurricane.hurricaneQty) * 7.5
																}
															/>
															<div className="yearLabel">
																{hurricane.decade}s
															</div>
														</div>
													</>
												))}
											</div>
											<div className="yAxisLabel"></div>
										</div>
									</HurricaneChartMob>
								</Grid>
							</>
						)}
						<Grid item xs style={{ alignSelf: "center" }}>
							<div className="hscore">
								<div className="group">
									<div className="quantity">{hurricanesCount.counter}</div>
									<div className="text">
										<p>
											Hurricane eyes have passed within 100 miles of your
											location in the last 100 years.
										</p>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</HurricaneBox>
		</>
	);
};

interface DesktopHurricanesGraphProps {
	qty: number;
}

export const DesktopHurricanesGraph: React.FC<DesktopHurricanesGraphProps> = (
	props: DesktopHurricanesGraphProps
) => {
	const hurricanesQty = useCountUp({
		end: props.qty,
		duration: 2,
		capDecimals: false
	});
	/* const [hurricanesQty] = useCountUp({
    to: props.qty,
    speed: 1700,
    capDecimals: false
  }); */

	return (
		<>
			<HurricaneNode distance={hurricanesQty.counter * 7.5}>
				<>
					{props.qty > 0 && (
						<>
							<HurricaneCards distance={hurricanesQty.counter * 7.5}>
								<div className="hurricaneLabel">
									<span>{Math.round(hurricanesQty.counter)}</span>
								</div>
							</HurricaneCards>
						</>
					)}
				</>
			</HurricaneNode>
		</>
	);
};
