// import _clone from "lodash/clone";
import React, { /* useState, */ useContext } from "react";
import * as ReactMapboxGl from "react-mapbox-gl";
import { makeStyles } from "@material-ui/styles";
import { useObserver } from "mobx-react-lite";
import MapMarkerSvg from "../../Content/map-pin.svg";
import { Stores, StoresContext } from "../../Stores/Stores";

const image: HTMLImageElement = new Image(56, 74);
image.src = MapMarkerSvg;
const images: [string, HTMLImageElement] = ["markerIcon", image];

const accessToken: string =
    "pk.eyJ1IjoiYW5keWp1bXAiLCJhIjoiY2pudnYzYWI4MDJ5eDNxbzhldWNhc3ZlZiJ9.fD-fnggIKvMR5QQLlb1M3Q";

const ReactMapboxGlMap: any = ReactMapboxGl.default({
    accessToken: accessToken
});

const DefaultZoom: [number] = [12.75];

const useStyles: any = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#FFFFFF",
        width: "1020px",
        minHeight: "250px"
    },
    map: {
        overflow: "none",
        width: "1020px",
        minHeight: "250px"
    }
}));

export const Map: React.FC = () => {
    const classes: Record<"root" | "map", string> = useStyles("map");
    const zoom: [number] = DefaultZoom;
    const mapStoreContext: any = useContext<Stores>(StoresContext).domain.MapStore;

    function handleOnDragEnd(dragResult: any): void {
        if (dragResult) {
    /*      let latitude: number = dragResult ? dragResult.lngLat.lat : 0;
            let longitude: number = dragResult ? dragResult.lngLat.lng : 0; */
        }
    }

    return useObserver(() => (
        <main role="main" className={classes.root}>
            <ReactMapboxGlMap
                key="mainMap"
                className={classes.map}
                style="mapbox://styles/mapbox/satellite-v9"
                center={[mapStoreContext.longitude, mapStoreContext.latitude]}
                zoom={zoom}
                fitBoundsOptions={{ duration: 0 }}
            >
                {[mapStoreContext.longitude, mapStoreContext.latitude] && (
                    <ReactMapboxGl.Layer
                        type="symbol"
                        id="marker"
                        layout={{ "icon-image": "markerIcon" }}
                        images={images}
                    >
                        <ReactMapboxGl.Feature
                            draggable={true}
                            onDragEnd={handleOnDragEnd}
                            coordinates={[
                                mapStoreContext.longitude,
                                mapStoreContext.latitude
                            ]}
                        />
                    </ReactMapboxGl.Layer>
                )}
                <ReactMapboxGl.ZoomControl />
                <ReactMapboxGl.ScaleControl />
            </ReactMapboxGlMap>
        </main>
    ));
};
