/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from "react";
import { Typography, FormControl, TextField, Box, Link } from "@material-ui/core";
import { LoginModel } from "./LoginModel";
import {
	useValidation,
	usePercentageHeightOfWidth,
	UseRouter
} from "../../Shoothill.Core/Utils";
import validator from "validator";

interface IProps {
	onSubmit: (loginModel: LoginModel) => void;
	isLoading: boolean;
	errorMessage?: string;
	loginBtnText?: string;
	hideForgot?: boolean;
}
export const LoginEmbed: React.FC<IProps> = props => {
	const { history } = UseRouter();
	const {
		onSubmit,
		isLoading,
		errorMessage = "",
		loginBtnText = "Login",
		hideForgot
	} = props;

	const [formState, { password, email }] = useValidation<LoginModel>({
		emailAddress: "",
		password: ""
	} as any);

	const el1 = useRef<HTMLDivElement>(null);
	const el2 = useRef<HTMLDivElement>(null);
	const el3 = useRef<HTMLButtonElement>(null);

	usePercentageHeightOfWidth([el1, el2, el3], 0.112);

	const doSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		if (formState.isFormValid()) {
			onSubmit(formState.formData);
		}
	};
		
	function onClickPasswordForgot(): void {
		history.push("/prouser/setpassword");
	}

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var isIE: boolean =
		msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

	return (
		<form onSubmit={doSubmit}>
			{errorMessage.length > 0 && (
				<Box pb={1} pt={1}>
					<Typography component="h6" color="error" variant="caption">
						{errorMessage}
					</Typography>
				</Box>
			)}

			<FormControl margin="normal" fullWidth className=" inputField">
				<TextField
					margin="normal"
					variant={(isIE ? undefined : "outlined") as "outlined"}
					placeholder="Email address"
					fullWidth
					autoComplete="email"
					ref={el1}
					{...email("emailAddress", {
						validationRules: [
							(v: string) => validator.isEmail(v) || "Email address is invalid",
							(v: string) => (v && v.length > 0) || "Email address is required"
						]
					})}
					className=" inputField"
				/>
			</FormControl>
			{/* 	{formState.showErrors("emailAddress", "login_error", 2)}  */}

			{/* EN: Example of a contrik without Textfield */}
			<FormControl margin="normal" fullWidth className=" inputField">
				<TextField
					margin="normal"
					variant={(isIE ? undefined : "outlined") as "outlined"}
					fullWidth
					placeholder="Password"
					autoComplete="current-password"
					ref={el2}
					{...password("password", {
						validationRules: [
							(v: string) => (v && v.length > 0) || "Password is required"
						]
					})}
				/>
				{/* 	{formState.showErrors("password", "login_error")} */}
			</FormControl>
			{/* <FormControlLabel
				control={
					<Checkbox
						value="true"
						id="rememberMe"
						name="rememberMe"
						color="primary"
					/>
				}
				label="Remember me"
			/> */}
			{ hideForgot !== undefined && hideForgot === true &&
			<>
			<Link onClick={onClickPasswordForgot} className={"ALink"}>Forgot Password</Link>
			</>
			}
			
			<button className={"submitBtn"} ref={el3}>
				{isLoading ? "Please Wait..." : loginBtnText}
			</button>
		</form>
	);
};
