import React from "react";
import { Box, Typography } from "@material-ui/core";
import potentialFloodRiskTool from "../../Content/icon-PotentialFloodRiskTool2.svg";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import myFloodRiskTool from "../../Content/icon-MyFloodRiskTool.svg";
import styled from "styled-components";
/* import { useMediaQuery } from "../../Shoothill.Core/Utils/Media"; */

export interface RowToolsCardProps {
	colorSet: boolean;
}

export const CardBox = styled(Box)`
	text-align: center;
	/* width: 100%; */
	max-width: 300px;
	margin: 5px auto 30px;

	.image{
		margin: 0 auto;
		width: 55px;
		height: 57px;
		img{
			height: auto;
			width: 100%;
		}
	}

    h3 {
        font-size: 28px;
        margin: 6px 0 8px 0;
        padding: 0;
    }

    p {
        font-size: 18px;
        line-height: 1.2;
        margin: 0 0 16px 0;
        padding: 0;
    }

    .buttonContainer {
        display: flex;
        justify-content: center;

        .button {
            border-radius: 5px;
            border: none;
            display: block;
            font-weight: bold;
            padding: 7px;
            text-align: center;
            width: 200px;
        }
    }

	@media (min-width: 1025px) {
		margin: 5px;
		max-width: 360px;
		margin: 5px;
		:first-child {
			margin-right: 60px;
		}

		.image {
			width: 125px;
			height: 127px;
		}

        h3 {
            margin: 14px 0 18px 0 ;
        }

        p {
            margin-bottom: 29px;
        }

        .buttonContainer {
            .button {
                border-radius: 10px;
                font-size: 1.25em;
                padding: 14px;
                width: 318px;
            }
        }
    }

	a:hover{
		text-decoration: none!important;
	}
`;

export interface CardBoxTxtProps {
	colorisset: string;
}

export const CardBoxTxt = styled(CardBox)<CardBoxTxtProps>`
	h3 {
		color: ${props => (props.colorisset === "true" ? "#4190ca" : "#ffffff")};
	}
	p {
		color: ${props => (props.colorisset === "true" ? "#222120" : "#ffffff")};
		font-weight: 400;
	}
	@media screen and (max-width: 1024px) {

		h3 {
			color: ${props => (props.colorisset === "true" ? "#4190ca" : "#222120")};
			font-size: 16px;
			letter-spacing: -0.4px;
		}
		p {
			color: ${props => (props.colorisset === "true" ? "#222120" : "#222120")};
			font-size: 12px;
			letter-spacing: -0.3px;
		}
	}
	@media (min-width: 1025px) {
		p {
			height: 90px; 
		}
	}
	.paddingTop15 {
		padding-top: 15px;
		font-weight: bold;
	}
	button,
	.button {
		background-color: ${props => (props.colorisset === "true" ? "#4190ca" : "#ffffff")};
		color: ${props => (props.colorisset === "true" ? "#ffffff" : "#4190ca")};
		font-weight: bold;
	}
`;

export const RowToolsCard: React.FC<RowToolsCardProps> = props => {
    return (
        <>
            <CardBoxTxt
                colorisset={props.colorSet.toString()}>
                <div
                    className="image">
                    <img
                        src={myFloodRiskTool}
                        alt={"My flood risk tool"} />
                </div>
                <Typography
                    variant="h3">
                    My Flood Risk Tool
                </Typography>
                <Typography>
                    The purpose of this tool is to provide an illustration of real-life, industry-proven flood factors, 
                    and to give a true understanding of potential flood risk.
                </Typography>
                <Box
                    className="buttonContainer">
                    <Link
                        className="button"
                        component={RouterLink}
                        to="/myrisk">
                        Try it now
                    </Link>
                </Box>
            </CardBoxTxt>
            <CardBoxTxt
                colorisset={props.colorSet.toString()}>
                <div
                    className="image">
                    <img
                        src={potentialFloodRiskTool}
                        alt={"Potential flood loss tool"} />
                </div>
                <Typography
                    variant="h3">
                    Potential Flood Loss Tool
                </Typography>
                <Typography>
                    The purpose of this tool is to provide an illustration of potential losses in the event of a flood based on
                    specific property factors such as home and content values.
                </Typography>
                <Box
                    className="buttonContainer">
                    <Link
                        className="button"
                        component={RouterLink}
                        to="/floodloss">
                        Try it now
                    </Link>
                </Box>
            </CardBoxTxt>
        </>
	);
};
