import React from "react";
import styled from "styled-components";

import usweatherLogo from "../../Content/usweatherLogo.png";
import femaLogo from "../../Content/femaLogo.png";
import usgsLogo from "../../Content/usgsLogo.png";
import noaaLogo from "../../Content/noaaLogo.png";

import { Link } from '@material-ui/core';


export interface IProps {
	misc?: string;
}

export const GovLogos: any = styled.div<IProps>`
    max-width: 527px;
    width: 100%;
    height: 69px;
    a {
        display: inline-block;
        margin-right: 25px;
    }
    a:last-child {
        margin-right: 0;
    }
    img {
        width: 100%;
        height: auto;
    }
    ${props => props.misc}
`;

export interface IIncLogosProps {
	miscCSS: string;
}

export const IncLogos: React.FC<IIncLogosProps> = props => {
    return (
        <>
        <GovLogos misc={props.miscCSS}>
            <Link
                href={"https://www.noaa.gov/"}
                title={"NOAA"}
                target={"_blank"}
            >
                <img src={noaaLogo} alt={"NOAA"} />
            </Link>
            <Link
                href={"https://www.fema.gov/"}
                title={"FEMA"}
                target={"_blank"}
            >
                <img src={femaLogo} alt={"FEMA"} />
            </Link>
            <Link
                href={"https://www.weather.gov/"}
                title={"US National Weather Service"}
                target={"_blank"}
            >
                <img src={usweatherLogo} alt={"US National Weather Service"} />
            </Link>
            <Link
                href={"https://www.usgs.gov/"}
                title={"USGS"}
                target={"_blank"}
            >
                <img src={usgsLogo} alt={"USGS"} />
            </Link>
        </GovLogos>
        </>
    )
}