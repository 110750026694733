import React, { useContext, useEffect } from "react";
import moment from "moment";

import { StoresContext, Stores, DomainStores } from "../../Stores";
import { AuditProOrderModel } from 'Models';

import { Box, Grid, Button, Modal, Typography, Table, TableBody, TableCell, TableSortLabel, TableHead, TableRow, Paper, Link} from "@material-ui/core";
import { useReportStyles, ReportModalBox } from "../../Shoothill.Components/Styles/ReportStyles";
import { Row1040 } from "../../Shoothill.Components/Styles/BlogRefStyles";
import { useAdminStyles} from "../../Shoothill.Components/Styles/AdminStyles";
import { useRefLibStyles } from "../../Shoothill.Components/Styles/RefLibStyles";
import editIcon from "../../Content/visibility-24px.svg";
import { useObserver } from 'mobx-react-lite';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

interface IProps {
    userGuId: string;
    sDate: string;
    eDate: string;
}

export interface headCell {
    id: keyof AuditProOrderModel;
    label: string;
    allowSorting: boolean;
}

export const OrderListComp: React.FC<IProps> = props => {
    const classes: any = useReportStyles();
    const adminClasses: any = useAdminStyles();
    const refClasses: any = useRefLibStyles("contact");

    const AuditProStoresContext: DomainStores["AuditProStores"] = useContext<Stores>(StoresContext).domain.AuditProStores;

    function downloadCSV(): void {
      window.open(
          AuditProStoresContext.getAPICall("/api/auditprouser/getfzdcsv", {
              start: formatDate(new Date(props.sDate)),
              end: formatDate(new Date(props.eDate), true),
              guid: props.userGuId
          })
      );
    }

    function formatDate(date: Date, isEndDate: boolean = false): string {
      let count: number = 0;
      if (isEndDate) {
          count = 1;
      }
      const utc: moment.Moment = moment(date).add(count, "days").startOf("day").utc();
      return utc.toISOString();
    }

    function formatDateTime(date: string): string {
        const mom: moment.Moment = moment.utc(date);
        return mom.local().format("MM/DD/YYYY HH:mm");
    }

    function handleLoadMore(): void {
        AuditProStoresContext.getMoreUserRecords();
    }

    const handleCounterChange: (event: any, newValue: number) => void = (event: any, newValue: number): void => {
      AuditProStoresContext.setMoreOrderCounter(newValue);
    };

    function showLoadMore(): boolean {
        return (
            AuditProStoresContext.auditProOrders.length >
            AuditProStoresContext.tableOrderCount
        );
    }

    const createSortHandler = (property: keyof AuditProOrderModel) => (event: React.MouseEvent<unknown>) => {
        AuditProStoresContext.sortOrderHandler(property);
    };

    const headCells: headCell[] = [
        { id: 'createdOn', label: 'Date Created', allowSorting: true },
        { id: 'addressReport', label: 'Address of report', allowSorting: true },
        { id: 'type', label: 'Type', allowSorting: true },
        { id: 'reportPath_supplemental', label: 'Report Link', allowSorting: false}
    ];

    const seeUserOrdersClick: () => void = (): void => {
        /* onClickValue(""); */
    };

    return useObserver(() => (
        <>
        <div className="buttons">
          <Button
            className={adminClasses.adminBtn}
            variant="outlined"
            onClick={downloadCSV}
          >
            {"Download CSV"}
          </Button>
        </div>
        <div className="adminReportResults">
          { AuditProStoresContext.isLoading === true &&
            <div>Loading!!!!!!!!!!!!!!!</div>
          }

          { (AuditProStoresContext.isLoading === false && AuditProStoresContext.filteredOrderRecords.length > 0) &&
            <>
              <div>
              <div className={classes.toggleContainer}>
                  <ToggleButtonGroup
                    value={AuditProStoresContext.moreMFRCounter.toString()}
                    exclusive
                    onChange={handleCounterChange}
                    aria-label="text alignment"
                      >
                        <ToggleButton value="10" aria-label="left aligned">
                          10
                        </ToggleButton>
                        <ToggleButton value="50" aria-label="centered">
                          50
                        </ToggleButton>
                        <ToggleButton value="100" aria-label="right aligned">
                          100
                        </ToggleButton>
                      </ToggleButtonGroup>
                  <div className={classes.toggleContainerSummary}>
                      Showing 1-{AuditProStoresContext.filteredMFRRecords.length}
                      &nbsp;of {AuditProStoresContext.filteredMFRRecords.length} Records.
                  </div>
                </div>
              <div className={classes.horizontalScroll}>
                <Table className={classes.table}>
                  <TableHead>
                      <TableRow className={classes.tableHeader}>
                          {headCells.map(headCell => (
                            <TableCell
                              key={headCell.id}
                              className={classes.tableHeaderCell}
                              sortDirection={AuditProStoresContext.orderBy === headCell.id ? AuditProStoresContext.order : false}
                            >
                              <TableSortLabel
                                active={AuditProStoresContext.orderBy === headCell.id}
                                direction={AuditProStoresContext.order}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                                {AuditProStoresContext.orderBy === headCell.id ? (
                                  <span className={classes.visuallyHidden}>
                                    {AuditProStoresContext.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {(AuditProStoresContext.filteredOrderRecords != null && AuditProStoresContext.filteredOrderRecords.length > 0) &&
                        <>
                          {AuditProStoresContext.filteredOrderRecords.map((n: AuditProOrderModel, index: number) => {
                            return (
                              <TableRow key={index}
                                onClick={() => seeUserOrdersClick()}
                                className={""}>
                                <TableCell className={classes.tableRowCellDate}>
                                    {formatDateTime(n.createdOn)}
                                </TableCell>
                                <TableCell className={classes.tableRowCellAddress}>
                                    {n.addressReport}
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                    { n.type === 1 &&
                                    <>Guaranteed</>
                                    }
                                    { n.type !== 1 &&
                                    <>Non-guaranteed</>
                                    }
                                </TableCell>
                                <TableCell className={classes.tableRowCell}>
                                    <Link
                                        href={n.reportPath_supplemental}
                                        target={"_blank"}
                                    >
                                        <img className={classes.editIcon} src={editIcon} alt="View Report"/>
                                    </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      }
                  </TableBody>
                </Table>
              </div>
              <div className={classes.whiteToBlueGrad}>
                {showLoadMore() && (
                    <Row1040 container>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                className={refClasses.loadMoreBtn}
                                onClick={handleLoadMore}
                            >
                                Load more
                            </Button>
                        </Grid>
                    </Row1040>
                )}
              </div>
              </div>
            </>
          }
        </div>
        </>
    ));
}