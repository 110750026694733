import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
	MyFZDReportBox,
	MyFZDReportOrder,
	TypeContainer,
	MobileOrder,
	MobileOrderText
} from "./MyFZDReportStyling";
import { UseDataApi, usePercentageHeightOfWidth } from "Shoothill.Core/Utils";
import * as AppUrls from "../../Globals/AppUrls";
import { ProAccountReports } from "../../Models";
import Link from "@material-ui/core/Link";

import reportIcon from "../../Content/reportIcon.svg";
import reportIconGuarenteed from "../../Content/reportIconGuarenteed.svg";

import FitText from "@kennethormandy/react-fittext";
import { useMediaQuery } from "@material-ui/core";
import { GenerateID } from "Shoothill.Core/Utils/Utils";

interface ReportTypeProps {
	reports: ProAccountReports[];
	type: number;
	reportsprops: MyFZDReportsProps;
}

interface ReportOrderProps extends ReportTypeProps {
	report: ProAccountReports;
}

export const MyFZDReportOrderComp: React.FC<ReportOrderProps> = props => {
	const el = useRef<HTMLDivElement>(null);

	const [reportStep, setReportStep] = props.reportsprops.reportStep;

	let useMobile = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	function formatDate(thisDate: string): string {
		let retVal: string = moment(thisDate).format("DD/MM/YYYY");
		return retVal;
	}

	function onResize() {
		window.setTimeout(() => {
			if (el.current) {
				var elC = el.current.children[0];
				var style = window.getComputedStyle(elC as HTMLDivElement);
				if (style.lineHeight) {
					if (elC.scrollHeight > elC.clientHeight) {
						(elC as HTMLDivElement).style.height = ``;
					} else {
						(elC as HTMLDivElement).style.height = `${+style.lineHeight.replace(
							"px",
							""
						) * 2}px`;
					}
				}
			}
		}, 500);
	}

	useEffect(() => {
		onResize();
	}, [el.current]);

	useEffect(() => {
		window.addEventListener("resize", onResize);

		return () => window.removeEventListener("resize", onResize);
	});

	return (
		<>
			<MyFZDReportOrder
				className={
					!!props.report.reportPath_supplemental &&
					props.report.paymentStatus === "succeeded"
						? " done"
						: !!!props.report.reportPath_supplemental &&
						  props.report.paymentStatus === "succeeded"
						? " pending"
						: " unpaid"
				}
			>
				{useMobile ? (
					<>
						<MobileOrder>
							<img
								src={props.type === 1 ? reportIconGuarenteed : reportIcon}
								className="iconImg"
								alt={"Report"}
							/>
							<MobileOrderText>
								<div className="orderDateMob">
									<FitText compressor={1.84} debounce={0}>
										Date ordered:{" "}
										{formatDate(props.report.createdOn.toString())}
									</FitText>
								</div>
								<div
									className="orderDetailMob"
									ref={el}
									key={props.report.reportPath_sfhdf}
								>
									<FitText compressor={1.84} debounce={0}>
										{props.report.addressReport}
									</FitText>
								</div>
								<div className="orderStatus">
									<FitText compressor={1.38} debounce={0}>
										{!!props.report.reportPath_supplemental &&
										props.report.paymentStatus === "succeeded" ? (
											<>
												<Link
													href={
														"/Report/" + props.report.reportPath_supplemental
													}
												>
													DOWNLOAD
												</Link>
											</>
										) : !!!props.report.reportPath_supplemental &&
										  props.report.paymentStatus === "succeeded" ? (
											<>
												<p>PENDING</p>
											</>
										) : (
											<>
												<Link
													href="/pro"
													onClick={(e: any) => {
														e.preventDefault();
														props.reportsprops.type.current = props.report.type;
														setReportStep(6);
													}}
												>
													UNPAID
												</Link>
											</>
										)}
									</FitText>
								</div>
							</MobileOrderText>
						</MobileOrder>
					</>
				) : (
					<>
						<div className="certIcon">
							<img
								src={props.type === 1 ? reportIconGuarenteed : reportIcon}
								alt={"Report"}
							/>
						</div>
						<div className="orderDetail">{props.report.addressReport}</div>

						<div className="orderDate">
							Date ordered: {formatDate(props.report.createdOn.toString())}
						</div>

						<div className="orderStatus">
							{!!props.report.reportPath_supplemental &&
							props.report.paymentStatus === "succeeded" ? (
								<>
									<Link
										href={"/Report/" + props.report.reportPath_supplemental}
									>
										DOWNLOAD
									</Link>
								</>
							) : !!!props.report.reportPath_supplemental &&
							  props.report.paymentStatus === "succeeded" ? (
								<>
									<p>PENDING</p>
								</>
							) : (
								<>
									<Link
										href="/pro"
										onClick={(e: any) => {
											e.preventDefault();
											props.reportsprops.type.current = props.report.type;
											setReportStep(6);
										}}
									>
										UNPAID
									</Link>
								</>
							)}
						</div>
					</>
				)}
			</MyFZDReportOrder>
		</>
	);
};

export const MyFZDReportsType: React.FC<ReportTypeProps> = props => {
	return (
		<TypeContainer>
			{props.reports
				.filter((report: ProAccountReports) => report.type === props.type)
				.map((report: ProAccountReports, index: number) => {
					return (
						<div key={report.reportPath_supplemental + GenerateID()}>
							<MyFZDReportOrderComp report={report} {...props} />
						</div>
					);
				})}
		</TypeContainer>
	);
};

interface MyFZDReportsProps {
	reportStep: [number, React.Dispatch<React.SetStateAction<number>>];
	type: React.MutableRefObject<number>;
}

export const MyFZDReports: React.FC<MyFZDReportsProps> = props => {
	const { data, doRequest } = UseDataApi();
	const [reports, setReports] = useState(new Array<ProAccountReports>());

	let useMobile: boolean = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	useEffect(() => {
		if (data) {
			var dataReports: ProAccountReports[] = data.payload;
			setReports(dataReports);
		} else {
			doRequest(
				AppUrls.Server.Api.ProAccountReportRequest.GetProAccountReports
			);
		}
	}, [data]);

	const FZDReportsHeading = (
		<>
			<h2>
				My Flood Zone{" "}
				{useMobile && (
					<>
						<br />
					</>
				)}
				Determination Reports
			</h2>
		</>
	);

	const ngHeading = (
		<>
			<h4>
				<span>Non-Guaranteed</span>
			</h4>
		</>
	);

	const gHeading = (
		<>
			<h4>
				<span>Guaranteed</span>
			</h4>
		</>
	);

	return (
		<>
			{reports.length > 0 && (
				<>
					<MyFZDReportBox>
						{useMobile ? (
							<>
								<FitText compressor={1.63} debounce={0}>
									{FZDReportsHeading}
								</FitText>
							</>
						) : (
							<>{FZDReportsHeading}</>
						)}
						{/* 				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sapien
					elit, malesuada non nunc id, porta eleifend dui. Aliquam non massa
					eget nisl sagittis luctus id eu nisl.
				</p> */}
						{reports.filter(rep => rep.type === 2).length > 0 && (
							<div>
								{useMobile ? (
									<>
										<FitText compressor={1.84} debounce={0}>
											{ngHeading}
										</FitText>
									</>
								) : (
									<>{ngHeading}</>
								)}
								<MyFZDReportsType
									reports={reports}
									type={2}
									reportsprops={props}
								/>
							</div>
						)}
						{reports.filter(rep => rep.type === 1).length > 0 && (
							<div>
								{useMobile ? (
									<>
										<FitText compressor={1.84} debounce={0}>
											{gHeading}
										</FitText>
									</>
								) : (
									<>{gHeading}</>
								)}
								<MyFZDReportsType
									reports={reports}
									type={1}
									reportsprops={props}
								/>
							</div>
						)}
					</MyFZDReportBox>
				</>
			)}
		</>
	);
};
