import { BaseStore } from "./BaseStore";
import { RegionCost } from "../../Models";
import { observable, action, computed } from "mobx";
import { Stores } from "./../Stores";

export class RegionCostStore extends BaseStore {
    @observable public regionCosts: RegionCost[] = [];

    public constructor() {
        super();
    }

    // tslint:disable-next-line: no-empty
    public init(stores: Stores): void {}

    @action
    public SetRegionCosts(regionCosts: RegionCost[]): void {
        this.regionCosts = regionCosts;
    }

    @action
    public AddRegionCost(regionCost: RegionCost): void {
        this.regionCosts.push(regionCost);
    }

    @action GetCostByRegion(region: string): string {
        const regionCost: RegionCost | undefined = this.regionCosts.find(c => c.region === region);
        return regionCost ? regionCost!.dollarsPerSquareFoot : "";
    }

    @computed get GetRegionCosts(): RegionCost[] {
        var filteredRegionCosts: RegionCost[] = [];

        this.regionCosts.map(regionCost => {
            filteredRegionCosts.push(regionCost);
        });

        return filteredRegionCosts;
    }
}
