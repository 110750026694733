import { useState, useRef, useEffect, RefObject } from "react";
import { CountUp as PlainCU } from "countup.js";
import BezierEasing from "bezier-easing";
/* import { BrowserRouter } from "react-router-dom"; */

// const easing = BezierEasing(0.38, 0.22, 0.5, 0.99);
// const easing = BezierEasing(0.45, 0.13, 0.02, 0.99);
const easing = BezierEasing(0.73, 0.85, 0.86, 0.99);

function countDecimals(n: number): number {
	let retVal: number = 0;

	if (Math.floor(n) === n) {
		// nothing to do. return 0
	} else {
		let components: string[] = n.toString().split(".");

		if (components.length > 1) {
			retVal = components[1].length;
		} // else leave it as zero
	}

	return retVal;
	// original code which occasionally crashes, but I can't get it breakpoint when it does.
	// return Math.floor(n) === n ? 0 : n.toString().split(".")[1].length || 0;
}

function widestNumber(n: number, dp: number): number {
	return +(
		new Array(Math.floor(n).toString().length + 1).join("9") +
		"." +
		new Array(dp + 1).join("9")
	);
}

function doEase(t: number, b: number, c: number, d: number) {
	if (t === 0) return b;
	if (t === d) return b + c;
	var perc = t / d;
	var num = easing(perc) * (c + b);
	return num;
}

export default function useCountUp({
	end,
	duration,
	capDecimals = true,
	autoStart = true,
	start = 0,
	callback,
	callbackOverlap = 1,
	easeStart = true
}: {
	end: number;
	duration: number;
	capDecimals?: boolean;
	autoStart?: boolean;
	start?: number;
	callback?: () => void;
	callbackOverlap?: number;
	easeStart?: boolean;
}): {
	counter: number;
	start: () => void;
	reset: () => void;
	containerEl: RefObject<HTMLDivElement>;
} {
	end = end || 0;
	var dp: number = countDecimals(end);
	var decimals = capDecimals ? dp : 10;
	var widnum = widestNumber(end, dp);
	/* var calcedMaxWid: number = 0; */
	const [count, setCount] = useState(start);
	const el: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
	var reset = () => {
		setCount(0);
	};
	const go = () => {
		setCount(start);
		const countUp: PlainCU = new PlainCU(document.createElement("div"), end, {
			duration: duration,
			decimalPlaces: decimals,
			startVal: start,
			easingFn: easeStart ? doEase : undefined
		});
		countUp.formattingFn = (num: number) => {
			setCount(num);
			if (callback && num / end >= callbackOverlap) {
				callback();
				callback = () => {};
			}
			return String(num);
		};
		countUp.start();
		reset = () => {
			countUp.reset();
		};
	};

	useEffect(() => {
		setCount(widnum);
	}, [el.current]);

	useEffect(() => {
		if (count === widnum) {
			if (el.current) {
				el.current.style.width = "";
				var style: CSSStyleDeclaration = window.getComputedStyle(el.current);
				if (style.width) {
					el.current.style.width = /* style.width */ `${el.current.clientWidth}px`;
				}
			}

			if (widnum !== end) {
				setCount(start);
			}
		}
	}, [count]);

	useEffect(() => {
		if (autoStart) {
			go();
		}
	}, []);
	return {
		counter: count,
		start: go,
		reset: reset,
		containerEl: el
	};
}

export function useGroupedCountUp({
	end,
	duration,
	capDecimals = true,
	easeStart = true
}: {
	end: number[];
	duration: number;
	capDecimals?: boolean;
	easeStart?: boolean;
}) {
	const [counters, setCounters] = useState(new Array(end.length).fill(0));
	const counter = useCountUp({
		end: Math.max(...end),
		duration: duration,
		capDecimals: capDecimals,
		easeStart: easeStart
	});
	useEffect(() => {
		end.forEach((e, i) => {
			var newCounters = counters;
			newCounters[i] = Math.min(e, counter.counter);
			setCounters(newCounters);
		});
	}, [counter.counter]);
	return [...counters];
}
