import styled from "styled-components";
import { Box, Button } from "@material-ui/core";

export const MyFZDReportBox: any = styled(Box)`
	font-family: "Lato" sans-serif;
	border-top: solid 1px #787878;
	padding: 30px 0px;
	width: 100%;

	h4 {
		font-weight: Bold;
		font-size: 21px;
		color: #4190ca;
		margin: 30px 0;
		line-height: 100%;

		@media (max-width: 1140px) {
			margin: 6.8% 0 3.4% !important;
		}
	}

	@media (max-width: 1140px) {
		padding: 10.2% 0 0;
	}
`;

export const TypeContainer: any = styled.div``;

export const MyFZDReportOrder: any = styled(Box)`
	font-size: 14px;
	font-weight: Bold;
	width: 100%;
	height: 64px;
	border-radius: 10px;
	box-shadow: 0px 3px 6px #00000029;
	background: #fff 0% 0% no-repeat padding-box;
	margin: 15px 0px;
	padding: 0;
	text-align: right;
	position: relative;

	@media (max-width: 1140px) {
		margin: 3.4% 0;
	}

	.opac {
		opacity: 1;
	}

	.orderDetail,
	.orderDate,
	.orderStatus,
	.certIcon {
		position: absolute;
	}

	.certIcon {
		top: 18px;
		left: 19px;
		height: calc(100% - 32px);
		display: flex;
		align-items: center;
		img {
			width: 25px;
			height: auto;
		}
	}

	.orderDetail {
		left: 60px;
		text-align: left;
		top: 22px;
		width: 515px;
	}

	.orderDate {
		top: 22px;
		left: 600px;
		text-align: left;
	}

	.orderStatus {
		right: 24px;
		text-align: right;
		width: 33%;
		width: 127px;
		top: 17px;
	}

	.orderStatus a {
		position: unset;
		color: #4190ca;
	}

	.orderStatus,
	.orderStatus a {
		font-size: 21px;
		text-decoration: none;
	}

	@media (max-width: 1140px) {
		height: fit-content;
	}
`;

export const MobileOrder: any = styled.div`
	padding: 5.1%;
	display: flex;

	img {
		width: 10.6%;
		margin: auto;
		margin-top: 0;
	}
`;

export const MobileOrderText: any = styled.div`
	margin-left: 5.7%;
	width: 100%;

	div {
		text-align: left;
	}
	.orderDateMob {
		font-weight: bold;
	}
	.orderDetailMob {
		font-weight: normal;
		line-clamp: 2;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.orderStatus {
		position: static;
		width: initial;
		overflow: hidden;

		a {
			margin: 0 !important;
		}

		> div {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;
