export class RegProUserFormModel {
	public firstName: string = "";
	public surname: string = "";
	public emailAddress: string = "";
	public companyName: string = "";
	public companylogoPath: string = "";
	public companyType: string = "";
	public password: string = "";
	public passwordConfirm: string = "";
}
