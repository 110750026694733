import { DomainStores } from "./DomainStores";
/* eslint-disable @typescript-eslint/no-parameter-properties */
import * as History from "history";
import * as Models from "../Models";
import * as MobX from "mobx";
import { createContext } from "react";

export class Stores {
	public history: History.History | undefined;
	public domain: DomainStores = new DomainStores();

	public init(
		initialState: Models.InitialState,
		history: History.History
	): void {
		this.history = history;
		this.domain.init(this, initialState);
		// en: For debugging
		(window as any).Stores = this;
		(window as any).MobX = MobX;
	}

	public populate(json: string): void {
		var stores: any = JSON.parse(json);
		this.domain.populate(stores.domain);
	}
}
export const StoresContext: React.Context<Stores> = createContext(new Stores());
