import { 
  Box,
  Tab,
  useMediaQuery 
} from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react-lite";
import { ArticleAdmin } from "./ArticleAdmin";
import { BlogAdmin } from "./BlogAdmin";
import { ReportAdmin } from "./ReportAdmin";
import { ProAdminComp } from "./ProAdmin/ProAdminComp";
import { WidgetAdminComp } from "./WidgetAdmin/WidgetAdminComp";
import {
  useAdminStyles,
  TabsCustom
} from "../Shoothill.Components/Styles/AdminStyles";

import { AppHeader } from "../Shoothill.Components/Styles/BlogRefStyles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export const Admin: React.FC = () => {
  const classes = useAdminStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  let useMobile: boolean;
  useMobile = useMediaQuery("screen and (max-width: 767px)")
      ? true
      : false;

  return useObserver(() => (
    <>
      <div>
        <AppHeader className={classes.adminHeader}>
          <Typography variant="h1" className={classes.adminHeading}>
            Admin
          </Typography>
          <div className={classes.tabsContainer}>
            <TabsCustom
              value={value}
              onChange={handleChange}
              aria-label="My Flood Risk Admin"
              className={classes.tabsWidth}
              variant={
                useMobile 
                ? "scrollable"
                : "standard"
              }
              scrollButtons="auto"
            >
              <Tab label="Blog" {...a11yProps(0)} />
              <Tab label="Reference Library" {...a11yProps(1)} />
              <Tab label="Reports" {...a11yProps(2)} />
              <Tab label="Pro Accounts" {...a11yProps(3)} />
              <Tab label="Widget Reports" {...a11yProps(4)} />
            </TabsCustom>
          </div>
        </AppHeader>
      </div>

      <TabPanel value={value} index={0}>
        <BlogAdmin />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ArticleAdmin />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReportAdmin />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProAdminComp />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <WidgetAdminComp />
      </TabPanel>
    </>
  ));
};
