/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState, useReducer } from "react";
import { GenerateID } from "../Utils";
import * as React from "react";

export const CHECKBOX: string = "checkbox";
export const COLOR: string = "color";
export const DATE: string = "date";
export const EMAIL: string = "email";
export const MONTH: string = "month";
export const NUMBER: string = "number";
export const PASSWORD: string = "password";
export const RADIO: string = "radio";
export const RANGE: string = "range";
export const SEARCH: string = "search";
export const SELECT: string = "select";
export const TEL: string = "tel";
export const TEXT: string = "text";
export const TIME: string = "time";
export const URL: string = "url";
export const WEEK: string = "week";
/**
 * @todo add support for datetime-local
 */
export const DATETIME_LOCAL: string = "datetime-local";
/**
 * @todo add support for a multiple select
 */
export const MULTIPLE: string = "multiple";

export const TYPES: string[] = [
	CHECKBOX,
	COLOR,
	DATE,
	EMAIL,
	MONTH,
	NUMBER,
	PASSWORD,
	RADIO,
	RANGE,
	SEARCH,
	SELECT,
	TEL,
	TEXT,
	TIME,
	URL,
	WEEK
];

type Maybe<T> = T;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type InputValues<T> = { readonly [A in keyof T]: T[A] } & {
	readonly [key: string]: Maybe<string | string[]>;
};
type InputValuesErrors<T> = { readonly [A in keyof T]: Maybe<string[]> } & {
	readonly [key: string]: Maybe<string[]>;
};

type InputValuesValidity<T> = { readonly [A in keyof T]: Maybe<boolean> } & {
	readonly [key: string]: Maybe<boolean>;
};

interface InputOptions {
	value?: any;
	validationRules?: any[];
}

interface Inputs {
	select(name: string, inputOptions?: InputOptions): Omit<InputProps, "type">;
	email(name: string, inputOptions?: InputOptions): InputProps;
	color(name: string, inputOptions?: InputOptions): InputProps;
	password(name: string, inputOptions?: InputOptions): InputProps;
	text(name: string, inputOptions?: InputOptions): InputProps;
	url(name: string, inputOptions?: InputOptions): InputProps;
	search(name: string, inputOptions?: InputOptions): InputProps;
	number(name: string, inputOptions?: InputOptions): InputProps;
	range(name: string, inputOptions?: InputOptions): InputProps;
	tel(name: string, inputOptions?: InputOptions): InputProps;
	radio(name: string, inputOptions?: InputOptions): InputProps & ICheckedProp;
	checkbox(
		name: string,
		inputOptions?: InputOptions
	): Omit<InputProps & ICheckedProp, "type">;
	date(name: string, inputOptions?: InputOptions): InputProps;
	month(name: string, inputOptions?: InputOptions): InputProps;
	week(name: string, inputOptions?: InputOptions): InputProps;
	time(name: string, inputOptions?: InputOptions): InputProps;
}

interface ICheckedProp {
	checked: boolean;
}

interface IFormState<T> {
	formData: InputValues<T>;
	errors: InputValuesErrors<T>;
	validity: InputValuesValidity<T>;
	touched: InputValuesValidity<T>;
	focused: InputValuesValidity<T>;
	blurred: InputValuesValidity<T>;
	isFormValid(): boolean;
	validate(): void;
	handleSubmit(e: any): any;
	showErrors(name: string, className?: string, amountToShow?: number): any;
	wipeFormData(): void;
	remount(): void;
	resetValidationCallbacks(): void;
	populateFormData(data: T): void;
}

interface InputProps {
	onChange(e: any): void;
	onBlur(e: any): void;
	onFocus(e: any): void;
	checked: boolean;
	value: string | number | string[] | undefined;
	name: string;
	id: string;
	type?: string;
	error: boolean;
}

export default function stateReducer(state: any, newState: any): any {
	return { ...state, ...newState };
}

interface IOptions {
	validateOnBlur?: boolean;
	validateOnChange?: boolean;
	validateOnFocus?: boolean;
	handleSubmitCallback?: any;
	invalidAttr?: any;
	submitCallback?: any;
}

const defaultOptions: IOptions = {
	validateOnBlur: true,
	validateOnChange: false,
	validateOnFocus: false,
	handleSubmitCallback: null,
	invalidAttr: { error: false },
	submitCallback: null
};

// must be global so as not to reset state. Hacky workaround!
// let validationCallbacks: {} = {} as any;

/**
 *
 * @param defaultValues
 * @param options
 *
 * Usage: const [formState, { text, checkbox }] = useValidation({email: "info@shoothill.com"});
 *
 */
export function useValidation<T>(
	defaultValues: T,
	options: IOptions = defaultOptions
): [IFormState<T>, Inputs] {
	options = { ...defaultOptions, ...options };

	const initErrors: any = (defaultValues: T) => {
		let obj: any = {};
		if (defaultValues) {
			Object.keys(defaultValues).forEach(keyName => {
				obj[keyName] = [];
			});
		}
		return obj;
	};

	const [mounted, setMounted] = useState(false);
	const [formData, setFormData] = useReducer(stateReducer, defaultValues || {});
	const [touched, setTouchedState] = useReducer(stateReducer, {});
	const [blurred, setBlurredState] = useReducer(stateReducer, {});
	const [errors, setErrorsState] = useReducer(
		stateReducer,
		initErrors(defaultValues) || {}
	);
	const [focused, setFocusedState] = useReducer(stateReducer, {});
	const [validity, setValidityState] = useReducer(stateReducer, {});
	const [initialValidation, setInitialValidation] = useState(true);
	const [validationCallBacks, setValidationCallBacks] = useReducer(
		stateReducer,
		{} as any
	);
	const [uniqueId] = useState(GenerateID());

	const wipeFormData: () => void = (): void => {
		setFormData(defaultValues);
	};

	const populateFormData: (data: T) => void = (data: T): void => {
		setFormData(data);
	};

	const handleSubmit: (event: any) => void = (event: any): void => {
		if (event) {
			event.preventDefault();
		}
		if (options.submitCallback) {
			options.submitCallback();
		}
	};

	const showErrors: any = (
		name: string,
		className: string = "",
		amountToShow: number = 1
	) => {
		let retval: any[] = [];
		if (!validity[name]) {
			if (errors[name] && errors[name].length > 0) {
				errors[name].forEach((error: string, index: number) => {
					if (index === amountToShow) {
						return;
					}
					retval.push(
						<div key={`error-${name}-${index}`} className={className}>
							{error}
						</div>
					);
				});
			}
		}
		return retval;
	};

	const validate: () => void = (): void => {
		(window as any)[uniqueId] = formData;
		(window as any)[uniqueId].validity = {} as any;
		// toggle state to force validation
		Object.keys(validationCallBacks).forEach((name: string) => {
			if (validationCallBacks[name] === undefined) return;
			validationCallBacks[name](formData[name], initialValidation);
		});
	};

	const isFormValid: () => boolean = (): boolean => {
		validate();
		let localValidity: any = validity;
		let isValid: boolean = true;
		if ((window as any)[uniqueId].validity) {
			setValidityState((window as any)[uniqueId].validity);
			localValidity = (window as any)[uniqueId].validity;
		}
		Object.keys(defaultValues).forEach(keyName => {
			if (localValidity[keyName] === false) {
				isValid = false;
				return false;
			}
		});
		return isValid;
	};

	// initial mounted flag
	useEffect(() => {
		setMounted(true);
		setInitialValidation(false);
	}, []);

	const remount = () => {
		setMounted(false);
	};

	const resetValidationCallbacks = () => {
		Object.keys(validationCallBacks).forEach(keyName => {
			setValidationCallBacks({
				[keyName]: undefined
			});
		});
	};

	const createPropsGetter: any = (type: any) => (
		name: string,
		inputOptions: InputOptions
	) => {
		const hasValue: any = formData[name] !== undefined;
		if (!inputOptions) {
			inputOptions = {};
		}
		let { value, validationRules } = inputOptions;

		const handleValidation: any = (
			value: any,
			initialValidation: boolean = false
		): boolean => {
			var isValid: boolean = true;
			setErrorsState({ [name]: [] });

			if (validationRules) {
				let newErrors: string[] = [];

				validationRules!.forEach((rule, index) => {
					// en: Arrggghh god damn closures. Dirty hack until I figure out how to bypass block level scope on a closure
					let currentState: any = (window as any)[uniqueId];
					if (!currentState) {
						currentState = formData;
					}

					var valid: any = rule(value, currentState);
					// assume valid unless at least one rule fails
					if (valid !== true) {
						isValid = false;
						// don't show error text on initial validation
						newErrors.push(valid);
						setErrorsState({ [name]: newErrors });
					}
				});
			}
			setValidityState({ [name]: isValid });
			// en: Hack to get around closures :-(
			if ((window as any)[uniqueId] && (window as any)[uniqueId].validity) {
				(window as any)[uniqueId].validity[name] = isValid;
			}
			return isValid;
		};

		if (!mounted) {
			if (validationCallBacks[name] === undefined) {
				setValidationCallBacks({
					[name]: (value: any, initialRun: boolean) => {
						handleValidation(value, validationCallBacks, initialRun);
					}
				});
			}
		}

		const inputProps: any = {
			name,
			get id(): string {
				return name;
			},
			...{ error: validity[name] === false },
			get type(): any {
				if (type !== SELECT) {
					return type;
				}
				return "";
			},
			get checked(): boolean | string | any {
				if (type === CHECKBOX) {
					return hasValue ? formData[name] : false;
				}
				if (type === RADIO) {
					return formData[name] === value;
				}

				return "";
			},
			get value(): any {
				// populating values of the form state on first render
				if (!hasValue) {
					setFormData({ [name]: type === CHECKBOX ? [] : "" });
				}
				if (type === CHECKBOX || type === RADIO) {
					return value;
				}
				if (hasValue) {
					return formData[name];
				}
				return "";
			},
			onChange(e: any): any {
				if (e instanceof Date || type === DATE) {
					setFormData({ [name]: e });
					if (options.validateOnChange) {
						handleValidation(e, false);
					}
				} else {
					const { value: targetValue, checked } = e.target;
					// let inputValue: string = validator.stripLow(targetValue, true);
					let inputValue: any = targetValue;
					if (type === CHECKBOX) {
						setFormData({ [name]: checked });
					} else {
						setFormData({ [name]: inputValue });
					}
					if (options.validateOnChange) {
						handleValidation(inputValue, false);
					}
				}
			},
			onFocus(e: any): any {
				const { value: targetValue } = e.target;
				let inputValue: any = targetValue;
				setFocusedState({ [name]: true });
				setBlurredState({ [name]: false });
				if (options.validateOnFocus) {
					handleValidation(inputValue, false);
				}
			},
			onBlur(e: any): any {
				const { value: targetValue } = e.target;
				let inputValue: any = targetValue;
				setTouchedState({ [name]: true });
				setBlurredState({ [name]: true });
				if (options.validateOnBlur) {
					handleValidation(inputValue, false);
				}
			}
		};
		return inputProps;
	};

	const typeMethods: any = TYPES.reduce(
		(methods: any, type: string) => ({
			...methods,
			[type]: createPropsGetter(type)
		}),
		{}
	);

	return [
		{
			formData,
			errors,
			focused,
			blurred,
			touched,
			validity,
			isFormValid,
			validate,
			handleSubmit,
			showErrors,
			wipeFormData,
			remount,
			resetValidationCallbacks,
			populateFormData
		},
		typeMethods
	];
}
