import { BaseStore } from "./BaseStore";
import { HurricaneDataModel } from "../../Models";
import { observable, action, computed } from "mobx";
import { Stores } from "../Stores";

export class HurricaneDataStore extends BaseStore {
	@observable public HurricaneData: HurricaneDataModel | undefined;
	@observable public HurricaneDataArr: HurricaneDataModel[] = [];

	@observable public season: number = 0;
	@observable public name: string = "";
	@observable public basin: string = "";
	@observable public subBasin: string = "";
	@observable public nature: string = "";
	@observable public latitude: string = "";
	@observable public longitude: string = "";
	@observable public serialNo: string = "";
	@observable public windWMO: number = 0;
	@observable public presWMO: number = 0;
	@observable public center: string = "";
	@observable public windPercentile: number = 0;
	@observable public presPercentile: number = 0;
	@observable public trackType: string = "";
	@observable public distance: number = 0;

	public constructor() {
		super();
	}

	// tslint:disable-next-line: no-empty
	public init(stores: Stores): void {}

	@action
	public populate(store: any): void {
		this.season = store.season;
		this.name = store.name;
		this.basin = store.basin;
		this.subBasin = store.subBasin;
		this.nature = store.nature;
		this.latitude = store.latitude;
		this.longitude = store.longitude;
		this.serialNo = store.serialNo;
		this.windWMO = store.windWMO;
		this.presWMO = store.presWMO;
		this.center = store.center;
		this.windPercentile = store.windPercentile;
		this.presPercentile = store.presPercentile;
		this.trackType = store.trackType;
		this.distance = store.distance;
	}

	@action
	public SetHurricaneDataResult(HurricaneData: HurricaneDataModel): void {
		this.HurricaneData = HurricaneData;

		if (HurricaneData) {
			this.season = HurricaneData.season;
			this.name = HurricaneData.name;
			this.basin = HurricaneData.basin;
			this.subBasin = HurricaneData.subBasin;
			this.nature = HurricaneData.nature;
			this.latitude = HurricaneData.latitude;
			this.longitude = HurricaneData.longitude;
			this.serialNo = HurricaneData.serialNo;
			this.windWMO = HurricaneData.windWMO;
			this.presWMO = HurricaneData.presWMO;
			this.center = HurricaneData.center;
			this.windPercentile = HurricaneData.windPercentile;
			this.presPercentile = HurricaneData.presPercentile;
			this.trackType = HurricaneData.trackType;
			this.distance = HurricaneData.distance;
		}
	}

	@action
	public SetHurricaneDataResults(HurricaneData: HurricaneDataModel[]): void {
		this.HurricaneDataArr = HurricaneData;
	}

	@action
	public ResetHurricaneDataArr(): void {
		this.HurricaneDataArr = [];
	}

	@action
	public ResetHurricaneData(): void {
		this.season = 0;
		this.name = "";
		this.basin = "";
		this.subBasin = "";
		this.nature = "";
		this.latitude = "";
		this.longitude = "";
		this.serialNo = "";
		this.windWMO = 0;
		this.presWMO = 0;
		this.center = "";
		this.windPercentile = 0;
		this.presPercentile = 0;
		this.trackType = "";
		this.distance = 0;
	}

	@computed get GetHurricanes(): HurricaneDataModel[] {
		var hurricaneArr: HurricaneDataModel[] = [];

		this.HurricaneDataArr.forEach(hurricaneDataModel => {
			hurricaneArr.push(hurricaneDataModel);
		});

		return hurricaneArr;
	}

	@computed get getHurricaneScore(): number {
		if (this.HurricaneDataArr != null) {
			return 100;
		}
		return 0;
	}
}
