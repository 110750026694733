function _isNil(value: any): boolean {
    return value == null;
}

export const GenerateID: () => string = function(): string {
    // math.random should be unique because of its seeding algorithm.
    // convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return (
        "_" +
        Math.random()
            .toString(36)
            .substr(2, 9)
    );
};

export const SortByString: (
    a: string | undefined,
    b: string | undefined,
    options?: Intl.CollatorOptions
) =>  number = (
    a: string | undefined,
    b: string | undefined,
    options?: Intl.CollatorOptions
): number => {
    if (a === undefined && b === undefined) {
        return 0;
    }
    if (a === undefined) {
        return -1;
    }
    if (b === undefined) {
        return 1;
    }

    return a.localeCompare(b, undefined, options);
};

export const Coalesce: <TArg>(...args: (TArg | null)[]) => TArg | null = <TArg>(...args: (TArg | null)[]): TArg | null => {
    for (const arg of args) {
        if (_isNil(arg) === false) {
            return arg;
        }
    }

    return null;
};
export const GetApiUrl: () => string = (): string => (window as any).apiurl;

export const GetImageUrl: (imageUrl: string) => string  = (imageUrl: string): string => {
    return GetApiUrl() + imageUrl;
};

export const GetBaseUrl: () => string | undefined = (): string | undefined => {
    const baseElements: HTMLCollectionOf<HTMLBaseElement> = document.getElementsByTagName("base");

    if (baseElements.length === 0) {
        throw new Error("Base element not found");
    }

    if (baseElements.length > 1) {
        throw new Error("Multiple base elements found");
    }

    const baseElement: HTMLBaseElement = baseElements[0];
    const baseUrl: string | null | undefined = baseElement.getAttribute("href");

    if (baseUrl === undefined) {
        throw new Error("Base element 'href' attribute not found.");
    }

    let retVal: string | undefined;
    if (baseUrl !== null) {
        retVal = baseUrl;
    }
    return retVal;
};

export const ParseJwt: (token: string) => any = (token: string): any => {
    const base64Url: string = token.split(".")[1];
    const base64: string = decodeURIComponent(
        atob(base64Url)
            .split("")
            .map(c => {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(base64);
};

export const getCookie: (cname: string) => string = (cname: string): string => {
    var name: string = cname + "=";
    var decodedCookie: string = decodeURIComponent(document.cookie);
    var ca: string[] = decodedCookie.split(";");
    for (var i: number = 0; i < ca.length; i++) {
        var c: string = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
