import React from "react";
import FitText from "@kennethormandy/react-fittext";

interface IToggleFitTextProps {
	toggle: boolean;
	compressor?: number;
	debounce?: number;
}

const ToggleFitText: React.FC<IToggleFitTextProps> = props => {
	const { toggle, compressor, children, debounce } = props;

	return (
		<>
			{toggle ? (
				<>
					<FitText compressor={compressor} debounce={debounce}>
						{children}
					</FitText>
				</>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export default ToggleFitText;
