import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useObserver } from "mobx-react-lite";
import { Link } from "@material-ui/core";
import { Stores, StoresContext, DomainStores } from "../Stores";
import { StringToArray } from "../Shoothill.Core/Utils/Misc";
import * as AppUrls from "../Globals/AppUrls";
import { UseDataApi } from "../Shoothill.Core/Utils";
import { UpdateAuditSearchRequest } from "../Models";
import { Link as RouterLink } from "react-router-dom";

import iconFloodInsurancePeople from "../Content/iconFloodInsurancePeople.svg";
import moreaboutbackgroundTop from "../Content/umbrellaImage.png";
import MFIBannerAd1020x100 from "../Content/MFIBannerAd1020x100.png";
import MFIbannerAdMobile from "../Content/MFIbannerAd-Mobile.png";
import { RowKnowOptions } from "../Shoothill.Components/RowTools/RowKnowOptions";
import {
	AppHead1020,
	BlueGradientBox,
	BoxButton,
	KYOptions,
	PageContainer,
	TopFloodBackground,
	SecondBackground,
} from "../Shoothill.Components/Styles/FloodInsuranceStyling";

export const FloodInsurance: React.FC = () => {
	const { doRequest } = UseDataApi();
	const userContext: DomainStores["UserStore"] = useContext<Stores>(
		StoresContext
	).domain.UserStore;
	const mapStoresContext: DomainStores["MapStore"] = useContext<Stores>(
		StoresContext
	).domain.MapStore;
	const floodDataContext: DomainStores["FloodAndClaimDataStore"] = useContext<
		Stores
	>(StoresContext).domain.FloodAndClaimDataStore;

	function tfiformatLink(): string {
		let retVal = "";

		if (mapStoresContext.address.length > 0) {
			const arrAddress: string[] = StringToArray(mapStoresContext.address, ",");
			if (arrAddress != null) {
				const zipCode: string = arrAddress[arrAddress.length - 2].replace(
					floodDataContext.stateShortCode + " ",
					""
				);
				retVal = "site=mfr";
				retVal += "&address1=" + encodeURIComponent(arrAddress[0]);
				retVal += "&address2=" + encodeURIComponent(arrAddress[1]);
				retVal +=
					"&city=" + encodeURIComponent(arrAddress[arrAddress.length - 3]);
				retVal +=
					"&state=" + encodeURIComponent(floodDataContext.stateShortCode);
				retVal += "&zip=" + encodeURIComponent(zipCode);
				retVal += "&email=";
				retVal +=
					userContext.EmailAddress === undefined
						? ""
						: userContext.EmailAddress;
				retVal += "&zone=" + floodDataContext.fldZone.substring(0, 1);
			}
		}

		return retVal;
	}

	function getQuoteLink(): string {
		const quoteUri = "https://www.myfloodrisk.org";
		return quoteUri;
	}

	function getQuoteClick(a: any): void {
		const tfiLink: string = tfiformatLink();
		if (
			floodDataContext.auditSearchId !== undefined &&
			floodDataContext.auditSearchId.length > 0
		) {
			// post to API
			const data: UpdateAuditSearchRequest = {
				id: floodDataContext.auditSearchId,
				quoteClickThrough: tfiLink,
				clickSource: "More About Flooding",
			};

			doRequest(AppUrls.Server.Api.AuditSearch.UpdateUserQuote, data);
		}
	}

	return useObserver(() => (
		<>
			<Helmet>
				<title>
					Flood Insurance - Provide You With the Best Insurance Coverage and
					Premium Options | My Flood Risk
				</title>
				<meta
					name="description"
					content="Getting a flood insurance quote is free and easy. My Flood Risk is an affiliate of one of the nation's
                     largest flood-only insurance agencies, National Flood Insurance, LLC. Our flood experts can provide you with the
                      best insurance coverage and premium options."
				/>
			</Helmet>
			<PageContainer>
				<BlueGradientBox>
					<TopFloodBackground imagesrc={moreaboutbackgroundTop}>
						<div>&nbsp;</div>
					</TopFloodBackground>
					<SecondBackground>
						<AppHead1020>
							<h1>Flood Insurance</h1>
							<p>
								Floods can happen anywhere, at anytime. In fact, flooding is the
								most common type of natural disaster in the United States, with
								90 percent of natural disasters involving some form of flooding.
								The best way to protect your property from the threat of rising
								water is with a flood insurance policy.
							</p>
							<p>
								Getting a flood insurance quote is easy and free. My Flood Risk
								is an affiliate of one of the nation's largest flood-only
								insurance agencies, National Flood Insurance, LLC. Our affiliate
								provides you with access to over 20 private flood insurance
								companies, as well as the standard FEMA flood insurance
								carriers. More companies mean more options, including lower
								premiums!
							</p>
							<Link
								href="http://myfloodinsurance.com"
								target="_blank"
								title={
									"Get an instant quote now from our partner site myfloodinsurance.com"
								}
								className="MFIBanner"
							>
								<img
									src={MFIBannerAd1020x100}
									alt={
										"Get an instant quote now from our partner site myfloodinsurance.com"
									}
								/>
							</Link>
							<Link
								href="http://myfloodinsurance.com"
								target="_blank"
								title={
									"Get an instant quote now from our partner site myfloodinsurance.com"
								}
								className="MFIBannerMobile"
							>
								<img
									src={MFIbannerAdMobile}
									alt={
										"Get an instant quote now from our partner site myfloodinsurance.com"
									}
								/>
							</Link>
							{/* 							<BoxButton>
								<Link
									to="/"
									component={RouterLink}
									title={"Get a flood insurance quote now"}
									className="external-link"
								>
									<div className="rowabout-btnrow">
										<div className="rowabout-btn getquote-btn">
											<img src={iconFloodInsurancePeople} alt={""} />
											<div>
												Click here to find out your true risk and get a FREE
												Flood Insurance quote NOW!
											</div>
										</div>
									</div>
								</Link>
							</BoxButton> */}
						</AppHead1020>
						<KYOptions>
							<h2>Know Your Options</h2>
							<p>
								FEMA's National Flood Insurance Program VS. Private Flood
								Insurance
							</p>
						</KYOptions>
						<RowKnowOptions />
						{/* 						<BoxButton>
							<Link
								to="/"
								component={RouterLink}
								title={"Get a flood insurance quote now"}
								className="external-link"
							>
								<div className="rowabout-btnrow">
									<div className="rowabout-btn getquote-btn">
										<img src={iconFloodInsurancePeople} alt={""} />
										<div>
											Click here to find out your true risk and get a FREE Flood
											Insurance quote NOW!
										</div>
									</div>
								</div>
							</Link>
						</BoxButton> */}
					</SecondBackground>
				</BlueGradientBox>
			</PageContainer>
		</>
	));
};
