import * as AppUrls from "Globals/AppUrls";
import { ArticleListItemAdmin } from "../Shoothill.Components/Articles";
import awaitingImage from "../Content/awaitingimage.jpg";
import { /* Box, Link, */ Grid, GridList, Select, MenuItem } from "@material-ui/core";
import { GenericIncludeDeletedWithId } from "../Models";
import React, { useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Stores, StoresContext } from "../Stores";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react-lite";
import { UseDataApi } from "../Shoothill.Core/Utils";
import { BlogPostStore } from "../Stores/Domain/BlogPostStore";
import * as MUI from "@material-ui/core";

import {
    useBlogStyles/* ,
    BlogFilterBox */
} from "../Shoothill.Components/Styles/BlogStyles";
import {
    Row1040,
    MasonryCustom,
    Row1076
} from "../Shoothill.Components/Styles/BlogRefStyles";
import {
    BlogFilterBoxAdmin,
    CreateLink,
    CreateBox,
    ControlsContainer,
    WhiteToBlueGradAdmin,
    LoadMore1040Admin
} from "../Shoothill.Components/Styles/AdminStyles";

export const BlogAdmin: React.FC = () => {
    const classes: any = useBlogStyles("contact");

    const { data, doRequest } = UseDataApi();

    const blogPostStoreContext: BlogPostStore = useContext<Stores>(
        StoresContext
    ).domain.BlogPostStore;

    useEffect(() => {
        if (blogPostStoreContext.blogPostsForAdmin.length === 0) {
            const includeGenericDeletedWithId: GenericIncludeDeletedWithId = {
                id: undefined,
                isBlog: true,
                includeDeleted: true
            };

            doRequest("/api/BlogPost/GetAll", includeGenericDeletedWithId);
        }
    }, [data]);

    useEffect(() => {
        if (data !== null) {
            if (data.wasSuccessful) {
                blogPostStoreContext.SetBlogPostsForAdmin(data.payload);
            }
        }
    }, [data]);

    useEffect(() => {
        blogPostStoreContext.ClearFilters();
    }, []);

    useEffect(() => {
        blogPostStoreContext.SetCreatingBlog(false);
    }, []);

    function handleFilterChange(event: any): void {
        blogPostStoreContext.SetActiveFilter(event.target.value);
    }

    function handleLoadMore() {
        blogPostStoreContext.SetActiveBlogCount();
    }

    function showLoadMore() {
        return (
            blogPostStoreContext.blogPostsForAdmin.length >
            blogPostStoreContext.activeBlogCount
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function handleCreate(e: any) {
        blogPostStoreContext.SetCreatingBlog(true);
    }

    const breakpointColumnsMasonry = {
        default: 3,
        1000: 2,
        600: 1
    };

    return useObserver(() => (
        <>
            <div className={classes.blogBottom}>
                <Row1040 container>
                    <Grid container>
                        <ControlsContainer item xs={12}>
                            <BlogFilterBoxAdmin textAlign={"left"}>
                                <Typography
                                    id="header"
                                    className={classes.headingBlue34}
                                >
                                    Show
                                </Typography>
                                <Select
                                    className={classes.textField}
                                    name="ShowFilter"
                                    value={blogPostStoreContext.activeFilter}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem
                                        key={"Newest first"}
                                        value={"Newest first"}
                                    >
                                        {"Newest first"}
                                    </MenuItem>
                                    <MenuItem
                                        key={"Oldest first"}
                                        value={"Oldest first"}
                                    >
                                        {"Oldest first"}
                                    </MenuItem>
                                    <MenuItem
                                        key={"Title A to Z"}
                                        value={"Title A to Z"}
                                    >
                                        {"Title A to Z"}
                                    </MenuItem>
                                    <MenuItem
                                        key={"Title Z to A"}
                                        value={"Title Z to A"}
                                    >
                                        {"Title Z to A"}
                                    </MenuItem>
                                </Select>
                            </BlogFilterBoxAdmin>
                            <CreateBox mt={3} mb={3} textAlign="center">
                                <CreateLink
                                    onClick={handleCreate}
                                    title={"Create"}
                                    component={RouterLink}
                                    to={AppUrls.Client.BlogPostAdmin.replace(
                                        ":Id?",
                                        ""
                                    )}
                                >
                                    <span className={classes.adminBtn}>
                                        {"Create new blog"}
                                    </span>
                                </CreateLink>
                            </CreateBox>
                        </ControlsContainer>
                    </Grid>
                </Row1040>
                <Row1076 container>
                    <GridList cellHeight={454} className={classes.gridList}>
                        <MasonryCustom
                            breakpointCols={breakpointColumnsMasonry}
                            columnClassName="my-masonry-grid_column"
                        >
                            {blogPostStoreContext.GetBlogPostsForAdmin.map(
                                blogPost => (
                                    <ArticleListItemAdmin
                                        id={blogPost.id}
                                        title={blogPost.name}
                                        excerpt={blogPost.description}
                                        publishDate={blogPost.startDate}
                                        link={"blogpost/" + blogPost.id}
                                        linkText={"Edit"}
                                        imagePath={
                                            blogPost.desktopBtnImageUrl
                                                ? blogPost.desktopBtnImageUrl
                                                : awaitingImage
                                        }
                                        key={
                                            blogPost.tabletImageURL +
                                            "-" +
                                            blogPost.id
                                        }
                                        isBlog={blogPost.isBlog}
                                        isDeleted={blogPost.isDeleted}
                                    />
                                )
                            )}
                        </MasonryCustom>
                    </GridList>
                </Row1076>
                <WhiteToBlueGradAdmin className={classes.whiteToBlueGrad}>
                    {showLoadMore() && (
                        <LoadMore1040Admin container>
                            <Grid item xs={12}>
                                <MUI.Button
                                    variant="outlined"
                                    className={classes.loadMoreBtn}
                                    onClick={handleLoadMore}
                                >
                                    Load more
                                </MUI.Button>
                            </Grid>
                        </LoadMore1040Admin>
                    )}
                </WhiteToBlueGradAdmin>
            </div>
        </>
    ));
};
