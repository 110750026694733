import styled from "styled-components";
import { Box, Button } from "@material-ui/core";
import { GenericModalBox } from "../Styles/ModalStyles";
import quoteSliderLabel from "../../Content/quote-slider-label.svg";

export const ScoresBox: any = styled(Box)`
	text-align: left;
	letter-spacing: 0;
	color: #292b28;
	margin-top: /* 15px */ 0;

	h1 {
		text-align: center;
		font: Bold 32px "Lato", sans-serif;
		letter-spacing: 0;
		color: #292b28;
		opacity: 1;
	}

	p {
		text-align: center;
		font: Bold 18px "Lato", sans-serif;
		letter-spacing: 0;
		color: #4190ca;
		opacity: 1;
	}

	.group {
		display: inline-flex;
		width: 100%;

		h1 {
			margin-bottom: 0px;

			@media (max-width: 767px) {
				font-size: 24px;
				line-height: 18px;
				margin: 20px 0;
			}
		}

		p {
			margin: 2px 0px;
		}

		> div {
			width: 866px;
		}
		> div:last-child {
			width: 200px;
			text-align: right;
			img {
				margin: 30px auto 0;
			}
		}
	}

	.legend {
		width: 125px;
		padding: 30px 0px;
		vertical-align: middle;
		border-right: solid 1px #707070;
		text-align: left;
		font-weight: Bold;
		font-size: 18px;
	}
`;

export const Disclaim: any = styled(Box)`
	display: block;
	clear: both;
	width: 100%;
	max-width: 1000px;
	margin: auto;
	text-align: left;
	font-weight: normal;
	font-size: 10px;
	font-family: Lato, sans-serif;
	letter-spacing: 0;
	color: rgba(41, 43, 40, 0.5);

	@media (min-width: 768px) and (max-width: 860px) {
		margin-top: -12px;
	}

	@media (min-width: 1180px) {
		margin-left: 20px;
	}

	> a {
		color: rgba(41, 43, 40, 0.5);
		text-decoration: underline;
	}
`;

export interface IScoreChartProps {
	scoreVal: number;
	scoreCol: string;
}

export const ScoreChartBox: any = styled(Box)<IScoreChartProps>`
	width: 100%;
	margin-top: 40px;
	margin-bottom: 30px;
	padding: 12px 1px;
	.barBase {
		border: 1px solid #e0daf0;
		border-radius: 5px;
		width: 100%;
		height: 50px;
		display: inline-flex;

		.scoreGraph {
			position: relative;
			width: 100%;
			background: transparent
				linear-gradient(
					90deg,
					#39b54a 0%,
					#cde02b 28%,
					#fbe521 46%,
					#f8a51f 58%,
					#fe0c02 81%,
					#8b0000 100%
				)
				0% 0% no-repeat padding-box;
			border-radius: 5px;
			.marker {
				position: absolute;
				left: ${(props) => props.scoreVal}%;
				width: 125px;
				height: 125px;
				border-radius: 50%;
				background-color: ${(props) => props.scoreCol};
				margin: -40px 0px 0px -50px;
				border: solid 10px #ffffff;
				text-align: center;
				color: #ffffff;
				font-size: 74px;
				font-weight: bold;
				box-shadow: 0px 3px 6px #cccccc;

				@media (max-width: 767px) {
					width: 91px;
					height: 91px;
					font-size: 50px;
					margin: -22px 0px 0px -50px;
					left: ${(props) => props.scoreVal}px;
				}
			}
		}

		.scoreEndPoint {
			max-width: 50px;
			width: 100%;
			font-size: 18px;
			line-height: 28px;
			color: #464545;
			text-align: center;
			padding: 10px 0px;
		}
	}
`;

export const ReportSummaryGroup: any = styled(Box)`
	margin: 0 auto;
	padding: 0 0 12px;
	width: 96%;
	display: flex;
	flex-direction: row;
	min-height: 1px;
	flex-wrap: wrap;
	justify-content: center;

	@media (min-width: 1180px) {
		width: auto;
		margin: 0 20px 0 0;
		padding: 0;
	}

	@media (max-width: 856px) {
		width: 100%;
		flex-basis: 100%;
	}

	@media (max-width: 767px) {
		flex-direction: column;
		padding: 0 0 20px;
	}

	@media (max-width: 1179px) {
		margin: 13px 0;
		padding: 0;
	}

	.MFIBannerMobile {
		@media (min-width: 769px) {
			display: none;
		}

		@media (max-width: 768px) {
			display: block;
			margin: 20px 0px 10px;
			img {
				min-width: 335px;
				width: 100%;
			}
		}
	}
`;

export const ReportScoreButton: any = styled(Button)`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 4px solid #4190ca;
	border-radius: 10px;
	color: #4190ca;
	padding: 5px 10px;
	/* margin-right: 10px; */
	min-width: 318px;
	height: 72px;

	text-transform: none;
	font-size: 14px;
	font-weight: bold;
	font-family: "Lato" sans-serif;
	letter-spacing: 0;
	line-height: 1.2em;
	text-align: left;

	@media (max-width: 767px) {
		margin: 0 auto;
	}

	@media (max-width: 856px) {
		width: 100%;
	}

	@media (max-width: 1179px) {
		height: 54px;
	}

	@media (min-width: 1180px) {
		margin-bottom: 0;
	}

	.report-score-icon {
		width: 37px;
		height: 32px;
		margin-right: 10px;
	}

	:hover {
		background-color: #ffffff;
	}
`;

export const ForwardScoreButton: any = styled(ReportScoreButton)`
	border: none;
	background: #4190ca 0% 0% no-repeat padding-box;
	color: #ffffff;

	@media (min-width: 857px) {
		max-width: 318px;
	}

	@media (max-width: 767px) {
		margin: 20px auto;
	}

	@media (min-width: 857px) {
		margin-left: 33px;
	}

	@media (min-width: 1180px) {
		margin-right: 33px;
	}

	span {
		margin-left: 10px;
	}

	:hover {
		background-color: #4190ca;
	}
`;

export const PDFModalBox: any = styled(GenericModalBox)`
	outline: none;

	div {
		width: 100%;
	}

	.pdfmodal-title {
		margin-top: -20px;
		height: 70px;
		img {
			width: 90px;
			height: 56px;
			padding-right: 30px;
			margin-top: 20px;
		}

		.pdfmodal-title-text {
			position: relative;
			display: inline-block;
			top: -20px;
			color: #4190ca;
			font-weight: 700;
			font-size: 21px;
			height: 56px;
			line-height: 56px;
			vertical-align: center;
		}
	}

	@media screen and (max-width: 768px) {
		.pdfmodal-title {
			height: 60px;
			img {
				width: 88px;
				height: 46px;
				padding-right: 25px;
			}

			.pdfmodal-title-text {
				font-size: 18px;
				height: 46px;
				line-height: 46px;
			}
		}
	}

	@media screen and (max-width: 576px) {
		.pdfmodal-title {
			height: 45px;
			img {
				width: 45px;
				height: 27px;
				padding-right: 7px;
			}

			.pdfmodal-title-text {
				top: -7px;
				font-size: 15px;
				height: 32px;
				line-height: 32px;
			}
		}
	}

	@media screen and (max-width: 388px) {
		.pdfmodal-title {
			height: 45px;
			img {
				width: 38px;
				height: 23px;
				padding-right: 5px;
			}

			.pdfmodal-title-text {
				top: -5px;
				font-size: 14px;
				height: 32px;
				line-height: 32px;
			}
		}
	}

	.pdfmodal-input {
		input {
			width: 100%;
			height: 54px;
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #e0daf0;
			opacity: 0.95;
			padding: 0 15px;
		}
	}

	.button-row {
		width: 100%;
		text-align: center;
	}

	.button-row.pdf-buttons {
		margin-top: 30px;
	}

	.pdfmodal-radios {
		p {
			margin-bottom: 0;
		}

		.pdfmodal-radiogroup {
			margin-left: -10px;
		}
	}

	.errormessage {
		color: red !important;
	}
`;

export const PDFModalLoaderBox = styled(PDFModalBox)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin: 22.5% auto;
	padding: 60px;

	h3 {
		color: #4190ca;
		font-weight: 700;
		font-size: 21px;
		text-align: center;
		margin: 0 auto 30px;
	}
	.MuiCircularProgress-indeterminate {
		width: 60px !important;
		height: 60px !important;
		color: #005792;
	}
`;

export const HR: any = styled.div<{ halfBottom: boolean }>`
	border-bottom: 1px solid #4190ca;
	width: 100%;
	margin: ${(props) => (props.halfBottom ? "60px 0 30px" : "60px 0")};

	@media (max-width: 767px) {
		margin: ${(props) => (props.halfBottom ? "9% 0 4.5%" : "9% 0")};
	}
`;

export const QuoteFormWrapper: any = styled.div`
	h2 {
		color: #222120;
		font-size: 32px;
		font-weight: bold;
		margin: 0;

		@media (max-width: 767px) {
			font-size: 20px;
			text-align: center;
		}
	}

	.radioCol {
		margin-right: 11px;
	}

	.radioCol .MuiTouchRipple-root {
		color: black;
	}

	.radioCol:hover {
		background-color: rgba(0, 0, 0, 0.08);
	}

	.MuiFormGroup-root {
		margin-top: 11px;

		.MuiFormControlLabel-label {
			font-weight: normal;
		}
	}

	.MuiFormGroup-row {
		.MuiFormControlLabel-root {
			padding-right: 20px;
			margin-right: 11px;
			width: 33.3333%;
		}

		@media (min-width: 768px) {
			.MuiFormControlLabel-root:first-child:nth-last-child(4),
			.MuiFormControlLabel-root:first-child:nth-last-child(4)
				~ .MuiFormControlLabel-root {
				width: 50%;
			}
		}

		@media (max-width: 767px) {
			.MuiFormControlLabel-root {
				width: 100%;
			}
		}
	}

	.form-heading {
		.heading {
			display: flex;

			.quote-icon-wrapper {
				/* width: 101px; */
				display: inline-flex;
				width: 100%;
				max-width: 59px;
				margin-right: 33px;

				@media (max-width: 931px) {
					width: 32%;
				}

				.quote-icon {
					margin-top: -5px;
					height: calc(100% - 3px);
					max-height: 77px;
				}
			}

			h2 {
				display: inline-flex;
			}

			.MFIBanner {
				margin: 40px 0px;

				@media (min-width: 769px) {
					display: block;
				}

				@media (max-width: 768px) {
					display: none;
				}
			}
		}
	}

	.quote-icon {
		margin-top: -10px;
		height: calc(100% + 10px);
		svg {
			height: 100%;
			width: auto;
		}
	}

	form {
		padding: 0;
		margin-top: 50px;

		#year-helper-text {
			top: 66px;
			position: absolute;
			font-size: 14px;
			color: red;
			font-weight: normal;
		}

		.uploader {
			display: flex;
			align-items: center;
			white-space: nowrap;

			p {
				padding: 15px 30px 0;
				text-overflow: ellipsis;
				overflow: hidden;

				span {
					font-weight: normal;
				}
			}

			@media (max-width: 767px) {
				flex-wrap: wrap;
				justify-content: center;
			}
		}

		.uploadDiv {
			position: relative;
			width: auto;
			display: inline-block;
		}

		p {
			margin: 0;
			color: #4190ca;
			font-size: 21px;
			font-weight: bold;

			@media (max-width: 767px) {
				font-size: 14px;
			}
		}

		input,
		textarea {
			border-radius: 0;
			margin: 8px 0;
		}

		.coverage-textfield {
			div::before {
				content: "$";
				left: 20px;
				font-size: 18px;
				font-weight: bold;
				color: #464545;
				line-height: 405%;
				bottom: 0;
				top: 0;
			}

			input {
				padding-left: 38px;
			}
		}

		.submit-btn {
			height: 54px;
			background-color: #4190ca;
			color: white;
			border-radius: 10px;
			margin-bottom: 75px;
		}

		.error {
			margin: 0;
			position: absolute;
			font-weight: normal;
			font-size: 14px;

			:not(.file-error) {
				margin: -8px 0 0;
			}
		}

		.coverage-slider {
			padding: 12px 20px;
			background-color: rgba(65, 144, 202, 0.3);
			border-radius: 24px;
			display: flex;
			margin: 25px 0 36px;

			@media (max-width: 767px) {
				margin: 25px 20px 36px;
			}

			.slider-track {
				opacity: 0;
			}

			.slider-rail {
				background-color: #4190ca;
				opacity: 1;
			}

			.slider-thumb {
				width: 40px;
				height: 40px;
				transform: translate(-14px, -14px);
				background-color: white;
				border: 4px solid rgb(65, 144, 202);

				:hover {
					box-shadow: 0px 0px 0px 6px rgba(65, 144, 202, 0.16);
				}

				:active {
					box-shadow: 0px 0px 0px 10px rgba(65, 144, 202, 0.16);
				}
			}

			.slider-label {
				left: auto;
				top: auto;
				bottom: -54px;
				background-image: url(${quoteSliderLabel});
				padding-top: 22px;
				width: 118px;
				height: 56px;

				> span {
					transform: none;
					width: auto;
					border-radius: 0;
					background-color: transparent;
					border: none;
					padding: 16px;

					> span {
						transform: none;
						color: black;
						font-size: 19px;
					}
				}
			}
		}

		.contents-coverage-wrapper {
			display: flex;
			height: 100%;
			flex-direction: column;

			.contents-coverage-container {
				height: auto;
				display: flex;
				flex-grow: 1;
				align-items: center;

				@media (min-width: 768px) {
					margin: 25px 0 36px;
				}

				p {
					color: #464545;
					font-weight: normal;

					@media (max-width: 767px) {
						text-align: center;

						width: 100%;
					}

					span {
						font-weight: bold;
					}
				}
			}
		}
	}
`;

export const UploadButton = styled(ReportScoreButton)`
	z-index: 0;
	padding: 5px 25px;
	min-width: 0;
`;
