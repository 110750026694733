import { LocationState } from "history";
import { Context, useContext, useEffect } from "react";
import {
    __RouterContext,
    RouteComponentProps,
    StaticContext
} from "react-router";
import { UseForceUpdate } from "./UseForceUpdate";

type AnyContext = Context<RouteComponentProps<any, any, any>>;

const INCORRECT_VERSION_ERROR: Error = new Error(
    "use-react-router may only be used with react-router@^5."
);

const MISSING_CONTEXT_ERROR: Error = new Error(
    "useReactRouter may only be called within a <Router /> context."
);

export function UseRouter<
    P extends { [K in keyof P]?: string } = {},
    C extends StaticContext = StaticContext,
    S = LocationState
>(): RouteComponentProps<P, C, S> {
    // if this version of react-router does not support Context,
    if (!__RouterContext) {
        throw INCORRECT_VERSION_ERROR;
    }

    // if the react-router Context is not a parent Component,
    const context: RouteComponentProps<P, C, S> = useContext<
        RouteComponentProps<P, C, S>
    >((__RouterContext as AnyContext) as Context<RouteComponentProps<P, C, S>>);
    if (!context) {
        throw MISSING_CONTEXT_ERROR;
    }

    const forceUpdate: VoidFunction = UseForceUpdate();
    useEffect((): VoidFunction => context.history.listen(forceUpdate), [
        context,
        forceUpdate
    ]);
    return context;
}
