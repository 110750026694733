import styled from "styled-components";
import { Box } from "@material-ui/core";

import totalFemWhitehouse from "../../Content/totalFem-whitehouse.svg";
import totelFemWhiteSofa from "../../Content/totalFEm-whiteSofa.svg";

export const FemaTotalBox: any = styled(Box)`
  width: 100%;
  display: inline-flex;
  text-align: center;
  letter-spacing: 0;
  color: #292b28;

  h1 {
    font-size: 32px;
    text-align: left;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 18px;
      margin: 20px 0;
    }
  }

  .Outcome {
    width: 616px;
    padding: 0;
    margin: 0;
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    font-family: Lato;

    @media (min-width: 768px) {
      margin-left: 90px;
      width: 616px;
    }

    @media (max-width: 900px) {
      width: 230px;
      margin-left: auto;
    }

    @media (max-width: 767px) {
      margin-left: unset;
    }

    /* HACK for IE only */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .claimsContainer {
        display: flex;
        justify-content: space-between;
      }
    }

    /* HACK for Edge only */
    @supports (-ms-ime-align: auto) {
      .claimsContainer {
        display: flex;
        justify-content: space-between;
      }
    }

    .claims {
      display: inline-block;
      margin: 0 30px 0 0;

      .claimPadding {
        padding: 18px 0;
      }

      .buildingClaimsPadding {
        @media (max-width: 767px) {
          padding-top: 0;
        }
      }
    }
    h2 {
      text-align: left;
      font-weight: Bold;
      font-size: 32px;
      font-family: Lato;
      margin: 0 0 18px;
    }
    span {
      font-size: 32px;
      font-weight: Bold;

      @media (max-width: 900px) {
        font-size: 21px;
      }
    }
    span.magenta {
      font-size: 52px;

      @media (max-width: 900px) {
        font-size: 32px;
      }
    }
  }

  .OutcomeDiagram {
    padding-top: 15px;
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }

  .OutcomeDiagram {
    @media (max-width: 767px) {
      position: absolute;
      left: 217px;
    }
  }

  .magenta {
    color: #ee00ee;
  }

  .lightblue {
    color: #79b2da;
  }

  .darkblue {
    color: #207fc1;
  }
`;

export interface IFemaPieProps {
  sofaX: number;
  sofaY: number;
  houseX: number;
  houseY: number;
}

export const FemaPieBox: any = styled(Box)<IFemaPieProps>`
  position: relative;
  width: 327px;
  height: 327px;
  background-color: #ffffff;
  border-radius: 50%;
  border: solid 10px #ee00ee;

  .pieContainer {
    width: 160px;
    height: 160px;
    position: relative;
    top: -9px;
    left: -24px;
  }

  @media (max-width: 767px) {
    width: 131px;
    height: 131px;
  }

  .contentClaims,
  .buildingClaims {
    position: absolute;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .contentClaims {
    background-image: url(${totelFemWhiteSofa});
    top: ${props => props.sofaY}px;
    right: ${props => props.sofaX}px;
    margin-top: 20px;
    margin-right: 10px;
  }

  .buildingClaims {
    background-image: url(${totalFemWhitehouse});
    top: ${props => props.houseY}px;
    left: ${props => props.houseX}px;
    margin-left: 10px;
  }

  .pie-chart {
    padding: 0;
    margin: 0;
    margin-top: -10px;
    g.arc {
      path {
        opacity: 1 !important;
      }
    }
    g.arc-0 {
      /* Content */
      path {
        fill: #79b2da !important;
      }
    }
    g.arc-1 {
      /* Building */
      path {
        fill: #207fc1 !important;
      }
    }
  }

  .OutcomeDiagram {
    width: 327px;
    @media (max-width: 767px) {
      width: 131px;
    }
  }
`;
