import { BaseStore } from "./BaseStore";
import {
	getFeetInchesFromDecimal,
	IFeetInches
} from "../../Shoothill.Core/Utils/Useful";
import {
	FloodAndClaimDataModel,
	DatasetWeights,
	HurricaneDataModel,
	HurricaneSummaryModel
} from "../../Models";
import { observable, action, computed } from "mobx";

export class FloodAndClaimDataStore extends BaseStore {
	@observable public facData: FloodAndClaimDataModel | undefined;
	@observable public auditSearchId: string = "";
	@observable public fldZone: string = "";
	@observable public fldZoneScore: number = 0;
	@observable public elevation: number = 0;
	@observable public baseElevation: number | undefined = undefined;
	@observable public rawData: string = "";
	@observable public lat: string = "";
	@observable public lng: string = "";
	@observable public county: string = "";
	@observable public stateShortCode: string = "";
	@observable public physcity: string = "";
	@observable public physzip: string = "";
	@observable public state_abbr: string = "";
	@observable public claims: number = 0;
	@observable public paidBuildingClaims: number = 0;
	@observable public paidContentClaims: number = 0;
	@observable public earlistsDate: string = "";
	@observable public floodQtyCounty: number = 0;
	@observable public floodQtyCountyScore: number = 0;
	@observable public floodQtyState: number = 0;
	@observable public floodQtyStateScore: number = 0;
	@observable public disasterAssistanceScore: number = 0;
	@observable public disasterAssistanceTotal: number = 0;
	@observable public mappng: string = "";
	@observable public statessvg: string = "";
	@observable public weightVal: string = "five";
	@observable public hurricaneQty: number = 0;
	@observable public surgeQty: number = 0;
	@observable public surgeHazard: boolean = false;
	@observable public bfeVal: number = 0;
	@observable public riskWeights: any | undefined;
	@observable public hurricaneData: any | undefined;
	@observable public hurricaneSummaries: any | undefined;
	@observable private isAPILoading: boolean = false;
	@observable public mfrScore: number = 0;

	public constructor() {
		super();
	}

	@action
	public populate(store: any): void {
		this.auditSearchId = store.auditSearchId;
		this.fldZone = store.fldZone;
		this.fldZoneScore = store.fldZoneScore;
		this.elevation = store.elevation;
		this.baseElevation = store.baseElevation;
		this.lat = store.lat;
		this.lng = store.lng;
		this.rawData = store.rawData;
		this.county = store.county;
		this.stateShortCode = store.stateShortCode;
		this.physcity = store.physcity;
		this.physzip = store.physzip;
		this.state_abbr = store.state_abbr;
		this.claims = store.claims;
		this.paidBuildingClaims = store.paidBuildingClaims;
		this.paidContentClaims = store.paidContentClaims;
		this.earlistsDate = store.earlistsDate;
		this.floodQtyCounty = store.floodQtyCounty;
		this.floodQtyCountyScore = store.floodQtyCountyScore;
		this.floodQtyState = store.floodQtyState;
		this.floodQtyStateScore = store.floodQtyStateScore;
		this.disasterAssistanceScore = store.disasterAssistanceScore;
		this.disasterAssistanceTotal = store.disasterAssistanceTotal;
		this.mappng = store.mappng;
		this.weightVal = store.weightVal;
		this.surgeHazard = store.surgeHazard;
		this.surgeQty = store.surgeQty;
		this.hurricaneQty = store.hurricaneQty;
		this.riskWeights = store.riskWeights;
		this.hurricaneData = store.hurricaneData;
		this.hurricaneSummaries = store.hurricaneSummaries;
		this.statessvg = store.statessvg;
		this.mfrScore = store.mfrScore;
	}

	@action
	public SetFloodClaimsDataResult(
		floodClaimsData: FloodAndClaimDataModel
	): void {
		this.facData = floodClaimsData;

		if (floodClaimsData) {
			this.auditSearchId = floodClaimsData.auditSearchId;
			this.fldZone = floodClaimsData.fld_zone;
			this.fldZoneScore = floodClaimsData.fld_zonescore;
			this.elevation = floodClaimsData.elevation;
			this.baseElevation = floodClaimsData.base_elevation;
			this.lat = floodClaimsData.lat;
			this.lng = floodClaimsData.lng;
			this.rawData = floodClaimsData.raw_data;
			this.county = floodClaimsData.county;
			this.stateShortCode = floodClaimsData.stateShortCode;
			this.physcity = floodClaimsData.physcity;
			this.physzip = floodClaimsData.physzip;
			this.state_abbr = floodClaimsData.state_abbr;
			this.claims = floodClaimsData.claims;
			this.paidBuildingClaims = floodClaimsData.paidBuildingClaims;
			this.paidContentClaims = floodClaimsData.paidContentClaims;
			this.earlistsDate = floodClaimsData.earlistsDate;
			this.floodQtyCounty = floodClaimsData.floodQtyCounty;
			this.floodQtyCountyScore = floodClaimsData.floodQtyCountyScore;
			this.floodQtyState = floodClaimsData.floodQtyState;
			this.floodQtyStateScore = floodClaimsData.floodQtyStateScore;
			this.disasterAssistanceScore = floodClaimsData.disasterAssistanceScore;
			this.disasterAssistanceTotal = floodClaimsData.disasterAssistanceTotal;
			this.mappng = floodClaimsData.mappng;
			this.weightVal = floodClaimsData.weightVal;
			this.surgeHazard = floodClaimsData.surgeHazard;
			this.surgeQty = floodClaimsData.surgeQty;
			this.hurricaneQty = floodClaimsData.hurricaneQty;
			this.riskWeights = floodClaimsData.riskWeights;
			this.hurricaneData = floodClaimsData.hurricaneDataSet;
			this.hurricaneSummaries = floodClaimsData.hurricaneSummaries;
			this.statessvg = floodClaimsData.statessvg;
			this.mfrScore = floodClaimsData.mfrScore;
		}
		this.isAPILoading = false;
	}

	@action
	public setAPIIsLoading(isAPILoading: boolean): void {
		this.isAPILoading = isAPILoading;
	}

	@computed
	public get getIsAPILoading(): boolean {
		return this.isAPILoading;
	}

	@action
	public setSurgeHazard(value: boolean): void {
		// surge Map used this to set the bool, this is now coming from the API directly
		/* this.surgeHazard = value; */
	}

	@action
	public ResetFloodClaimsData(): void {
		this.auditSearchId = "";
		this.fldZone = "";
		this.fldZoneScore = 0;
		this.elevation = 0;
		this.baseElevation = 0;
		this.lat = "";
		this.lng = "";
		this.rawData = "";
		this.county = "";
		this.stateShortCode = "";
		this.physcity = "";
		this.physzip = "";
		this.state_abbr = "";
		this.claims = 0;
		this.paidBuildingClaims = 0;
		this.paidContentClaims = 0;
		this.earlistsDate = "";
		this.floodQtyCounty = 0;
		this.floodQtyCountyScore = 0;
		this.floodQtyState = 0;
		this.floodQtyStateScore = 0;
		this.disasterAssistanceScore = 0;
		this.disasterAssistanceTotal = 0;
		this.mappng = "";
		this.statessvg = "";
		this.hurricaneQty = 0;
		this.surgeQty = 0;
		this.surgeHazard = false;
		this.weightVal = "";
		this.bfeVal = 0;
		this.mfrScore = 0;
	}

	@computed get gethurricaneSummary(): HurricaneSummaryModel[] {
		if (this.hurricaneSummaries) {
			return this.hurricaneSummaries;
		}
		return [];
	}

	@computed get getriskHurricane(): HurricaneDataModel[] {
		if (this.hurricaneData) {
			return this.hurricaneData;
		}
		return [];
	}

	@computed get getriskWeights(): DatasetWeights[] {
		if (this.riskWeights) {
			return this.riskWeights;
		}
		return [];
	}

	@computed get getFloodzoneScore(): number {
		if (this.fldZoneScore) {
			return Math.round(this.fldZoneScore);
		}
		return 0;
	}

	@computed get getAssistanceTotal(): string | number {
		if (this.disasterAssistanceTotal) {
			return Intl.NumberFormat("en-US").format(
				Math.round(this.disasterAssistanceTotal)
			);
		}
		return 0;
	}

	@computed get getAssistanceScore(): number {
		if (this.disasterAssistanceScore) {
			return this.disasterAssistanceScore;
		}
		return 0;
	}

	@computed get getStateScore(): number {
		if (this.floodQtyStateScore) {
			return this.floodQtyStateScore;
		}
		return 0;
	}

	@computed get getCountyScore(): number {
		if (this.floodQtyCountyScore) {
			return this.floodQtyCountyScore;
		}
		return 0;
	}

	@computed get getFloodQtyCounty(): number {
		if (this.floodQtyCounty) {
			return this.floodQtyCounty;
		}
		return 0;
	}

	@computed get getFloodQtyState(): number {
		if (this.floodQtyState) {
			return this.floodQtyState;
		}
		return 0;
	}

	@computed get getBaseElevation(): number | undefined {
		return this.baseElevation;
	}

	@computed get getClaimsScore(): number {
		return 0;
	}

	@computed get getPropertyElevation(): any {
		if (this.elevation) {
			return this.elevation;
		}
		return 0;
	}

	@computed get getPropertySurgeHazard(): number {
		/*         if (this.surgeHazard) {
            return 100;
        } */
		if (this.surgeQty > 0) {
			return 100;
		}
		return 0;
	}

	@computed get getElevationDifferenceInches(): number {
		if (this.elevation && this.baseElevation !== undefined) {
			const ele: IFeetInches = getFeetInchesFromDecimal(this.elevation);
			const base: IFeetInches = getFeetInchesFromDecimal(this.baseElevation);
			const retVal: number = ele.totalInches - base.totalInches;
			return retVal;
		}
		return 0;
	}

	/* @computed get getOutcomeScore(): number {
		let retVal: number = 0;
		let outcomeVal: number = 0;
		outcomeVal = this.elevation - this.bfeVal;

		if (outcomeVal < 0) {
			retVal = 100;
		}

		if (outcomeVal === 0) {
			retVal = 98;
		}

		if (outcomeVal > 0 && outcomeVal <= 0.5) {
			retVal = 92;
		}

		if (outcomeVal > 0.5 && outcomeVal <= 1) {
			retVal = 85;
		}

		if (outcomeVal > 1 && outcomeVal <= 1.5) {
			retVal = 75;
		}

		if (outcomeVal > 1.5 && outcomeVal <= 2) {
			retVal = 65;
		}

		if (outcomeVal > 2 && outcomeVal <= 2.5) {
			retVal = 50;
		}

		if (outcomeVal > 2.5 && outcomeVal <= 3) {
			retVal = 35;
		}

		if (outcomeVal > 3 && outcomeVal <= 3.5) {
			retVal = 25;
		}

		if (outcomeVal > 3.5 && outcomeVal <= 4) {
			retVal = 10;
		}

		if (outcomeVal > 4) {
			retVal = 0;
		}

		retVal = this.bfeVal > 0 ? retVal : 0;

		return retVal;
	} */
}
