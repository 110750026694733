import React, { useState, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useObserver } from "mobx-react-lite";
import ReactGA from "react-ga";
import { Link as RouterLink } from "react-router-dom";
import { Button, Link } from "@material-ui/core";

import { AccountStore } from "../Stores/Domain/AccountStores";
import { StoresContext, Stores } from "../Stores";
import {
	UseDataApi,
	usePercentageHeightOfWidth,
	isNullorWhiteSpace,
	UseRouter
} from "../Shoothill.Core/Utils";
import { LoginEmbed, LoginModel } from "../Shoothill.Components/Account";
import { ApiResult } from "../Models";

import { RegProUserFormModel } from "Models/FormModels/RegProUserFormModel";

import homepageLogo from "../Content/myfloodprologo.svg";
import {
	ProBackground,
	PageContainer,
	ProRadiusBox
} from "Shoothill.Components/Styles/ProAccStyling";

import { AboutComp } from "Shoothill.Components/Pro/AboutComp";
import { RegisterFormComp } from "Shoothill.Components/Pro/RegisterFormComp";
import { BrandedComp } from "Shoothill.Components/Pro/BrandedComp";
import { MyFZDReports } from "Shoothill.Components/Pro/MyFZDReports";
import { ContactGroup } from "Shoothill.Components/RowTools/ContactGroup";

import { useMediaQuery } from "@material-ui/core";

import FitText from "@kennethormandy/react-fittext";
import { ReportRequest } from "Shoothill.Components/Pro";
import * as AppUrls from "../Globals/AppUrls";
import { MapStore } from "Stores/Domain";
import ToggleFitText from "Shoothill.Components/General/ToggleFitText";

export const Pro: React.FC = () => {
	const setStates = (state: any) => {
		if (state instanceof Array) {
			const [stateReportStep, stateRegisterStep, statereportOutcome] = state;
			setReportStep(stateReportStep);
			setRegisterStep(stateRegisterStep);
			setreportOutcome(statereportOutcome);
		}
	};

	(window as any).proSetStates = setStates;

	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
		.AccountStore;

	const mapStore: MapStore = useContext<Stores>(StoresContext).domain.MapStore;

	const [registerStep, setRegisterStep] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const reportStepObj = useState(0);
	const reportOutcomeObj = useState(false);
	const reportType = useRef(0);

	const [reportStep, setReportStep] = reportStepObj;
	const [reportOutcome, setreportOutcome] = reportOutcomeObj;
	const [rer, forcerer] = (window as any).rer;

	(window as any).reportStep = reportStepObj;

	let isLoggedIn: boolean = accountStore.IsLoggedIn;

	const { data, isLoading, doRequest } = UseDataApi<ApiResult>();

	const { data: userSet, doRequest: userRequest } = UseDataApi();

	const handleLogin: (loginModel: LoginModel) => void = (
		loginModel: LoginModel
	): void => {
		doRequest("/api/account/login", loginModel);
	};

	useEffect(() => {
		forcerer();
		if (isLoggedIn) {
			if (reportStep === 0) {
				mapStore.ResetLocation();
			}
		}
	}, [isLoggedIn, reportStep]);

	useEffect(() => {
		if (isLoggedIn) {
			setReportStep(0);
		}
	}, [isLoggedIn]);

	const saveStep = () => {
		window.sessionStorage.setItem("step", reportStep.toString());
		window.sessionStorage.setItem("type", reportType.current.toString());
	};

	useEffect(() => {
		window.addEventListener("beforeunload", saveStep);

		return () => window.removeEventListener("beforeunload", saveStep);
	});

	useEffect(() => {
		var sss = window.sessionStorage.getItem("step");
		var sst = window.sessionStorage.getItem("type");
		if (sss && !isNaN(+sss)) {
			setReportStep(+sss);

			if (+sss > 1) {
				var mss = window.sessionStorage.getItem("stores");
				if (mss) {
					mapStore.populate(JSON.parse(mss).domain.MapStore);
				}
			}
		}
		if (sst && !isNaN(+sst)) {
			reportType.current = +sst;
		}
	}, []);

	const el = useRef<HTMLButtonElement>(null);

	usePercentageHeightOfWidth([el], 0.112);

	let useMobile: boolean = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	useEffect(() => {
		const checkLoginReturn: () => any = (): any => {
			if (data !== null) {
				if (data.wasSuccessful) {
					accountStore.getLoginState(data.payload);
					setRegisterStep(2);
				} else {
					if (data.errors.length > 0) {
						setErrorMessage("* " + data.errors[0].message);
					} else {
						setErrorMessage("* Unknown error has occurred.");
					}
				}
			}
		};
		checkLoginReturn();
	}, [data]);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0 });
	}, [reportStep, registerStep]);

	useEffect(() => {
		if (
			!(window as any).propopped &&
			window.history.state instanceof Array &&
			!(window as any).newload
		) {
			window.history.pushState([reportStep, registerStep], "");
		}
		(window as any).newload = false;
		(window as any).propopped = false;
	}, [reportStep, registerStep]);

	useEffect(() => {
		window.history.replaceState([reportStep, registerStep], "");
	}, []);

	const handleRegister: (registerModel: RegProUserFormModel) => void = (
		registerModel: RegProUserFormModel
	): void => {
		userRequest("/api/account/register", registerModel);
	};

	useEffect(() => {
		if (userSet !== null) {
			setRegisterStep(2);
		}
	}, [userSet]);

	const startReport = () => {
		mapStore.ResetLocation();
		setReportStep(1);
	};

	function onClickReset(): number {
		setRegisterStep(0);
		setReportStep(0);
		return registerStep;
	}

	function onClickAdvance(): number {
		setRegisterStep(registerStep + 1);
		return registerStep;
	}

	function onClickRetard(): number {
		let thisStep: number = registerStep;
		setRegisterStep(thisStep <= 0 ? 0 : registerStep - 1);
		return registerStep;
	}

	function onClickLogout(e: any): void {
		e.preventDefault(true);
		accountStore.Logout();
		setRegisterStep(0);
	}

	function getPageHeight(): number {
		if (!isLoggedIn && registerStep === 0) {
			return 580;
		}
		return -1;
	}

	function getMobDesignHeight(): number {
		if (!isLoggedIn && registerStep === 0) {
			return 557;
		}
		return 0;
	}

	const loginHeader = (
		<>
			<h2 className="loginprompt">
				Login to <span>Myfloodrisk Pro</span>
			</h2>
		</>
	);

	const logoHeader = (
		<>
			<Link
				component={RouterLink}
				to="/Pro"
				className={"Logo"}
				onClick={onClickReset}
			>
				<img src={homepageLogo} alt="MyFlood Risk Pro" />
			</Link>
		</>
	);

	const logoutBtn = (
		<>
			<Link onClick={onClickLogout} href="" className="logoutBtn">
				Log Out
			</Link>
		</>
	);

	const loggedOnHeader = (
		<>
			<h2>
				Hi {accountStore.FirstName + " " + accountStore.LastName}
				{useMobile || logoutBtn}
			</h2>
		</>
	);

	const loginBG: string = useMobile
		? "rgba(230, 246, 255, 0.7)"
		: "linear-gradient(90deg, rgba(230,246,255,0.7) 50%, rgba(65,144,202,1) 50%)";
	const otherBG: string = "rgba(230,246,255,0.7)";

	return useObserver(() => (
		<>
			<Helmet>
				<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
				<title>My Flood Risk Pro</title>
			</Helmet>
			<ProBackground className="mui-fixed">
				<PageContainer pageHeightIE={getPageHeight()}>
					<div className="mobMargins">
						<ProRadiusBox
							color="#464545"
							background={!isLoggedIn && registerStep === 0 ? loginBG : otherBG}
							radius="30px"
							mg="auto"
							pd={"30px 0px 0px"}
							/* minht={getPageHeight()} */
							mobdesignheight={getMobDesignHeight()}
							minheight={isLoggedIn ? 653 : 507}
						>
							<h1 style={{ display: "none" }}>My Flood Risk Pro</h1>
							{!isLoggedIn && (registerStep === 0 || registerStep > 2) && (
								<>
									<div className={"PlaceHolderPart"}>
										<div className={"LoginPane"}>
											{logoHeader}

											<div className="PlaceHolderPart loginSignup">
												{useMobile ? (
													<>
														{/* <FitText compressor={1.23} debounce={0}>
															{loginHeader}
														</FitText> */}
													</>
												) : (
													loginHeader
												)}
												<LoginEmbed
													onSubmit={handleLogin}
													errorMessage={errorMessage}
													isLoading={isLoading}
													loginBtnText={
														useMobile ? "Login to Myfloodrisk Pro" : undefined
													}
													hideForgot={true}
												/>
											</div>
										</div>
									</div>

									<div className={"PlaceHolderPart signup"}>
										<div className="getALogin">
											<AboutComp />

											<button
												onClick={onClickAdvance}
												ref={el}
												className="signmeup"
											>
												Sign me up!
											</button>
										</div>
									</div>
								</>
							)}

							{!isLoggedIn && registerStep === 1 && (
								<>
									{logoHeader}

									<RegisterFormComp
										onSubmit={handleRegister}
										errorMessage={errorMessage}
										isLoading={isLoading}
										confirmMsg={"Confirm and sign up"}
										onCancel={onClickRetard}
									/>
								</>
							)}

							{!isLoggedIn && registerStep === 2 && (
								<>
									{logoHeader}

									<div className="loginSignup">
										<div className={"PlaceHolderPart"}>
											<ToggleFitText
												compressor={1.14}
												debounce={0}
												toggle={useMobile}
											>
												<h2 className={"regSuccess"}>
													{userSet && userSet.wasSuccessful ? (
														<>
															Registration complete
															<br />
															<br />
															Please login to your
															<br />
															<br />
															<span>Myfloodrisk Pro</span> Dashboard
														</>
													) : (
														<>{userSet && userSet.errors[0].message}</>
													)}
												</h2>
											</ToggleFitText>
											{userSet && !userSet.wasSuccessful && (
												<>
													<Button
														onClick={() => {
															setRegisterStep(1);
														}}
													>
														Back
													</Button>
												</>
											)}
										</div>
										<div className={"PlaceHolderPart"}>
											<ToggleFitText
												toggle={useMobile}
												compressor={1.14}
												debounce={0}
											>
												{loginHeader}
											</ToggleFitText>
											<LoginEmbed
												onSubmit={handleLogin}
												errorMessage={errorMessage}
												isLoading={isLoading}
											/>
										</div>
									</div>
								</>
							)}

							{isLoggedIn && reportStep === 0 && (
								<>
									{logoHeader}

									<div className="loggedIn">
										{useMobile ? (
											<>
												<FitText compressor={1.23} debounce={0}>
													{loggedOnHeader}
												</FitText>
												<FitText compressor={/* 1.95 */ 2.3} debounce={0}>
													{logoutBtn}
												</FitText>
											</>
										) : (
											loggedOnHeader
										)}

										<BrandedComp
											brandLogo={accountStore.CompanyLogo}
											reportType={reportType}
											startReport={startReport}
										/>

										<MyFZDReports
											type={reportType}
											reportStep={reportStepObj}
										/>

										<ToggleFitText
											toggle={useMobile}
											compressor={1.63}
											debounce={0}
										>
											<h2 className="speakHeading">Speak to someone</h2>
										</ToggleFitText>
										<ToggleFitText
											toggle={useMobile}
											compressor={2.118}
											debounce={0}
										>
											<p className="contactP">
												If you have any questions, we are available Monday -
												Friday from 9:00am - 5:00pm EST by phone, e-mail, or
												live chat!
											</p>
										</ToggleFitText>
										<div className="contactCards">
											<ContactGroup />
										</div>
									</div>
								</>
							)}

							{isLoggedIn && reportStep > 0 && (
								<>
									{logoHeader}

									<div>
										<ReportRequest
											type={reportType.current}
											reportStep={reportStepObj}
											reportOutcome={reportOutcomeObj}
										/>
									</div>
								</>
							)}
						</ProRadiusBox>
					</div>
				</PageContainer>
			</ProBackground>
		</>
	));
};
