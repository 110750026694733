import styled from "styled-components";
import { Box, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridSmall, GridLarge, homeimage2, LdrImg } from "./Styles";
import MobileHomeBG from "../../Content/Mobile/HomeBgTop.png";

export const useStyles: any = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  rootMargin: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10px",
      marginBottom: "-10px"
    }
  },
  bottomMargin: {
    marginBottom: "50px",
    "@media (max-width: 767px)": {
      marginBottom: "0px"
    }
  },
  blueGradient: {
    backgroundImage:
      "linear-gradient(0deg, rgba(65,144,202,1) 1%, rgba(255,255,255,1) 52%)",
    paddingBottom: "1px"
  },
  homeBottom: {
    flexGrow: 1,
    paddingTop: "30px",
    textAlign: "center"
  },
  titleBlue: {
    color: "#4190CA",
    fontSize: "3.75em",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
      width: "55%",
      margin: "0 auto"
    }
  },
  titleWhite: {
    color: "#FFFFFF",
    fontSize: "3.75em",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
      color: "#4190CA",
      width: "55%",
      margin: "0 auto"
    }
  },
  headingWhite: {
    color: "#FFFFFF",
    fontSize: "3.25em"
  },
  titleBlack: {
    color: "#222120",
    fontSize: "21px",
    fontWeight: 100,
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      width: "68%",
      margin: "0 auto"
    }
  },
  subTitleWhite: {
    color: "#FFFFFF",
    fontSize: "18px"
  },
  paper: {
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperLeft: {
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperRight: {
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  marginTop: {
    marginTop: "40px",
    "@media (max-width: 767px)": {
      marginTop: "20px;"
    }
  },
  rowTools: {
    paddingBottom: "151px",
    "@media (max-width: 767px)": {
      paddingBottom: "50px"
    }
  }
  /*   ,
  caseVariation: {
    fontSize: "48px",
    letterSpacing:  "-1.2px",
    "@media (max-width: 1040px)": {
      fontSize: "32px",
      letterSpacing:  "-0.8px",
      lineHeight: "1.1",
      width: "80px"
    }
  } */
}));

export const HomeMergeToWhite: any = styled.div`
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 56%,
    rgba(255, 255, 255, 0) 99%
  );
  .GrdCntr1 {
    padding-top: 190px;
    padding-bottom: 146px;
    @media (max-width: 767px) {
      padding-top: 53px;
      padding-bottom: 42px;
    }
  }
  .GrdItm1 {
    margin-bottom: 72px;
    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
`;

export const Row826: any = styled(GridSmall)`
  margin: 0 auto;

  .cards {
    @media (max-width: 819px) {
      padding-left: 15%;
      padding-right: 15%;
    }
  }
`;

export const Row1040: any = styled(GridLarge)`
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    margin-top: -10px;
  }
`;

export const BridgeOver: any = styled.h2`
  font-size: 48px;
  letter-spacing: -1.2px;
  @media (max-width: 1040px) {
    font-size: 32px;
    letter-spacing: -0.8px;
    line-height: 1.1;
    width: 225px;
    margin: 50px auto 30px;
  }
`;

export const HowTypo: any = styled.h2`
  width: 100%;
  vertical-align: middle;
  color: #ffffff;
  margin: -20px 0px 20px;
  font-size: 1.7em;
  @media screen and (max-width: 1024px) {
    font-weight: normal;
    img {
      width: 20%;
      margin: 0 5px -5px;
    }
  }
  @media screen and (min-width: 1025px) {
    margin-bottom: 50px;
    font-size: 3.25em;
  }
`;

export const TroubledTypo: any = styled(Typography)`
  margin-bottom: 30px;
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
`;

export const SomeBox: any = styled(LdrImg)`
  display: none;
`;

export const HomeWrap: any = styled(Box)`
  @media screen and (max-width: 1023px) {
    background-image: url(${MobileHomeBG});
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
  }

  @media screen and (max-width: 767px) {
    margin-top: 70px;
  }

  @media screen and (min-width: 960px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

export const HomeBox: any = styled(Box)`
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 767px) {
    margin-top: -70px;
  }

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
`;

export const HomeHeader: any = styled.header`
  margin: 0 auto;
`;

export const AppHeader: any = styled(Box)`
  min-height: calc(100vh - 70px);
  height: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 1040px;
  margin: 0 auto;
  h1 {
    font-size: 72px;
    
  }
  @media screen and (max-width: 767px) {
    min-height: 85vh;
    h1 {
      margin-top: 60px;
      font-size: 32px;
      @media (min-width: 769px) {
        margin-top: -10%scirro;
      }
    }
  }

  .PEWBanner {
		margin: 40px 0px;

		@media (min-width: 769px) {
			display: block;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.PEWBannerMobile {
		margin: 20px 0px;

		@media (min-width: 769px) {
			display: none;
		}

		@media (max-width: 768px) {
			display: block;
			img {
				width: 100%;
			}
		}
	}
`;

export const HomeMiddle: any = styled(Box)`
  text-align: center;
  @media screen and (min-width: 768px) {
    background-image: url(${homeimage2});
    background-position: center -10px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .buckets {
    width: 70%;
  }

  .cards {
    @media (max-width: 809px) {
      width: 335px;
      margin: auto;
    }
  }
`;

export const VideoPlayer: any = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48%;
  @media screen and (min-width: 768px) {
    height: 108vh;
    margin-top: -40px;
  }
  z-index: -1;
  object-fit: cover;
`;
