/*global google*/
// import _clone from "lodash/clone";
import React, { useContext, useEffect } from "react";
/* import { GeocodeResult } from "../../Models"; */
import { makeStyles } from "@material-ui/styles";
import { useObserver } from "mobx-react-lite";
import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";
import MapMarkerSvg from "../../Content/map-pin.svg";
import { Stores, StoresContext } from "../../Stores/Stores";

const image: HTMLImageElement = new Image(56, 74);
image.src = MapMarkerSvg;
const apiKey: string = "AIzaSyCgnFtd2hi5Frhea2jswhZrH2mWZT3IC2Q";
const DefaultZoom: number = 18;

const useStyles: any = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
    width: "100%",
    minHeight: "100%",
    borderBottomRightRadius: "20px",
    borderTopRightRadius: "20px"
  },
  map: {
    overflow: "none",
    width: "1020px",
    minHeight: "634px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px"
  }
}));

export const GoogleMap: React.FC = () => {
  const classes: Record<"root" | "map", string> = useStyles("map");
  const zoom: number = DefaultZoom;
  const mapStoreContext: any = useContext<Stores>(StoresContext).domain
    .MapStore;
  const [lat, setLat] = React.useState<any>(
    mapStoreContext.latitude ? mapStoreContext.latitude : 42.317877
  );
  const [lng, setLng] = React.useState<any>(
    mapStoreContext.longitude ? mapStoreContext.longitude : -71.2376827
  );
  const [latLng, setLatLng] = React.useState<any>(
    new google.maps.LatLng(
      mapStoreContext.latitude ? mapStoreContext.latitude : 42.317877,
      mapStoreContext.longitude ? mapStoreContext.longitude : -71.2376827
    )
  );

  var geocoder: google.maps.Geocoder = new google.maps.Geocoder();

  function handleLatChange(lat: any): void {
    setLat(lat);
  }

  function handleLngChange(lng: any): void {
    setLng(lng);
  }

  function handleOnDragEnd(event: google.maps.MouseEvent): void {
    if (event) {
      // let latitude: number = event ? event.latLng.lat() : 0;
      // let longitude: number = event ? event.latLng.lng() : 0;

      var latLng = new google.maps.LatLng(
        parseFloat(event.latLng.lat().toString()),
        parseFloat(event.latLng.lng().toString())
      );
      setLatLng(latLng);
    }
  }

  useEffect(() => {
    geocoder.geocode({ location: latLng }, function(
      // tslint:disable-next-line: typedef
      results
    ) {
      if (results[0]) {
        const lat: any = results[0].geometry.location.lat();
        const lng: any = results[0].geometry.location.lng();

        /* let result: GeocodeResult = {
          bBox: [],
          center: [lng, lat],
          placeName: results[0].formatted_address,
          placeType: [],
          text: results[0].formatted_address,
          type: "",
          region: "",
          county: ""
        };

        /* results[0].address_components.forEach((addressComponent: any) => {
            if (
              addressComponent.short_name.length === 2 &&
              addressComponent.short_name !== "US"
            ) {
              result.region = addressComponent.short_name;
            }
          });

        let region: string =
          results[0].address_components![4] === undefined
            ? ""
            : results[0].address_components![4].short_name;

        let county: string =
          results[0].address_components![3] === undefined
            ? ""
            : results[0].address_components![3].long_name;

        if (region.length > 2 && region != "US") {
          region = results[0].address_components![5].short_name;
          county = results[0].address_components![4].long_name;
        }

        if (region.length === 2 && region === "US") {
          region = results[0].address_components![3].short_name;
          county = results[0].address_components![2].long_name;
        }

        result.region = region;
        result.county = county;
        mapStoreContext.SetLocation(result); */
        mapStoreContext.SetLatitudeAndLongitude(lat, lng);
        handleLatChange(lat);
        handleLngChange(lng);
      } else {
        window.alert("No results found");
      }
    });
  }, [latLng]);

  var img: google.maps.Icon = {
    url: MapMarkerSvg,
    scaledSize: new google.maps.Size(58, 72)
  };

  return useObserver(() => (
    <div className={classes.root + " mapRoot"}>
      <GoogleMapProvider key="mainmap">
        <MapBox
          apiKey={apiKey}
          className={classes.map + " mobMap"}
          opts={{
            zoom: zoom,
            center: {
              lat: lat,
              lng: lng
            },
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.SATELLITE
          }}
          onCenterChanged={() => {
            // console.log("The center of the map has changed.");
          }}
        />
        <Marker
          id="marker"
          opts={{
            draggable: true,
            position: {
              lat: lat,
              lng: lng
            },
            optimized: false,
            icon: img
          }}
          onDragEnd={handleOnDragEnd}
        />
      </GoogleMapProvider>
    </div>
  ));
};
