import React from "react";
import infoGraphic from "../../Content/7factslandscape.svg";
import infoGraphicMobile from "../../Content/7factsportrait.svg";
import styled from "styled-components";
import { GridLarge } from "../Styles";
import { useMediaQuery } from "../../Shoothill.Core/Utils/Media";

export const InfographicGrid = styled(GridLarge)`
    text-align: center;
    h2{
        font-size: 52px;
        font-weight: 700;
        margin: 20px 0 42px;
        color: #4190ca;
        text-align: center;
        font-family: "Lato", sans-serif;
        letter-spacing: -0.8px;
        padding: 0;     
        line-height: 1.1;   
        @media screen and (min-width: 1024px) {
            /* font-size: 32px; */
            margin: 0 0 42px;
        }
    }        
    @media screen and (max-width: 1024px) {
        h2 {
            margin: 10px 50px 30px;
            font-size: 32px;
        }
        img {
            width: 70%;
        }
    }
`;

export const SevenFactsInfographic: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 1023px)")
      ? true
      : false;
    return (
        <>
        <InfographicGrid>
            <h2>{!isMobile ? "Seven" : "7"} Facts Everyone<br /> Should Know About Flood Risk</h2>
            <img 
                src={!isMobile ? infoGraphic : infoGraphicMobile} 
                title={"Seven Facts Everyone Should Know About Flood Risk"} 
                alt={"Seven Facts Everyone Should Know About Flood Risk"}
            />
        </InfographicGrid>
        </>
    );
};
