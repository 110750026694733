import { BaseStore } from "./BaseStore";
import { Category } from "../../Models";
import { observable, action, computed } from "mobx";
import { Stores } from "./../Stores";

export class CategoryStore extends BaseStore {
    @observable public categories: Category[] = [];

    public constructor() {
        super();
    }

    // tslint:disable-next-line: no-empty
    public init(stores: Stores): void {}

    @action
    public SetCategories(categories: Category[]): void {
        this.categories = categories;
    }

    @action
    public AddCategory(category: Category): void {
        this.categories.push(category);
    }

    @action GetCategoryName(categoryId: string): string {
        const category: Category | undefined = this.categories.find(c => c.id === categoryId);
        return category ? category!.name : "";
    }

    @computed get GetCategories(): Category[] {
        var filteredCategories: Category[] = [];

        this.categories.map(category => {
            if (category.isDeleted === false) {
                filteredCategories.push(category);
            }
        });

        return filteredCategories;
    }
}
