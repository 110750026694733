import React from "react";
import { useMediaQuery } from "@material-ui/core";

import financialConsiderations from "../../Content/iconFinancalConsiderations.svg";
import optionalCoverage from "../../Content/iconOptionalCoverages.svg";
import insurancePolicy from "../../Content/icon-Policy.svg";

import iconCross from "../../Content/iconCross.svg";
import iconTick from "../../Content/iconTick.svg";

import { YourOptions } from "./RowKnowOptionsStyling";

export const RowKnowOptions: React.FC = () => {
    let useMobileLayout: boolean;
    useMobileLayout = useMediaQuery("screen and (max-width: 1023px)")
      ? true
      : false;

    return (
        <>
        <YourOptions>
            <div className="wrapper">
                <div className="inner">
                    <img src={insurancePolicy} alt="Policy Conditions" className={"icon"} />
                    <h2><span>Policy Conditions</span></h2>
                    <p>The general requirements for the insurer and the policyholder under the policy contract</p>
                </div>
            </div>
            <ul>
                <li className="heading">
                    <span className="option">Policy Conditions</span>
                    <span>NFIP</span>
                    <span>Private</span>
                </li>
                <li>
                    <span>Waiting Period</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }30 days</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }0 - 15 days</span>
                </li>
                <li>
                    <span>Available during government shutdown</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }<img src={iconTick} alt="available" /></span>
                </li>
                <li>
                    <span>Government reauthorization required</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconTick} alt="available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }<img src={iconCross} alt="not available" /></span>
                </li>
                <li>
                    <span>NFIP Non-participating communities</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }Available</span>
                </li>
                <li>
                    <span>Coastal barrier resource areas</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }Available</span>
                </li>
                <li>
                    <span>Guaranteed renewal</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconTick} alt="available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }<img src={iconCross} alt="not available" /></span>
                </li>
                <li>
                    <span>Elevation certification required</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }Some zones</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }Not Usually</span>
                </li>
            </ul>
        </YourOptions>

        <YourOptions>
            <div className="wrapper">
                <div className="inner">
                    <img src={financialConsiderations} alt="Financial Considerations" className={"icon"} />
                    <h2><span>Financial Considerations</span></h2>
                    <p>Coverage limits, premiums, and claim payment information</p>
                </div>
            </div>
            <ul>
                <li className="heading">
                    <span className="option">Financial Considerations</span>
                    <span>NFIP</span>
                    <span>Private</span>
                </li>
                <li>
                    <span>Residential coverage maximum</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }$250k/$100k*</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }$1 Mill +</span>
                </li>
                <li>
                    <span>Commercial coverage maximum</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }$500k</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }$1 Mill +</span>
                </li>
                <li>
                    <span>Occupancy surcharge</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }$25-$250</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }<img src={iconCross} alt="not available" /></span>
                </li>
                <li>
                    <span>Rate stability</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconTick} alt="available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }<img src={iconCross} alt="not available" /></span>
                </li>
                <li>
                    <span>Claims paid by</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }FEMA</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }Insurer</span>
                </li>
                <li>
                    <span>Premiums</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }Varies</span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }Varies</span>
                </li>
                <li className="white">
                    <span className="comment">*$250k for building, $100k for contents</span>
                    <span></span>
                    <span></span>
                </li>
            </ul>
        </YourOptions>

        <YourOptions>
            <div className="wrapper">
                <div className="inner">
                    <img src={optionalCoverage} alt="Optional Coverage" className={"icon"} />
                    <h2><span>Optional Coverages</span></h2>
                    <p>Additional policy coverages that may be offered by private flood carriers</p>
                </div>
            </div>
            <ul>
                <li className="heading">
                    <span className="option">Optional Coverages</span>
                    <span>NFIP</span>
                    <span>Private</span>
                </li>
                <li>
                    <span>Replacement Cost for Non-Primary Homes</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }RC available</span>
                </li>
                <li>
                    <span>Replacement Cost For Contents</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }RC available</span>
                </li>
                <li>
                    <span>Enhanced Detached Structures Coverage</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }available</span>
                </li>
                <li>
                    <span>Additional Living Expense Business</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private</h3></> }available</span>
                </li>
                <li>
                    <span>Business Income Coverage</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }available</span>
                </li>
                <li>
                    <span>Pool Repair & Fill Coverage</span>
                    <span>{ useMobileLayout && <><h3>NFIP:</h3></> }<img src={iconCross} alt="not available" /></span>
                    <span>{ useMobileLayout && <><h3>Private:</h3></> }available</span>
                </li>
            </ul>
        </YourOptions>
        </>
    );
};