import React from "react";
import { Box, Button } from "@material-ui/core";
import styled from "styled-components";
import { useMediaQuery } from "Shoothill.Core/Utils/Media";

import iconContactByPhone from "../../Content/icon-contact-byPhone-invert.svg";
import iconContactByChat from "../../Content/icon-contact-viaChat-invert.svg";
import iconContactByEmail from "../../Content/icon-contact-byEmail-invert.svg";

export const ContactButton: any = styled(Button)`
	width: 320px !important;
	height: 95px !important;
	background: white !important;
	border: solid 4px #4190ca !important;
	border-radius: 10px;
	display: block;
	margin: auto;

	span {
		display: flex;
		justify-content: flex-start;

		span {
			margin: auto;
			flex-direction: column;
		}
	}

	img {
		padding-left: 12px;
		display: block;
		margin: 0;
	}

	h5 {
		color: #4190ca;
		margin: 0;
		font-size: 28px;
		font-weight: bold;
		line-height: 30px;
	}
	p {
		color: #222120;
		margin: 0;
		font-size: 18px;
		line-height: 21px;
		font-weight: bold !important;
	}

	.MuiTouchRipple-root {
		display: none;
	}
`;

declare var purechatApi: any;

export const ContactGroup: React.FC = () => {
	return (
		<>
			<ContactButton style={{ cursor: "default" }}>
				<img src={iconContactByPhone} alt="" />
				<span>
					<h5 className="full">By phone</h5>
					<p>(833) 723-7322</p>
				</span>
			</ContactButton>
			<ContactButton onClick={() => purechatApi.set("chatbox.expanded", true)}>
				<img src={iconContactByChat} alt="" />
				<span>
					<h5>Via Chat</h5>
					<p>Chat now</p>
				</span>
			</ContactButton>
			<ContactButton
				onClick={() =>
					(window.location.href =
						"mailto:info@myfloodrisk.org?subject=Web Enquiry from Pro Account user")
				}
			>
				<img src={iconContactByEmail} alt="" />
				<span>
					<h5>By email</h5>
					<p>info@myfloodrisk.org</p>
				</span>
			</ContactButton>
		</>
	);
};
