import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import * as MUI from "@material-ui/core";
import { GenericIncludeDeleted, GenericIncludeDeletedWithId } from "../Models";
import { Stores, StoresContext } from "../Stores";
import { useObserver } from "mobx-react-lite";
/* import { useMediaQuery } from "../Shoothill.Core/Utils/Media"; */
import Typography from "@material-ui/core/Typography";
import { Grid /* , Box */ } from "@material-ui/core";
import { ArticleListItem } from "../Shoothill.Components/Articles";
import { LargeWhiteFormControl } from "../Components/Controls/Forms/LargeWhiteFormControl";
import validator from "validator";
import * as AppUrls from "../Globals/AppUrls";
import { useValidation, UseDataApi } from "../Shoothill.Core/Utils";
import { ReferenceLibraryFormModel } from "../Models/FormModels/ReferenceLibraryFormModel";
/* import { FilterBox } from "../Shoothill.Components/Styles"; */
import ReactGA from "react-ga";
/* import Masonry from "react-masonry-css"; */

import {
    RefFilterBox,
    useRefLibStyles
} from "../Shoothill.Components/Styles/RefLibStyles";
import {
    BRFormBox,
    BtnGrid,
    AppHeader,
    Row1040,
    Row1076,
    MasonryCustom,
    LeftBox,
    RightBox
} from "../Shoothill.Components/Styles/BlogRefStyles";

export const ReferenceLibrary: React.FC = () => {
    const classes: any = useRefLibStyles("contact");
    const [isSent, SetIsSent] = useState(false);

/*     let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 1023px)") ? true : false; */

    const [
        formState,
        { text, /* checkbox, select, password, */ email }
    ] = useValidation<ReferenceLibraryFormModel>(
        new ReferenceLibraryFormModel()
    );
    const { data, isLoading, /* isError, */ doRequest } = UseDataApi();

    ReactGA.pageview(window.location.pathname + window.location.search);

    useEffect(() => {
        if (data === 200) {
            SetIsSent(true);
            formState.wipeFormData();
        }
    }, [data]);

    const onSubmit: (e: any)=> void = (e: any): void => {
        e.preventDefault();
        if (formState.isFormValid()) {
            doRequest(
                AppUrls.Server.Api.Inquiry.PostReferenceLibraryFormAsync,
                formState.formData
            );
        }
    };

    const { data: categoryData, doRequest: doCategoryRequest } = UseDataApi();
    const { data: blogData, doRequest: doBlogRequest } = UseDataApi();
    const categoriesStoreContext: any = useContext<Stores>(StoresContext).domain.CategoryStore;
    const blogPostStoreContext: any = useContext<Stores>(StoresContext).domain.BlogPostStore;

    useEffect(() => {
        if (categoriesStoreContext.categories.length === 0) {
            const includeGenericDeleted: GenericIncludeDeleted = {
                includeDeleted: true
            };

            doCategoryRequest("/api/Category/GetAll", includeGenericDeleted);
        }
    }, [categoryData]);

    useEffect(() => {
        if (categoryData !== null) {
            if (categoryData.wasSuccessful) {
                categoriesStoreContext.SetCategories(categoryData.payload);
            }
        }
    }, [categoryData]);

    useEffect(() => {
        if (blogPostStoreContext.articles.length === 0) {
            const includeGenericDeletedWithId: GenericIncludeDeletedWithId = {
                id: undefined,
                isBlog: false,
                includeDeleted: true
            };

            doBlogRequest("/api/BlogPost/GetAll", includeGenericDeletedWithId);
        }
    }, [blogData]);

    useEffect(() => {
        if (blogData !== null) {
            if (blogData.wasSuccessful) {
                blogPostStoreContext.SetArticles(blogData.payload);
            }
        }
    }, [blogData]);

    useEffect(() => {
        blogPostStoreContext.ClearFilters();
    }, []);

    function handleChange(event: any): void {
        blogPostStoreContext.SetActiveCategory(event.target.value);
    }

/*     function handleFilterChange(event: any): void {
        blogPostStoreContext.SetActiveFilter(event.target.value);
    }

    function handlePageSizeChange(event: any): void {
        if (event.target.value > 0) {
            blogPostStoreContext.SetActivePageSize(event.target.value);
        } else {
            blogPostStoreContext.SetActivePageSize(10000);
        }
    } */

    function handleLoadMore(): void {
        blogPostStoreContext.SetActiveBlogCount();
    }

    function showLoadMore(): boolean {
        return (
            blogPostStoreContext.articles.length >
            blogPostStoreContext.activeBlogCount
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breakpointColumnsMasonry: any = {
        default: 3,
        1000: 2,
        600: 1
    };

    return useObserver(() => (
        <>
            <Helmet>
                <title>Reference Library - Advocacy, Awareness, and Education
                 Regarding Flood Risk | My Flood Risk</title>
                <meta name="description" content="My Flood Risk focuses on advocacy, awareness, and education
                 regarding flood risk throughout the United States. Our reference library features articles about
                 FEMA, NFIP reform, economic and environmental concerns, flood mitigation, and flood insurance." />
            </Helmet>
            <div className={classes.library}>
                <AppHeader>
                    <Typography
                        variant="h1"
                        className={classes.libraryHeadingWhite}
                    >
                        Reference Library
                    </Typography>
                    <Typography className={classes.headingWhite}>
                        Knowledge is power.
                    </Typography>
                </AppHeader>
            </div>
            <div className={classes.libraryBottom}>
                <Row1040 container>
                    <Grid item xs={12} md={6}>
                        <RefFilterBox textAlign={"left"}>
                            <Typography
                                id="header"
                                className={classes.headingBlue34}
                            >
                                Article category
                            </Typography>
                            <MUI.Select
                                className={classes.categoryFieldDisplay}
                                name="Category"
                                value={blogPostStoreContext.activeCategory}
                                onChange={handleChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MUI.MenuItem
                                    key={"all"}
                                    value={"Show all articles"}
                                >
                                    {"Show all articles"}
                                </MUI.MenuItem>
                                {categoriesStoreContext.GetCategories.map(
                                    (category: any) => {
                                        return (
                                            <MUI.MenuItem
                                                key={category.id}
                                                value={category.id}
                                            >
                                                {category.name}
                                            </MUI.MenuItem>
                                        );
                                    }
                                )}
                            </MUI.Select>
                        </RefFilterBox>
                    </Grid>
                </Row1040>
                <Row1076 container>
                    <MUI.GridList className={classes.gridList}>
                        <MasonryCustom
                            breakpointCols={breakpointColumnsMasonry}
                            columnClassName="my-masonry-grid_column"
                        >
                            {blogPostStoreContext.GetArticles.map((blogPost: any) => (
                                <ArticleListItem
                                    id={blogPost.id}
                                    title={blogPost.name}
                                    category={categoriesStoreContext.GetCategoryName(
                                        blogPost.categoryId!
                                    )}
                                    excerpt={blogPost.description}
                                    publishDate={blogPost.startDate}
                                    link={blogPost.linkUrl}
                                    linkText={"Read more"}
                                    imagePath={blogPost.desktopBtnImageUrl}
                                    key={
                                        blogPost.tabletImageURL +
                                        "-" +
                                        blogPost.id
                                    }
                                    isBlog={false}
                                />
                            ))}
                        </MasonryCustom>
                    </MUI.GridList>
                </Row1076>

                <div className={classes.whiteToBlueGrad}>
                    {showLoadMore() && (
                        <Row1040 container>
                            <Grid item xs={12}>
                                <MUI.Button
                                    variant="outlined"
                                    className={classes.loadMoreBtn}
                                    onClick={handleLoadMore}
                                >
                                    Load more
                                </MUI.Button>
                            </Grid>
                        </Row1040>
                    )}

                    <Row1040 container>
                        <Grid container>
                            <Grid item xs={12}>
                                <BRFormBox textAlign="center" width={1}>
                                    <MUI.Box
                                        mt={"42px"}
                                        ml={"48px"}
                                        mr={"48px"}
                                        textAlign="center"
                                        className={classes.headingBlue}
                                    >
                                        We'd love to hear from you!
                                    </MUI.Box>
                                    <MUI.Box
                                        mt={3}
                                        ml={"48px"}
                                        mr={"48px"}
                                        textAlign="center"
                                        className={classes.subHeadingGrey}
                                    >
                                        Have an article, research paper or other
                                        interesting work you'd like to share?
                                    </MUI.Box>
                                    <MUI.Box
                                        mt={3}
                                        ml={"48px"}
                                        mr={"48px"}
                                        textAlign="center"
                                        className={classes.subHeadingGrey}
                                    >
                                        Interested in becoming a contributing
                                        editor for MyFloodRisk?
                                    </MUI.Box>

                                    <form
                                        className={classes.form}
                                        onSubmit={onSubmit}
                                    >
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <LeftBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        {formState.showErrors(
                                                            "firstName",
                                                            classes.error
                                                        )}
                                                        <MUI.TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="fname"
                                                            placeholder="What is your first name? (required)"
                                                            {...text(
                                                                "firstName",
                                                                {
                                                                    validationRules: [
                                                                        (
                                                                            v: string
                                                                        ) =>
                                                                            (v &&
                                                                                v.length >
                                                                                    0) ||
                                                                            "* Your first name is required"
                                                                    ]
                                                                }
                                                            )}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </LeftBox>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6}>
                                                <RightBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        {formState.showErrors(
                                                            "lastName",
                                                            classes.error
                                                        )}
                                                        <MUI.TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="lastName"
                                                            placeholder="What is your last name? (required)"
                                                            {...text(
                                                                "lastName",
                                                                {
                                                                    validationRules: [
                                                                        (
                                                                            v: string
                                                                        ) =>
                                                                            (v &&
                                                                                v.length >
                                                                                    0) ||
                                                                            "* Your last name is required"
                                                                    ]
                                                                }
                                                            )}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </RightBox>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6}>
                                                <LeftBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        <MUI.TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="phone"
                                                            placeholder="Your phone number"
                                                            {...text(
                                                                "phoneNumber"
                                                            )}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </LeftBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <RightBox textAlign="center">
                                                    <LargeWhiteFormControl>
                                                        {formState.showErrors(
                                                            "emailAddress",
                                                            classes.error
                                                        )}
                                                        <MUI.TextField
                                                            label=""
                                                            fullWidth
                                                            autoComplete="email"
                                                            placeholder="Your email address (required)"
                                                            {...email(
                                                                "emailAddress",
                                                                {
                                                                    validationRules: [
                                                                        (
                                                                            v: string
                                                                        ) =>
                                                                            validator.isEmail(
                                                                                v
                                                                            ) ||
                                                                            "* Email address is invalid",
                                                                        (
                                                                            v: string
                                                                        ) =>
                                                                            (v &&
                                                                                v.length >
                                                                                    0) ||
                                                                            "* Email address is required"
                                                                    ]
                                                                }
                                                            )}
                                                        />
                                                    </LargeWhiteFormControl>
                                                </RightBox>
                                            </Grid>

                                            {isSent && (
                                                <div
                                                    className={classes.width100}
                                                >
                                                    <p
                                                        className={
                                                            classes.successSentTxt
                                                        }
                                                    >
                                                        We have received your
                                                        details. A member of our
                                                        team will be in touch
                                                        with you shortly.
                                                        Thank you.
                                                    </p>
                                                </div>
                                            )}

                                            <BtnGrid>
                                                <MUI.Box
                                                    mt={3}
                                                    mb={3}
                                                    textAlign="center"
                                                >
                                                    <MUI.Button
                                                        variant="outlined"
                                                        className={
                                                            classes.contactBtn
                                                        }
                                                        type="submit"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading
                                                            ? "Sending.."
                                                            : "Send"}
                                                    </MUI.Button>
                                                </MUI.Box>
                                            </BtnGrid>
                                        </Grid>
                                    </form>
                                </BRFormBox>
                            </Grid>
                        </Grid>
                    </Row1040>
                </div>
            </div>
        </>
    ));
};
