import React, { useState, useContext, useEffect /* , useRef */ } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import * as ReactRouterDom from "react-router-dom";

import {
	LoginEmbed,
	LoginModel,
	ForgotPasswordResetForm
} from "../../Shoothill.Components/Account";
import FitText from "@kennethormandy/react-fittext";
import homepageLogo from "../../Content/myfloodprologo.svg";
import {
	ProBackground,
	PageContainer,
	ProRadiusBox
} from "../../Shoothill.Components/Styles/ProAccStyling";
import {
	UseDataApi,
	/* 	usePercentageHeightOfWidth,
	isNullorWhiteSpace, */
	UseRouter
} from "../../Shoothill.Core/Utils";
import { Link } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { AccountStore } from "../../Stores/Domain/AccountStores";
import { StoresContext, Stores } from "../../Stores";
import { ApiResult } from "../../Models";
import { forgotPasswordModel } from "../../Models/FormModels/forgotPasswordModel";
import { useObserver } from "mobx-react-lite";
import ToggleFitText from "Shoothill.Components/General/ToggleFitText";

export interface IMatchParams {
	email?: string;
	token1?: string;
	token2?: string;
}

export const SetPasswordComplete: React.FC<ReactRouterDom.RouteComponentProps<
	IMatchParams
>> = props => {
	const [tokensPresent, setTokensPresent] = useState(false);
	const [outcomeDone, setOutcomeDone] = useState(false);
	const { history } = UseRouter();

	// Tokens present?
	useEffect(() => {
		if (
			props.match.params.token1 !== undefined &&
			props.match.params.token2 !== undefined
		) {
			setTokensPresent(true);
		} else {
			history.push("/pro");
		}
	});

	let token1: string =
		props.match.params.token1 !== undefined ? props.match.params.token1 : "";
	let token2: string =
		props.match.params.token2 !== undefined ? props.match.params.token2 : "";
	let email: string =
		props.match.params.email !== undefined ? props.match.params.email : "";

	// Shouldn't be already logged in but lets check
	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain
		.AccountStore;
	let isLoggedIn: boolean = accountStore.IsLoggedIn;

	const [errorMessage, setErrorMessage] = useState("");

	// Submit updated stuff to API
	const { data: userSet, doRequest: userRequest } = UseDataApi();
	const handlePostback: (forgotpasswordModel: forgotPasswordModel) => void = (
		forgotpasswordModel: forgotPasswordModel
	): void => {
		userRequest("/api/account/forgotpasswordreset", forgotpasswordModel);
	};
	useEffect(() => {
		if (userSet !== null) {
			setOutcomeDone(true);
		}
	}, [userSet, outcomeDone]);

	// Login Stuff
	const {
		data: loggedin,
		doRequest: doLoginRequest,
		isLoading: loginLoader
	} = UseDataApi<ApiResult>();
	const handleLogin: (loginModel: LoginModel) => void = (
		loginModel: LoginModel
	): void => {
		doLoginRequest("/api/account/login", loginModel);
	};
	useEffect(() => {
		if (isLoggedIn) {
			history.push("/pro");
		}
	}, [isLoggedIn, loggedin]);

	useEffect(() => {
		const checkLoginReturn: () => any = (): any => {
			if (loggedin !== null) {
				if (loggedin.wasSuccessful) {
					accountStore.getLoginState(loggedin.payload);
				} else {
					if (loggedin.errors.length > 0) {
						setErrorMessage("* " + loggedin.errors[0].message);
					} else {
						setErrorMessage("* Unknown error has occurred.");
					}
				}
			}
		};
		checkLoginReturn();
	}, [loggedin]);

	let useMobile: boolean = useMediaQuery("screen and (max-width: 1140px)")
		? true
		: false;

	const logoHeader = (
		<>
			<Link component={RouterLink} to="/Pro" className={"Logo"}>
				<img src={homepageLogo} alt="MyFlood Risk Pro" />
			</Link>
		</>
	);

	return useObserver(() => (
		<>
			<Helmet>
				<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
				<title>My Flood Risk Pro</title>
			</Helmet>
			<ProBackground className="mui-fixed">
				<PageContainer pageHeightIE={"580"}>
					<div className="mobMargins">
						<ProRadiusBox
							color="#464545"
							background={"rgba(230,246,255,0.7)"}
							radius="30px"
							mg="auto"
							pd={"30px"}
							mobdesignheight={"557"}
							minheight={507}
						>
							{logoHeader}
							{tokensPresent && !outcomeDone && (
								<>
									<ForgotPasswordResetForm
										onSubmit={handlePostback}
										token1={token1}
										token2={token2}
										UserEmail={email}
									/>
								</>
							)}
							{tokensPresent && outcomeDone && (
								<>
									<div className="loginSignup">
										<div className={"PlaceHolderPart"}>
											<FitText compressor={/* 1.2 */ 1.14} debounce={0}>
												<h2 className={"regSuccess"}>
													{userSet && userSet.wasSuccessful ? (
														<>
															Success, please login to
															<br />
															<br />
															<span>Myfloodrisk Pro</span>
														</>
													) : (
														<>{userSet && userSet.errors[0].message}</>
													)}
												</h2>
											</FitText>
										</div>
										<div className={"PlaceHolderPart"}>
											<ToggleFitText
												toggle={useMobile}
												compressor={1.14}
												debounce={0}
											>
												<h2>
													Login to <span>Myfloodrisk Pro</span>
												</h2>
											</ToggleFitText>
											<LoginEmbed
												onSubmit={handleLogin}
												errorMessage={errorMessage}
												isLoading={loginLoader}
											/>
										</div>
									</div>
								</>
							)}
						</ProRadiusBox>
					</div>
				</PageContainer>
			</ProBackground>
		</>
	));
};
