import { BaseStore } from "./BaseStore";
import { HurricaneSummaryModel } from "../../Models";
import { observable, action, computed } from "mobx";
import { Stores } from "../Stores";

export class HurricaneSummaryStore extends BaseStore {
	@observable public HurricaneSummary: HurricaneSummaryModel | undefined;
	@observable public HurricaneSummaryArr: HurricaneSummaryModel[] = [];

	@observable public decade: number = 0;
	@observable public hurricaneQty: number = 0;

	public constructor() {
		super();
	}

	// tslint:disable-next-line: no-empty
	public init(stores: Stores): void {}

	@action populate(store: any): void {
		this.decade = store.decade;
		this.hurricaneQty = store.hurricaneQty;
	}

	@action
	public SetHurricaneSummaryResult(
		HurricaneSummary: HurricaneSummaryModel
	): void {
		this.HurricaneSummary = HurricaneSummary;

		if (HurricaneSummary) {
			this.decade = HurricaneSummary.decade;
			this.hurricaneQty = HurricaneSummary.hurricaneQty;
		}
	}

	@action
	public SetHurricaneSummaryResults(
		HurricaneSummary: HurricaneSummaryModel[]
	): void {
		this.HurricaneSummaryArr = HurricaneSummary;
	}

	@action
	public ResetHurricaneSummaryArr(): void {
		this.HurricaneSummaryArr = [];
	}

	@action
	public ResetHurricaneSummary(): void {
		this.decade = 0;
		this.hurricaneQty = 0;
	}

	@computed get GetHurricanesSummary(): HurricaneSummaryModel[] {
		var hurricaneArr: HurricaneSummaryModel[] = [];

		this.HurricaneSummaryArr.forEach(HurricaneSummaryModel => {
			hurricaneArr.push(HurricaneSummaryModel);
		});

		return hurricaneArr;
	}
}
