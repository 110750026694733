import { FloodLossModel } from "../Models";

export const CalculateFloodLoss = (
    costOfProperty: number,
    depth: number,
    dollarsPerSquareFoot: number,
    costOfPossessions: number
) => {
    const squareFootage: number = costOfProperty / dollarsPerSquareFoot;
    let contentValue: number = costOfPossessions;
    let totalLoss: number = 0;

    let d1k: number = 0;
    let c1k: number = 0;
    let d2k: number = 0;
    let c2k: number = 0;

    switch (true) {
        case depth < 1:
            d1k = 0;
            c1k = 0;
            d2k = 0;
            c2k = 0;
            break;
        case depth === 1:
            d1k = 9550;
            c1k = 1050;
            d2k = 18940;
            c2k = 1980;
            break;
        case depth === 2:
            d1k = 9620;
            c1k = 1050;
            d2k = 19020;
            c2k = 1980;
            break;
        case depth === 3:
            d1k = 9820;
            c1k = 1630;
            d2k = 19520;
            c2k = 3070;
            break;
        case depth === 4:
            d1k = 12730;
            c1k = 2420;
            d2k = 25140;
            c2k = 4510;
            break;
        case depth === 5:
            d1k = 12780;
            c1k = 4530;
            d2k = 25210;
            c2k = 8660;
            break;
        case depth === 6:
            d1k = 15300;
            c1k = 4850;
            d2k = 29940;
            c2k = 9210;
            break;
        case depth <= 12:
            d1k = 15300 + (1250 / 6) * (depth - 6);
            c1k = 4850 + (5750 / 6) * (depth - 6);
            d2k = 29940 + (2140 / 6) * (depth - 6);
            c2k = 9210 + (10930 / 6) * (depth - 6);
            break;
        case depth <= 24:
            d1k = 16550 + (2950 / 12) * (depth - 12);
            c1k = 10600 + (3600 / 12) * (depth - 12);
            d2k = 32080 + (3970 / 12) * (depth - 12);
            c2k = 20140 + (6690 / 12) * (depth - 12);
            break;
        case depth <= 36:
            d1k = 19500 + (1600 / 12) * (depth - 24);
            c1k = 14200 + (1300 / 12) * (depth - 24);
            d2k = 36050 + (2920 / 12) * (depth - 24);
            c2k = 26830 + (2300 / 12) * (depth - 24);
            break;
        case depth <= 48:
            d1k = 21100 + (2300 / 12) * (depth - 36);
            c1k = 15500 + (1050 / 12) * (depth - 36);
            d2k = 38970 + (4400 / 12) * (depth - 36);
            c2k = 29130 + (2080 / 12) * (depth - 36);
            break;
        default:
            d1k = 23400;
            c1k = 16550;
            d2k = 43370;
            c2k = 31210;
            break;
    }

    let dwellingLoss: number =
        d1k + ((d2k - d1k) / 1000) * (squareFootage - 1000);
    let contentBaseLoss: number =
        c1k + ((c2k - c1k) / 1000) * (squareFootage - 1000);
    let maxContent: number = 16550 + 14.66 * (squareFootage - 1000);
    let contentLoss: number = contentBaseLoss * (contentValue / maxContent);
    let dlprem: number = 0;
    let ctprem: number = 0;
    let prem: number = 0;

    totalLoss = dwellingLoss + contentLoss;

    switch (true) {
        case dwellingLoss <= 20000:
            dlprem = 15;
            break;
        case dwellingLoss <= 30000:
            dlprem = 17;
            break;
        case dwellingLoss <= 50000:
            dlprem = 22;
            break;
        case dwellingLoss <= 75000:
            dlprem = 25;
            break;
        case dwellingLoss <= 100000:
            dlprem = 28;
            break;
        case dwellingLoss <= 125000:
            dlprem = 29;
            break;
        case dwellingLoss <= 150000:
            dlprem = 31;
            break;
        case dwellingLoss <= 200000:
            dlprem = 34;
            break;
        default:
            dlprem = 36;
            break;
    }

    switch (true) {
        case contentLoss <= 8000:
            ctprem = 15;
            break;
        case contentLoss <= 12000:
            ctprem = 17;
            break;
        case contentLoss <= 20000:
            ctprem = 22;
            break;
        case contentLoss <= 30000:
            ctprem = 25;
            break;
        case contentLoss <= 40000:
            ctprem = 28;
            break;
        case contentLoss <= 50000:
            ctprem = 29;
            break;
        case contentLoss <= 60000:
            ctprem = 31;
            break;
        case contentLoss <= 80000:
            ctprem = 34;
            break;
        default:
            ctprem = 36;
            break;
    }

    prem = dlprem > ctprem ? dlprem : ctprem;

    //let totalLossFormatted = FormatMoney(totalLoss, 0, "", ",");
    //let dwellingLossFormatted = FormatMoney(dwellingLoss, 0, "", ",");
    //let possessionsLossFormatted = FormatMoney(contentLoss, 0, "", ",");
    //let premiumFormatted = FormatMoney(prem, 0, "", ",");

    const floodLoss: FloodLossModel = {
        totalLossFormatted: totalLoss,
        dwellingLossFormatted: dwellingLoss,
        possessionsLossFormatted: contentLoss,
        premiumFormatted: prem
    };

    return floodLoss;
};
