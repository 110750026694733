import styled from "styled-components";

export const TopDiv: any = styled.div`
	background-color: #005792;
	border-top-left-radius: 18px;
	border-top-right-radius: 18px;
	margin: 0 -60px;
	padding: 40px;

	.quote-icon {
		padding: 20px;
		margin-right: 15px;
	}

	svg {
		height: 100%;
		width: 80px;
	}

	h2 {
		font-size: 32px;
		color: white;

		span {
			color: #f8974a;
		}

		@media (max-width: 767px) {
			padding: 20px;
			margin: 0;
			text-align: center;
			font-size: 18px;
		}
	}
`;

export const BottomDiv: any = styled.div`
	padding: 60px 0;

	h3 {
		font-size: 28px;
		margin: 0;

		@media (max-width: 767px) {
			font-size: 16px;
		}
	}

	p {
		font-weight: normal;
		font-size: 12px;
		margin: 0;

		:first-of-type {
			font-size: 18px;
			margin: 40px 0 50px;

			@media (max-width: 767px) {
				font-size: 14px;
			}
		}
	}
`;
