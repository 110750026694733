import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Link, Modal } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import floodRiskLogo from "../../Content/myfloodRiskLogo.svg";
import iconFooterEmail from "../../Content/icon-footer-email.svg";
import instagramIcon from "../../Content/instagramIcon.svg";
import twitterIcon from "../../Content/twitterIcon.svg";
import facebookIcon from "../../Content/facebookIcon.svg";
/* import iconfooterupArrow from "../../Content/icon-footer-upArrow.svg"; */

import { Box } from "@material-ui/core";
import styled from "styled-components";
import { Stores, StoresContext, DomainStores } from "../../Stores";
import {
    GenericAcceptButton,
    GenericCancelButton
} from "../../Shoothill.Components/Styles";

import {
    PrivacyModalBox,
    TermsModalBox
} from "../../Shoothill.Components/Styles/FooterStyles";

const useStyles: any = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#00002A",
        color: "#FFFFFF",
        /* height: "251px", */
        "@media (min-width: 1040px)": {
            height: "241px"
        }
    },
    logo: {
        width: "157px",
        "@media (min-width: 728px)": {
            marginBottom: "44px"
        }
    },
    socialalignment: {
        textAlign: "right"
    },
    socialicons: {
        width: "30px",
        height: "30px",
        margin: "10px"
    },
    copyrightMsg: {
        "& a": {
            paddingLeft: "10px",
            paddingRight: "10px"
        }
    }
}));

export const FooterBox: any = styled(Box)`
    padding: 2px;
    text-align: center;
    color: #ffffff;
    padding-top: 30px;
`;

export const SocialMediaBox: any = styled(FooterBox)`
    padding-top: 10px;
    @media (min-width: 728px) {
        padding-top: 30px;
    }
`;

export const CopyMsgBox: any = styled(SocialMediaBox)`
    font-size: 12px;
    font-weight: 100;
    .spacer {
        padding-left: 10px;
        padding-right: 10px;
    }
    .smallDevice {
        display: block;
    }
    .mediumDevice {
        display: none;
    }
    @media (min-width: 728px) {
        font-size: 18px;
        font-weight: 700;
        .smallDevice {
            display: none;
        }
        .mediumDevice {
            display: block;
        }
    }
`;

export const BackToTopGrid: any = styled(Grid)`
    position: relative;
    text-align: center;
    padding: 0;
    margin: 0;
    button {
        position: absolute;
        top: -26px;
        left: 56%;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        img {
            padding: 0;
            margin: 0;
            margin-left: -44px;
            width: 50px;
        }
        @media (min-width: 728px) {
            top: -46px;
            left: unset;
            img {
                width: 88px;
            }
        }
    }
`;

/* function handleToTopAction(): void {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
} */

export const Footer: React.FC = () => {
    /* const dudUrl: string = "javascript:;"; */
    const classes: any = useStyles("footer");
    const globalStoresContext: DomainStores["GlobalStore"] = useContext<Stores>(
        StoresContext
    ).domain.GlobalStore;

    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    const today: Date = new Date();

    function handleHideTerms(): void {
        globalStoresContext.SetIsTermsModalOpen(false);
        setShowTerms(false);
    }

    function handleHidePrivacy(): void {
        globalStoresContext.SetIsPrivacyModalOpen(false);
        setShowPrivacy(false);
    }
/*
    function handleShowTerms(): void {
        globalStoresContext.SetIsTermsModalOpen(true);
        setShowTerms(true);
    }

    function handleShowPrivacy(): void {
        globalStoresContext.SetIsPrivacyModalOpen(true);
        setShowPrivacy(true);
    } */

    const doSubmitTerms: (e: any) => void = (e: any): void => {
        e.preventDefault();
        globalStoresContext.SetIsTermsChecked(true);
        globalStoresContext.SetIsTermsModalOpen(false);
        setShowTerms(false);
    };

    const doSubmitPrivacy: (e: any) => void = (e: any): void => {
        e.preventDefault();
        globalStoresContext.SetIsPrivacyModalOpen(false);
        setShowPrivacy(false);
    };

    return useObserver(() => (
        <footer className={classes.root}>
            <Grid container>
                {/*                 <BackToTopGrid item xs={12}>
                    <button onClick={handleToTopAction}>
                        <img src={iconfooterupArrow} alt={"Return to top"} />
                    </button>
                </BackToTopGrid> */}
                <Grid item xs={12} sm={4}>
                    <FooterBox>
                        <img
                            src={floodRiskLogo}
                            className={classes.logo}
                            alt={"My Flood Risk"}
                        />
                    </FooterBox>
                </Grid>
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={4}>
                    <SocialMediaBox>
                        <Link
                            component={RouterLink}
                            to="/contact#contactform"
                            title="Contact us via email"
                            target="_self"
                        >
                            <img
                                src={iconFooterEmail}
                                className={classes.socialicons}
                                alt={"Contact us via email"}
                            />
                        </Link>
                        <Link
                            href="https://www.instagram.com/myfloodrisk1/"
                            title="Follow us on Instragram"
                            target="_blank"
                        >
                            <img
                                src={instagramIcon}
                                className={classes.socialicons}
                                alt={"Follow us on Instagram"}
                            />
                        </Link>
                        <Link
                            href="https://twitter.com/myfloodrisk"
                            title="Follow us on twitter"
                            target="_blank"
                        >
                            <img
                                src={twitterIcon}
                                className={classes.socialicons}
                                alt={"Follow us on Twitter"}
                            />
                        </Link>
                        <Link
                            href="https://Facebook.com/myfloodrisk"
                            title="Like us on Facebook"
                            target="_blank"
                        >
                            <img
                                src={facebookIcon}
                                className={classes.socialicons}
                                alt={"Like us on Facebook"}
                            />
                        </Link>
                    </SocialMediaBox>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <CopyMsgBox mb="25px">
                        <Typography
                            color="inherit"
                            display="inline"
                            className={classes.copyrightMsg}
                        >
                            <span className="mediumDevice">
                                <span className="spacer">
                                    &copy;{moment(today).format("YYYY")} AB Risk
                                    Corporation Inc
                                </span>
                                |
                                <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to="/terms-of-use"
                                >
                                    Terms of Use
                                </Link>
                                |
                                <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to="/methodology"
                                >
                                    Methodology
                                </Link>
                                |
                                <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to="/privacy-policy"
                                >
                                    Privacy Policy
                                </Link>
                            </span>
                            <span className="smallDevice">
                                &copy;{moment(today).format("YYYY")} AB Risk
                                Corporation Inc
                                <br />
                                <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to="/terms-of-use"
                                >
                                    Terms of Use
                                </Link>
                                |
                                <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to="/methodology"
                                >
                                    Methodology
                                </Link>
                                |
                                <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to="/privacy-policy"
                                >
                                    Privacy Policy
                                </Link>
                            </span>
                        </Typography>
                    </CopyMsgBox>
                </Grid>
            </Grid>
            <Modal open={showTerms} onClose={handleHideTerms}>
                <TermsModalBox>
                    <form onSubmit={doSubmitTerms}>
                        <div className="termsmodal-wrapper">
                            <div className="termsmodal-title">
                                <span className="termsmodal-title-text">
                                    AB Risk Corporation's Terms of Use
                                </span>
                            </div>
                            <div className="termsmodal-text">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Fusce blandit tristique nulla
                                quis efficitur. Nulla congue mattis mi, vitae
                                commodo neque porta in. Quisque tempor molestie
                                tincidunt. Sed quis ultrices lectus. Donec et
                                fringilla arcu, at posuere dolor. Duis aliquet
                                massa faucibus erat laoreet rhoncus. Nullam
                                commodo lacus eu leo commodo, sit amet pretium
                                lectus fringilla. Nam euismod, arcu vitae
                                posuere vestibulum, odio libero faucibus ligula,
                                sed viverra quam quam ac velit. Interdum et
                                malesuada fames ac ante ipsum primis in
                                faucibus. Quisque dignissim justo lectus, a
                                fermentum augue tempus vel. Lorem ipsum dolor
                                sit amet, consectetur adipiscing elit. Fusce
                                blandit tristique nulla quis efficitur. Nulla
                                congue mattis mi, vitae commodo neque porta in.
                                Quisque tempor molestie tincidunt. Sed quis
                                ultrices lectus. Donec et fringilla arcu, at
                                posuere dolor. Duis aliquet massa faucibus erat
                                laoreet rhoncus. Nullam commodo lacus eu leo
                                commodo, sit amet pretium lectus fringilla. Nam
                                euismod, arcu vitae posuere vestibulum, odio
                                libero faucibus ligula, sed viverra quam quam ac
                                velit. Interdum et malesuada fames ac ante ipsum
                                primis in faucibus. Quisque dignissim justo
                                lectus, a fermentum augue tempus vel.
                            </div>
                        </div>
                        <div className="button-row">
                            <GenericAcceptButton onClick={doSubmitTerms}>
                                Ok
                            </GenericAcceptButton>
                            <GenericCancelButton onClick={handleHideTerms}>
                                Cancel
                            </GenericCancelButton>
                        </div>
                    </form>
                </TermsModalBox>
            </Modal>
            <Modal open={showPrivacy} onClose={handleHidePrivacy}>
                <PrivacyModalBox>
                    <form onSubmit={doSubmitPrivacy}>
                        <div className="privacymodal-wrapper">
                            <div className="privacymodal-title">
                                <span className="privacymodal-title-text">
                                    AB Risk Corporation's Privacy Policy
                                </span>
                            </div>
                            <div className="privacymodal-text">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Fusce blandit tristique nulla
                                quis efficitur. Nulla congue mattis mi, vitae
                                commodo neque porta in. Quisque tempor molestie
                                tincidunt. Sed quis ultrices lectus. Donec et
                                fringilla arcu, at posuere dolor. Duis aliquet
                                massa faucibus erat laoreet rhoncus. Nullam
                                commodo lacus eu leo commodo, sit amet pretium
                                lectus fringilla. Nam euismod, arcu vitae
                                posuere vestibulum, odio libero faucibus ligula,
                                sed viverra quam quam ac velit. Interdum et
                                malesuada fames ac ante ipsum primis in
                                faucibus. Quisque dignissim justo lectus, a
                                fermentum augue tempus vel. Lorem ipsum dolor
                                sit amet, consectetur adipiscing elit. Fusce
                                blandit tristique nulla quis efficitur. Nulla
                                congue mattis mi, vitae commodo neque porta in.
                                Quisque tempor molestie tincidunt. Sed quis
                                ultrices lectus. Donec et fringilla arcu, at
                                posuere dolor. Duis aliquet massa faucibus erat
                                laoreet rhoncus. Nullam commodo lacus eu leo
                                commodo, sit amet pretium lectus fringilla. Nam
                                euismod, arcu vitae posuere vestibulum, odio
                                libero faucibus ligula, sed viverra quam quam ac
                                velit. Interdum et malesuada fames ac ante ipsum
                                primis in faucibus. Quisque dignissim justo
                                lectus, a fermentum augue tempus vel.
                            </div>
                        </div>
                        <div className="button-row">
                            <GenericAcceptButton onClick={doSubmitPrivacy}>
                                Ok
                            </GenericAcceptButton>
                            <GenericCancelButton onClick={handleHidePrivacy}>
                                Cancel
                            </GenericCancelButton>
                        </div>
                    </form>
                </PrivacyModalBox>
            </Modal>
        </footer>
    ));
};
